import React, { useCallback, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import { Box } from '@chakra-ui/core'
import { useSelector } from 'react-redux'
import { RootState } from 'constants/interfaces'
import { useDatePickerLocale } from 'hooks/useDatePickerLocale'

interface DatePickerComponentProps {
  isOpen: boolean
  onClose: () => void
  selectedDate: Date | null
  handleChange: (date: Date | null) => void
  minDate: Date
}

export const DeadlinePickerComponent: React.FC<DatePickerComponentProps> = ({
  isOpen,
  onClose,
  selectedDate,
  handleChange,
  minDate,
}) => {
  const locale = useSelector((state: RootState) => state.general.locale)
  const datePickerLocale = useDatePickerLocale(locale)

  const scrollToLastSubtask = useCallback(() => {
    const scrollContainer = document.querySelector('[data-scroll-container]') as HTMLDivElement
    if (!scrollContainer) return

    scrollContainer.scrollTo({
      top: scrollContainer.scrollHeight,
      behavior: 'smooth',
    })
  }, [])

  useEffect(() => {
    if (isOpen) {
      scrollToLastSubtask()
    }
  }, [isOpen, scrollToLastSubtask])

  return isOpen ? (
    <Box zIndex={9999} pos="absolute" top="80px" w="65%" left="50%" transform="translateX(-50%)">
      <DatePicker
        open
        selected={selectedDate}
        onChange={handleChange}
        dateFormat="dd/MM/yy HH:mm"
        timeFormat="HH:mm"
        locale={datePickerLocale}
        showMonthDropdown
        showYearDropdown
        minDate={minDate}
        inline
        onClickOutside={onClose}
      />
    </Box>
  ) : null
}
