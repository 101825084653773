import React from 'react'
import { Input, InputGroup, InputLeftElement, InputRightElement, InputProps } from '@chakra-ui/core'
import { useSelector } from 'react-redux'
import { isRtlSelector } from 'redux/selectors/general'

interface InputWithIcon extends InputProps {
  icon?: React.ReactNode
  elementProps?: any
}

export const InputWithIcon: React.FC<InputWithIcon> = ({ icon, elementProps, ...props }) => {
  const isRtl = useSelector(isRtlSelector)

  if (!icon) {
    return <Input {...props} />
  }

  return (
    <InputGroup>
      <Input {...props} pl={isRtl ? '35px' : '10px'} pr={isRtl ? '10px' : '35px'} />
      {isRtl ? (
        <InputLeftElement {...elementProps}>{icon}</InputLeftElement>
      ) : (
        <InputRightElement {...elementProps}>{icon}</InputRightElement>
      )}
    </InputGroup>
  )
}
