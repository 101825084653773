import React, { useEffect, useRef } from 'react'
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody } from '@chakra-ui/core'
import { CustomModalClose } from 'components/Elements'
import { css } from '@emotion/core'

const scaleUpAnimation = css`
  @keyframes scaleUp {
    from {
      transform: scale(0.8);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }

  animation: scaleUp 0.3s forwards;
`

interface IProps {
  isOpen: boolean
  onClose: () => void
  bodyContent?: React.ReactChild
  headerContent?: React.ReactChild
  footerContent?: React.ReactChild
  hideCloseButton?: boolean
  closeOnEsc?: boolean
  borderRadius?: string
  px?: string
  overflowY?: string
  backGroundModal?: string
  backGroundModalContent?: string
  closeOnOverlayClick?: boolean
  top?: string
  isShowAnimation?: boolean
  height?: string
  footerJustifyment?: string
  display?: string
  minWidth?: string
  headerPaddingTop?: string
  heightOffset?: number
  headerShadow?: string
  maxWidth?: string
}

const BaseModal = ({
  isOpen,
  borderRadius = '15px',
  minWidth = '400px',
  px = '0',
  top,
  onClose,
  headerContent,
  bodyContent,
  footerContent,
  closeOnEsc,
  hideCloseButton = false,
  overflowY = 'auto',
  footerJustifyment = 'flex-end',
  backGroundModal,
  backGroundModalContent = 'white',
  closeOnOverlayClick = false,
  isShowAnimation = false,
  height = 'auto',
  display = 'block',
  headerPaddingTop = '1rem',
  heightOffset = 410,
  headerShadow = 'none',
  maxWidth = '700px',
}: IProps) => {
  const bodyRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleResize = () => {
      if (bodyRef.current) {
        bodyRef.current.style.maxHeight = `${window.innerHeight - heightOffset}px`
      }
    }

    window.addEventListener('resize', handleResize)

    // Set initial maxHeight
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [heightOffset])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={closeOnOverlayClick}
      closeOnEsc={closeOnEsc}
      isCentered
    >
      <ModalOverlay background={backGroundModal} zIndex={3500} />
      <ModalContent
        css={isShowAnimation ? scaleUpAnimation : undefined}
        minW={minWidth}
        maxWidth={maxWidth}
        borderRadius={borderRadius}
        bg={backGroundModalContent}
        px={px}
        top={top}
        height={height}
        zIndex={9999}
        display={display}
        pos="static"
      >
        {headerContent && (
          <ModalHeader textAlign="center" boxShadow={headerShadow} pt={headerPaddingTop}>
            {headerContent}
            {!hideCloseButton && <CustomModalClose onClick={onClose} />}
          </ModalHeader>
        )}
        {bodyContent && (
          <ModalBody ref={bodyRef} maxHeight={`${window.innerHeight - heightOffset}px`} p="0" overflowY={'auto'}>
            {bodyContent}
          </ModalBody>
        )}
        {footerContent && <ModalFooter justifyContent={footerJustifyment}>{footerContent}</ModalFooter>}
      </ModalContent>
    </Modal>
  )
}

export default BaseModal
