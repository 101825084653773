import React, { useState } from 'react'
import { Subtask } from 'constants/interfaces'
import { useResults } from 'components/TasksV2/hooks/useResults'
import { MediaSubtaskCompleting } from './MediaSubtaskCompleting'
import { v4 as uuidv4 } from 'uuid'

interface IProps {
  subtask: Subtask
  isReadMode: boolean
  resultContextId: string
  retail_id_context_id: string
  recurrence_ts: string | null
}

const ModalMediaSection = ({ subtask, isReadMode, resultContextId, retail_id_context_id, recurrence_ts }: IProps) => {
  const { type, tid } = subtask
  const subTaskResult = useResults({ subtask, resultContextId })

  const subtaskWithUuid = {
    st_id: subtask.st_id,
    type: subtask.type,
    status: 0,
    title: subtask.title,
    context_id: resultContextId,
    uuid: uuidv4(),
  }
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0)

  const onMediaChange = (index: number) => {
    setCurrentMediaIndex(index)
  }

  return (
    <>
      <MediaSubtaskCompleting
        images={subTaskResult?.images ?? []}
        videos={subTaskResult?.videos ?? []}
        subtask={subtaskWithUuid}
        retail_id_context_id={retail_id_context_id}
        recurrence_ts={recurrence_ts}
        resultContextId={resultContextId}
        isReadMode={isReadMode}
        mediaType={type}
        onMediaChange={onMediaChange}
        currentMediaIndex={currentMediaIndex}
        updatedBy={subTaskResult?.updated_by ?? ''}
        tid={tid}
      />
    </>
  )
}

export default ModalMediaSection
