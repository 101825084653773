import React from 'react'
import { FrequencyType } from 'components/NewsFeed/PollTaskForm/utils'
import { DailySelector } from './DailySelector'
import { WeeklySelector } from './WeeklySelector'
import { useFormContext } from 'react-hook-form'
import { MonthlyRangePicker } from './MonthlySelector'
import { WidgetWrapper } from 'components/TasksV2/UI/WidgetWrapper'
import { Box, Flex, Text } from '@chakra-ui/core'
import TimeInput from './TimeInput'
import CustomTimePicker from 'components/CommonComponents/CutomTimePicker'
import useStartDatePicker from '../StartDateWidget/useStartDatePicker'
import useLocaleText from 'components/useLocaleText'
import { useFormStepContext } from 'components/TasksV2/TaskCreation/FormStepContext'

const FrequencyDatesPicker = () => {
  const { watch, errors } = useFormContext()
  const { isEdit } = useFormStepContext()
  const recurrenceFrequency: number | null = watch('recurrence_frequency')
  const isRecurring: boolean = watch('is_recurring')
  const recc_task_time = useLocaleText('recc_task_time')

  const { handleTimeChange, startTime } = useStartDatePicker()

  const isError = !!errors?.days_of_week || !!errors?.frequency_time || !!errors?.monthly_range

  if (!isRecurring) {
    return null
  }

  return (
    <WidgetWrapper
      p="20px"
      borderRadius="20px"
      isError={isError}
      pointerEvents={isEdit ? 'none' : 'auto'}
      opacity={isEdit ? 0.5 : 1}
    >
      {recurrenceFrequency === FrequencyType.DAY && <DailySelector />}
      {recurrenceFrequency === FrequencyType.WEEK && <WeeklySelector />}
      {recurrenceFrequency === FrequencyType.MONTH && <MonthlyRangePicker />}

      <Box>
        {
          <Text pb="5px" fontSize="12px">
            {recc_task_time}
          </Text>
        }
        <Flex alignItems="center" style={{ columnGap: '10px' }}>
          <WidgetWrapper display={isRecurring ? 'flex' : 'none'}>
            <CustomTimePicker value={startTime} onChange={handleTimeChange} />
          </WidgetWrapper>
          {'-'}
          <Box display={isRecurring ? 'block' : 'none'}>
            <TimeInput />
          </Box>
        </Flex>
      </Box>
    </WidgetWrapper>
  )
}

export default FrequencyDatesPicker
