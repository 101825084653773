// components/StartDatePicker.tsx
import React from 'react'
import { Box, Flex } from '@chakra-ui/core'
import 'react-datepicker/dist/react-datepicker.css'
import { WidgetWrapper } from 'components/TasksV2/UI/WidgetWrapper'
import useLocaleText from 'components/useLocaleText'
import { ErrorMessage } from 'components/TasksV2/UI/ErrorMessage'
import { Controller, useFormContext } from 'react-hook-form'
import useStartDatePicker from './useStartDatePicker'
import { DateToLocaleValue } from '../../../../UI/DateToLocaleValue'
import { StartDatePickerComponent } from './StartDatePickerComponent'
import { FormInputLabel } from 'components/TasksV2/UI/FormInputLabel'
import CustomTimePicker from 'components/CommonComponents/CutomTimePicker'
import { useFormStepContext } from 'components/TasksV2/TaskCreation/FormStepContext'

const StartDatePicker: React.FC = () => {
  const { control, errors, watch } = useFormContext()
  const { isEdit } = useFormStepContext()
  const t_start_date = useLocaleText('t_start_date')
  const isRecurring = watch('is_recurring')

  const {
    isDatePickerOpen,
    handleOpenDatePicker,
    handleCloseDatePicker,
    handleDateChange,
    handleTimeChange,
    startDate,
    startTime,
  } = useStartDatePicker()

  const minDate = new Date()

  return (
    <Box pos="relative" pointerEvents={isEdit ? 'none' : 'auto'} opacity={isEdit ? 0.5 : 1}>
      <FormInputLabel label={t_start_date} />

      <Controller
        name="from_ts"
        control={control}
        key="from_ts"
        rules={{ required: 'Start time is required' }}
        as={
          <StartDatePickerComponent
            isOpen={isDatePickerOpen}
            onClose={handleCloseDatePicker}
            selectedDate={new Date(startDate)}
            handleDateChange={handleDateChange}
            minDate={minDate}
          />
        }
      />
      <Flex alignItems="center" style={{ columnGap: '15px' }}>
        <WidgetWrapper
          p="10px"
          isError={!!errors?.from_ts}
          onClick={handleOpenDatePicker}
          cursor="pointer"
          alignItems="center"
          justifyContent="space-between"
          flexDir="row"
        >
          <DateToLocaleValue
            minW="180px"
            textAlign="center"
            date={new Date(startDate)}
            placeholder={t_start_date}
            format="DD MMMM YYYY"
          />
        </WidgetWrapper>
        <WidgetWrapper display={isRecurring ? 'none' : 'flex'} isError={!!errors?.from_ts}>
          <CustomTimePicker value={startTime} onChange={handleTimeChange} />
        </WidgetWrapper>
      </Flex>

      {errors?.from_ts && (
        <Box px="10px">
          <ErrorMessage errorMessage={errors.from_ts.message || 'Start date is required'} />
        </Box>
      )}
    </Box>
  )
}

export default StartDatePicker
