import React from 'react'
import { Box } from '@chakra-ui/core'
import { css } from '@emotion/core'
import FileIcon from 'components/CommonComponents/JSXIcons/FileIcon'

interface FileUploadButtonProps {
  onClick: () => void
}

export const FileUploadButton: React.FC<FileUploadButtonProps> = ({ onClick }) => {
  return (
    <Box
      css={css`
        & > * {
          transition: transform 0.3s ease, margin 0.3s ease;
        }
        & > *:hover {
          transform: scale(1.2);
        }
      `}
    >
      <FileIcon
        bgColor="black"
        iconColor="white"
        iconWidth={15}
        iconHeight={15}
        width="25px"
        height="25px"
        cursor="pointer"
        onClick={onClick}
      />
    </Box>
  )
}
