import React, { useEffect } from 'react'
import { Box, FormControl } from '@chakra-ui/core'
import { Controller, useFormContext } from 'react-hook-form'
import { useMediaState } from 'hooks/useMediaState'
import MediaGallery from '../../../../../UI/MediaGallery'
import HiddenFileInput from '../../../../../UI/HiddenInut'
import { WidgetWrapper } from 'components/TasksV2/UI/WidgetWrapper'
import useLocaleText from 'components/useLocaleText'
import { useFormStepContext } from 'components/TasksV2/TaskCreation/FormStepContext'
import WidgetHeader from 'components/TasksV2/UI/WidgetHeader'
import { Content } from 'components/TasksV2/TaskCreation/FormStepContext'
import DescriptionTextarea from '../../Description/DescriptionTextarea'
import { AddMediaButtonts } from '../../Description/AddMediaButtonts'

interface DescriptionWidgetProps {
  index: number
  isDragging: boolean
  field: Content
  subtaskPath: string
  remove: () => void
}

const DescriptionWidget: React.FC<DescriptionWidgetProps> = ({ index, isDragging, field, subtaskPath, remove }) => {
  const { getValues, setValue, control } = useFormContext()
  const { setIsMediaUploading, isEdit } = useFormStepContext()
  const t_say_something = useLocaleText('t_say_something')

  const {
    uploadedImagesURL,
    uploadedVideosURL,
    uploadedFilesURL,
    handleMediaUploading,
    handleDeleteMedia,
    deleteFileHandler,
    handleFileUpload: uploadFile,
    imageInputRef,
    fileInputRef,
    isUploadLoading,
  } = useMediaState({
    images: getValues(`${subtaskPath}.images`) || [],
    videos: getValues(`${subtaskPath}.videos`) || [],
    files: getValues(`${subtaskPath}.files`) || [],
  })

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const data = await handleMediaUploading(event)
    if (!data || (!data.uploadedImages.length && !data.uploadedVideos.length)) {
      return
    }

    if (data.uploadedImages.length) {
      const newImages = [...uploadedImagesURL, ...data.uploadedImages]
      setValue(`${subtaskPath}.images`, newImages, true)
    }
    if (data.uploadedVideos.length) {
      const newVideos = [...uploadedVideosURL, ...data.uploadedVideos]
      setValue(`${subtaskPath}.videos`, newVideos, true)
    }
  }

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const data = await uploadFile(event)
    if (!data || !data.fileName || !data.fileUrl) {
      return
    }
    const newFiles = [...uploadedFilesURL, data.fileUrl]
    setValue(`${subtaskPath}.files`, newFiles, true)
  }

  const handleRemoveMedia = (index: number) => {
    const { filteredImages, filteredVideos } = handleDeleteMedia(index)
    setValue(`${subtaskPath}.videos`, filteredVideos)
    setValue(`${subtaskPath}.images`, filteredImages)
  }

  const handleRemoveFile = (url: string) => {
    const updatedFiles = deleteFileHandler(url)
    setValue(`${subtaskPath}.files`, updatedFiles)
  }

  const handleMediaUploadClick = () => {
    imageInputRef.current?.click()
  }

  const handleFileUploadClick = () => {
    fileInputRef.current?.click()
  }
  useEffect(() => {
    setValue(`${subtaskPath}.index_ref`, index)
  }, [index, setValue, subtaskPath])

  useEffect(() => {
    setIsMediaUploading(isUploadLoading)
  }, [isUploadLoading, setIsMediaUploading])

  return (
    <WidgetWrapper pos="relative" p="15px" isError={false} isDragging={isDragging}>
      {!isEdit && <WidgetHeader onRemove={remove} subtaskType={field.type} />}

      <FormControl mb={4} w="100%">
        <Controller
          name={`${subtaskPath}.images`}
          control={control}
          defaultValue={field.images || []}
          as={<Box display="none" />}
        />
        <Controller
          name={`${subtaskPath}.videos`}
          control={control}
          defaultValue={field.videos || []}
          as={<Box display="none" />}
        />
        <Controller
          name={`${subtaskPath}.files`}
          control={control}
          defaultValue={field.files || []}
          as={<Box display="none" />}
        />
        <Controller
          name={`${subtaskPath}.type`}
          control={control}
          defaultValue={field.type}
          as={<Box display="none" />}
        />
        <Controller
          name={`${subtaskPath}.index_ref`}
          control={control}
          defaultValue={index}
          as={<Box display="none" />}
        />

        <Controller
          name={`${subtaskPath}.text`}
          control={control}
          as={DescriptionTextarea}
          value={getValues(`${subtaskPath}.text`) as string}
          onChange={([event]) => event.target.value}
          onBlur={([event]) => event.target.value}
          onImageUploadClick={handleMediaUploadClick}
          onFileUploadClick={handleFileUploadClick}
          placeholder={t_say_something}
        />

        <MediaGallery
          attachedImages={uploadedImagesURL}
          attachedFiles={uploadedFilesURL}
          attachedVideos={uploadedVideosURL}
          onRemoveMedia={handleRemoveMedia}
          onRemoveFile={handleRemoveFile}
          isUploadLoading={isUploadLoading}
        />

        <AddMediaButtonts onFileUploadClick={handleFileUploadClick} onImageUploadClick={handleMediaUploadClick} />

        <HiddenFileInput accept="image/*, video/*" multiple onChange={handleImageUpload} ref={imageInputRef} />
        <HiddenFileInput accept="*/*" onChange={handleFileUpload} ref={fileInputRef} />
      </FormControl>
    </WidgetWrapper>
  )
}

export default DescriptionWidget
