// MediaGallery.tsx
import React, { memo, useState } from 'react'
import { Box, Image, Link, Portal, Text } from '@chakra-ui/core'
import { FiFile } from 'react-icons/fi'
import { extractFileName, getFileExtension, openInCloudViewer } from 'utils'
import { RenoveItemIcon } from 'components/TasksV2/UI/RenoveItemIcon'
import { isVideo } from 'components/CommonComponents/MediaViewer/utils'
import FsLightbox from 'fslightbox-react'
import strings from 'constants/strings'
import { useSelector } from 'react-redux'
import { isRtlSelector } from 'redux/selectors/general'

interface MediaGalleryProps {
  attachedImages: string[]
  attachedFiles: string[]
  attachedVideos: string[]
  isUploadLoading?: boolean
  isShowRemoveIcon?: boolean
  onRemoveMedia?: (index: number) => void
  onRemoveFile?: (url: string) => void
}

const MediaGallery: React.FC<MediaGalleryProps> = memo(
  ({
    attachedImages,
    attachedFiles,
    attachedVideos,
    onRemoveMedia,
    onRemoveFile,
    isUploadLoading = false,
    isShowRemoveIcon = true,
  }) => {
    const isRtl = useSelector(isRtlSelector)
    const [isOpenLightBox, setIsOpenLightBox] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(0)

    if (attachedImages.length === 0 && attachedFiles.length === 0 && attachedVideos.length === 0) {
      return null
    }

    const openViewer = (index: number) => {
      setCurrentIndex(index)
      setIsOpenLightBox((prev) => !prev)
    }
    const media = [...attachedImages, ...attachedVideos]

    const mediaTypes = media?.map((el) => {
      const fileExt = getFileExtension(el)
      return strings.SERVER_VIDEO_FORMATS.includes(fileExt) ? 'video' : 'image'
    })

    const isSingleMedia = media.length === 1

    return (
      <>
        <Box
          overflowX="auto"
          mt="8px"
          pb="10px"
          style={{ gap: '15px' }}
          display={isSingleMedia && !isShowRemoveIcon ? 'flex' : '-webkit-inline-box'}
          justifyContent={isSingleMedia && !isShowRemoveIcon ? 'center' : 'unset'}
        >
          {isSingleMedia && !isShowRemoveIcon ? (
            <Box
              position="relative"
              width="70%"
              minW="300px"
              height="300px"
              borderRadius="10px"
              overflow="hidden"
              cursor="pointer"
            >
              <>
                {isVideo(media[0]) ? (
                  <video
                    src={media[0]}
                    controls={isSingleMedia && !isShowRemoveIcon}
                    onClick={() => openViewer(0)}
                    style={{ objectFit: 'none', width: '100%', height: '100%' }}
                  />
                ) : (
                  <Image
                    src={media[0]}
                    width="100%"
                    height="100%"
                    alt={`Uploaded ${0}`}
                    objectFit="cover"
                    onClick={() => openViewer(0)}
                  />
                )}
              </>
            </Box>
          ) : (
            <>
              {media.map((url, index) => {
                return (
                  <Box
                    key={index}
                    position="relative"
                    width="100px"
                    height="100px"
                    borderRadius="10px"
                    overflow="hidden"
                    cursor="pointer"
                  >
                    {!isVideo(url) ? (
                      <Image
                        src={url}
                        width="100%"
                        height="100%"
                        alt={`Uploaded ${index}`}
                        objectFit="cover"
                        onClick={() => openViewer(index)}
                      />
                    ) : (
                      <video
                        src={url}
                        onClick={() => openViewer(index)}
                        style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                      />
                    )}

                    {isShowRemoveIcon && (
                      <RenoveItemIcon
                        aria-label="Remove File"
                        onClick={() => onRemoveMedia?.(index)}
                        pos="absolute"
                        right={isRtl ? 'unset' : '4px'}
                        left={isRtl ? '4px' : 'unset'}
                      />
                    )}
                  </Box>
                )
              })}
            </>
          )}

          {attachedFiles.map((fileUrl, index) => (
            <Box key={index} position="relative">
              {isUploadLoading ? (
                <Image
                  src={fileUrl}
                  alt={`Uploaded ${index}`}
                  width="100px"
                  height="100px"
                  objectFit="cover"
                  borderRadius="10px"
                />
              ) : (
                <Link
                  display="flex"
                  flexDir="column"
                  width="100px"
                  bg="#d9d9d9"
                  height="100px"
                  alignItems="center"
                  justifyContent="space-evenly"
                  borderRadius="10px"
                  cursor="pointer"
                  href={openInCloudViewer(fileUrl)}
                  target="_blank"
                  rel="noopener noreferrer"
                  textDecor="none"
                  color="black"
                  _hover={{ textDecoration: 'none', color: 'black' }}
                  _focus={{ boxShadow: 'none', outline: 'none', color: 'black', textDecoration: 'none' }}
                >
                  <FiFile size={40} />
                  <Text maxW="80px" fontSize="12px" isTruncated>
                    {extractFileName(fileUrl)}
                  </Text>
                </Link>
              )}
              {isShowRemoveIcon && (
                <RenoveItemIcon
                  aria-label="Remove File"
                  onClick={() => onRemoveFile?.(fileUrl)}
                  pos="absolute"
                  right={isRtl ? 'unset' : '4px'}
                  left={isRtl ? '4px' : 'unset'}
                />
              )}
            </Box>
          ))}
        </Box>

        <Portal container={document.getElementById('root')}>
          <FsLightbox
            key={JSON.stringify(media)}
            toggler={isOpenLightBox}
            sources={media}
            sourceIndex={currentIndex}
            types={mediaTypes}
          />
        </Portal>
      </>
    )
  }
)

export default MediaGallery
