import { useEffect, useState } from 'react'
import { Locale } from 'date-fns'
import { loadDatePickerLocale } from 'components/TasksV2/TaskCreation/Steps/TimeFrameSelection/loadDatePickerLocale'

export const useDatePickerLocale = (locale: string): Locale | undefined => {
  const [datePickerLocale, setDatePickerLocale] = useState<Locale>()

  useEffect(() => {
    const loadLocale = async () => {
      const loadedLocale = await loadDatePickerLocale(locale)
      setDatePickerLocale(loadedLocale)
    }

    loadLocale()
  }, [locale])

  return datePickerLocale
}
