import { Box, Text } from '@chakra-ui/core'
import LocaleText from 'components/LocaleText'
import { RootState } from 'constants/interfaces'
import React, { memo, useMemo } from 'react'
import { useSelector } from 'react-redux'

interface DayCircleProps {
  day: string
  isSelected: boolean
  isInRange?: boolean
  onClick: () => void
}

export const DayCircle: React.FC<DayCircleProps> = memo(({ day, isSelected, onClick, isInRange = false }) => {
  const theme = useSelector((state: RootState) => state?.general?.theme)

  const bgColor = useMemo(() => {
    if (isSelected) return theme?.elementsColor
    if (isInRange) return '#dadada'
    return 'transparent'
  }, [isInRange, isSelected, theme])
  return (
    <Box
      w="40px"
      h="40px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderRadius="full"
      cursor="pointer"
      border="1px solid #dadada"
      bg={bgColor}
      color={isSelected ? 'white' : 'black'}
      onClick={onClick}
      fontWeight="bold"
    >
      <Text>
        <LocaleText text={day} />
      </Text>
    </Box>
  )
})
