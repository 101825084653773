import { Text } from '@chakra-ui/core'
import React from 'react'

interface Props {
  errorMessage: string
}
export const ErrorMessage: React.FC<Props> = ({ errorMessage }) => {
  return (
    <Text as="span" fontSize="14px" color="#EA9999">
      * {errorMessage}
    </Text>
  )
}
