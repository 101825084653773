import React from 'react'
import { Box, Flex, Text, BoxProps } from '@chakra-ui/core'
import { useSelector } from 'react-redux'
import { isRtlSelector } from 'redux/selectors/general'

interface ToggleSwitchProps {
  isChecked: boolean
  onChange: () => void
  fontSize?: string
  className?: string
  checkedLabel?: string
  uncheckedLabel?: string
  trackProps?: BoxProps
  thumbProps?: BoxProps
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  isChecked,
  onChange,
  fontSize = '14px',
  className = '',
  checkedLabel = 'On',
  uncheckedLabel = 'Off',
  trackProps = {},
  thumbProps = {},
}) => {
  const isRtl = useSelector(isRtlSelector)
  const thumbPosition = isChecked ? (isRtl ? '1%' : '49%') : isRtl ? '49%' : '1%'

  return (
    <Flex
      style={{ direction: 'ltr' }}
      position="relative"
      alignItems="center"
      borderRadius="full"
      width="100%"
      height="40px"
      className={className}
      cursor="pointer"
      onClick={onChange}
      {...trackProps}
    >
      <Box
        position="absolute"
        top="2.5px"
        left={thumbPosition}
        width="50%"
        height="calc(100% - 5px)"
        borderRadius="full"
        transition="left 0.3s ease"
        {...thumbProps}
      />

      <Flex flex="1" justifyContent="center" alignItems="center" zIndex={1}>
        <Text
          fontSize={fontSize}
          textAlign="center"
          fontWeight={!isChecked ? 'bold' : 'normal'}
          color={trackProps.color || '#718096'}
        >
          {isRtl ? checkedLabel : uncheckedLabel}
        </Text>
      </Flex>
      <Flex flex="1" justifyContent="center" alignItems="center" zIndex={1}>
        <Text
          fontSize={fontSize}
          textAlign="center"
          fontWeight={isChecked ? 'bold' : 'normal'}
          color={trackProps.color || '#718096'}
        >
          {isRtl ? uncheckedLabel : checkedLabel}
        </Text>
      </Flex>
    </Flex>
  )
}

export default ToggleSwitch
