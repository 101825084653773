import React, { useMemo } from 'react'
import { Avatar } from '@chakra-ui/core'
import { useSelector } from 'react-redux'
import { RootState } from 'constants/interfaces'
import { PopType } from './utils'

interface IProps {
  profile_image_url: string
  pop_initiator?: number
  pop_type: number
  created_by?: string
  width?: string
  height?: string
  top?: string
  left?: string
}
export default function PopAvatar({
  profile_image_url,
  pop_initiator,
  pop_type,
  created_by,
  width = '190px',
  height = '190px',
  top = '-85px',
  left = '50%',
}: IProps) {
  const { retail_users } = useSelector((state: RootState) => state.config.config)
  const uid = useSelector((state: RootState) => state.auth.uid)

  const profileImage = useMemo(() => {
    if (pop_type === PopType.FAKE_POP) {
      const user = retail_users?.find((user) => uid === user.uid)
      const profileImage = user?.profile_img_url
      const avatarImage = user?.avatar_profile_img_url
      return avatarImage ? avatarImage : profileImage
    }
    if (profile_image_url) {
      return profile_image_url
    } else if (pop_initiator === 1 && created_by) {
      return retail_users?.find((user) => user.uid === created_by)?.profile_img_url
    }
  }, [created_by, pop_initiator, pop_type, profile_image_url, retail_users, uid])

  return (
    <>
      {profileImage ? (
        <Avatar
          style={{
            position: 'absolute',
            top: top,
            width: width,
            height: height,
            left: left,
            transform: 'translatex(-50%)',
          }}
          src={profileImage}
        />
      ) : (
        <Avatar
          bg="#f1f1f1"
          style={{ position: 'absolute', top: top, left: left, transform: 'translatex(-50%)' }}
          w={width}
          h={height}
        />
      )}
    </>
  )
}
