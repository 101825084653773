import React, { memo } from 'react'
import { WidgetWrapper } from 'components/TasksV2/UI/WidgetWrapper'
import PollQuestion from './PollQuestion'
import PollOptions from './PollOptions'
import { Controller, useFormContext } from 'react-hook-form'
import { Box } from '@chakra-ui/core'
import WidgetHeader from 'components/TasksV2/UI/WidgetHeader'
import { useWidgetErrorMessage } from '../../useWidgetErrorMessage'
import { WidgetProps } from '../types'
import { WidgetIsRequiredSwitch } from '../WidgetIsRequiredSwitch'

const PollWidget: React.FC<WidgetProps> = memo(({ index, isDragging, field, remove, subtaskPath }) => {
  const { control, errors } = useFormContext()

  const optionsPath = `${subtaskPath}.options`

  const pollError = useWidgetErrorMessage({ errors, index, field })

  return (
    <WidgetWrapper pos="relative" p="10px" isDragging={isDragging} isError={!!pollError} errorMessage={pollError}>
      <WidgetHeader onRemove={remove} subtaskType={field.type} />

      <PollQuestion subtaskPath={subtaskPath} index={index} field={field} />

      <PollOptions optionsPath={optionsPath} />

      <WidgetIsRequiredSwitch subtaskPath={subtaskPath} />

      {/*Register constant values in the form context*/}
      <Controller
        name={`${subtaskPath}.type`}
        control={control}
        as={<Box />}
        // key={`${subtaskPath}.type`}
        defaultValue={field.type}
      />
    </WidgetWrapper>
  )
})

export default PollWidget
