import { IConfigRetailUser, IConfigStateGroups, ITag } from 'constants/interfaces'
import { OptionType } from '../constants'
import keys from 'constants/keys'

export const options = [
  { type: OptionType.POST, label: 'create_post_tab' },
  { type: OptionType.POP, label: 'create_pop_tab' },
  { type: OptionType.CHAT, label: 'create_group_tab' },
]

interface SplittedLink {
  key: string
  value: string
}
interface IGetNamesForTooltip {
  groups: IConfigStateGroups
  retailUsersObject: { [key: string]: IConfigRetailUser }
  selectedGroupsIds: string[]
  groupsToPayload: string[]
  selectedUsersUids: string[]
  selectedOptionType: string
  selectedTagsIds?: string[]
  tags?: ITag[]
}

export const getNamesForTooltip = ({
  groups,
  retailUsersObject,
  selectedGroupsIds,
  groupsToPayload,
  selectedUsersUids,
  selectedOptionType,
  tags,
  selectedTagsIds,
}: IGetNamesForTooltip) => {
  const targetGroups =
    selectedOptionType === OptionType.CHAT || selectedOptionType === OptionType.POLL
      ? selectedGroupsIds
      : groupsToPayload
  let selectedGroupNames = targetGroups.filter((key) => key in groups).map((key) => groups[key]?.name) ?? []
  let selectedUserNames = selectedUsersUids.map(
    (uid) =>
      retailUsersObject?.[uid]?.first_name +
      ' ' +
      (retailUsersObject?.[uid]?.last_name ? retailUsersObject?.[uid].last_name : '')
  )
  const selectedTagNames = selectedTagsIds
    ? tags?.filter((tag) => selectedTagsIds?.includes(tag.sk))?.map((tag) => tag.name)
    : []

  return [...selectedGroupNames, ...selectedUserNames, ...(selectedTagNames ?? [])]
}

export const getSplittedGroupNames = (namesForTooltip: string[], limit: number = 2) => {
  if (namesForTooltip.length <= limit) {
    return namesForTooltip.join(', ')
  } else {
    return namesForTooltip.slice(0, limit).join(', ') + ` + ${namesForTooltip.length - limit}`
  }
}

export const isUserIncludedInGroups = (userId: string, groupsToPayload: string[], treeGroups: IConfigStateGroups) => {
  return groupsToPayload.some((group) => treeGroups[group]?.uids.includes(userId))
}

export const determinePostContent = (postType: number, groupTitle: string, regularContent: string) => {
  return [keys.POST_TYPE.GROUP_POST, keys.POST_TYPE.GROUP_POST_ANNOUNCEMENT].includes(postType)
    ? groupTitle
    : regularContent
}

export const determineUserUIDs = (
  postType: number,
  isUserInPayloadGroups: boolean,
  selectedUsersUids: string[],
  userId: string
) => {
  if ([keys.POST_TYPE.GROUP_POST, keys.POST_TYPE.GROUP_POST_ANNOUNCEMENT].includes(postType)) {
    return isUserInPayloadGroups ? selectedUsersUids.filter((id) => id !== userId) : [...selectedUsersUids, userId]
  }
  return selectedUsersUids
}

export const determinePayloadGroups = (postType: number, selectedGroupsIds: string[], groupsToPayload: string[]) => {
  return [keys.POST_TYPE.GROUP_POST, keys.POST_TYPE.GROUP_POST_ANNOUNCEMENT].includes(postType)
    ? selectedGroupsIds
    : groupsToPayload
}

export const splitString = (input: string): { key: string; value: string } => {
  const regex = /^(posts|tasks)?-?([a-f0-9-]+)$/i
  const match = input.match(regex)

  if (match) {
    return { key: match[1] || '', value: match[2] }
  } else {
    return { key: '', value: input }
  }
}

export const getInitialRadioValue = (taskLink: string | null, chatLink: string | null): string => {
  if (taskLink) return '1'
  if (chatLink) return '2'
  return ''
}

export const getPostType = (postType?: number) => {
  switch (postType) {
    case keys.POST_TYPE.GROUP_POST:
    case keys.POST_TYPE.GROUP_POST_ANNOUNCEMENT:
      return 'CHAT'
    case keys.POST_TYPE.LINKED:
    case keys.POST_TYPE.REGULAR:
      return 'POST'
    default:
      return 'POST'
  }
}
