import { Text, useDisclosure } from '@chakra-ui/core'
import { DateRangePopover } from 'components/DateRange'
import LocaleText from 'components/LocaleText'
import colors from 'constants/colors'
import { ReportRangeType } from 'constants/interfaces'
import keys from 'constants/keys'
import dayjs from 'dayjs'
import React, { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CustomMenu } from './CustomMenu'
import { isRtlSelector } from 'redux/selectors/general'
import { IDateFilterProps } from '../commonTypes'

export const DateFilter = memo(
  ({
    reportData,
    handleGetReportData,
    isReportLoading,
    retailConfig,
    setRangeType,
    selectedReportRangeType,
  }: IDateFilterProps) => {
    const dispatch = useDispatch()
    const { isOpen, onClose, onOpen } = useDisclosure()
    const isRtl = useSelector(isRtlSelector)

    const getReportByRangeType = useCallback(
      ({ type, title }: ReportRangeType) => {
        dispatch(setRangeType(type, title))
        if (type !== keys.REPORT_DATE_NAMES.CUSTOM_DATE) {
          handleGetReportData({ rangeType: type })
        } else if (type === keys.REPORT_DATE_NAMES.CUSTOM_DATE) {
          onOpen()
        }
      },
      [dispatch, handleGetReportData, onOpen, setRangeType]
    )
    const onResetFilter = useCallback(() => {
      const initialRangeType = retailConfig?.data_range_types[0]!
      dispatch(setRangeType(initialRangeType.type, initialRangeType?.title))
      handleGetReportData({ rangeType: initialRangeType.type })
    }, [dispatch, handleGetReportData, retailConfig, setRangeType])

    const getReportByCustomDate = useCallback(
      (startDate: Date, endDate: Date) => {
        const reportRangeTypeName = `${dayjs(startDate).format('DD/MM/YYYY')}-${dayjs(endDate).format('DD/MM/YYYY')}`
        dispatch(setRangeType(keys.REPORT_DATE_NAMES.CUSTOM_DATE, reportRangeTypeName))
        handleGetReportData({ rangeType: keys.REPORT_DATE_NAMES.CUSTOM_DATE, startDate, endDate })
      },
      [dispatch, handleGetReportData, setRangeType]
    )

    return (
      <>
        <CustomMenu
          buttonLabel={
            <Text as={'span'} fontFamily='"Asap Condensed", sans-serif'>
              <LocaleText text={selectedReportRangeType?.title || reportData?.title} />
            </Text>
          }
          menuItems={retailConfig!.data_range_types!.map((rangeType) => ({
            key: rangeType.type,
            label: <LocaleText text={rangeType.title} />,
            value: rangeType,
            isChecked: rangeType.type === selectedReportRangeType?.type,
          }))}
          onMenuItemClick={(selectedRangeType) => getReportByRangeType(selectedRangeType)}
          isReportLoading={isReportLoading}
          isRtl={isRtl}
        />
        <DateRangePopover
          style={{ top: '30px' }}
          open={isOpen}
          close={onClose}
          onResetFilter={onResetFilter}
          onSelectDate={getReportByCustomDate}
          bgOfTriggerPopover={colors.tealLight}
          openPopoverTriggerLiteral="Select custom Range"
          resetButtonLiteral="archive_current_reports"
        />
      </>
    )
  }
)
