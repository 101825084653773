import React from 'react'
import { WidgetIcon } from './WidgetIcon'
import { RemoveItemButton } from './RemoveItemButton'

interface PollHeaderProps {
  onRemove: () => void
  subtaskType: number
}

const WidgetHeader: React.FC<PollHeaderProps> = ({ onRemove, subtaskType }) => {
  return (
    <>
      <RemoveItemButton onRemove={onRemove} />
      <WidgetIcon subtaskType={subtaskType} />
    </>
  )
}
export default WidgetHeader
