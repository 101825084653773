import { ServiceCallsInitialState } from 'constants/interfaces'
import { actionTypes } from 'redux/actions/serviceCall'

export const initialState: ServiceCallsInitialState = {
  serviceCalls: [],
  serviceCallsDashboard: [],
  historicalServiceCalls: [],
  isLoading: false,
  dateFilter: null,
}
export const serviceCallsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }
    case actionTypes.SET_SERVICE_CALLS:
      return {
        ...state,
        serviceCalls: action.payload,
      }
    case actionTypes.SET_SERVICE_CALLS_DASHBOARD:
      return {
        ...state,
        serviceCallsDashboard: action.payload,
      }
    case actionTypes.SET_DATE_FILTER:
      return {
        ...state,
        dateFilter: action.payload,
      }
    case actionTypes.SET_HISTORICAL_SERVICE_CALLS:
      return {
        ...state,
        historicalServiceCalls: action.payload,
      }
    default:
      return state
  }
}
