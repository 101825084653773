import { Avatar, Box, Flex, IconButton, Tag, Text, useDisclosure } from '@chakra-ui/core'
import { NoFocusButton } from 'components/CommonComponents/NoFocusButton'
import { TooltipList } from 'components/CommonComponents/TooltipList'
import LocaleText from 'components/LocaleText'
import { PostModal } from 'components/NewsFeed/PostModal'
import { PostAdminsModal } from 'components/Post/PostAdminsModal'
import { PostMenu } from 'components/Post/PostHeader/PostMenu'
import ServicaCallOveviewModal from 'components/ServiceCalls/OverviewModal'
import { AppDispatch } from 'config/redux'
import { IConfigStateGroups, ITag, RootState } from 'constants/interfaces'
import keys from 'constants/keys'
import { useUserName } from 'hooks/useUserName'
import { Wrench } from 'lucide-react'
import React, { memo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getServiceCall, ServiceCall } from 'redux/actions/serviceCall'
import { Post } from 'redux/reducers/feed'
import { isRtlSelector } from 'redux/selectors/general'
import { findGroupByUid, findTagByUid } from 'utils'

interface IChatHeader {
  currentChat: Post
  chatAudience: string
  trimmedAudience: string
}

export const ChatHeader = memo(({ currentChat, chatAudience, trimmedAudience }: IChatHeader) => {
  const { images, title, groups, users, post_id: chatId, created_by, tags } = currentChat
  const dispatch: AppDispatch = useDispatch()
  const { uid } = useSelector((state: RootState) => state.auth)
  const navigate = useNavigate()
  const isRtl = useSelector(isRtlSelector)
  const { isOpen: isEditPostModalOpen, onOpen: onEditPostModalOpen, onClose: onCloseEditPostModal } = useDisclosure()
  const { isOpen: isSetAdminModalOpen, onOpen: onSetAdminModalOpen, onClose: onCloseSetAdminModal } = useDisclosure()
  const {
    isOpen: isOpenServiceCallModal,
    onOpen: onOpenServiceCallModal,
    onClose: onCloseServiceCallModal,
  } = useDisclosure()
  const chatCreator = useUserName(created_by)
  const theme = useSelector((state: RootState) => state.general.theme)
  const {
    config: { permission, tags: configTags, user_groups, groups: configGroups },
  } = useSelector((state: RootState) => state.config)
  const isAdminPostPermission = permission?.posts === keys.USER_ACTIONS_MAP.APP_ADMIN
  const { serviceCalls, isLoading } = useSelector((state: RootState) => state.serviceCalls)

  const [selectedServiceCall, setSelectedServiceCall] = useState<ServiceCall | null>(null)

  const isExistServiceCall = currentChat?.ref_type === 1

  const calculateContextIdForGetServiceCall = () => {
    const matchingGroup = getMatchingGroup(groups, user_groups ?? [])
    if (matchingGroup) {
      return matchingGroup
    }

    const matchingTag = getMatchingTag(configTags, tags)
    if (matchingTag) {
      return matchingTag
    }

    if (currentChat?.created_by === uid) {
      return getGroupOrTagForUser(uid, groups, configGroups, configTags, tags)
    }

    return users.includes(uid!) ? uid : null
  }

  const handleGetServiceCall = async () => {
    if (!currentChat?.ref_id) {
      return
    }
    const serviceCall = isExistServiceCall
      ? serviceCalls?.find((serviceCall) => serviceCall?.service_call_id === currentChat?.ref_id) ?? null
      : null
    if (serviceCall) {
      setSelectedServiceCall(serviceCall)
      onOpenServiceCallModal()
    } else {
      const contextId = calculateContextIdForGetServiceCall()
      if (!contextId) {
        return
      }
      const apiServiceCall = await dispatch(
        getServiceCall({ contextId: contextId, service_call_id: currentChat?.ref_id })
      )
      if (apiServiceCall) {
        setSelectedServiceCall(apiServiceCall)
        onOpenServiceCallModal()
      }
    }
  }

  return (
    <>
      <Flex
        justifyContent="space-between"
        px="10px"
        py="20px"
        alignItems="center"
        style={{ columnGap: '10px' }}
        w="100%"
        pos="relative"
      >
        {isExistServiceCall && (
          <NoFocusButton
            pos="absolute"
            bg="#DDCCE9"
            p="5px"
            h="unset"
            minW="unset"
            left={isRtl ? '57px' : 'unset'}
            right={isRtl ? 'unset' : '57px'}
            top="47px"
            style={{ columnGap: '10px' }}
            cursor="pointer"
            onClick={handleGetServiceCall}
            isDisabled={isLoading}
            isLoading={isLoading}
          >
            <Wrench style={{ fill: 'white' }} width="20px" height="20px" />
          </NoFocusButton>
        )}

        <Flex alignItems="center" style={{ columnGap: '10px' }} position="relative" mx="20px">
          <IconButton
            icon={!isRtl ? 'chevron-left' : 'chevron-right'}
            fontSize="30px"
            position="absolute"
            top="10px"
            left={isRtl ? 'unset' : '-125px'}
            right={isRtl ? '-125px' : 'unset'}
            h="35px"
            w="35px"
            bg="none"
            borderRadius="full"
            minW="unset"
            onClick={() => navigate(-1)}
            aria-label="back"
            mx="20px"
          />
          <Avatar src={images?.[0]} w="50px" h="50px" />
          <Flex flexDir="column">
            <Text fontWeight="bold">{title}</Text>
            <Flex alignItems="center" py="5px">
              <Tag bg={theme?.elementsColor} color="white" minH="unset" borderRadius="15px" fontWeight="bold">
                <LocaleText text="search_feed_creator_filter" />
              </Tag>
              <Text fontWeight="bold" isTruncated maxW="450px">
                &nbsp;
                {chatCreator}
              </Text>
            </Flex>

            <TooltipList items={chatAudience.split(',')}>
              <Text>{trimmedAudience}</Text>
            </TooltipList>
          </Flex>
        </Flex>
        {!isExistServiceCall && (currentChat.created_by === uid || isAdminPostPermission) && (
          <Box mx="20px">
            <PostMenu
              isGroup={
                currentChat.type === keys.POST_TYPE.GROUP_POST ||
                currentChat.type === keys.POST_TYPE.GROUP_POST_ANNOUNCEMENT
              }
              groups={groups}
              users={users}
              post_id={chatId}
              editRole={created_by === uid}
              onEditPost={onEditPostModalOpen}
              isShowAdminsModalMenuItem={currentChat.type === keys.POST_TYPE.GROUP_POST_ANNOUNCEMENT}
              onSetAdminModalOpen={onSetAdminModalOpen}
              isAdminPostPermission={isAdminPostPermission}
            />
          </Box>
        )}
      </Flex>
      {isEditPostModalOpen && (
        <PostModal
          isOpen={isEditPostModalOpen}
          onClose={onCloseEditPostModal}
          postType={currentChat.type}
          postTitle={currentChat.title}
          isChat={true}
          isEditing={true}
          {...currentChat}
        />
      )}
      {isSetAdminModalOpen && (
        <PostAdminsModal isOpen={isSetAdminModalOpen} onClose={onCloseSetAdminModal} post={currentChat} />
      )}
      {selectedServiceCall && isOpenServiceCallModal && (
        <ServicaCallOveviewModal
          isOpen={isOpenServiceCallModal}
          onClose={onCloseServiceCallModal}
          serviceCallViewId={selectedServiceCall.view}
          serviceCallNumber={selectedServiceCall.service_call_number}
        />
      )}
    </>
  )
})

const getMatchingGroup = (groups: string[], userGroups: string[]): string | undefined =>
  groups.find((group) => userGroups?.includes(group))

const getMatchingTag = (configTags: ITag[], tags: string[]): string | null =>
  configTags.find((tag) => tags.includes(tag.sk))?.sk ?? null

const getGroupOrTagForUser = (
  uid: string,
  groups: string[],
  configGroups: IConfigStateGroups,
  configTags: ITag[],
  tags: string[]
): string | null => {
  const groupId = findGroupByUid(groups, configGroups, uid)
  if (groupId) {
    return groupId
  }

  const currentTags = configTags.filter((tag) => tags.includes(tag.sk))
  const tagId = findTagByUid(currentTags, configGroups, uid)
  return tagId || null
}
