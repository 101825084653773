import React, { useCallback, useEffect } from 'react'
import { Flex, useDisclosure, Text, Box } from '@chakra-ui/core'
import BaseModal from './BaseModal'
import { ReactComponent as PopIcon } from 'assets/pop icon.svg'
import LocaleText from 'components/LocaleText'

interface IProps {
  text: string
  status?: boolean | null
  resetStatus: (status: null) => void
}
export const FlashModal = ({ text, status, resetStatus }: IProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleClose = useCallback(() => {
    onClose()
    resetStatus(null)
  }, [onClose, resetStatus])

  useEffect(() => {
    if (status) {
      const openTimeout = setTimeout(() => {
        onOpen()

        setTimeout(() => {
          onClose()
          resetStatus(null)
        }, 2000)
      }, 500)

      return () => {
        clearTimeout(openTimeout)
      }
    }
  }, [status, onOpen, onClose, resetStatus])

  return (
    <>
      {status && (
        <BaseModal
          isOpen={isOpen}
          onClose={handleClose}
          isShowAnimation={true}
          closeOnEsc={false}
          px="55px"
          overflowY="none"
          bodyContent={
            <Flex flexDir="column" alignItems="center">
              <Box
                style={{
                  position: 'absolute',
                  borderRadius: '50%',
                  top: '-85px',
                  width: '190px',
                  height: '180px',
                  background: 'white',
                }}
              >
                <PopIcon width="120px" height="120px" style={{ position: 'absolute', left: '35px', top: '30px' }} />
              </Box>
              <Flex minH="250px" flexDir="column" alignItems="center" textAlign="center" justifyContent="center">
                <Text fontSize="25px" fontWeight="700" lineHeight="2.5rem">
                  <LocaleText text={text} />
                </Text>
              </Flex>
            </Flex>
          }
        />
      )}
    </>
  )
}
