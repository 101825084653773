import { Flex, Menu, MenuButton, MenuDivider, MenuItem, MenuList, useDisclosure } from '@chakra-ui/core'
import LocaleText from 'components/LocaleText'
import ServiceCallModal from 'components/ServiceCalls/CreationModal'
import { Wrench } from 'lucide-react'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { serviceCallConfigSelector } from 'redux/selectors/config'

export const CreateServiceCallButton: React.FC<{ setIsHidden?: (hide: boolean) => void }> = ({ setIsHidden }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const serviceCalls = useSelector(serviceCallConfigSelector)
  const [seelctedViewId, setSelectedViewId] = useState<number | null>(null)

  const handleMenuItemClick = (viewId: number) => {
    setIsHidden?.(true)
    setSelectedViewId(viewId)
    onOpen()
  }

  const handleCloseServiceCallCreationModal = () => {
    setIsHidden?.(false)
    onClose()
  }

  if (!serviceCalls.length) return null

  return (
    <>
      <Menu>
        <MenuButton as={Flex} w="max-content" alignItems="center" style={{ columnGap: '10px' }} cursor="pointer">
          <Flex
            bg="#DDCCE9"
            borderRadius="10px"
            h="36px"
            w="36px"
            color="white"
            alignItems="center"
            justifyContent="center"
          >
            <Wrench style={{ fill: 'white' }} width="20px" height="20px" />
          </Flex>
          <LocaleText text="create_service_call_btn" />
        </MenuButton>
        <MenuList placement="bottom-end" maxH="300px" overflowY="auto" zIndex={9999999999}>
          {serviceCalls.map(({ task_view_name, task_view_id }, index) => (
            <React.Fragment key={task_view_id}>
              <MenuItem key={task_view_id} onClick={() => handleMenuItemClick(task_view_id as number)}>
                {task_view_name}
              </MenuItem>
              {index !== serviceCalls.length - 1 && <MenuDivider />}
            </React.Fragment>
          ))}
        </MenuList>
      </Menu>
      {isOpen && seelctedViewId && (
        <ServiceCallModal
          isOpen={isOpen}
          onClose={handleCloseServiceCallCreationModal}
          serviceCallViewId={seelctedViewId}
        />
      )}
    </>
  )
}
