import React, { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { TaskInput } from 'components/TasksV2/UI/TaskInput'
import dayjs from 'dayjs'

export const TimeInput: React.FC = () => {
  const { control, setValue, watch } = useFormContext()

  const fromTs = watch('from_ts')
  const date = dayjs(fromTs)
  const updatedDate = date.add(8, 'hour')
  const defaultFrequencyTime = updatedDate.format('HH:mm')

  const deadline = watch('deadline') ? new Date(watch('deadline')) : null
  const isRecurring = watch('is_recurring')

  const validateTime = (value: string) => {
    if (!value) {
      if (isRecurring) {
        return 'Time is required for recurring tasks'
      }
      return true
    }
    const timeRegex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/
    return timeRegex.test(value) || 'Invalid time format'
  }

  const handleTimeChange = (value: string) => {
    setValue('frequency_time', value)

    const updatedDeadline = deadline ? new Date(deadline) : null

    if (updatedDeadline) {
      const [hours, minutes] = value.split(':').map(Number)
      updatedDeadline.setHours(hours, minutes, 0, 0)
      setValue('deadline', updatedDeadline.toISOString())
    }
  }
  useEffect(() => {
    const frequencyTime = watch('frequency_time')
    if (!frequencyTime) {
      setValue('frequency_time', defaultFrequencyTime)
    }
  }, [defaultFrequencyTime, setValue, watch])

  return (
    <Controller
      name="frequency_time"
      control={control}
      rules={{ validate: validateTime }}
      onChange={([event]) => {
        const value = event.target.value
        handleTimeChange(value)
        return value
      }}
      as={
        <TaskInput type="time" placeholder="HH:mm" maxW="100px" border="none" textAlign="center" borderRadius="15px" />
      }
    />
  )
}

export default TimeInput
