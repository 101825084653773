import React, { useCallback, useEffect } from 'react'
import { RootState } from 'constants/interfaces'
import { useDispatch, useSelector } from 'react-redux'
import { Flex } from '@chakra-ui/core'
import { ChatPreview } from './ChatPreview'
import { InfiniteScrollWithScrollControl } from './InfiniteScrollWithScrollControl'
import { CHAT, handleGetPosts } from 'redux/actions/feed'
import { StoreeDollPreview } from './StoreeDollPreview'

export default function ChatsPreviewsContainer() {
  const dispatch = useDispatch()
  const { chats, isAllPostCollapsed, hasMoreChats, chatsFetching } = useSelector((state: RootState) => state.feed ?? [])
  const { isConfigReady } = useSelector((state: RootState) => state.config)

  const fetchChats = useCallback(() => {
    if (!isConfigReady || chats.length > 0) return
    const currentEpochTimeUTC = Math.floor(new Date().getTime() / 1000).toString()
    const handleGetPostsParams = {
      type: CHAT,
      ts: currentEpochTimeUTC,
      is_init: true,
      isRefresh: true,
    }
    dispatch(handleGetPosts(handleGetPostsParams))
  }, [chats.length, dispatch, isConfigReady])

  useEffect(() => {
    fetchChats()
  }, [fetchChats])

  return (
    <Flex width="100%" pos="relative" alignItems="center" justifyContent="center">
      {isConfigReady && (
        <InfiniteScrollWithScrollControl
          chats={chats}
          isAllPostCollapsed={isAllPostCollapsed}
          hasMoreChats={hasMoreChats}
          chatsFetching={chatsFetching}
        >
          <StoreeDollPreview />
          {chats?.length > 0 && chats.map((chat, index) => <ChatPreview key={index} chat={chat} />)}
        </InfiniteScrollWithScrollControl>
      )}
    </Flex>
  )
}
