import { RootState } from 'constants/interfaces'
import React, { memo, useMemo, useRef } from 'react'
import CommentSection from '.'
import { extractMediaIdentifier } from 'utils'
import { useSelector } from 'react-redux'
import { SubtaskWithUuid } from '../Insights/Content/AllContextsMediaResultsOverview'

interface IProps {
  subtask: SubtaskWithUuid
  updatedBy: string
  resultContextId: string
  objectType: number
  mediaIndex: number
  retail_id_context_id: string
  recurrence_ts: string | null
  media: string[]
  tid: string
}

export const ResultCommments: React.FC<IProps> = memo(
  ({
    subtask,
    resultContextId,
    objectType,
    updatedBy,
    mediaIndex,
    retail_id_context_id,
    recurrence_ts,
    media,
    tid,
  }) => {
    const { st_id, uuid } = subtask
    const uid = useSelector((state: RootState) => state.auth.uid)!
    const { cdn, rid, retailUsersObject } = useSelector((state: RootState) => state.config.config)

    const url = media?.length > 0 ? media[mediaIndex] : ''

    const mediaHash = useMemo(
      () => extractMediaIdentifier({ url, cdn, rid, uid: updatedBy }),
      [cdn, rid, updatedBy, url]
    )

    const previousUuidRef = useRef<string | undefined>(undefined)

    const isSameUuid = uuid === previousUuidRef.current

    if (!isSameUuid) {
      previousUuidRef.current = uuid
    }
    if (!url) {
      return null
    }
    const contextIdRecurrenceTs = `${resultContextId}${recurrence_ts ? '_' + recurrence_ts : ''}`
    const oid = `${st_id}_${contextIdRecurrenceTs}`

    const result_level = Object.keys(retailUsersObject!).includes(resultContextId) ? 'user' : 'group'

    return (
      <CommentSection
        oid={oid}
        rg_id={retail_id_context_id!}
        objectType={objectType}
        parentCid={mediaHash!}
        tid={tid}
        mediaIndex={mediaIndex}
        created_by={uid}
        result_context_id={resultContextId}
        result_level={result_level}
        {...(recurrence_ts ? { recurrence_ts } : {})}
      />
    )
  }
)
