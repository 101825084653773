import { Box, Text } from '@chakra-ui/core'
import useLocaleText from 'components/useLocaleText'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Status } from 'redux/actions/serviceCall'
import { StatusesMenu } from '../StatusesMenu'

interface IProps {
  statuses: Status[]
  isAllowToUpdate: boolean
}
export const ControlledStatusesMenu: React.FC<IProps> = ({ statuses, isAllowToUpdate }) => {
  const { control, setValue, watch, errors, triggerValidation } = useFormContext()

  const status = watch('status')

  const choose_status = useLocaleText('choose_status')

  const handleChangeStatus = async (value: number) => {
    setValue('status', value)
    await triggerValidation('status')
  }
  return (
    <Box w="100%" mx={'auto'} pointerEvents={isAllowToUpdate ? 'all' : 'none'} opacity={isAllowToUpdate ? 1 : 0.7}>
      <Text px="5px">{choose_status}</Text>
      <Controller
        name={'status'}
        control={control}
        as={
          <StatusesMenu
            statuses={statuses}
            statusValue={status}
            handleChangeStatus={handleChangeStatus}
            errors={errors?.status}
          />
        }
      />
    </Box>
  )
}
