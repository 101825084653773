import React, { useState } from 'react'
import { Box, Flex } from '@chakra-ui/core'
import useLocaleText from 'components/useLocaleText'
import { GetOtpForm } from 'components/Login/Otp/GetOtpForm'
import { ConfirmCode } from 'components/Login/Otp/ConfirmCode'
import { PossibleUsersForm } from 'components/Login/Otp/PossibleUsersForm'
import { DividerWithText } from 'components/CommonComponents/DividerWithText'
import NavigationButton from 'components/Login/NavigationButton'
import keys from 'constants/keys'
import { HiLockClosed } from 'react-icons/hi'
import { LoginWrapper } from 'components/Login/LoginWrapper'
import { ConfirmOTPCodeResponse } from 'redux/actions/auth'
import { useSelector } from 'react-redux'
import { isLoggedInSelector } from 'redux/selectors/auth'
import { Navigate } from 'react-router-dom'

type OtpScreen = 'GET_OTP' | 'CONFIRM_CODE' | 'SELECT_USER'

interface OtpNavigationState {
  screen: OtpScreen
  users?: string[]
  token?: string
  error?: string
  contactInfo?: {
    email?: string
    phone_number?: string
    country_code?: string
  }
  usersList?: ConfirmOTPCodeResponse
}
const LoginOTP: React.FC = () => {
  const isLoggedIn = useSelector(isLoggedInSelector)
  const or = useLocaleText('or')
  const continue_with_password = useLocaleText('continue_with_password')
  const continue_sso = useLocaleText('continue_sso')
  const [navigation, setNavigation] = useState<OtpNavigationState>({
    screen: 'GET_OTP',
  })

  const navigateToScreen = (screenData: Partial<OtpNavigationState>) => {
    setNavigation((prevState) => ({
      ...prevState,
      ...screenData,
    }))
  }

  const handleOtpAction = (action: string, data?: any) => {
    switch (action) {
      case 'GET_OTP':
        navigateToScreen({
          screen: 'CONFIRM_CODE',
          contactInfo: data,
        })
        break

      case 'CONFIRM_CODE':
        navigateToScreen({
          screen: 'SELECT_USER',
          usersList: data,
        })
        break

      case 'SELECT_USER':
        break

      default:
        console.error('Unknown action:', action)
    }
  }

  const renderScreen = () => {
    switch (navigation.screen) {
      case 'GET_OTP':
        return <GetOtpForm handleOtpAction={(data) => handleOtpAction('GET_OTP', data)} />

      case 'CONFIRM_CODE':
        return (
          <ConfirmCode
            contactInfo={navigation.contactInfo}
            handleOtpAction={(data) => handleOtpAction('CONFIRM_CODE', data)}
            navigateToScreen={(data) => navigateToScreen(data)}
          />
        )

      case 'SELECT_USER':
        return (
          <PossibleUsersForm
            usersList={navigation.usersList as ConfirmOTPCodeResponse}
            navigateToScreen={(data) => navigateToScreen(data)}
          />
        )

      default:
        return <GetOtpForm handleOtpAction={(data) => handleOtpAction('GET_OTP', data)} />
    }
  }

  if (isLoggedIn) {
    return <Navigate to={keys.ROUTE_NAMES.NEWS_FEED} />
  }

  return (
    <LoginWrapper>
      <>
        {renderScreen()}

        <Box my="30px" w="100%">
          <DividerWithText text={or} />
        </Box>
        <Flex flexDirection="column" style={{ rowGap: '20px' }}>
          <NavigationButton navigateTo={keys.ROUTE_NAMES.LOGIN_PAGE} buttonText={continue_with_password} />
          <NavigationButton navigateTo={keys.ROUTE_NAMES.LOGIN_SSO} buttonText={continue_sso} icon={<HiLockClosed />} />
        </Flex>
      </>
    </LoginWrapper>
  )
}

export default LoginOTP
