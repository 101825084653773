import { IconButton, IconButtonProps } from '@chakra-ui/core'
import React from 'react'
import { IoIosClose } from 'react-icons/io'

interface IProps extends Omit<IconButtonProps, 'aria-label'> {
  'aria-label': string
}
export const RenoveItemIcon: React.FC<IProps> = (props) => {
  return (
    <IconButton
      icon={IoIosClose}
      size="xs"
      bg="white"
      variant="ghost"
      fontSize="20px"
      borderRadius="full"
      color="black"
      position="absolute"
      top="4px"
      {...props}
    />
  )
}
