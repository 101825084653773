import { calculateTaskStatus } from 'components/Tasks/utils'
import { RootState, Task } from 'constants/interfaces'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { CircleTask } from './CircleTask'
import { DraggableButton } from 'components/CommonComponents/DraggableButton'
import { isRtlSelector } from 'redux/selectors/general'
import { Box, Flex, Text, useDisclosure } from '@chakra-ui/core'
import { TaskOverviewModal } from 'components/TasksV2/TasksOverview/TaskOverviewModal'
const TASK_Y_OFFSET = 125

export const DraggableTasks = () => {
  const { tasks, subtasks, userResults } = useSelector((state: RootState) => state.tasks_v2)
  const { isAllPostCollapsed } = useSelector((state: RootState) => state.feed)
  const {
    config: { retailUsersObject },
  } = useSelector((state: RootState) => state.config)
  const isRtl = useSelector(isRtlSelector)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedTask, setSelectedTask] = useState<Task | null>(null)
  const [maxItems, setMaxItems] = useState(8)

  const tasksDueInNext24Hours = useMemo(
    () =>
      tasks
        .map((task) => {
          const taskStatus = calculateTaskStatus(task, subtasks, userResults)
          return {
            ...task,
            taskStatus: taskStatus,
          }
        })
        .filter((task) => task.show_task_bubble)
        .sort((a, b) => {
          const hoursUntilEndA = a.hours_until_end
          const hoursUntilEndB = b.hours_until_end
          return hoursUntilEndA - hoursUntilEndB
        })
        .slice(0, maxItems),
    [maxItems, subtasks, tasks, userResults]
  )

  const onCircleClick = (task: Task) => {
    setSelectedTask(task)
    onOpen()
  }
  const onCloseModal = () => {
    setSelectedTask(null)
    onClose()
  }

  useEffect(() => {
    const updateMaxItems = () => {
      const height = window.innerHeight
      if (height >= 1200) {
        setMaxItems(8)
      } else if (height > 992) {
        setMaxItems(5)
      } else if (height >= 768) {
        setMaxItems(4)
      } else {
        setMaxItems(3)
      }
    }
    updateMaxItems()

    window.addEventListener('resize', updateMaxItems)

    return () => window.removeEventListener('resize', updateMaxItems)
  }, [])

  return (
    <>
      {tasksDueInNext24Hours.map((task, index) => {
        const { taskStatus, hours_until_end, title } = task
        return (
          <Box key={task.tid} opacity={selectedTask ? (task.tid === selectedTask.tid ? 1 : 0.5) : 1}>
            <DraggableButton
              isRtl={isRtl}
              posY={(index * TASK_Y_OFFSET) / window.innerHeight}
              posX={isAllPostCollapsed ? 0.67 : 0.43}
              callback={() => onCircleClick(task)}
              isShowShadow={false}
            >
              <CircleTask
                status={taskStatus}
                houraUntilEnd={hours_until_end}
                avatar={retailUsersObject?.[task.created_by]?.profile_img_url ?? ''}
              />
              <Flex justifyContent="center" mt="5px">
                <Text fontSize="12px" maxW="80px" isTruncated>
                  {title}
                </Text>
              </Flex>
            </DraggableButton>
          </Box>
        )
      })}
      {selectedTask && isOpen && <TaskOverviewModal onClose={onCloseModal} task={selectedTask} />}
    </>
  )
}
