import { Box, Flex, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/core'
import LocaleText from 'components/LocaleText'
import { WidgetWrapper } from 'components/TasksV2/UI/WidgetWrapper'
import useLocaleText from 'components/useLocaleText'
import { RootState, SupervisorOrHandler } from 'constants/interfaces'
import { getContextName } from 'hooks/useContextName'
import { ChevronDown } from 'lucide-react'
import React, { Fragment, useCallback, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { SERVICE_CALL_STATUSES } from '../utils'

interface IProps {
  handlers: SupervisorOrHandler[]
  isAllowToUpdate: boolean
}

export const AssigneesMenu: React.FC<IProps> = ({ handlers, isAllowToUpdate }) => {
  const { control, setValue, watch, getValues, errors, triggerValidation } = useFormContext()
  const choosedHandler = watch('assignee')

  const { retailUsersObject, groups, tags } = useSelector((state: RootState) => state.config.config)
  const choose_assignee = useLocaleText('asign_to')

  const getHandlerName = useCallback(
    (context_id?: string) => {
      if (!context_id) {
        return ''
      }
      const tagName = tags.find((tag) => tag.sk === context_id)?.name ?? ''
      const contexName = getContextName(context_id, groups, retailUsersObject!)
      return contexName || tagName
    },
    [groups, retailUsersObject, tags]
  )

  const choosedHandlerName = useMemo(() => getHandlerName(choosedHandler?.context_id), [choosedHandler, getHandlerName])

  const handlersWithNames = useMemo(
    () =>
      handlers.map((handler) => ({
        ...handler,
        name: getHandlerName(handler.context_id),
      })),
    [getHandlerName, handlers]
  )

  const handleReassign = async (handler: SupervisorOrHandler) => {
    setValue('assignee', handler)
    await triggerValidation('assignee')
  }

  return (
    <Box w="100%" mx="auto" pointerEvents={isAllowToUpdate ? 'all' : 'none'} opacity={isAllowToUpdate ? 1 : 0.7}>
      <Text px="5px">{choose_assignee}</Text>
      <Controller
        name="assignee"
        control={control}
        rules={{
          validate: (value) => {
            const currentStatus = getValues('status')
            if (currentStatus === SERVICE_CALL_STATUSES.CLOSED) return true
            return !!value?.context_id || 'Please select assignee'
          },
        }}
        as={
          <Menu>
            <MenuButton as={Flex} w="100%" style={{ columnGap: '10px' }} alignItems="center">
              <WidgetWrapper p="10px" borderRadius="10px" cursor="pointer" w="100%" isError={!!errors?.assignee}>
                <Flex
                  alignItems="center"
                  minW="200px"
                  flexDir="row"
                  justifyContent="space-between"
                  style={{ columnGap: '10px' }}
                >
                  <Text>
                    <LocaleText text={choosedHandlerName || choose_assignee} />
                  </Text>
                  <ChevronDown />
                </Flex>
              </WidgetWrapper>
            </MenuButton>
            <MenuList
              maxH="300px"
              h="max-content"
              overflowY="auto"
              fontSize="14px"
              fontWeight="500"
              borderRadius="10px"
              placement="bottom-start"
            >
              {handlersWithNames.map((handler, index) => {
                const name = handler.name
                const handlerInfo = {
                  context_id: handler.context_id,
                  context_type: handler.context_type,
                }
                return (
                  <Fragment key={index}>
                    {name && (
                      <MenuItem py="10px" style={{ columnGap: '10px' }} onClick={() => handleReassign(handlerInfo)}>
                        <LocaleText text={name} />
                      </MenuItem>
                    )}
                  </Fragment>
                )
              })}
            </MenuList>
          </Menu>
        }
      />
    </Box>
  )
}
