import { Flex } from '@chakra-ui/core'
import { css } from '@emotion/core'
import { ArrowUp } from 'lucide-react'
import React from 'react'
import { useSelector } from 'react-redux'
import { isRtlSelector } from 'redux/selectors/general'

interface IProps {
  onSubmit: () => void
  is_numeric?: boolean
}
export const TextAreaSubmitBtn: React.FC<IProps> = ({ onSubmit, is_numeric = false }) => {
  const isRtl = useSelector(isRtlSelector)
  return (
    <Flex
      alignItems="flex-start"
      onMouseDown={onSubmit}
      pos="absolute"
      cursor="pointer"
      bottom={is_numeric ? '7px' : '15px'}
      right={isRtl ? 'unset' : '10px'}
      left={isRtl ? '10px' : 'unset'}
      style={{ columnGap: '10px' }}
      css={css`
        & > * {
          transition: transform 0.3s ease, margin 0.3s ease, background-color 0.3s ease;
        }
        & > *:hover {
          transform: scale(1.1);
        }
      `}
    >
      <Flex
        borderRadius="full"
        bg="black"
        w="30px"
        h="30px"
        alignItems="center"
        justifyContent="center"
        cursor="pointer"
        boxShadow="0px 0px 2px 1px white"
      >
        <ArrowUp color="white" size={16} strokeWidth={4} />
      </Flex>
    </Flex>
  )
}
