import { BoxProps, Text } from '@chakra-ui/core'
import React from 'react'

interface IProps extends BoxProps {
  title: string
}
export const SubtaskTitile: React.FC<IProps> = ({ title, ...props }) => {
  return (
    <Text mt="15px" mb="10px" fontSize="16px" textAlign={'center'} {...props}>
      {title}
    </Text>
  )
}
