// components/DeadLineDatePicker.tsx
import { Box, Flex } from '@chakra-ui/core'
import React from 'react'
import { WidgetWrapper } from 'components/TasksV2/UI/WidgetWrapper'
import useLocaleText from 'components/useLocaleText'
import { ErrorMessage } from 'components/TasksV2/UI/ErrorMessage'
import { Controller, useFormContext } from 'react-hook-form'
import { DeadlinePickerComponent } from './DeadlinePickerComponent'
import { DateToLocaleValue } from '../../../../UI/DateToLocaleValue'
import useDeadlineDatePicker from './useDeadLinePicker'
import dayjs from 'dayjs'
import { getNextDateForDayOfWeek } from '../utils'
import { FrequencyType } from 'components/NewsFeed/PollTaskForm/utils'
import { FormInputLabel } from 'components/TasksV2/UI/FormInputLabel'
import CustomTimePicker from 'components/CommonComponents/CutomTimePicker'

const DeadLineDatePicker: React.FC = () => {
  const { control, errors, watch } = useFormContext()
  const t_end_date = useLocaleText('t_end_date')
  const recc_end_date = useLocaleText('recc_end_date')
  const t_dealline_error = useLocaleText('t_dealline_error')

  const {
    fromTs,
    isRecurring,
    isDatePickerOpen,
    handleDateChange,
    handleTimeChange,
    deadlineDate,
    deadlineTime,
    openDatePicker,
    closeDatePicker,
  } = useDeadlineDatePicker()

  return (
    <Box pos="relative">
      <FormInputLabel label={isRecurring ? recc_end_date : t_end_date} />
      <Flex alignItems="center" style={{ columnGap: '15px' }}>
        <WidgetWrapper
          p="10px"
          isError={!!errors?.deadline}
          cursor="pointer"
          onClick={openDatePicker}
          flexDir="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <DateToLocaleValue
            date={deadlineDate ? new Date(deadlineDate) : null}
            placeholder={isRecurring ? recc_end_date : t_end_date}
            format="DD MMMM YYYY"
            minW="180px"
            textAlign="center"
          />
        </WidgetWrapper>
        <WidgetWrapper display={isRecurring ? 'none' : 'flex'} isError={!!errors?.deadline}>
          <CustomTimePicker value={deadlineTime} onChange={handleTimeChange} />
        </WidgetWrapper>
      </Flex>

      <Controller
        name="deadline"
        control={control}
        key="deadline"
        rules={{
          validate: (value) => {
            const deadlineDate = dayjs(value)
            const fromTs = watch('from_ts')
            const recurrence_frequency = watch('recurrence_frequency')
            const daysOfWeek = watch('days_of_week')
            const monthly_range = watch('monthly_range')
            const isToday = dayjs().isSame(fromTs, 'day')

            if (!isRecurring) {
              if (!deadlineDate.isValid()) {
                return t_dealline_error
              }

              if (dayjs(fromTs).isAfter(deadlineDate) || dayjs(fromTs).isSame(deadlineDate)) {
                return t_dealline_error
              }

              if (isToday && dayjs(deadlineDate).isBefore(dayjs())) {
                return t_dealline_error
              }
            }
            if (recurrence_frequency === FrequencyType.DAY) {
              if (daysOfWeek && daysOfWeek.length > 0) {
                const dayOfWeek = daysOfWeek[daysOfWeek.length - 1]
                const date = getNextDateForDayOfWeek(fromTs, dayOfWeek)

                if (dayjs(date).isAfter(deadlineDate)) {
                  return t_dealline_error
                }
              }
            }

            if (recurrence_frequency === FrequencyType.MONTH) {
              if (monthly_range && monthly_range.length >= 2 && monthly_range[1]) {
                const monthlyRangeEndDate = dayjs(monthly_range[1])
                if (!monthlyRangeEndDate.isValid()) {
                  return t_dealline_error
                }
                if (monthlyRangeEndDate.isAfter(deadlineDate) || monthlyRangeEndDate.isSame(deadlineDate)) {
                  return t_dealline_error
                }
              }
            }

            if (recurrence_frequency === FrequencyType.WEEK) {
              if (daysOfWeek && daysOfWeek.length >= 2) {
                const dayOfWeek = daysOfWeek[1]
                const date = getNextDateForDayOfWeek(fromTs, dayOfWeek)
                if (dayjs(date).isAfter(deadlineDate) || dayjs(date).isSame(deadlineDate)) {
                  return t_dealline_error
                }
              }
            }
          },
        }}
        as={
          <DeadlinePickerComponent
            isOpen={isDatePickerOpen}
            onClose={closeDatePicker}
            selectedDate={deadlineDate ? new Date(deadlineDate) : null}
            handleChange={handleDateChange}
            minDate={fromTs}
          />
        }
      />
      {errors?.deadline && (
        <Box px="10px" pt="5px">
          <ErrorMessage errorMessage={t_dealline_error} />
        </Box>
      )}
    </Box>
  )
}

export default DeadLineDatePicker
