// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react'
import { Box, Flex, Heading } from '@chakra-ui/core'
import { css, Global } from '@emotion/core'
import styled from '@emotion/styled'
import LocaleDropDown from 'components/DropDown/LocaleDropDown'
import Logo from 'components/Header/Logo'
import { ContactUsWhatsApp } from './ContactUsWhatsApp'
import LocaleText from '../LocaleText'
import UserDropDown from 'components/DropDown/UserDropDown'

interface IProps {
  children: JSX.Element
  header?: JSX.Element
  isChangingPassword?: boolean
  title?: string
  isShowUserMenu?: boolean
}

const LoginBlock = styled(Box)`
  max-width: 320px;
  background: white;
  border-radius: 12px;
`

export const LoginWrapper = ({
  children,
  header,
  isChangingPassword = false,
  title,
  isShowUserMenu = false,
}: IProps) => {
  return (
    <Flex flexDirection="column" justifyContent="space-between" height="100vh" backgroundColor="white">
      <Flex justifyContent="space-between" mb={3} pl="34px" pr="20px">
        <Logo mt="30px" width="100%" maxWidth="220px" h="42px" cursor="pointer" />
        <Box mt="20px">
          <LocaleDropDown />
          {isShowUserMenu && <UserDropDown />}
        </Box>
      </Flex>
      <LoginBlock
        display="flex"
        flexDirection="column"
        flexGrow={1}
        maxHeight="calc(100% - 40px)"
        justifyContent={{
          lg: 'center',
          base: 'center',
        }}
        mx="auto"
        width="100%"
      >
        <Global
          styles={css`
            body {
              background: white;
            }
          `}
        />
        <Flex width="100%">
          {header && (
            <Heading as="h3" size="md" mb="20px" width="100%">
              {header}
            </Heading>
          )}
        </Flex>

        {children}
        <Flex flexDirection="column" alignItems="center" mt="30px">
          <ContactUsWhatsApp />
        </Flex>
      </LoginBlock>
    </Flex>
  )
}
