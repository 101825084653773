import { useState, useEffect } from 'react'
import dayjs from 'dayjs'

export const useDateLocale = (locale: string) => {
  const [isLocaleReady, setIsLocaleReady] = useState(false)

  useEffect(() => {
    let cancel = false

    async function loadLocale(localeToLoad: string) {
      setIsLocaleReady(false)
      try {
        await import(`dayjs/locale/${localeToLoad}.js`)

        if (!cancel) {
          dayjs.locale(localeToLoad)
        }
      } catch (error) {
        console.error(`Failed to load locale ${localeToLoad}`, error)
      } finally {
        if (!cancel) {
          setIsLocaleReady(true)
        }
      }
    }

    if (locale) {
      loadLocale(locale)
    }

    return () => {
      cancel = true
    }
  }, [locale])

  return isLocaleReady
}
