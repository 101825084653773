import { Locale } from 'date-fns'

export const loadDatePickerLocale = async (locale: string): Promise<Locale> => {
  try {
    const localeModule = await import(`date-fns/locale/${locale}`)
    return localeModule.default
  } catch (error) {
    console.error(`Failed to load locale "${locale}", falling back to "en-US".`, error)
    const defaultLocaleModule = await import('date-fns/locale/en-US')
    return defaultLocaleModule.default
  }
}
