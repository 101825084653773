import React from 'react'
import { Flex, useDisclosure } from '@chakra-ui/core'
import LocaleText from 'components/LocaleText'
import { PostModal } from '../NewsFeed/PostModal'
import { ReactComponent as CreatePostIcon } from 'assets/icon_sms.svg'

export const ComposeButton: React.FC<{ setIsHidden?: (isHidden: boolean) => void }> = ({ setIsHidden }) => {
  const { isOpen: isOpenPostModal, onOpen: onOpenPostModal, onClose: onClosePostModal } = useDisclosure()

  const handleOpenPostModal = () => {
    setIsHidden?.(true)
    onOpenPostModal()
  }

  const handleClosePostModal = () => {
    setIsHidden?.(false)
    onClosePostModal()
  }

  return (
    <>
      <Flex
        onClick={handleOpenPostModal}
        w="max-content"
        alignItems="center"
        style={{ columnGap: '10px' }}
        cursor="pointer"
      >
        <Flex
          bg="#F5ADBF"
          borderRadius="10px"
          h="36px"
          w="36px"
          color="white"
          alignItems="center"
          justifyContent="center"
        >
          <CreatePostIcon />
        </Flex>
        <LocaleText text="componse_post" />
      </Flex>

      {isOpenPostModal && <PostModal isOpen={isOpenPostModal} onClose={handleClosePostModal} />}
    </>
  )
}
