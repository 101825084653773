import React, { useRef, useEffect, useState, memo } from 'react'
import { Text } from '@chakra-ui/core'
import LocaleText from 'components/LocaleText'

interface CountdownTimerProps {
  initialSeconds: number
  onExpire?: () => void
  isActive?: boolean
  timeRemainingText?: string
  expiredText?: string
  isMaxAttemptsReached?: boolean
  maxAttemptsText?: string
}

export const CountdownTimer = memo(
  ({
    initialSeconds,
    onExpire,
    isActive = true,
    timeRemainingText = 'time_remaining',
    expiredText = 'code_expired',
    isMaxAttemptsReached = false,
    maxAttemptsText = 'max_attempts_reached',
  }: CountdownTimerProps) => {
    const [timeLeft, setTimeLeft] = useState(initialSeconds)
    const intervalRef = useRef<NodeJS.Timeout | null>(null)

    useEffect(() => {
      if (!isActive || timeLeft <= 0) return

      intervalRef.current = setInterval(() => {
        setTimeLeft((prev) => {
          if (prev <= 1) {
            clearInterval(intervalRef.current!)
            onExpire?.()
            return 0
          }
          return prev - 1
        })
      }, 1000)

      return () => clearInterval(intervalRef.current!)
    }, [isActive, onExpire, timeLeft])

    useEffect(() => {
      setTimeLeft(initialSeconds)
    }, [initialSeconds])

    const formatTime = (seconds: number): string => {
      const minutes = Math.floor(seconds / 60)
      const remainingSeconds = seconds % 60
      return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`
    }

    return (
      <Text textAlign="center" color={timeLeft === 0 || isMaxAttemptsReached ? 'red.500' : 'gray.600'}>
        {isMaxAttemptsReached ? (
          <LocaleText text={maxAttemptsText} />
        ) : timeLeft === 0 ? (
          <LocaleText text={expiredText} />
        ) : (
          <>
            <LocaleText text={timeRemainingText} />: <span>{formatTime(timeLeft)}</span>
          </>
        )}
      </Text>
    )
  },
  (prevProps, nextProps) =>
    prevProps.initialSeconds === nextProps.initialSeconds &&
    prevProps.isActive === nextProps.isActive &&
    prevProps.isMaxAttemptsReached === nextProps.isMaxAttemptsReached &&
    prevProps.onExpire === nextProps.onExpire
)

CountdownTimer.displayName = 'CountdownTimer'
