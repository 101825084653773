import React from 'react'
import { Controller, Control } from 'react-hook-form'
import { TaskInput } from 'components/TasksV2/UI/TaskInput'
import { regExLinkRecognizer } from 'constants/baseValues'

interface LinkInputFieldProps {
  name: string
  control: Control
  placeholder: string
  defaultValue?: string
}

export const LinkInputField: React.FC<LinkInputFieldProps> = ({ name, control, placeholder, defaultValue }) => (
  <Controller
    as={TaskInput}
    name={name}
    control={control}
    placeholder={placeholder}
    rules={{
      required: 'Title is required',
      pattern: {
        value: regExLinkRecognizer,
        message: 'Please enter a valid URL',
      },
    }}
    key={name}
    boxShadow="none"
    borderRadius="10px"
    border="none"
    px="5px"
    maxLength={150}
    defaultValue={defaultValue}
  />
)
