import { Box, Button, Divider, Flex, Text, useDisclosure } from '@chakra-ui/core'
import { BackButton } from 'components/TasksV2/UI/BackButton'
import BaseModalV2 from 'components/TasksV2/UI/BaseModalV2'
import useLocaleText from 'components/useLocaleText'
import { AppDispatch } from 'config/redux'
import { RootState, SupervisorOrHandler } from 'constants/interfaces'
import React, { useState } from 'react'
import { Controller, FormContext, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { serviceCallConfigSelector } from 'redux/selectors/config'
import { ConstantFields } from '../ConstantFileds'
import { TaskDescription } from 'components/TasksV2/TasksOverview/Content/Main/TaskDescription'
import { ServiceCallTypesOverview } from './ServiceCallTypesOverview'
import { AssigneesMenu } from './AssigneesMenu'
import { Notes } from './Notes'
import { ServiceCall, updateServiceCall } from 'redux/actions/serviceCall'
import { ControlledStatusesMenu } from './ControlledStatusesMenu'
import { ChatCreationForm } from '../ChatCreationForm'
import { ServiceCallChat } from '../ServiceCallChat'
import ConfirmationPopup from 'components/CommonComponents/ConfirmationPopup'
import { SERVICE_CALL_STATUSES } from '../utils'
import { setChats } from 'redux/actions/feed'

interface Props {
  isOpen: boolean
  onClose: VoidFunction
  serviceCallViewId: number
  serviceCallNumber?: number
  isAllowToUpdate?: boolean
  isDashboardview?: boolean
  isSupervisor?: boolean
}

const ServicaCallOveviewModal: React.FC<Props> = ({
  isOpen,
  onClose,
  serviceCallViewId,
  serviceCallNumber,
  isAllowToUpdate = false,
  isDashboardview = false,
  isSupervisor = false,
}) => {
  const dispatch: AppDispatch = useDispatch()
  const { isOpen: isConfirmationOpen, onOpen: onOpenConfirmation, onClose: onCloseConfirmation } = useDisclosure()
  const serviceCallView = useSelector(serviceCallConfigSelector).find(
    ({ task_view_id }) => task_view_id === serviceCallViewId
  )
  const theme = useSelector((state: RootState) => state.general.theme)
  const { serviceCalls, historicalServiceCalls, serviceCallsDashboard } = useSelector(
    (state: RootState) => state.serviceCalls
  )
  const chats = useSelector((state: RootState) => state.feed.chats)

  const [isFetching, setIsFetching] = useState(false)
  const [updatedServiceCallData, setUpdatedServiceCallData] = useState<ServiceCall | null>(null)
  const serviceCallsList = isDashboardview ? serviceCallsDashboard : [...serviceCalls, ...historicalServiceCalls]
  const serviceCall = serviceCallsList.find(({ service_call_number }) => service_call_number === serviceCallNumber)

  const publish = useLocaleText('save')
  const description = useLocaleText('description')
  const card_confirm_text = useLocaleText('confirm_close_service_call')
  const card_confirm_desc = useLocaleText('confirm_close_service_call_desc')
  const yes = useLocaleText('yes')
  const no = useLocaleText('no')

  const methods = useForm({
    defaultValues: {
      service_call_id: serviceCall?.service_call_id,
      status: serviceCall?.status,
      assignee: serviceCall?.assignee,
      priority: 1,
      notes: serviceCall?.notes ?? '',
    },
  })

  const { control, watch } = methods

  const assignee: SupervisorOrHandler = watch('assignee')
  const status: number = watch('status')

  const handleUpdateServiceCall = async (data: any) => {
    const refToChat = chats.find((chat) => chat.post_id === serviceCall?.ref_to_chat)?.post_id
    try {
      setIsFetching(true)
      const payload = {
        service_call_id: data.service_call_id,
        assignee: data.assignee,
        status: data.status,
        notes: data.notes,
        ref_to_chat: refToChat,
      }

      const res = await dispatch(updateServiceCall({ ...payload }))
      if (res) {
        const isServiceCallClosed = data.status === SERVICE_CALL_STATUSES.CLOSED

        if (isServiceCallClosed) {
          const updatedChats = chats.filter((chat) => chat.post_id !== serviceCall?.ref_to_chat)
          dispatch(setChats({ chats: updatedChats, isRefresh: true }))
        }
        onClose()
      }
    } catch (e) {
      console.log(e)
    } finally {
      setIsFetching(false)
    }
  }

  const onSubmit = async (data: any) => {
    if (data.status === SERVICE_CALL_STATUSES.CLOSED) {
      setUpdatedServiceCallData(data)
      onOpenConfirmation()
      return
    } else {
      handleUpdateServiceCall(data)
    }
  }

  if (!serviceCall || !serviceCallView || !serviceCallView.service_request_config) {
    return null
  }

  const {
    desc,
    videos,
    images,
    files,
    config: { options },
  } = serviceCall

  const {
    service_request_config: { handlers, statuses },
  } = serviceCallView

  const isEditable = isAllowToUpdate && isDashboardview
  const isStatusClosed = serviceCall?.status === SERVICE_CALL_STATUSES.CLOSED

  return (
    <>
      <FormContext {...methods}>
        <BaseModalV2
          isOpen={isOpen}
          onClose={onClose}
          modalFooterProps={{ background: '#f4f4f4' }}
          header={
            <Flex justifyContent="space-between" h="60px" alignItems="center">
              <BackButton title={`#${serviceCall?.service_call_number.toString()}`} event={onClose} />
              {isAllowToUpdate && isDashboardview && !isStatusClosed && (
                <Button
                  bg={theme?.elementsColor}
                  color="white"
                  _focus={{ outline: 'none' }}
                  borderRadius="20px"
                  isLoading={isFetching}
                  isDisabled={isFetching}
                  onClick={methods.handleSubmit(onSubmit)}
                >
                  <Text>{publish}</Text>
                </Button>
              )}
            </Flex>
          }
          body={
            <Box
              w={{ base: '100%', md: '80%' }}
              mx={'auto'}
              pointerEvents={isFetching ? 'none' : 'all'}
              opacity={isFetching ? 0.5 : 1}
            >
              {isEditable ? (
                <>
                  <Flex
                    flexDir="column"
                    style={{ rowGap: '30px' }}
                    my="20px"
                    mx="auto"
                    w={{ base: '100%', md: '80%', lg: '60%' }}
                  >
                    <AssigneesMenu handlers={handlers} isAllowToUpdate={isAllowToUpdate && !isStatusClosed} />
                    <ControlledStatusesMenu statuses={statuses} isAllowToUpdate={isAllowToUpdate && !isStatusClosed} />
                    <Notes isAllowToUpdate={isSupervisor && !isStatusClosed} />
                  </Flex>
                  {serviceCall?.ref_to_chat ? (
                    <ServiceCallChat serviceCall={serviceCall} />
                  ) : (
                    <>
                      {serviceCall?.status !== 1 && status !== 1 && (
                        <ChatCreationForm assignee={assignee} serviceCall={serviceCall} status={status} />
                      )}
                    </>
                  )}

                  <Divider my="30px" />
                </>
              ) : (
                <> {serviceCall?.ref_to_chat && !isDashboardview && <ServiceCallChat serviceCall={serviceCall} />} </>
              )}

              <Box opacity={0.7}>
                <ConstantFields
                  created_by={serviceCall?.created_by}
                  location_id={serviceCall.location_id}
                  isDisplayDate={false}
                >
                  <ServiceCallTypesOverview options={options} />
                  <Box>
                    <Text px="10px" pb="5px">
                      {description}
                    </Text>
                    <TaskDescription desc={desc} videos={videos} images={images} files={files} />
                  </Box>
                </ConstantFields>
              </Box>
              <Controller name="priority" control={control} as={<Box />} defaultValue={watch('priority')} />
              <Controller
                name="service_call_id"
                control={control}
                as={<Box />}
                defaultValue={watch('service_call_id')}
              />
            </Box>
          }
          footer={<></>}
        />
      </FormContext>
      <ConfirmationPopup
        isOpen={isConfirmationOpen}
        onConfirm={() => handleUpdateServiceCall(updatedServiceCallData!)}
        onCancel={onCloseConfirmation}
        title={card_confirm_text}
        description={card_confirm_desc}
        cancelButtonText={no}
        confirmButtonText={yes}
        isLoading={isFetching}
      />
    </>
  )
}

export default ServicaCallOveviewModal
