import { Flex, Stack, useToast, Text, Box } from '@chakra-ui/core'
import permanentData from 'components/AdminSettings/permanentData'
import Select from 'components/AdminSettings/UserForm/Select'
import { NoFocusButton } from 'components/CommonComponents/NoFocusButton'
import ToggleSwitch from 'components/CommonComponents/ToggleSwitch'
import LocaleText from 'components/LocaleText'
import useLocaleText from 'components/useLocaleText'
import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Mail, Phone } from 'lucide-react'
import { InputWithIcon } from 'components/CommonComponents/InputWithIcon'
import { getOTP } from 'redux/actions/auth'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from 'config/redux'
import { RootState } from 'constants/interfaces'

const buttonStyle = {
  borderRadius: '20px',
  width: '100%',
}

interface SendOtpProps {
  handleOtpAction: (data: any) => void
}

type FormValues = {
  country_code?: string
  phone_number?: string
  email?: string
}

export const GetOtpForm = ({ handleOtpAction }: SendOtpProps) => {
  const toast = useToast()
  const dispatch: AppDispatch = useDispatch()

  const { isLoginLoading } = useSelector((state: RootState) => state.auth)

  const [isChecked, setIsChecked] = useState(false)

  const t_sms = useLocaleText('sms')
  const otp_login = useLocaleText('otp_login')
  const email_login = useLocaleText('email_login')
  const send_otp = useLocaleText('send_otp')
  const invalid_email = useLocaleText('invalid_email')
  const invalid_phone_number = useLocaleText('invalid_phone_number')
  const invalid_country_code = useLocaleText('invalid_country_code')

  const { control, handleSubmit, errors, reset } = useForm<FormValues>({
    defaultValues: {
      country_code: '',
      phone_number: '',
      email: '',
    },
    mode: 'onSubmit',
  })

  const handleToggle = () => {
    setIsChecked((prev) => !prev)
    reset()
  }

  const onSubmit = async (data: any) => {
    const payload = {
      ...(!isChecked
        ? {
            country_code: data.country_code.id,
            phone_number: data.phone_number,
          }
        : {
            email: data.email,
          }),
    }

    const response = await dispatch(getOTP(payload))
    if (response) {
      handleOtpAction(payload)
    } else {
      toast({
        title: 'Error',
        description: 'Failed to send OTP',
        status: 'error',
      })
    }
  }

  return (
    <Stack
      direction="column"
      style={{ gap: '20px' }}
      pointerEvents={isLoginLoading ? 'none' : 'auto'}
      opacity={isLoginLoading ? 0.8 : 1}
    >
      <Text textAlign="center" fontSize="32px" mb="20px">
        <LocaleText text={otp_login} />
      </Text>
      <ToggleSwitch
        isChecked={isChecked}
        onChange={handleToggle}
        checkedLabel={email_login}
        uncheckedLabel={t_sms}
        trackProps={{
          backgroundColor: '#E2E8F0',
          color: '#718096',
          padding: '2px',
        }}
        thumbProps={{
          backgroundColor: '#FFFFFF',
          boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
        }}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        {!isChecked ? (
          <Flex alignItems="center" style={{ gap: '10px' }} my="10px">
            <Controller
              name="country_code"
              control={control}
              rules={{ required: !isChecked || invalid_country_code }}
              as={
                <Select
                  disabled={isLoginLoading ?? false}
                  isShowSearch={true}
                  placeholder="Code"
                  field="country_code"
                  options={permanentData.COUNTRY_CODES_LIST}
                  inInvalid={!!errors.country_code}
                  onChange={(value: any) => control.setValue('country_code', value)}
                />
              }
            />

            <Controller
              name="phone_number"
              control={control}
              rules={{ validate: (value) => !!value || invalid_phone_number }}
              as={
                <InputWithIcon
                  type="number"
                  borderRadius="10px"
                  style={{ marginBottom: '0px' }}
                  h="40px"
                  _focus={{ boxShadow: 'none' }}
                  isInvalid={!!errors.phone_number}
                  icon={<Phone size={16} />}
                />
              }
              disabled={isLoginLoading ?? false}
            />
          </Flex>
        ) : (
          <Controller
            name="email"
            control={control}
            rules={{
              validate: (value) => permanentData.EMAIL_REG_EX.test(value) || invalid_email,
            }}
            as={
              <Box my="10px">
                <InputWithIcon
                  type="email"
                  borderRadius="10px"
                  h="40px"
                  _focus={{ boxShadow: 'none' }}
                  isInvalid={!!errors.email}
                  icon={<Mail size={16} />}
                  elementProps={{ mx: '10px' }}
                />
              </Box>
            }
            disabled={isLoginLoading ?? false}
          />
        )}

        <NoFocusButton
          isDisabled={isLoginLoading ?? false}
          isLoading={isLoginLoading ?? false}
          type="submit"
          style={{ ...buttonStyle, marginTop: '20px' }}
          bg="#d53f8c"
          color="white"
        >
          <LocaleText text={send_otp} />
        </NoFocusButton>
      </form>
    </Stack>
  )
}
