import { Avatar, Flex, Text } from '@chakra-ui/core'
import dayjs from 'dayjs'
import React from 'react'

import localeData from 'dayjs/plugin/localeData'
import 'dayjs/locale/en'
import { useSelector } from 'react-redux'
import { RootState } from 'constants/interfaces'

dayjs.extend(localeData)

interface IProps {
  id: string
  avatar?: string
  name: string
  date?: string
}
const ContextInfo: React.FC<IProps> = ({ id, avatar, name: name, date }) => {
  const { locale } = useSelector((state: RootState) => state.general)
  const { retailUsersObject, mappedGroupsByUsers } = useSelector((state: RootState) => state.config.config)
  const userObject = retailUsersObject?.[id]
  const userDefaultGroup = mappedGroupsByUsers?.[id]?.[0]
  return (
    <Flex direction="row" alignItems="center" fontSize="16px" style={{ columnGap: '10px' }}>
      <Avatar src={avatar} name={name} />
      <Flex direction="column" alignItems="flex-start" justifyContent="center">
        <Flex direction="column">
          <Text fontWeight={600}>{name}</Text>
          {userObject && (
            <Text
              fontSize="14px"
              color="#9c9c9c"
              maxW={{ base: '150px', md: '190px', xl: '100%' }}
              style={{
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
              }}
            >
              {userDefaultGroup}
            </Text>
          )}
        </Flex>
        {date && <Text>{dayjs(date).locale(locale).format('DD MMMM YYYY HH:mm')}</Text>}
      </Flex>
    </Flex>
  )
}
export default ContextInfo
