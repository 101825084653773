import { Flex, Skeleton } from '@chakra-ui/core'
import { SkeletonText } from 'components/CommonComponents/SkeletonText'
import React from 'react'

export const MediaOverviewSkeleton = () => {
  return (
    <Flex flexDir="column" borderRadius="15px" background="white" overflow="hidden">
      <Skeleton height="300px" />
      <Flex alignItems="center" justifyContent="center" pt="10px">
        <SkeletonText height="30px" width="30%" />
      </Flex>
      <Flex alignItems="center" justifyContent="space-between" p="10px">
        <SkeletonText width="70%" />
        <Skeleton height="23px" width="30px" />
      </Flex>
    </Flex>
  )
}
