import { useMemo } from 'react'
import keys from 'constants/keys'
import { Result } from 'constants/interfaces'
import { SubtaskDeepDiveItem } from 'redux/actions/tasks_v2'

interface UseMediaMappingReturn {
  images: string[]
  videos: string[]
  media: string[]
  mediaIndexToStId: string[]
}

export const useMediaMapping = (results: Result[], subtasks: SubtaskDeepDiveItem[]): UseMediaMappingReturn => {
  const { images, videos, imageIndexToStId, videoIndexToStId } = useMemo(() => {
    const imagesArray: string[] = []
    const videosArray: string[] = []
    const imageMapping: string[] = []
    const videoMapping: string[] = []

    const resultsByStId: { [key: string]: Result } = {}
    results.forEach((result) => {
      if (result?.st_id) {
        resultsByStId[result.st_id] = result
      }
    })

    subtasks.forEach((subtask) => {
      const st_id = subtask.st_id
      const result = resultsByStId[st_id]

      if (subtask.type === keys.SUBTASK_TYPES.IMAGE_SUBTASK) {
        const images = result?.images ?? []
        imagesArray.push(...images)
        imageMapping.push(...Array(images.length).fill(st_id))
      }

      if (subtask.type === keys.SUBTASK_TYPES.VIDEO_SUBTASKS) {
        const videos = result?.videos ?? []
        videosArray.push(...videos)
        videoMapping.push(...Array(videos.length).fill(st_id))
      }
    })

    return { images: imagesArray, videos: videosArray, imageIndexToStId: imageMapping, videoIndexToStId: videoMapping }
  }, [results, subtasks])

  const media = useMemo(() => [...images, ...videos], [images, videos])
  const mediaIndexToStId = useMemo(
    () => [...imageIndexToStId, ...videoIndexToStId],
    [imageIndexToStId, videoIndexToStId]
  )

  return { images, videos, media, mediaIndexToStId }
}
