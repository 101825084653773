import { Box, Text, Collapse, Heading } from '@chakra-ui/core'
import { renderTextWithLineBreaks } from 'components/Post/PostTitle'
import React, { memo, useEffect, useRef, useState } from 'react'
import { isHtml, isRTL } from 'utils'
import { WidgetWrapper } from 'components/TasksV2/UI/WidgetWrapper'
import MediaGallery from 'components/TasksV2/UI/MediaGallery'
import useLocaleText from 'components/useLocaleText'

interface Props {
  desc: string
  videos: string[]
  images: string[]
  files: string[]
}

const TextDescription = memo(({ text }: { text: string }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [isCollapsible, setIsCollapsible] = useState(false)
  const t_see_more = useLocaleText('t_see_more')
  const t_see_less = useLocaleText('t_see_less')
  const contentRef = useRef<HTMLDivElement>(null)
  const startingHeight = 100

  useEffect(() => {
    if (contentRef.current) {
      setIsCollapsible(contentRef.current.scrollHeight > startingHeight + 20)
    }
  }, [text])

  const toggleExpand = () => setIsExpanded(!isExpanded)

  return (
    <Box textAlign={isRTL(text) ? 'right' : 'left'} bg="white" borderRadius="12px" overflow="hidden">
      <Collapse startingHeight={isCollapsible ? startingHeight : 'auto'} isOpen={isExpanded}>
        <Box ref={contentRef}>
          {isHtml(text)
            ? text
                .split('\n')
                .filter(Boolean)
                .map((paragraph: string, idx) => (
                  <Heading
                    as="p"
                    key={`${paragraph}-${idx}`}
                    fontSize="16px"
                    my="15px"
                    dangerouslySetInnerHTML={{ __html: paragraph }}
                  />
                ))
            : renderTextWithLineBreaks({ text, isShowPreview: true })}
        </Box>
      </Collapse>

      {isCollapsible && (
        <Text color="blue.500" onClick={toggleExpand} cursor="pointer">
          {isExpanded ? t_see_less : t_see_more}
        </Text>
      )}
    </Box>
  )
})

export const TaskDescription: React.FC<Props> = ({ desc, videos, images, files }) => {
  if (!desc && !images?.length && !files?.length && !videos?.length) return null
  return (
    <WidgetWrapper mt="15px" p="15px">
      {desc && <TextDescription text={desc} />}

      <MediaGallery attachedImages={images} attachedVideos={videos} attachedFiles={files} isShowRemoveIcon={false} />
    </WidgetWrapper>
  )
}
