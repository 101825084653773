import dayjs from 'dayjs'
import { useState, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

const useStartDatePicker = () => {
  const { watch, setValue } = useFormContext()

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)

  const fromTs = useMemo(() => {
    const watchedValue = watch('from_ts')
    return watchedValue ? new Date(watchedValue) : new Date()
  }, [watch])

  const startDate = useMemo(() => dayjs(fromTs).format('YYYY-MM-DD'), [fromTs])
  const startTime = useMemo(() => dayjs(fromTs).format('HH:mm'), [fromTs])

  const deadline = watch('deadline') ? new Date(watch('deadline')) : null

  const handleOpenDatePicker = () => setIsDatePickerOpen(true)
  const handleCloseDatePicker = () => setIsDatePickerOpen(false)

  const handleDateChange = (date: Date | null) => {
    if (date) {
      const updatedTs = dayjs(fromTs)
        .set('year', date.getFullYear())
        .set('month', date.getMonth())
        .set('date', date.getDate())
        .toISOString()

      setValue('from_ts', updatedTs)

      if (deadline && dayjs(updatedTs).isAfter(dayjs(deadline))) {
        setValue('deadline', null)
      }
    }
  }

  const handleTimeChange = (time: string) => {
    const [hours, minutes] = time.split(':').map(Number)
    const updatedTs = dayjs(fromTs).set('hour', hours).set('minute', minutes).toISOString()
    setValue('from_ts', updatedTs)
  }

  return {
    isDatePickerOpen,
    handleOpenDatePicker,
    handleCloseDatePicker,
    handleDateChange,
    handleTimeChange,
    startDate,
    startTime,
  }
}

export default useStartDatePicker
