import { actionTypes } from '../actions/auth'
import { AuthState } from '../../constants/interfaces'

export const initialState: AuthState = {
  token: null,
  isLoggedIn: false,
  isLoginLoading: false,
  isError: false,
  errorMessage: '',
  isLoginByLink: false,
  candidateToLogin: null,
  isSessionClosed: false,
  uid: '',
}

const AuthReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_USER_NAME: {
      return {
        ...state,
        userName: action.payload,
      }
    }
    case actionTypes.SET_USERS_FORGOT_PASSWORD_DETAILS:
      return {
        ...state,
        forgotPasswordDetails: action.payload,
      }

    case actionTypes.SET_UID: {
      return {
        ...state,
        uid: action.payload,
      }
    }
    case actionTypes.SET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload,
      }
    case actionTypes.LOGIN_BY_LINK:
      return {
        ...state,
        isLoginByLink: true,
      }
    case actionTypes.SET_TOKEN:
      return {
        ...state,
        token: action.token,
        isLoggedIn: true,
        isSessionClosed: false,
      }
    case actionTypes.SET_REFRESHED_TOKEN:
      return {
        ...state,
        token: action.payload,
      }
    case actionTypes.SET_IS_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: action.payload,
        isSessionClosed: false,
      }
    case actionTypes.CLEAR_TOKEN:
      return {
        ...state,
        token: null,
        isLoggedIn: false,
      }
    case actionTypes.SET_IS_LOGIN_LOADING:
      return {
        ...state,
        isLoginLoading: action.payload,
      }
    case actionTypes.SET_IS_ERROR:
      return {
        ...state,
        isError: action.isError,
      }
    case actionTypes.SET_CANDIDATE_TO_LOGIN:
      return {
        ...state,
        candidateToLogin: action.payload,
      }
    case actionTypes.SET_IS_SESSION_CLOSED:
      return {
        ...state,
        isSessionClosed: action.payload,
      }
    default:
      return state
  }
}

export default AuthReducer
