import React from 'react'
import { Button, Text } from '@chakra-ui/core'
import { ControlProps } from 'react-select'
import { IOption } from './SelectOption'
import { useSelector } from 'react-redux'
import { RootState } from 'constants/interfaces'
import { GoTriangleDown } from 'react-icons/go'

interface SelectControlProps extends ControlProps<IOption, false> {
  inInvalid?: boolean
  disabled: boolean
}

export const SelectControl = ({ innerRef, inInvalid, disabled, ...props }: SelectControlProps) => {
  const theme = useSelector((state: RootState) => state.general.theme)
  const onToggleMenu = () => {
    if (props.selectProps.menuIsOpen && props?.selectProps?.onMenuClose) {
      props?.selectProps?.onMenuClose()
    }
    if (!props.selectProps.menuIsOpen && props?.selectProps?.onMenuOpen) {
      props?.selectProps?.onMenuOpen()
    }
  }

  return (
    <Button
      isDisabled={disabled}
      ref={innerRef}
      bg="none"
      onClick={onToggleMenu}
      height="40px"
      borderRadius="10px"
      border="1px solid "
      style={{ columnGap: '5px' }}
      borderColor={inInvalid ? '#e53e3e' : '#E2E8F0'}
      boxShadow={inInvalid ? '0 0 0 1px #e53e3e' : 'none'}
      alignItems="center"
      justifyContent="center"
      color={theme?.backgroundMedium}
      cursor="pointer"
      overflow="hidden"
      w="110px"
      px="5px"
      isTruncated
      _hover={{ boxShadow: `0 0 0 1px ${theme?.elementsColor}` }}
      _focus={{ boxShadow: `0 0 0 1px ${theme?.elementsColor}`, border: `1px solid ${theme?.elementsColor}` }}
    >
      <Text maxW="105px" fontSize="12px" color="#8A919B" isTruncated>
        {props.children}
      </Text>
      <GoTriangleDown />
    </Button>
  )
}
