import WidgetHeader from 'components/TasksV2/UI/WidgetHeader'
import { WidgetWrapper } from 'components/TasksV2/UI/WidgetWrapper'
import React, { memo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Box, Divider } from '@chakra-ui/core'
import { LinkTitle } from './LinkTitle'
import { LinkPath } from './LinkPath'
import { useWidgetErrorMessage } from '../../useWidgetErrorMessage'
import { WidgetProps } from '../types'
import { WidgetIsRequiredSwitch } from '../WidgetIsRequiredSwitch'

const LinkWidget: React.FC<WidgetProps> = memo(({ index, isDragging, field, remove, subtaskPath }) => {
  const { control, errors } = useFormContext()

  const titleName = `${subtaskPath}.title`
  const pathName = `${subtaskPath}.external_link`

  const linkError = useWidgetErrorMessage({ errors, index, field })

  return (
    <WidgetWrapper pos="relative" p="10px" isDragging={isDragging} isError={!!linkError} errorMessage={linkError}>
      <WidgetHeader onRemove={remove} subtaskType={field.type} />

      <Box mt="25px" mb="15px" border="2px solid #f0f0f0" borderRadius="10px" style={{ rowGap: '10px' }} p="10px">
        <LinkTitle titleName={titleName} field={field} />
        <Divider my={0} />
        <LinkPath pathName={pathName} titleName={titleName} field={field} />
      </Box>

      <WidgetIsRequiredSwitch subtaskPath={subtaskPath} />

      {/*Register constant values in the form context*/}
      <Controller
        name={`${subtaskPath}.type`}
        control={control}
        as={<Box />}
        key={`${subtaskPath}.type`}
        defaultValue={field?.type}
      />
    </WidgetWrapper>
  )
})

export default LinkWidget
