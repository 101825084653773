import { Menu, MenuButton, MenuItem, MenuList, useDisclosure } from '@chakra-ui/core'
import ConfirmationPopup from 'components/CommonComponents/ConfirmationPopup'
import colors from 'constants/colors'
import { Ellipsis } from 'lucide-react'
import React from 'react'
import { useSelector } from 'react-redux'
import { isRtlSelector } from 'redux/selectors/general'
import useLocaleText from 'components/useLocaleText'

interface IProps {
  menuItemText: string
  isLoading: boolean
  handleReset: () => void
}
export const ResetMenu: React.FC<IProps> = ({ menuItemText, handleReset, isLoading }) => {
  const isRtl = useSelector(isRtlSelector)
  const {
    isOpen: isResetConfirmationOpen,
    onOpen: onOpenResetConfirmation,
    onClose: onCloseResetConfirmation,
  } = useDisclosure()

  const card_confirm_text = useLocaleText('card_confirm_text')
  const card_confirm_desc = useLocaleText('card_confirm_desc')
  const yes = useLocaleText('yes')
  const no = useLocaleText('no')

  const handleConfirm = () => {
    handleReset()
    onCloseResetConfirmation()
  }

  return (
    <>
      <Menu>
        <MenuButton borderWidth="none">
          <Ellipsis color={colors.analyticGrey} />
        </MenuButton>

        <MenuList placement={isRtl ? 'bottom-start' : 'bottom-end'}>
          <MenuItem onClick={onOpenResetConfirmation}>{menuItemText}</MenuItem>
        </MenuList>
      </Menu>
      {isResetConfirmationOpen && (
        <ConfirmationPopup
          onCancel={onCloseResetConfirmation}
          onConfirm={handleConfirm}
          isOpen={isResetConfirmationOpen}
          title={card_confirm_text}
          description={card_confirm_desc}
          cancelButtonText={no}
          confirmButtonText={yes}
          isLoading={isLoading}
        />
      )}
    </>
  )
}
