import { Flex, useDisclosure } from '@chakra-ui/core'
import LocaleText from 'components/LocaleText'
import TaskCreationModal from 'components/TasksV2/TaskCreation/TaskCreationModal'
import React from 'react'
import { ReactComponent as MenuTaskIcon } from 'assets/Icon_task.svg'

export const CreateTaskV2Button: React.FC<{ setIsHidden?: (hide: boolean) => void }> = ({ setIsHidden }) => {
  const { isOpen: isOpenCreateTask, onOpen: onOpenCreateTask, onClose: onCloseCreateTask } = useDisclosure()

  const handleOpenTaskCreationModal = () => {
    setIsHidden?.(true)
    onOpenCreateTask()
  }

  const handleCloseTaskCreationModal = () => {
    setIsHidden?.(false)
    onCloseCreateTask()
  }

  return (
    <>
      <Flex
        onClick={handleOpenTaskCreationModal}
        w="max-content"
        alignItems="center"
        style={{ columnGap: '10px' }}
        cursor="pointer"
      >
        <Flex
          bg="#DDCCE9"
          borderRadius="10px"
          h="36px"
          w="36px"
          color="white"
          alignItems="center"
          justifyContent="center"
        >
          <MenuTaskIcon style={{ fill: 'white' }} width="20px" height="20px" />
        </Flex>
        <LocaleText text="create_task_btn" />
      </Flex>
      {isOpenCreateTask && <TaskCreationModal isOpen={isOpenCreateTask} onClose={handleCloseTaskCreationModal} />}
    </>
  )
}
