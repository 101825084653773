import { Box, Flex, FlexProps, Spinner, Text } from '@chakra-ui/core'
import colors from 'constants/colors'
import { RootState } from 'constants/interfaces'
import { MessageSquare } from 'lucide-react'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { isRtlSelector } from 'redux/selectors/general'

interface IProps extends FlexProps {
  totalCommentsCount: number
  unredCommentsCounter: number
  loading: boolean
}

export const ConmmentsCount: React.FC<IProps> = memo(
  ({ totalCommentsCount, unredCommentsCounter, loading, ...props }) => {
    const theme = useSelector((state: RootState) => state.general.theme)
    const isRtl = useSelector(isRtlSelector)

    return (
      <Flex
        pos="absolute"
        bottom="10px"
        left={isRtl ? '15px' : 'unset'}
        right={isRtl ? 'unset' : '15px'}
        alignItems="center"
        {...props}
      >
        {loading ? (
          <Spinner h="14px" w="14px" borderWidth="1px" color={colors.analyticGrey} />
        ) : (
          <Text fontSize="14px" px="3px">
            {totalCommentsCount}
          </Text>
        )}
        <MessageSquare size={22} />
        {unredCommentsCounter > 0 && (
          <Flex
            pos="absolute"
            top="-5px"
            p="8px"
            w="15px"
            h="15px"
            justifyContent={'center'}
            alignItems="center"
            left={isRtl ? '-3px' : 'unset'}
            right={isRtl ? 'unset' : '-3px'}
            borderRadius="full"
            padding="0"
            bg={theme?.elementsColor}
          >
            <Box as={'span'} fontSize="9px" fontWeight="500" color="white">
              {unredCommentsCounter}
            </Box>
          </Flex>
        )}
      </Flex>
    )
  }
)
