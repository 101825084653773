import { Flex, Text } from '@chakra-ui/core'
import React from 'react'
import { VotedParticipantsAvatars } from './VotedParticipantsAvatars'
import { SubtaskDeepDiveItem } from 'redux/actions/tasks_v2'

interface IProps {
  count: number
  currentQuestionParticipants: {
    id: string
    date: string
    isClickable: boolean
    insights?: {
      items: SubtaskDeepDiveItem[]
      typeCounts: {
        [type: number]: number
      }
    }
  }[]
  label: string
}
export const PollVotedAvatars: React.FC<IProps> = ({ count, currentQuestionParticipants, label }) => {
  const votesCount = count > 4 ? `+${currentQuestionParticipants.length - 4}` : count

  return (
    <Flex alignItems="flex-end" justifyContent="space-between" w="100%">
      <Text fontWeight="500" fontSize="18px" fontFamily="Arial">
        {label}
      </Text>
      <Flex alignItems="center" h="24px">
        <VotedParticipantsAvatars participants={currentQuestionParticipants} />
        {count > 0 && (
          <Text fontWeight="bold" fontSize="12px" mx="5px">
            {votesCount}
          </Text>
        )}
      </Flex>
    </Flex>
  )
}
