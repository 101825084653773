import React, { useMemo } from 'react'
import { Flex, Text } from '@chakra-ui/core'
import { SelectDropdown, Item } from 'components/CommonComponents/Select/Control'
import { useSelector } from 'react-redux'
import { RootState } from 'constants/interfaces'
import { groupDynamicParamsByParameter } from 'redux/reducers/reports'
import keys from 'constants/keys'

interface IDynamicParamsSelectProps {
  selectedIds?: string[]
  onSubmit?: (items: string[]) => void
  menuButtonLiteral?: string
  isLoading?: boolean
  minW?: string
  mode?: string
}

export const DynamicParamsSelect: React.FC<IDynamicParamsSelectProps> = ({
  selectedIds,
  onSubmit,
  menuButtonLiteral = 'dynamic_params_filter',
  isLoading = false,
  minW = '330px',
  mode = keys.SELECT_MODES.MULTIPLE,
}) => {
  const dynamicParams = useSelector((state: RootState) => state.reports.dynamicParams)

  const groupedParams = useMemo(() => {
    return groupDynamicParamsByParameter(dynamicParams ?? [])
  }, [dynamicParams])

  const itemsDataArray = useMemo(() => {
    const result: Item[] = []

    Object.entries(groupedParams).forEach(([parameter, items]) => {
      result.push({
        id: `header-${parameter}`,
        name: items[0]?.ui_parameter || parameter,
        isHeader: true,
      })

      items.forEach((item) => {
        if (item.segment) {
          result.push({
            id: `${item.parameter}-${item.segment}`,
            name: item.segment || 'Без названия',
            parameter: item.parameter,
            ui_parameter: item.ui_parameter,
            groups: [item.ui_parameter!],
          })
        }
      })
    })

    return result
  }, [groupedParams])

  return (
    <SelectDropdown
      itemsDataArray={itemsDataArray}
      selectedIds={selectedIds}
      onSubmit={onSubmit}
      menuButtonLiteral={menuButtonLiteral}
      isLoading={isLoading}
      minW={minW}
      mode={mode}
      isShowSearch={true}
      renderCustomItem={(item) => {
        if (item.isHeader) {
          return (
            <Flex w="100%" px="15px" py="8px" bg="gray.100" fontWeight="bold" key={item.id}>
              <Text>{item.name}</Text>
            </Flex>
          )
        }
        return null
      }}
    />
  )
}
