import { Box } from '@chakra-ui/core'
import { TaskInput } from 'components/TasksV2/UI/TaskInput'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import useLocaleText from 'components/useLocaleText'
import { Subtask } from 'components/TasksV2/TaskCreation/FormStepContext'

interface Props {
  questionName: string
  field: Subtask
}
export const ImageQeustion: React.FC<Props> = ({ questionName, field }) => {
  const { control } = useFormContext()
  const t_pls_add_picture = useLocaleText('t_pls_add_picture')

  return (
    <Box my="15px">
      <Controller
        as={TaskInput}
        name={questionName}
        control={control}
        placeholder={t_pls_add_picture}
        rules={{
          required: 'Title is required',
          validate: (value) => {
            return value.trim() !== '' || 'Title is required'
          },
        }}
        key={questionName}
        boxShadow="none"
        borderRadius="10px"
        border="none"
        defaultValue={field.title}
      />
    </Box>
  )
}
