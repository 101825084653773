import { Icon, Input, InputGroup, InputLeftElement, InputRightElement } from '@chakra-ui/core'
import colors from 'constants/colors'
import React from 'react'
import { useSelector } from 'react-redux'
import { isRtlSelector } from 'redux/selectors/general'

interface IProps {
  searchText: string
  setSearchText: (text: string) => void
}
export const Search = ({ searchText, setSearchText }: IProps) => {
  const isRtl = useSelector(isRtlSelector)
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value)
  }
  const inputLeftElementToggle = () => {
    if (isRtl) {
      if (!searchText) {
        return
      } else {
        return <Icon name="small-close" cursor="pointer" />
      }
    }
    return <Icon name="search" />
  }

  const inputRightElementToggle = () => {
    if (isRtl) {
      return <Icon name="search" />
    } else if (!searchText) {
      return
    }
    return <Icon name="small-close" cursor="pointer" />
  }
  return (
    <InputGroup w="90%" m="auto">
      <InputLeftElement fontSize="1em" color={colors.analyticGrey} onClick={() => (!isRtl ? '' : setSearchText(''))}>
        {inputLeftElementToggle()}
      </InputLeftElement>

      <Input
        value={searchText}
        onChange={handleChange}
        borderColor={colors.analyticGrey}
        _focus={{ borderColor: colors.analyticGrey }}
        variant="flushed"
        paddingRight="2.5rem"
        paddingLeft="2.5rem"
      />
      <InputRightElement fontSize="1em" color={colors.analyticGrey} onClick={() => (isRtl ? '' : setSearchText(''))}>
        {inputRightElementToggle()}
      </InputRightElement>
    </InputGroup>
  )
}
