import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState, Result } from 'constants/interfaces'
import keys from 'constants/keys'
import { SubtaskDeepDiveItem } from 'redux/actions/tasks_v2'
import { useMediaMapping } from './useMediaMapping'

interface UseResultsDataReturn {
  media: string[]
  images: string[]
  videos: string[]
  mediaIndexToStId: string[]
  getSubtaskTitle: (st_id: string) => string
  getSubtask: (st_id: string) => SubtaskDeepDiveItem | undefined
  results: Result[]
}

export const useResultsData = (
  tid: string,
  contextId: string,
  subtasks: SubtaskDeepDiveItem[]
): UseResultsDataReturn => {
  const { allResults } = useSelector((state: RootState) => state.tasks_v2)

  const results: Result[] = useMemo(() => {
    return (
      allResults?.[tid]?.filter(
        (result) =>
          result?.context_id === contextId &&
          [keys.SUBTASK_TYPES.IMAGE_SUBTASK, keys.SUBTASK_TYPES.VIDEO_SUBTASKS].includes(result?.sub_task_type)
      ) ?? []
    )
  }, [allResults, tid, contextId])

  const { images, videos, media, mediaIndexToStId } = useMediaMapping(results, subtasks)

  const getSubtaskTitle = (st_id: string) => {
    const subtask = subtasks.find((subtask) => subtask.st_id === st_id)
    return subtask?.title ?? 'Unknown'
  }

  const getSubtask = (st_id: string) => {
    const subtask = subtasks.find((subtask) => subtask.st_id === st_id)
    return subtask
  }

  return { images, videos, media, mediaIndexToStId, getSubtaskTitle, getSubtask, results }
}
