import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { Box, Flex, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/core'
import LocaleText from 'components/LocaleText'
import { WidgetWrapper } from 'components/TasksV2/UI/WidgetWrapper'
import useLocaleText from 'components/useLocaleText'
import { IDynamicTaskView, IServiceCallConfig } from 'constants/interfaces'
import { ChevronDown } from 'lucide-react'

interface IProps {
  serviceCallView: IDynamicTaskView
}

export const ServiceCallTypeMenu: React.FC<IProps> = ({ serviceCallView }) => {
  const { service_request_config } = serviceCallView
  const { options } = service_request_config as IServiceCallConfig

  const { control, setValue, watch, errors, triggerValidation } = useFormContext()
  const t_choose_service_call_type = useLocaleText('choose_service_call_type')

  return (
    <Flex flexDir="column" style={{ rowGap: '15px' }} w="100%">
      {options.map((option, index) => {
        const currentValue = watch(`config.options[${index}]`)?.option
        const isPreviousOptionSelected = index === 0 || !!watch(`config.options[${index - 1}]`)?.option
        const pointerEvents = isPreviousOptionSelected ? 'all' : 'none'
        const cursor = isPreviousOptionSelected ? 'pointer' : 'not-allowed'
        const opacity = isPreviousOptionSelected ? 1 : 0.5
        const optionName = option.name

        const handleOptionChange = async (selectedValue: string) => {
          setValue(`config.options[${index}]`, { name: option.name, option: selectedValue })
          await triggerValidation(`config.options[${index}]`)

          for (let i = index + 1; i < options.length; i++) {
            setValue(`config.options[${i}]`, { name: options[i].name, option: '' })
          }
        }

        return (
          <Box key={index} w="100%" mx={'auto'}>
            <Text px="10px" pb="5px">
              <LocaleText text={optionName} />
            </Text>
            <Controller
              name={`config.options[${index}]`}
              control={control}
              defaultValue={{ name: option.name, option: '' }}
              rules={{
                validate: {
                  required: (value) => {
                    return value.option?.trim().length > 0
                  },
                },
              }}
              as={
                <Menu>
                  <MenuButton
                    as={Flex}
                    w="100%"
                    style={{ columnGap: '10px' }}
                    alignItems="center"
                    pointerEvents={pointerEvents}
                    cursor={cursor}
                    opacity={opacity}
                  >
                    <WidgetWrapper
                      p="10px"
                      borderRadius="10px"
                      cursor="pointer"
                      w="100%"
                      flexDir="row"
                      justifyContent="space-between"
                      alignItems="center"
                      isError={!!errors?.config?.options?.[index]}
                    >
                      <Text>
                        <LocaleText text={currentValue || t_choose_service_call_type} />
                      </Text>
                      <ChevronDown />
                    </WidgetWrapper>
                  </MenuButton>
                  <MenuList
                    maxH="300px"
                    h="max-content"
                    overflowY="auto"
                    fontSize="14px"
                    fontWeight="500"
                    borderRadius="10px"
                    placement="bottom-start"
                  >
                    {option.options.map((optionTitle, optionIndex) => (
                      <MenuItem
                        key={optionIndex}
                        py="10px"
                        style={{ columnGap: '10px' }}
                        onClick={() => handleOptionChange(optionTitle)}
                      >
                        <LocaleText text={optionTitle} />
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              }
            />
          </Box>
        )
      })}
    </Flex>
  )
}

export default ServiceCallTypeMenu
