import { Box, Flex, Spinner, useToast } from '@chakra-ui/core'
import TextareaAutosize from 'react-textarea-autosize'
import { MessageCirclePlus } from 'lucide-react'
import React, { useState, useRef, useEffect, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isRtlSelector } from 'redux/selectors/general'
import { TextAreaSubmitBtn } from 'components/TasksV2/UI/TextAreaSubmitBtn'
import useLocaleText from 'components/useLocaleText'
import { AppDispatch } from 'config/redux'
import { createGroup } from 'redux/actions/feed'
import keys from 'constants/keys'
import { createChatToken, ServiceCall, updateServiceCall } from 'redux/actions/serviceCall'
import { RootState, SupervisorOrHandler } from 'constants/interfaces'

const REF_TYPE = 1
interface IProps {
  serviceCall: ServiceCall
  assignee: SupervisorOrHandler
  status: number
}
export const ChatCreationForm: React.FC<IProps> = memo(({ serviceCall, assignee, status }) => {
  const { service_call_id, notes } = serviceCall
  const toast = useToast()
  const dispatch: AppDispatch = useDispatch()
  const [firstMessage, setFirstMessage] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const inputRef = useRef<HTMLTextAreaElement>(null)
  const isRtl = useSelector(isRtlSelector)
  const uid = useSelector((state: RootState) => state.auth.uid)
  const { retailUsersObject } = useSelector((state: RootState) => state.config.config)
  const type_something = useLocaleText('t_say_something')
  const avatar = retailUsersObject?.[uid!]?.profile_img_url

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFirstMessage(e.target.value)
  }

  const handleBlur = () => {
    if (firstMessage.trim() === '') {
      setIsOpen(false)
    }
  }

  const handleOpenMessageField = () => {
    setIsOpen(true)
  }

  const handleCreateChat = async () => {
    setIsFetching(true)
    try {
      const { users, groups, tags } = calculateAudience(serviceCall, assignee)

      const chatToken = await dispatch(createChatToken(service_call_id))
      if (!chatToken) {
        throw new Error('Error creating chat token.')
      }

      const chat = await dispatch(
        createGroup({
          title: serviceCall.service_call_number.toString(),
          content: firstMessage,
          groups,
          users,
          tags,
          images: avatar ? [avatar] : [],
          type: keys.POST_TYPE.GROUP_POST,
          ref_type: REF_TYPE,
          ref_id: service_call_id,
          service_call_id,
          token: chatToken,
          isInvokeStatusModal: false,
        })
      )
      if (!chat) {
        throw new Error('Error creating chat.')
      }

      const payload = {
        service_call_id,
        assignee,
        status,
        notes: notes ?? '',
        ref_to_chat: chat.post_id,
      }

      const isResOk = await dispatch(updateServiceCall({ ...payload }))
      if (!isResOk) {
        throw new Error('Error updating service call.')
      }

      setIsOpen(false)
      toast({
        title: 'Success',
        description: 'Chat was successfully created',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    } catch (e) {
      console.error(e)
      toast({
        title: 'Error',
        description: 'You dont have permission to create a chat',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    } finally {
      setIsFetching(false)
    }
  }

  useEffect(() => {
    if (isOpen) {
      inputRef.current?.focus()
    }
  }, [isOpen])

  return (
    <Flex flexDir="column" alignItems="center" pos="relative" mt="30px" w="100%">
      {!isOpen && (
        <Box>
          <MessageCirclePlus cursor="pointer" size={48} onClick={handleOpenMessageField} />
        </Box>
      )}
      {isFetching && <Spinner thickness="2px" speed=".5s" emptyColor="transparent" color="gray" size="xl" />}
      {isOpen && !isFetching && (
        <Flex style={{ rowGap: '10px' }} w="100%">
          <TextareaAutosize
            value={firstMessage ?? ''}
            onChange={handleChange}
            ref={inputRef}
            onBlur={handleBlur}
            minRows={2}
            placeholder={type_something}
            style={{
              transition: 'all 0.3s ease',
              padding: '10px',
              width: '100%',
              resize: 'none',
              outline: 'none',
              paddingLeft: isRtl ? '50px' : '10px',
              paddingRight: isRtl ? '10px' : '50px',
              background: 'white',
              borderRadius: '10px',
              boxShadow: '0px 2px 6px 0px  #dcdcdc',
              border: '1px solid #E0E0E0',
            }}
          />
          <Box
            pointerEvents={firstMessage.trim() === '' ? 'none' : 'all'}
            opacity={firstMessage.trim() === '' ? 0.5 : 1}
          >
            <TextAreaSubmitBtn onSubmit={handleCreateChat} />
          </Box>
        </Flex>
      )}
    </Flex>
  )
})

const calculateAudience = (data: ServiceCall, assignee?: SupervisorOrHandler) => {
  const audience = {
    users: new Set<string>(),
    groups: new Set<string>(),
    tags: new Set<string>(),
  }

  const processEntity = (entity: SupervisorOrHandler | SupervisorOrHandler[]) => {
    if (!entity) return
    if (Array.isArray(entity)) {
      entity.forEach((item) => processEntity(item))
    } else {
      const { context_type, context_id } = entity
      switch (context_type) {
        case 0:
          audience.users.add(context_id)
          break
        case 1:
          audience.groups.add(context_id)
          break
        case 2:
          audience.tags.add(context_id)
          break
        default:
          break
      }
    }
  }

  if (assignee) processEntity(assignee)
  if (data.supervisor) processEntity(data.supervisor)

  audience.groups.add(data.location_id)

  return {
    users: Array.from(audience.users),
    groups: Array.from(audience.groups),
    tags: Array.from(audience.tags),
  }
}
