import React from 'react'
import { Global, css } from '@emotion/core'
import colors from 'constants/colors'
import { RootState } from 'constants/interfaces'
import { useSelector } from 'react-redux'

export const GlobalStyles = () => {
  const theme = useSelector((state: RootState) => state.general.theme)
  return (
    <Global
      styles={css`
        html {
          scroll-behavior: smooth !important;
          font-size: 16px !important;
        }
        p {
          margin-block-start: 0;
          margin-block-end: 0;
          margin-inline-end: 0;
        }
        body {
          background: ${colors.greyMedium} !important;
          font-size: 16px !important;
          overflow-x: hidden !important;
        }

        ::-webkit-scrollbar {
          width: 8px;
          height: 8px;
        }

        ::-webkit-scrollbar-thumb {
          background: rgba(0, 0, 0, 0.5);
          border-radius: 4px;
        }

        ::-webkit-scrollbar-thumb:hover {
          background: rgba(0, 0, 0, 0.7);
        }

        ::-webkit-scrollbar-track {
          background: rgba(0, 0, 0, 0.1);
          border-radius: 4px;
        }

        /* Для Firefox */
        * {
          scrollbar-width: thin;
          scrollbar-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.1);
        }

        IMG[src=''],
        IMG:not([src]) {
          opacity: 0 !important;
        }
        img {
          max-width: none;
        }
        .datePicker {
          background-color: transparent !important;
        }
        .ellipsis {
          white-space: nowrap !important;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
        }
        .slick-dots {
          bottom: 10px !important;
        }
        .slick-dots li button:before {
          font-size: 12px !important;
          opacity: 0.4 !important;
          color: white !important;
        }
        .slick-dots li.slick-active button:before {
          color: white !important;
        }
        .story-carousel .slick-slide > div {
          margin: 5px !important;
        }
        [dir='rtl'] .slick-slide {
          float: left;
        }
        .story-carousel .slick-prev:before,
        .story-carousel .slick-next:before,
        .category-carousel .slick-prev:before,
        .category-carousel .slick-next:before {
          content: none !important;
        }
        .story-carousel .slick-prev,
        .story-carousel .slick-next {
          font-size: 22px !important;
          line-height: 1 !important;
          width: 50px !important;
          height: 50px !important;
          color: #616770 !important;
          border-radius: 50% !important;
          background: white !important;
          border: 1px solid #f2f3f5 !important;
          z-index: 2 !important;
        }
        .category-carousel .slick-prev,
        .category-carousel .slick-next {
          font-size: 22px !important;
          line-height: 1 !important;
          width: 50px !important;
          height: 50px !important;
          color: #616770;
          border-radius: 50% !important;
          background: white !important;
          border: 1px solid #f2f3f5 !important;
          z-index: 2 !important;
        }
        .category-carousel .slick-prev {
          left: 10px !important;
          right: auto;
        }
        .category-carousel .slick-next {
          right: 10px !important;
          left: auto;
        }

        .slick-slide > div {
          height: 100% !important;
          position: relative;
        }

        .slick-slider {
          height: 100%;
        }

        .slick-list {
          height: 100% !important;
        }

        .slick-track {
          height: 100%;
        }

        .react-datepicker {
          display: flex;
          width: 100%;
          flex-direction: column;
          border-radius: 15px !important;
          border: none;
          box-shadow: 0px 2px 6px 0px #dcdcdc;
        }
        .react-datepicker__time-container {
          width: 100%;
        }
        .react-datepicker__header {
          border-top-left-radius: 15px;
          border-top-right-radius: 15px !important;
          border-bottom: none;
          background-color: #e0e0e0;
        }
        .react-datepicker__month-container {
          width: 100%;
          display: flex;
          flex-direction: column;
        }
        .react-datepicker__week {
          display: flex;
          justify-content: space-evenly;
        }
        .react-datepicker__day-names {
          border-top: 1px solid #dadada;
          border-bottom: 1px solid #dadada;
          margin-top: 20px;
          display: flex;
          justify-content: space-evenly;
        }
        .react-datepicker__current-month {
          color: #757575;
        }

        .react-datepicker__day {
          outline: none;
          width: 35px !important;
          line-height: 35px !important;
        }
        .react-datepicker__day--in-selecting-range {
          background-color: #d3d3d3 !important;
          border-radius: 50% !important;
        }

        .react-datepicker__day--disabled.react-datepicker__day--today {
          color: #ccc;
        }
        .react-datepicker__day--in-range {
          background-color: ${theme?.elementsColor} !important;
        }

        .react-datepicker__day--today {
          color: #000;
          font-weight: normal;
          border-radius: 0.3rem;
        }

        .react-datepicker__month {
          margin: 0.4rem 0 !important;
        }

        .react-datepicker__day--keyboard-selected {
          background-color: ${theme?.elementsColor} !important;
          color: #fff !important;
        }

        .react-datepicker__day-name,
        .react-datepicker__day,
        .react-datepicker__time-name {
          width: 100%;
          display: inline-flex;
          justify-content: center;
        }
        [class*='_day--selected'] {
          background-color: ${theme?.elementsColor} !important;
          color: #fff !important;
        }

        .react-datepicker__time-list-item--selected {
          background-color: ${theme?.elementsColor} !important;
        }

        .category-carousel .slick-dots li button:before {
          font-size: 12px !important;
          opacity: 0.25 !important;
          color: initial !important;
        }
        .category-carousel .slick-dots li.slick-active button:before {
          color: initial !important;
          opacity: 0.75 !important;
        }
        .lazy-load-image-background {
          color: inherit !important;
        }
        .video-react .video-react-big-play-button {
          top: 0 !important;
          bottom: 0 !important;
          right: 0 !important;
          left: 0 !important;
          margin: auto !important;
        }
        .ReactModal__Overlay {
          z-index: 1700 !important;
        }

        .grid-container .container .row .border {
          border-radius: 0 !important;
        }
        .grid-container .container .cover {
          border-radius: 0 0 12px 0 !important;
        }

        .grid-container .container:first-of-type .row .border:first-of-type {
          border-top-left-radius: 12px !important;
        }

        .grid-container .container:first-of-type .row .border:last-child {
          border-top-right-radius: 12px !important;
        }

        .grid-container .container:last-child .row .border:first-of-type {
          border-bottom-left-radius: 12px !important;
        }

        .grid-container .container:last-child .row .border:last-child {
          border-bottom-right-radius: 12px !important;
        }

        .grid-container .height-one {
          max-height: 560px;
          background-size: contain !important;
        }
        .modal-slider {
          max-width: 790px;
          margin-top: 3rem;
          & > .slick-prev,
          .slick-next {
            background-color: transparent !important;
            font-size: 35px !important;
            transform: scale(3);
            z-index: 5;
            border: none !important;
          }
          & > .slick-next {
            right: -50px !important;
          }
          & > .slick-prev {
            left: -50px !important;
          }
          & > .slick-next::before {
            display: none;
          }
          & > .slick-prev::before {
            display: none;
          }
          & > .slick-dots {
            & > .slick-active > button::before {
              color: #d53f8c;
            }
            & > li > button::before {
              color: grey;
            }
          }
        }
        .rdrStartEdge ~ .rdrDayNumber,
        .rdrEndEdge ~ .rdrDayNumber {
          background: #2470ce !important;
          width: 30px !important;
          height: 30px !important;
          border-radius: 50% !important;
          transform: translateY(-2px);
        }
        .description-container {
          border: none;
          font-family: inherit;
        }
        .subtask-video {
          height: 100%;
          object-fit: cover;
        }
        .subtask-video-modal {
          object-fit: cover;
          height: 400px;
        }
        .fb-video {
          width: 100%;
          height: 100%;
          max-height: 300px;
        }
        .link-icon {
          path {
            fill: ${colors.blueDark};
          }
        }
        .store-item-sup-text {
          top: -1em;
          position: absolute;
          right: 25px;
        }

        .fslightbox-container img {
          min-height: 500px;
          object-fit: contain;
          min-width: 500px;
          height: 100% !important;
          width: 100% !important;
          max-height: 90vh;
        }
        @media (min-width: 1000px) {
          .ranking-slide {
            flex-direction: column;
            align-items: center;
            column-gap: 50px;
          }
          .ranking-slide-list {
            width: 100%;
          }
        }
        @media (min-width: 1400px) {
          .ranking-slide {
            flex-direction: row;
            align-items: center;
          }
          .ranking-slide-list {
            width: 60%;
          }
        }
        .byDateSelect .rdrStartEdge ~ .rdrDayNumber,
        .byDateSelect .rdrEndEdge ~ .rdrDayNumber {
          background: ${theme?.elementsColor} !important;
        }
        .competition-item {
          height: 200px;
          &:only-child {
            margin: 0 auto;
          }
        }
        .locale-user-dropdown {
          max-height: 550px;
          overflow: auto;
        }
        .fade-appear,
        .fade-enter {
          opacity: 0;
          z-index: 1;
        }
        .fade-appear-active,
        .fade-enter.fade-enter-active {
          opacity: 1;
          transition: opacity 200ms ease-in;
        }

        .fade-exit {
          opacity: 1;
        }

        .fade-exit.fade-exit-active {
          opacity: 0;
          transition: opacity 150ms ease-in-out;
        }
        .fadeIn-enter {
          opacity: 0;
        }

        .fadeIn-enter-active {
          opacity: 1;

          transition: opacity 1200ms;
        }

        .fadeIn-exit {
          opacity: 0;
        }

        .fadeIn-exit-active {
          opacity: 1;

          transition: opacity 1200ms;
        }
        @keyframes bounce {
          0%,
          100% {
            transform: translateX(-50%) translateY(0);
          }
          50% {
            transform: translateX(-50%) translateY(-10px);
          }
        }

        .bounce {
          animation: bounce 1.5s ease-in-out infinite;
        }
        .react-datepicker {
          border-radius: 0;
        }
        .fslightbox-video {
          object-fit: contain !important;
        }
        .swiper-button-prev,
        .swiper-button-next {
          display: none !important;
        }
        .swiper-pagination-bullet {
          background: white !important; /* Цвет всех точек */
          opacity: 0.6; /* Полупрозрачность для неактивных точек */
        }

        .swiper-pagination-bullet.swiper-pagination-bullet-active {
          background: white !important; /* Цвет активной точки */
          opacity: 1; /* Полная непрозрачность для активной точки */
        }
        .swiper-container {
          user-select: none; /* Отключает выделение */
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
        }
        .react-player video {
          object-fit: cover;
        }
      `}
    />
  )
}
