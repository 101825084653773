import { Button, ButtonProps } from '@chakra-ui/core'
import React from 'react'

interface Props extends ButtonProps {}
export const NoFocusButton = React.forwardRef<HTMLButtonElement, React.ComponentProps<typeof Button>>(
  ({ children, ...props }, ref) => {
    return (
      <Button
        ref={ref}
        bg="white"
        borderRadius="10px"
        border="none"
        _focus={{ outline: 'none', boxShadow: 'none' }}
        _active={{ bg: 'none', transform: 'none' }}
        {...props}
      >
        {children}
      </Button>
    )
  }
)
