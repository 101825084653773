import React from 'react'
import { Camera } from 'lucide-react'
import { Flex } from '@chakra-ui/core'
import { IconProps } from './types'

const CameraIcon: React.FC<IconProps> = ({
  iconWidth = 24,
  iconHeight = 24,
  width = '40px',
  height = '40px',
  iconColor = '#76B6D1',
  bgColor = '#D4E8F1',
  ...props
}) => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      bg={bgColor}
      width={width}
      height={height}
      borderRadius="full"
      {...props}
    >
      <Camera width={iconWidth} height={iconHeight} color={iconColor} />
    </Flex>
  )
}

export default CameraIcon
