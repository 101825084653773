import { ITag, SupervisorOrHandler } from 'constants/interfaces'

export const SERVICE_CALL_STATUSES = {
  OPEN: 0,
  CLOSED: 1,
}

export const calculateServiceCallViewContext = ({
  created_by,
  uid,
  activeGroupID,
  tags,
  supervisor,
  assignee,
}: {
  created_by?: string
  uid: string
  activeGroupID: string
  tags: ITag[]
  supervisor?: SupervisorOrHandler
  assignee?: SupervisorOrHandler
}) => {
  const userTags = tags.map((tag) => tag.sk)
  const validContextIds = [uid, activeGroupID, ...userTags]

  const isValidContext = (contextObj?: { context_id: string }) => {
    if (!contextObj) return null
    return validContextIds.includes(contextObj.context_id) ? contextObj.context_id : null
  }

  const supervisorContext = isValidContext(supervisor)
  if (supervisorContext) {
    return supervisorContext
  }

  const assigneeContext = isValidContext(assignee)
  if (assigneeContext) {
    return assigneeContext
  }

  if (created_by === uid) {
    return uid
  }

  return null
}

export const calculateViewContextForData = ({
  uid,
  activeGroupID,
  tags,
  supervisor,
  assignee,
}: {
  uid: string
  activeGroupID: string
  tags: ITag[]
  supervisor?: SupervisorOrHandler
  assignee?: SupervisorOrHandler
}) => {
  const usersGroup = activeGroupID
  const usersTagsIds = new Set(tags.map((tag) => tag.sk))
  const userId = uid

  const checkContext = (contextObj?: { context_id: string }) => {
    if (!contextObj) return null
    const contextId = contextObj.context_id
    return contextId === userId || contextId === usersGroup || usersTagsIds.has(contextId) ? contextId : null
  }

  return checkContext(supervisor) || checkContext(assignee) || activeGroupID
}
