import GroupsTree from 'components/CommonComponents/GroupsTree'
import { TagsList } from 'components/CommonComponents/TagsListModal'
import { UsersList } from 'components/CommonComponents/UsersListModal'
import { RootState } from 'constants/interfaces'
import strings from 'constants/strings'
import { useAudienceState } from 'hooks/useAudienceState'
import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import AudiencePublicationsModal from 'components/CommonComponents/AudiencePublicationModal'
import { WidgetWrapper } from 'components/TasksV2/UI/WidgetWrapper'
import { ErrorMessage } from 'components/TasksV2/UI/ErrorMessage'
import { Box, Flex, Text } from '@chakra-ui/core'
import { getSplittedGroupNames } from 'components/NewsFeed/PostModal/utils'
import { Users } from 'lucide-react'
import { useFormContext } from 'react-hook-form'
import useLocaleText from 'components/useLocaleText'
import { isEmpty } from 'lodash'
import { StepType, useFormStepContext } from 'components/TasksV2/TaskCreation/FormStepContext'
import { getTreeNodesIds } from 'components/CommonComponents/GroupsTree/utils'

interface IProps {
  onClose: () => void
  isAudienceOptionsModalOpen: boolean
  setIsAudienceOptionsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

interface IAudience {
  groups: string[]
  users: string[]
  tags: string[]
}

export const AudienceModalOptions: React.FC<IProps> = ({
  onClose,
  isAudienceOptionsModalOpen,
  setIsAudienceOptionsModalOpen,
}) => {
  const { setValue, errors, watch, reset, triggerValidation } = useFormContext()
  const { formData } = useFormStepContext()

  const create_task_assignee_placeholder = useLocaleText('create_task_assignee_placeholder')

  const {
    activeGroupID,
    userWriteTaskPermissions: { groups: treeTasksGroups },
    config: { user_tags, tags, groups: configGroups, default_group },
  } = useSelector((state: RootState) => state.config)

  const audience = watch('audience')

  const formDataAudience = formData[StepType.TASK_SETTINGS]?.audience

  const isFormDataAudienceEmpty =
    isEmpty(formDataAudience?.groups) && isEmpty(formDataAudience?.users) && isEmpty(formDataAudience?.tags)

  const getAudience = useMemo((): IAudience => {
    if (isFormDataAudienceEmpty) {
      return { groups: default_group ? [default_group] : [], users: [], tags: [] }
    }
    return {
      groups: audience.groups,
      users: audience.users,
      tags: audience.tags,
    }
  }, [audience.groups, audience.tags, audience.users, default_group, isFormDataAudienceEmpty])

  const {
    selectedUsersUids,
    setSelectedUsersUids,
    selectedGroupsIds,
    setSelectedGroupsIds,
    selectedTagsIds,
    setSelectedTagsIds,
    groupsToPayload,
    setGroupsToPayload,
    namesForTooltip,
    clearAudienceState,
    filteredRerailUsersByTreeUsers,
    treeUsers,
    treeGroups,
  } = useAudienceState({
    users: getAudience.users,
    groups: getAudience.groups,
    tags,
    tagIds: getAudience.tags,
    selectedOptionType: 'POLL',
    isCalculateGroupsChildren: isFormDataAudienceEmpty,
  })

  const splittedGroupNames = useMemo(() => {
    return getSplittedGroupNames(namesForTooltip, 5)
  }, [namesForTooltip])

  const tagsListToRender = useMemo(() => {
    const usersTags = user_tags ?? []
    const currentGroupTags = configGroups?.[activeGroupID!]?.tags ?? []
    const allTags = Array.from(new Set([...usersTags, ...currentGroupTags]))
    return tags.filter((tag) => allTags.includes(tag.sk))
  }, [activeGroupID, configGroups, tags, user_tags])

  const handleClose = async () => {
    const newAudience = {
      groups: Array.from(new Set(selectedGroupsIds)),
      users: selectedUsersUids,
      tags: selectedTagsIds,
    }

    if (isEmpty(newAudience.groups) && isEmpty(newAudience.users) && isEmpty(newAudience.tags) && default_group) {
      const getGroupsChildren = getTreeNodesIds(treeTasksGroups, default_group)
      setSelectedGroupsIds(getGroupsChildren)
      setValue('audience', { groups: getGroupsChildren, users: [], tags: [] })
      await triggerValidation('audience')
    } else {
      setValue('audience', { ...newAudience })
      await triggerValidation('audience')
    }

    onClose()
  }
  const handleOpen = () => {
    setValue('audience', { groups: [], users: [], tags: [] })
    clearAudienceState()
    setIsAudienceOptionsModalOpen(true)
  }

  useEffect(() => {
    if (isFormDataAudienceEmpty) {
      const newAudience = {
        groups: selectedGroupsIds,
        users: [],
        tags: [],
      }

      reset({
        audience: newAudience,
      })
    }
    // eslint-disable-next-line
  }, [isFormDataAudienceEmpty])

  const renderViewOptionModal = (
    viewOption: number,
    setViewOption: React.Dispatch<React.SetStateAction<number>> | any
  ) => {
    switch (viewOption) {
      case 0:
        return (
          <GroupsTree
            isOpen={true}
            selectedGroupID={selectedGroupsIds}
            mode={strings.PARTIAL_MULTILPE_MODE}
            setViewOption={setViewOption}
            setSelectedGroupID={setSelectedGroupsIds}
            setGroupsToPayload={setGroupsToPayload}
            groupsToPayload={groupsToPayload}
            isAllowSubmitWhithoutGroups={true}
            treeGroups={treeGroups}
          />
        )
      case 1:
        return (
          <UsersList
            isOpen={true}
            onClose={onClose}
            setViewOption={setViewOption}
            selectedUsersUids={selectedUsersUids}
            setSelectedUsersUids={setSelectedUsersUids}
            treeUsers={filteredRerailUsersByTreeUsers}
          />
        )
      case 2:
        return (
          <TagsList
            isOpen={true}
            onClose={onClose}
            setViewOption={setViewOption}
            tags={tagsListToRender}
            selectedTagsIds={selectedTagsIds}
            setSelectedTagsIds={setSelectedTagsIds}
          />
        )
      default:
        break
    }
  }

  return (
    <>
      <Flex
        w="100%"
        alignItems="center"
        flexDir="row-reverse"
        style={{ columnGap: '10px' }}
        onClick={handleOpen}
        cursor="pointer"
      >
        <Flex alignItems="center" justifyContent="center" bg="#ababab" p="8px" borderRadius="full">
          <Users size={16} strokeWidth={3} color="white" />
        </Flex>
        <WidgetWrapper isError={!!errors.audience} p="10px" w="100%">
          <Text>{splittedGroupNames.length > 0 ? splittedGroupNames : create_task_assignee_placeholder}</Text>
        </WidgetWrapper>
      </Flex>

      {errors.audience && (
        <Box px="10px" pt="5px">
          <ErrorMessage errorMessage={errors.audience.message} />
        </Box>
      )}

      {isAudienceOptionsModalOpen && (
        <AudiencePublicationsModal
          isOpen={isAudienceOptionsModalOpen}
          groups={treeGroups}
          users={treeUsers}
          onClose={handleClose}
          setShowOptionsList={setIsAudienceOptionsModalOpen}
          renderViewOptionModal={renderViewOptionModal}
          selectedUsersUids={selectedUsersUids}
          selectedOptionType={'POLL'}
          selectedTagsIds={selectedTagsIds}
          groupsToPayload={selectedGroupsIds}
        />
      )}
    </>
  )
}
