import { Flex, Text } from '@chakra-ui/core'
import React, { useEffect } from 'react'
import { DayCircle } from './DayCircle'
import useLocaleText from 'components/useLocaleText'
import { Controller, useFormContext } from 'react-hook-form'
import { FrequencyType } from 'components/NewsFeed/PollTaskForm/utils'
import { DAYS_OF_WEEK_ORDERED } from './utils'
import dayjs from 'dayjs'

export const DailySelector = () => {
  const { watch, setValue, control, errors, clearError, triggerValidation } = useFormContext()
  const t_choose_days_of_week = useLocaleText('t_choose_days_of_week')

  // Retrieve `recurrence_frequency` from the form's state
  const recurrenceFrequency: number | null = watch('recurrence_frequency')

  // Get `from_ts` from the form, defaulting to the current date if not present
  const fromTs = dayjs(watch('from_ts') || dayjs())
  const startDayIndex = fromTs.day()

  // Get selected days of the week from the form's state
  const daysOfWeek: number[] = watch('days_of_week') ?? []

  // Automatically set the default selected day to the `startDayIndex` if none are selected
  useEffect(() => {
    if (!daysOfWeek.length) {
      setValue('days_of_week', [startDayIndex])
    }
    // eslint-disable-next-line
  }, [])

  // Toggle the selection state of a day when clicked
  const toggleDay = async (dayIndex: number) => {
    const updatedDays = daysOfWeek.includes(dayIndex)
      ? daysOfWeek.filter((day) => day !== dayIndex) // Deselect the day
      : [...daysOfWeek, dayIndex] // Add the day to the selection

    // Update the form state with the new selection
    setValue('days_of_week', updatedDays)

    // Trigger validation for the `days_of_week` field
    await triggerValidation()

    // Clear errors if the field is now valid
    if (errors?.days_of_week && updatedDays.length > 0) {
      clearError('days_of_week')
    }
  }

  return (
    <>
      {/* Display the title for the day selector */}
      <Text fontSize="12px">{t_choose_days_of_week}</Text>

      {/* Controlled field for `days_of_week` with validation rules */}
      <Controller
        name="days_of_week"
        key="days_of_week"
        control={control}
        defaultValue={[startDayIndex]} // Default to the current day index
        rules={{
          validate: (value) => {
            if (recurrenceFrequency === FrequencyType.DAY) {
              // Ensure at least one day is selected if the frequency is daily
              return (Array.isArray(value) && value.length > 0) || 'Please select at least one day'
            }
            return true
          },
        }}
        as={
          <Flex justifyContent="space-between" my="30px">
            {/* Render a day circle for each day of the week */}
            {DAYS_OF_WEEK_ORDERED.map(({ index, label }) => (
              <DayCircle
                key={index}
                day={label}
                isSelected={daysOfWeek.includes(index)} // Highlight if the day is selected
                onClick={() => toggleDay(index)} // Toggle selection on click
              />
            ))}
          </Flex>
        }
      />
    </>
  )
}
