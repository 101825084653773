import { Divider, Flex, Stack, Text } from '@chakra-ui/core'
import { Title } from 'components/TasksV2/UI/Title'
import { WidgetWrapper } from 'components/TasksV2/UI/WidgetWrapper'
import { RootState, Subtask } from 'constants/interfaces'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { parseRecurrenceTimestamp, useTaskOverviewContext } from '../../TaskOverviewStackProvider'
import { getTaskAudience } from 'redux/actions/tasks_v2'
import useLocaleText from 'components/useLocaleText'
import { QuestionResultsList } from './QuestionResultsList'
import dayjs from 'dayjs'
import ExportToExcelButton from 'components/CommonComponents/ExportToExcelButton'
import { exportAnswersToExcel } from './utils'

interface IProps {
  questionSubtask: Subtask
}
const QuestionsOverview: React.FC<IProps> = ({ questionSubtask }) => {
  const { tid, st_id, title } = questionSubtask
  const { task } = useTaskOverviewContext()
  const { tags, groups, users, is_individual_results, recurrence_ts } = task

  const { allResults } = useSelector((state: RootState) => state.tasks_v2)
  const {
    config: { groups: groupsObject, tags: retailTags, retailUsersObject },
    userWriteTaskPermissions: { users: permittedUsers, groups: permittedGroups },
  } = useSelector((state: RootState) => state.config)

  const { groups: groupsAudience, users: usersAudience } = useMemo(
    () => getTaskAudience({ retailTags, groups, users, tags, is_individual_results, groupsObject }),
    [groups, groupsObject, is_individual_results, retailTags, tags, users]
  )

  const t_do_not_voted = useLocaleText('t_do_not_voted')

  const audience = [...groupsAudience, ...usersAudience].filter((participant) => {
    return Object.keys(permittedGroups).includes(participant) || permittedUsers.includes(participant)
  })

  const contextsWithResults = useMemo(
    () =>
      allResults?.[tid]?.filter((result) => {
        if (!result || !result.updated_at_ts) return false
        const recurrenceUnix = recurrence_ts ? parseRecurrenceTimestamp(recurrence_ts) : null
        const createAtCheck =
          result?.updated_at_ts && recurrenceUnix !== null ? Number(result.updated_at_ts) >= recurrenceUnix : true

        return result.st_id === st_id && createAtCheck
      }) ?? [],
    [allResults, recurrence_ts, st_id, tid]
  )

  const contextsWithoutResults = audience.filter(
    (participant) => !contextsWithResults.find((result) => result.context_id === participant)
  )

  const handleExportToExcel = () => {
    exportAnswersToExcel(contextsWithResults, groupsObject, retailUsersObject!, title)
  }

  return (
    <>
      {contextsWithResults && (
        <Flex mx="auto" mt="10px" mb="20px" justifyContent="center">
          <ExportToExcelButton actionWithoutArgs={handleExportToExcel} />
        </Flex>
      )}
      <Stack direction="column" spacing={4}>
        <WidgetWrapper p="15px">
          {contextsWithResults.length > 0 && (
            <QuestionResultsList
              contextsList={contextsWithResults.map(({ context_id, created_at_ts, answer }) => ({
                contextId: context_id,
                date: dayjs(created_at_ts! * 1000).format('YYYYMMDDHHmmss'),
                ...(answer !== null ? { answer } : {}),
              }))}
            />
          )}
        </WidgetWrapper>

        {contextsWithoutResults.length > 0 && (
          <WidgetWrapper p="20px">
            <Flex flexDir="row" justifyContent="space-between" fontWeight="500" fontSize="18px" fontFamily="Arial">
              <Title
                title={t_do_not_voted}
                w="max-context"
                isTruncated
                fontWeight="500"
                fontFamily="Arial"
                fontSize="18px"
              />
              <Text fontWeight="500" fontSize="18px" fontFamily="Arial">
                {contextsWithoutResults.length}
              </Text>
            </Flex>

            {contextsWithoutResults.length > 0 && (
              <>
                <Divider />
                <QuestionResultsList
                  contextsList={contextsWithoutResults.map((context) => ({
                    contextId: context,
                  }))}
                />
              </>
            )}
          </WidgetWrapper>
        )}
      </Stack>
    </>
  )
}

export default QuestionsOverview
