// TextareaWithIcons.tsx
import React, { forwardRef } from 'react'
import TextareaAutosize from 'react-textarea-autosize'

interface TextareaWithIconsProps {
  value: string | number
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
  onBlur: (event: React.FocusEvent<HTMLTextAreaElement>) => void
  name: string
  onImageUploadClick: () => void
  onFileUploadClick: () => void
  placeholder?: string
}

const DescriptionTextarea = forwardRef<HTMLTextAreaElement, TextareaWithIconsProps>(
  ({ value, onChange, onBlur, name, placeholder }, ref) => {
    return (
      <TextareaAutosize
        ref={ref}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        name={name}
        minRows={3}
        placeholder={placeholder}
        style={{
          width: '100%',
          resize: 'none',
          border: 'none',
          outline: 'none',
          background: 'transparent',
        }}
      />
    )
  }
)

export default DescriptionTextarea
