import React from 'react'
import DatePicker from 'react-datepicker'
import { Box } from '@chakra-ui/core'
import 'react-datepicker/dist/react-datepicker.css'

interface DatePickerComponentProps {
  isOpen: boolean
  onClose: () => void
  selectedDate: Date
  handleDateChange: (date: Date | null) => void
  minDate: Date
}

export const StartDatePickerComponent: React.FC<DatePickerComponentProps> = ({
  isOpen,
  onClose,
  selectedDate,
  handleDateChange,
  minDate,
}) => {
  return isOpen ? (
    <Box pos="absolute" zIndex={9999} top="80px" w="65%" left="50%" transform="translateX(-50%)">
      <DatePicker
        open
        selected={selectedDate}
        onChange={handleDateChange}
        dateFormat="dd/MM/yy HH:mm"
        minDate={minDate}
        showMonthDropdown
        showYearDropdown
        inline
        onClickOutside={onClose}
      />
    </Box>
  ) : null
}
