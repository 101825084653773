import { useSelector } from 'react-redux'
import { RootState, Subtask } from 'constants/interfaces'
import { useMemo } from 'react'

interface IProps {
  subtask: Subtask
  resultContextId: string
}

export const useResults = ({ subtask, resultContextId }: IProps) => {
  const { allResults } = useSelector((state: RootState) => state.tasks_v2)

  const results = allResults

  const subTaskResult = useMemo(() => {
    if (!subtask) {
      return
    }
    return results?.[subtask.tid]?.find(
      (result) => result.st_id === subtask.st_id && result.context_id === resultContextId
    )
  }, [results, subtask, resultContextId])

  return subTaskResult
}
