import { Flex, Text } from '@chakra-ui/core'
import { CustomSwitch } from 'components/CommonComponents/CustomSwitch'
import LocaleText from 'components/LocaleText'
import { RootState } from 'constants/interfaces'
import React from 'react'
import { useSelector } from 'react-redux'

export const PostModalSwitcher = ({
  isSwitchActive,
  onToggleSwitch,
  literal,
  isDisabled,
  size,
  ...props
}: {
  isSwitchActive: boolean
  onToggleSwitch: () => void
  literal: string
  isDisabled?: boolean
  size?: 'sm' | 'md' | 'lg'
}) => {
  const theme = useSelector((state: RootState) => state?.general?.theme)
  return (
    <Flex alignItems="center" h="100%">
      <Text fontWeight="600" mx="10px" fontSize="12px" w="100%" {...props}>
        <LocaleText text={literal} />
      </Text>
      <CustomSwitch
        isChecked={isSwitchActive}
        isDisabled={isDisabled}
        onToggle={onToggleSwitch}
        size={size}
        color={theme?.elementsColor}
      />
    </Flex>
  )
}
