import React, { useRef, useState, useEffect } from 'react'
import { AiOutlineCamera } from 'react-icons/ai'
import { RiImageAddLine, RiArrowGoBackFill } from 'react-icons/ri'

import BaseModal from './BaseModal'
import { Box, Button, Flex, Image, Input, Spinner } from '@chakra-ui/core'
import { getDeviceType } from 'utils'
import { DEVICE_TYPES_ENUM } from 'constants/enums'
import FileService from 'services/fileService'
import { PreviewFile } from 'config/types'
import { PublishButton } from './PublishButton'
import { isRtlSelector } from 'redux/selectors/general'
import { useSelector } from 'react-redux'
import colors from 'constants/colors'

interface IProps {
  isOpen: boolean
  onClose: () => void
  sendImage: (data: PreviewFile[]) => void
}

const CameraModal = ({ isOpen, onClose, sendImage }: IProps) => {
  const [cameraIsLoading, setIsCameraLoading] = useState<boolean>(true)
  const [imagePreview, setImagePreview] = useState<string[]>([])
  const [imageFiles, setImageFiles] = useState<File[]>([])

  const videoRef = useRef<HTMLVideoElement>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const publishBtnRef = useRef<HTMLButtonElement>(null)

  const isRtl = useSelector(isRtlSelector)

  const startCamera = async () => {
    if (videoRef && isOpen) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: false,
        })
        videoRef!.current!.srcObject = stream
      } catch (err) {
        console.log(err)
      } finally {
        setIsCameraLoading(false)
      }
    }
  }

  const removeImage = (index?: number) => {
    if (typeof index === 'number') {
      setImagePreview((prev) => prev.filter((_, i) => i !== index))
      setImageFiles((prev) => prev.filter((_, i) => i !== index))
    } else {
      setImagePreview([])
      setImageFiles([])
    }
  }

  const restartCameraModal = () => {
    removeImage()
    startCamera()
  }

  const closeModalHandler = () => {
    setIsCameraLoading(true)
    removeImage()
    stopCamera()
    onClose()
  }

  const stopCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const stream = videoRef!.current!.srcObject!
      // @ts-ignore
      const tracks = stream.getTracks()

      tracks.forEach((track: any) => {
        track.stop()
      })
    }
  }

  const takePhoto = () => {
    const width = videoRef.current!.videoWidth
    const height = videoRef.current!.videoHeight

    const ctx = canvasRef.current!.getContext('2d')

    canvasRef.current!.width = width
    canvasRef.current!.height = height

    ctx!.drawImage(videoRef.current!, 0, 0, width, height)

    const image = canvasRef.current!.toDataURL('image/png')
    const imageFile = FileService.dataURLtoFile(image, 'userPhoto')
    setImagePreview([image])
    setImageFiles([imageFile])
  }

  useEffect(() => {
    if (getDeviceType() === DEVICE_TYPES_ENUM.DESKTOP) {
      startCamera()
    } else {
      inputRef.current!.click()
    }

    return () => stopCamera()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const handleChangeEvent = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length) {
      const files = Array.from(e.target.files)
      const previews = await Promise.all(files.map((file) => FileService.imageToBase64(file)))
      setImagePreview(previews)
      setImageFiles(files)
    }
  }

  const selectImage = () => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  const sendImageHandler = () => {
    if (!imageFiles.length) return

    const previewFiles = imageFiles.map((file, index) => ({
      file,
      previewUrl: imagePreview[index],
    }))
    sendImage(previewFiles)
    closeModalHandler()
  }

  useEffect(() => {
    if (imageFiles.length) {
      publishBtnRef.current?.focus()
    }
  }, [imageFiles])

  return (
    <>
      <BaseModal
        isOpen={isOpen}
        onClose={closeModalHandler}
        closeOnEsc
        headerContent={
          <>
            {imagePreview.length > 0 && (
              <Button
                justifySelf="flex-start"
                variant="ghost"
                position="absolute"
                left={!isRtl ? '10px' : 'auto'}
                right={isRtl ? '10px' : 'auto'}
                top="8px"
                borderRadius="full"
                onClick={restartCameraModal}
                width="36px"
                height="36px"
                background={colors.greyMedium}
                p="0"
                minWidth={0}
              >
                <RiArrowGoBackFill size="12px" />
              </Button>
            )}
          </>
        }
        bodyContent={
          <Box mt="15px">
            <Flex height="100%" maxHeight="400px" justifyContent="center" flexWrap="wrap" style={{ gap: '10px' }}>
              {cameraIsLoading && (
                <Flex alignItems="center" justifyContent="center" height="350px" width="100%">
                  <Spinner size="xl" />
                </Flex>
              )}
              <video
                autoPlay
                ref={videoRef}
                style={{
                  display: imagePreview.length ? 'none' : 'block',
                  height: '100%',
                  width: '100%',
                }}
              />
              {imagePreview.map((preview, index) => (
                <Box key={index} position="relative">
                  <Image width="150px" height="150px" objectFit="cover" src={preview} />
                  <Button position="absolute" top="5px" right="5px" size="sm" onClick={() => removeImage(index)}>
                    ✕
                  </Button>
                </Box>
              ))}
            </Flex>
            <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
          </Box>
        }
        footerContent={
          <Flex width="100%" justifyContent="center" mb="3" alignItems="center" style={{ gap: '20px' }}>
            {imageFiles.length ? (
              <PublishButton onClick={sendImageHandler} tabIndex={0} ref={publishBtnRef} />
            ) : (
              <>
                <Button
                  onClick={takePhoto}
                  variant="outline"
                  borderRadius="full"
                  textTransform="uppercase"
                  width="50px"
                  height="50px"
                  p="5px"
                  tabIndex={1}
                >
                  <AiOutlineCamera fontSize="25px" />
                </Button>
                <Button
                  variant="outline"
                  borderRadius="full"
                  textTransform="uppercase"
                  width="50px"
                  height="50px"
                  p="5px"
                  onClick={selectImage}
                >
                  <RiImageAddLine />
                </Button>
              </>
            )}
          </Flex>
        }
      />
      <Input
        position="absolute"
        width="0"
        height="0"
        type="file"
        ref={inputRef}
        capture="environment"
        accept="image/*"
        multiple
        onChange={handleChangeEvent}
        visibility="hidden"
      />
    </>
  )
}

export default CameraModal
