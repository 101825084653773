import keys from 'constants/keys'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import isSolid from 'is-solid'
import { taskViewIdsSelector, hubViewNamesSelector } from 'redux/selectors/config'
import { allTasksSelector } from 'redux/selectors/tasks'
import { OTHER_TASK_VIEW_ID } from 'constants/baseValues'
import { taskObjectLinkedToPost } from 'utils'

interface UseLinkedPostTasks {
  ref_id: string | undefined
}
export const useLinkedPostTasks = ({ ref_id }: UseLinkedPostTasks) => {
  const navigate = useNavigate()
  const allTasks = useSelector(allTasksSelector)
  const taskViewsIds = useSelector(taskViewIdsSelector)
  const taskViewNames = useSelector(hubViewNamesSelector)
  const [currentLinkedTask, setCurrentLinkedTask] = useState<any>({})

  const isPostLinkedToTaskPage = ref_id?.includes('tasks-')
  const linkedToTaskPage = isPostLinkedToTaskPage ? ref_id?.split('-')[1] : ''

  const isValidRefId = useMemo(() => {
    if (isPostLinkedToTaskPage && ref_id) {
      const taskViewId = ref_id?.split('-').slice(1).join('-')
      return taskViewsIds.includes(taskViewId)
    } else if (ref_id) {
      return !!allTasks.find((task) => ref_id === task.ref_id)
    } else {
      return false
    }
  }, [ref_id, isPostLinkedToTaskPage, allTasks, taskViewsIds])

  const handleLinkedPost = useCallback(() => {
    if (ref_id && ref_id.includes('task')) {
      const taskView = ref_id.split('-').pop()
      return navigate(`${keys.ROUTE_NAMES.HUB}/${taskView}`)
    }

    const taskObjectByRefID = allTasks.find((item) => item.ref_id === ref_id)
    if (!taskObjectByRefID) {
      return
    }
    const taskViewId = taskObjectByRefID.view
    const navigateViewId = taskViewsIds.includes(taskObjectByRefID.view.toString()) ? taskViewId : OTHER_TASK_VIEW_ID
    navigate(`${keys.ROUTE_NAMES.HUB}/${navigateViewId}/${taskObjectByRefID.tid}`, {
      state: {
        ...taskObjectByRefID,
        cameFromNewsFeed: true,
      },
    })
  }, [allTasks, navigate, ref_id, taskViewsIds])

  useEffect(() => {
    if (ref_id) {
      const currentLinkedTask = taskObjectLinkedToPost(allTasks, ref_id)
      if (isSolid(currentLinkedTask)) {
        setCurrentLinkedTask(currentLinkedTask)
      }
    }
  }, [ref_id, allTasks])
  return {
    isValidRefId,
    currentLinkedTask,
    linkedToTaskPage,
    isPostLinkedToTaskPage,
    taskViewNames,
    handleLinkedPost,
  }
}
