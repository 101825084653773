import { applyMiddleware, combineReducers, createStore } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'
import authReducer from '../redux/reducers/auth'
import generalReducer from '../redux/reducers/general'
import configReducer from '../redux/reducers/config'
import tasksReducer from '../redux/reducers/tasks'
import feedReducer from '../redux/reducers/feed'
import customReportsReducer from 'redux/reducers/customReports'
import reportsReducer from 'redux/reducers/reports'
import tasksOverviewReducer from 'redux/reducers/tasksOverview'
import tasksOverviewDetail from 'redux/reducers/tasksOverviewDetail'
import adminReducer from 'redux/reducers/admin'
import { tasksV2Reducer } from 'redux/reducers/tasks_v2'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { RootState } from 'constants/interfaces'

import { composeWithDevTools } from 'redux-devtools-extension'
import createTaskReducer from 'redux/reducers/createTask'
import { actionTypes } from 'redux/actions/auth'
import { rankingReducer } from 'redux/reducers/ranking'
import { popsReducer } from 'redux/reducers/pops'
import { serviceCallsReducer } from 'redux/reducers/serviceCalls'
import commentsReducer from 'redux/reducers/comments'

const authPersistConfig = {
  key: 'auth',
  storage,
  blacklist: ['isLoginLoading', 'isError', 'token'],
}

const configPersistConfig = {
  key: 'config',
  storage,
  whitelist: ['translations', 'activeGroupID', 'retailConfig'],
}

const generalPersistConfig = {
  key: 'general',
  storage,
  whitelist: ['locale', 'translations'],
}

const tasksPersistConfig = {
  key: 'tasks',
  storage,
  whitelist: ['activeStoreId'],
}

const feedPersistConfig = {
  key: 'feed',
  storage,
  whitelist: ['isAllPostCollapsed'],
}

const customReportsPersistConfig = {
  key: 'custom-reports',
  storage,
}

const createTaskReducerPersistConfig = {
  key: 'create_task',
  storage,
  blacklist: ['view'],
}

const reportsReducerPersistConfig = {
  key: 'reports',
  storage,
  whitelist: [
    'selectedReportRangeType',
    'selectedUsersIds',
    'selectedTag',
    'selectedDeepDiveReportRangeType',
    'deepDiveSelectedUsersIds',
    'deepDiveReportConfig',
    'deepDiveSelectedTag',
    'storeRangeType',
    'storeSelectedTag',
    'storeSelectedUsersIds',
    'selectedDataFilter',
    'selectedDeepDiveDataFilter',
    'selectedStoreDataFilter',
  ],
}

const tasksOverviewReducerPersistConfig = {
  key: 'tasks_overview',
  storage,
  blacklist: ['dateFilter'],
}

const tasksOverviewDetailReducerPersistConfig = {
  key: 'tasks_overview_detail',
  storage,
  blacklist: ['availableGroups, isLoading, isPageReady'],
}

const rankingReducerPersistConfig = {
  key: 'ranking',
  storage,
  whitelist: ['none'],
}

const appReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  general: persistReducer(generalPersistConfig, generalReducer),
  config: persistReducer(configPersistConfig, configReducer),
  tasks: persistReducer(tasksPersistConfig, tasksReducer),
  feed: persistReducer(feedPersistConfig, feedReducer),
  customReports: persistReducer(customReportsPersistConfig, customReportsReducer),
  createTask: persistReducer(createTaskReducerPersistConfig, createTaskReducer),
  reports: persistReducer(reportsReducerPersistConfig, reportsReducer),
  tasksOverview: persistReducer(tasksOverviewReducerPersistConfig, tasksOverviewReducer),
  tasksOverviewDetail: persistReducer(tasksOverviewDetailReducerPersistConfig, tasksOverviewDetail),
  ranking: persistReducer(rankingReducerPersistConfig, rankingReducer),
  tasks_v2: tasksV2Reducer,
  pops: popsReducer,
  admin: adminReducer,
  comments: commentsReducer,
  serviceCalls: serviceCallsReducer,
})

composeWithDevTools(applyMiddleware(thunk))

const clearLocalStorageKeys = () => {
  for (let key in localStorage) {
    if (key.includes('persist')) {
      storage.removeItem(key)
    }
  }
}

const rootReducer = (state: any, action: any) => {
  if (action.type === actionTypes.LOGOUT_USER) {
    clearLocalStorageKeys()
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

export type AppDispatch = ThunkDispatch<RootState, any, AnyAction>

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))
const persistor = persistStore(store)

export { persistor, store }
