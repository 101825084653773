import { Flex } from '@chakra-ui/core'
import React, { useEffect } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import FrequencyDatesPicker from './FrequencyDatesPicker'
import { StepType, useFormStepContext } from '../../FormStepContext'
import { FormContext, useForm } from 'react-hook-form'
import DeadLineDatePicker from './DeadlineWidget'
import StartDatePicker from './StartDateWidget'
import dayjs from 'dayjs'
import { FrequencyMenu } from './FrequencyMenu'
import { RecurringSwitch } from './RecurringSwitch'

const TimeFrameSelection = () => {
  const { setCurrentFormSubmit, updateFormData, formData, goToPreviousStep } = useFormStepContext()

  const defaultValues = formData[StepType.TIME_FRAME_SELECTION] || {
    from_ts: dayjs().toISOString(),
    deadline: dayjs(dayjs()).add(8, 'hour').toISOString(),
    frequency_time: '',
    monthly_range: [],
    is_recurring: false,
    days_of_week: null,
    recurrence_frequency: null,
  }

  const methods = useForm({
    defaultValues,
    mode: 'onChange',
  })

  const { handleSubmit, watch } = methods

  const isRecurring = watch('is_recurring')
  useEffect(() => {
    setCurrentFormSubmit(() =>
      handleSubmit((data) => {
        updateFormData(StepType.TIME_FRAME_SELECTION, data)
        goToPreviousStep()
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FormContext {...methods}>
      <Flex flexDir="column">
        <Flex w="100%" justifyContent="center" my="30px">
          <RecurringSwitch />
        </Flex>

        <Flex flexDir="column">
          <StartDatePicker />
          <Flex flexDir="column" style={{ rowGap: '10px' }} my={!isRecurring ? '15px' : '45px'}>
            <FrequencyMenu />
            <FrequencyDatesPicker />
          </Flex>

          <DeadLineDatePicker />
        </Flex>
      </Flex>
    </FormContext>
  )
}

export default TimeFrameSelection
