import { Avatar, Flex } from '@chakra-ui/core'
import useAvatar from 'hooks/useAvatar'
import React, { memo, useCallback, useEffect } from 'react'
import { Post } from 'redux/reducers/feed'
import { setSelectedChat } from 'redux/actions/feed'
import { RootState } from 'constants/interfaces'
import { useSelector } from 'react-redux'
import { getCurrentEpoch, getTextDirection } from 'utils'
import useComments from 'hooks/useComments'
import isSolid from 'is-solid'
import { useNavigate } from 'react-router-dom'
import keys from 'constants/keys'
import { ChatPreviewText, ChatPreviewContainer, ChatPreviewTitle, LastMessageBox } from './helperStyles'
import { TooltipList } from 'components/CommonComponents/TooltipList'
import { MdGroup } from 'react-icons/md'
import { IconList } from 'components/Post/IconList'

interface ChatPreviewProps {
  chat: Post
  isAllowToNavigate?: boolean
}

export const ChatPreview = memo(({ chat, isAllowToNavigate = true }: ChatPreviewProps) => {
  const { post_id, title, images } = chat
  const navigate = useNavigate()
  const theme = useSelector((state: RootState) => state.general.theme)
  const { fetchComments, fetchCommentsCounter, fetchCommentsLoading } = useComments(post_id)
  const chatMessages = useSelector((state: RootState) => state.comments.commentsState?.[post_id]?.items ?? [])
  const viewedCommentsIds = useSelector((state: RootState) => state.comments.viewedCommentsIds?.[post_id] ?? [])

  const lastMessage = chatMessages && chatMessages.length > 0 ? chatMessages.slice(-1)[0] : null
  const isLastMessageUnred =
    lastMessage && viewedCommentsIds.length > 0 && !fetchCommentsLoading
      ? !viewedCommentsIds.includes(lastMessage?.cid)
      : false

  const avatar = useAvatar(lastMessage?.created_by)

  const lastMessageMedia = {
    audios: lastMessage?.audios || [],
    videos: lastMessage?.videos || [],
    files: lastMessage?.files || [],
    images: lastMessage?.images || [],
  }

  const hasNonEmptyMedia = Object.values(lastMessageMedia).some((mediaArray) => mediaArray.length > 0)

  const getComments = useCallback(
    async (isManuallyTriggered?: boolean, id?: string) => {
      try {
        let timestampFromToGetComments = getCurrentEpoch()

        if (chatMessages && chatMessages?.length > 0) {
          timestampFromToGetComments = chatMessages[0].created_at_ts
        }
        const commentsFromOrigin = await fetchComments({
          limit: 12,
          created_ts: timestampFromToGetComments,
          isManuallyTriggered,
          id,
        })
        if (!isSolid(commentsFromOrigin)) {
          return
        }
      } catch (error) {
        console.log(error)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chatMessages]
  )

  useEffect(() => {
    if (chatMessages && chatMessages.length > 0) return
    getComments()
    fetchCommentsCounter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post_id])

  const navigateToChat = () => {
    if (!isAllowToNavigate) {
      return
    }
    navigate(keys.ROUTE_NAMES.GROUP_CHAT(post_id))
    setSelectedChat(post_id)
  }
  return (
    <ChatPreviewContainer onClick={navigateToChat} mt={'24px'}>
      {isLastMessageUnred && lastMessage && (
        <>
          <LastMessageBox dir={getTextDirection(lastMessage?.message)}>
            {hasNonEmptyMedia ? (
              <IconList {...lastMessageMedia} w="20px" h="20px" containerWidth="100%" />
            ) : (
              <ChatPreviewText>{lastMessage?.message}</ChatPreviewText>
            )}
          </LastMessageBox>
          <Avatar
            src={avatar}
            pos="absolute"
            top="29px"
            left="42px"
            zIndex={1000}
            w="43px"
            h="43px"
            borderRadius="full"
            boxShadow="-1px 1px 3px 1px #8b8b8b"
          />
        </>
      )}

      <Flex
        border={isLastMessageUnred ? `2px solid ${theme?.elementsColor}` : '2px solid transparent'}
        borderRadius="50%"
        cursor="pointer"
        justifyContent="center"
        alignItems="center"
        bg="#A0AEC0"
        w="63px"
        h="63px"
      >
        {images?.[0] ? (
          <Avatar w="61px" h="61px" src={images?.[0]} />
        ) : (
          <MdGroup style={{ width: '45px', height: '45px', fill: 'white' }} />
        )}
      </Flex>
      <TooltipList items={[title]}>
        <ChatPreviewTitle isTruncated dir={getTextDirection(title)}>
          {title}
        </ChatPreviewTitle>
      </TooltipList>
    </ChatPreviewContainer>
  )
})
