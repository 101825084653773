import { Box, Flex, Spinner, Text } from '@chakra-ui/core'
import LocaleText from 'components/LocaleText'
import colors from 'constants/colors'
import useComments from 'hooks/useComments'
import { isEmpty } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getCurrentEpoch } from 'utils'
import Comment from 'components/Comments/Comment'
import CommentInput from 'components/Comments/CommentInput'
import { IComment, IHandleCreateComment } from 'constants/interfaces'
import { setCommentsCounter, setViewedCommentsIds, updateViews } from 'redux/actions/comments'

interface IProps {
  oid: string
  rg_id: string
  objectType: number
  parentCid: string
  tid: string
  created_by: string
  result_context_id: string
  mediaIndex: number
  recurrence_ts?: string
  result_level: string
}

const CommentSection: React.FC<IProps> = ({
  oid,
  objectType,
  rg_id,
  parentCid,
  tid,
  result_context_id,
  created_by,
  recurrence_ts,
  result_level,
}) => {
  const dispatch = useDispatch()
  const keyForSave = `${oid}-${parentCid}`

  const {
    fetchComments,
    comments,
    sendComment,
    sendCommentLoading,
    commentsCounter,
    fetchCommentsLoading,
    isLoadMore,
  } = useComments(oid, false, keyForSave)

  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(6)
  const [updatedCommentsCounter, setUpdatedCommentsCounter] = useState(commentsCounter)

  const getComments = useCallback(async () => {
    let timestampFromToGetComments = getCurrentEpoch()

    if (comments.length > 0) {
      timestampFromToGetComments = comments?.[0]?.created_at_ts
    }

    await fetchComments({ id: oid, limit, created_ts: timestampFromToGetComments, parentCID: parentCid, keyForSave })

    if (!isEmpty(comments)) {
      setOffset(offset + limit)
      setLimit(5)
    }
    // eslint-disable-next-line
  }, [fetchComments, limit, offset, parentCid])

  useEffect(() => {
    if (comments.length === 0) {
      getComments()
    }
    // eslint-disable-next-line
  }, [comments.length, keyForSave])

  const handleCreateComment = async ({ comment, images, videos, audios, file }: IHandleCreateComment) => {
    setUpdatedCommentsCounter(updatedCommentsCounter + 1)

    const isResponseOk = await sendComment({
      id: oid,
      comment,
      rasID: rg_id,
      parentCID: parentCid,
      objectType: objectType,
      createdByUserId: created_by,
      result_context_id: result_context_id,
      taskId: tid,
      images,
      videos,
      audios,
      file,
      keyForSave,
      isPostCommnet: false,
      result_level: result_level,
      ...(recurrence_ts ? { recurrence_ts } : {}),
    })
    if (isResponseOk) dispatch(setCommentsCounter({ count: commentsCounter + 1, key: oid }))
  }

  useEffect(() => {
    const viewedIds = comments.map((comment: IComment) => comment.cid)
    dispatch(
      updateViews({
        postID: oid,
        viewedIds: viewedIds,
      })
    )

    dispatch(
      setViewedCommentsIds({
        key: oid,
        ids: viewedIds,
      })
    )
  }, [comments, dispatch, oid])

  return (
    <Flex
      flexDirection="column"
      flexGrow={1}
      className="comments-container"
      justifyContent="flex-end"
      position="relative"
      h="100%"
      overflow="hidden"
    >
      {isLoadMore && updatedCommentsCounter > 2 && comments.length < updatedCommentsCounter && (
        <Flex onClick={getComments} color={colors.greyDark} cursor="pointer" mb="5px">
          <Text>
            <LocaleText text="fetch_more_comments_post_button" />
          </Text>
          {fetchCommentsLoading && <Spinner size="md" mx="20px" />}
        </Flex>
      )}

      <>
        {!isEmpty(comments) && (
          <Flex h="85%" overflowY="auto" px="15px" flexDir="column">
            {comments.map((comment: IComment) => (
              <Comment
                key={comment.cid}
                {...comment}
                rasID={rg_id}
                taskId={tid}
                postID={oid}
                authorID={created_by}
                isPostCommnet={false}
                inputBgColor="white"
                keyForSave={keyForSave}
              />
            ))}
          </Flex>
        )}
      </>
      <Box minH="55px" overflow="hidden">
        <CommentInput
          isFocused={false}
          postID={oid}
          handleCreateComment={handleCreateComment}
          sendCommentLoading={sendCommentLoading}
          inputBgColor="white"
        />
      </Box>
    </Flex>
  )
}

export default CommentSection
