import { Menu, MenuButton, MenuItem, MenuList, Skeleton } from '@chakra-ui/core'
import { DashboardMenuButton } from 'components/TasksV2/UI/DashboardMenuButton'
import { AppDispatch } from 'config/redux'
import { RootState } from 'constants/interfaces'
import React, { memo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllResults, getTask, SubtaskDeepDiveItem } from 'redux/actions/tasks_v2'
import { isRtlSelector } from 'redux/selectors/general'

export const DashboardOverviewMenu: React.FC<{
  tid: string
  handleOpenOverview: (contentProps: any) => void
  subtasks: SubtaskDeepDiveItem[]
  recurrenceTs: string | null
  btnText: string
  icon?: React.ReactNode
}> = memo(({ tid, handleOpenOverview, recurrenceTs, subtasks, btnText, icon }) => {
  const dispatch: AppDispatch = useDispatch()
  const { deepDiveInsights, isInsightsLoading } = useSelector((state: RootState) => state.tasks_v2)
  const isRtl = useSelector(isRtlSelector)
  const [isFetching, setIsFetching] = useState(false)

  const isLoading = isInsightsLoading || isFetching

  const handleOptionClick = async (st_id: string) => {
    setIsFetching(true)
    try {
      const contextEntries = Object.entries(deepDiveInsights.contexts)
      const [resultContextId, { taskContextId }] = contextEntries[0]

      const taskResponse = await dispatch(
        getTask({
          tid,
          task_context_id: taskContextId,
          result_context_id: resultContextId,
          recurrence_ts: recurrenceTs,
        })
      )
      if (!taskResponse.task || !taskResponse.subtasks) {
        return
      }
      const { task, subtasks } = taskResponse

      const subtask = subtasks.find((subtask) => subtask.st_id === st_id)
      if (!subtask) {
        return
      }
      const results = await dispatch(getAllResults({ task, subtasksIds: [subtask.st_id] }))
      if (results) {
        handleOpenOverview({ selectedSubtask: subtask, title: subtask.title })
      }
    } catch (e) {
      console.error(e)
    } finally {
      setIsFetching(false)
    }
  }

  return (
    <>
      {isInsightsLoading ? (
        <Skeleton height="40px" width="200px" mb="15px" borderRadius="30px" />
      ) : (
        subtasks.length > 0 && (
          <>
            {subtasks.length === 1 ? (
              <DashboardMenuButton
                isDataFetching={isFetching}
                isLoading={isLoading}
                btnText={btnText}
                icon={icon}
                onClick={() => handleOptionClick(subtasks[0].st_id)}
              />
            ) : (
              <Menu closeOnBlur>
                <MenuButton
                  as={DashboardMenuButton}
                  {...{
                    isLoading,
                    isDataFetching: isFetching,
                    btnText: btnText,
                    icon,
                  }}
                />

                <MenuList placement={isRtl ? 'bottom-end' : 'bottom-start'} usePortal={false}>
                  {subtasks.map((subtask) => (
                    <MenuItem key={subtask.st_id} onClick={() => handleOptionClick(subtask.st_id)}>
                      {subtask.title}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            )}
          </>
        )
      )}
    </>
  )
})
