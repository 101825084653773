import { Collapse, Divider, Flex, Icon, Stack, Text } from '@chakra-ui/core'
import { getAvatarSrc } from 'components/Tasks/utils'
import ContextInfo from 'components/TasksV2/UI/ContextInfo'
import colors from 'constants/colors'
import { RootState } from 'constants/interfaces'
import { getContextName } from 'hooks/useContextName'
import React, { memo, useState } from 'react'
import { useSelector } from 'react-redux'
import { isRtlSelector } from 'redux/selectors/general'
import { MdOutlineStar } from 'react-icons/md'

interface IQuestionResultsListItem {
  resultItem: { contextId: string; answer?: string | number; avatar?: string; name: string; date?: string }
}

const QeustionResultListItem: React.FC<IQuestionResultsListItem> = memo(({ resultItem }) => {
  const { answer, avatar, name, date, contextId } = resultItem
  const isRtl = useSelector(isRtlSelector)
  const uid = useSelector((state: RootState) => state.auth.uid)
  const activeGroupID = useSelector((state: RootState) => state.config.activeGroupID)

  const isUsersContext = contextId === uid || contextId === activeGroupID

  return (
    <Flex direction="column" width="100%" style={{ rowGap: '5px' }}>
      <Flex alignItems="center" justifyContent="space-between" w="100%">
        <ContextInfo id={contextId} avatar={avatar} name={name} date={date} />
        {isUsersContext && <Icon as={MdOutlineStar} />}
      </Flex>
      {answer !== undefined && (
        <Text ml={isRtl ? 0 : '60px'} mr={isRtl ? '60px' : 0}>
          {answer}
        </Text>
      )}
    </Flex>
  )
})

interface IQuestionResultsList {
  contextsList: { contextId: string; answer?: string | number; date?: string }[]
}
export const QuestionResultsList: React.FC<IQuestionResultsList> = ({ contextsList }) => {
  const { groups } = useSelector((state: RootState) => state.config.config)
  const { retailUsersObject } = useSelector((state: RootState) => state.config.config)
  const [showAll, setShowAll] = useState(false)

  const visibleParticipants = contextsList?.slice(0, 7)
  const collapedParticipants = contextsList?.slice(7)

  const handleToggle = () => setShowAll(!showAll)

  return (
    <Stack direction="column" width="100%">
      {visibleParticipants.map(({ contextId, date, answer }, index) => {
        const avatar = getAvatarSrc({ id: contextId, groups: groups, retailUsersObject: retailUsersObject! })
        const participantName = getContextName(contextId, groups, retailUsersObject!)

        return (
          <React.Fragment key={`${contextId}-${index}`}>
            <QeustionResultListItem resultItem={{ contextId, answer, avatar, name: participantName, date }} />
            <Divider />
          </React.Fragment>
        )
      })}
      {contextsList.length > 7 && (
        <>
          <Collapse isOpen={showAll}>
            {collapedParticipants.map(({ contextId, date, answer }, index) => {
              const avatar = getAvatarSrc({ id: contextId, groups: groups, retailUsersObject: retailUsersObject! })
              const participantName = getContextName(contextId, groups, retailUsersObject!)

              return (
                <React.Fragment key={`${contextId}-${index}`}>
                  <QeustionResultListItem resultItem={{ contextId, answer, avatar, name: participantName, date }} />
                  {index !== collapedParticipants.length - 1 && <Divider />}
                </React.Fragment>
              )
            })}
          </Collapse>
          <Flex
            cursor="pointer"
            justifyContent="space-between"
            alignItems="center"
            onClick={handleToggle}
            width="100%"
            pt="3px"
          >
            <Text color={colors.greyMain} fontWeight="500">
              {showAll ? 'Show Less' : 'Show More'}
            </Text>
            <Icon name="chevron-right" />
          </Flex>
        </>
      )}
    </Stack>
  )
}
