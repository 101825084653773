export const DAYS_OF_WEEK_SHORT = {
  1: 't_m',
  2: 't_tu',
  3: 't_w',
  4: 't_th',
  5: 't_f',
  6: 't_sa',
  0: 't_s',
}

export const DAYS_OF_WEEK_FULL = {
  1: 't_mon',
  2: 't_tue',
  3: 't_wed',
  4: 't_thu',
  5: 't_fry',
  6: 't_sat',
  0: 't_sun',
}

export const DAYS_OF_WEEK_ORDERED = [
  { index: 1, label: 't_m' },
  { index: 2, label: 't_tu' },
  { index: 3, label: 't_w' },
  { index: 4, label: 't_th' },
  { index: 5, label: 't_f' },
  { index: 6, label: 't_sa' },
  { index: 0, label: 't_s' },
]
