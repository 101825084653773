// HiddenFileInput.tsx
import React, { forwardRef } from 'react'

interface HiddenFileInputProps {
  accept: string
  multiple?: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const HiddenFileInput = forwardRef<HTMLInputElement, HiddenFileInputProps>(
  ({ accept, multiple = false, onChange }, ref) => (
    <input type="file" accept={accept} multiple={multiple} ref={ref} style={{ display: 'none' }} onChange={onChange} />
  )
)

export default HiddenFileInput
