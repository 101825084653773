import { createSelector } from 'reselect'
import { RootState } from 'constants/interfaces'
import { populateUrl } from 'utils'
import { OTHER_TASK_VIEW, OTHER_TASK_VIEW_ID } from 'constants/baseValues'
import { isRtlSelector } from './general'
import { isEmpty } from 'lodash'

export const userConfigSelector = (state: RootState) => state.config.config

export const retailIdSelector = (state: RootState) => state.config.config.rid

export const retailConfigSelector = (state: RootState) => state.config.retailConfig

export const translationsSelector = (state: RootState) => state.config.translations

export const translationKeysSelector = (state: RootState) => state.config.translationsKeys

export const userIdSelector = (state: RootState) => state.auth.uid

export const whatsAppUrlSelector = createSelector(
  [retailConfigSelector, retailIdSelector, userConfigSelector],
  (retailConfig, retailId, userConfig) => {
    const whatsapp_url = retailConfig?.whatsapp_url
    if (whatsapp_url) {
      return populateUrl(whatsapp_url, {
        retail_name: retailId,
        user_first_name: userConfig.first_name,
        user_id: userConfig.myUID,
      })
    }
  }
)

export const hubViewsSelector = createSelector(retailConfigSelector, (retailConfig) => {
  const dynamicTypesViews =
    retailConfig?.dynamic_task_views?.sort((type, nextType) => type.order - nextType.order) ?? []
  const defaultTypesViews =
    retailConfig?.default_task_views?.sort((type, nextType) => type.order - nextType.order) ?? []
  return [...defaultTypesViews, ...dynamicTypesViews]
})

export const taskViewIdsSelector = createSelector(hubViewsSelector, (taskViews) =>
  taskViews.map((task) => task.task_view_id.toString())
)

export const hubViewNamesSelector = createSelector(hubViewsSelector, (tasks) => {
  const viewName = tasks.reduce((acc, next) => {
    acc[next.task_view_id] = next?.literal || next.task_view_name
    return acc
  }, {})
  return { ...viewName, [OTHER_TASK_VIEW_ID]: OTHER_TASK_VIEW.task_view_name }
})

export const rankingTypesSelector = createSelector([retailConfigSelector, isRtlSelector], (retailConfig, isRtl) => {
  if (isRtl && retailConfig?.ranking_types) {
    return [...retailConfig.ranking_types].reverse()
  }
  return retailConfig?.ranking_types || []
})

export const contactUsOptionsSelector = createSelector(
  [translationsSelector, translationKeysSelector],
  (translations, translationKeys) => {
    return translationKeys.map((key) => ({
      name: translations[key].language_name,
      phone: translations[key].whatsapp_phone_number,
    }))
  }
)

export const serviceCallConfigSelector = createSelector(retailConfigSelector, (retailConfig) => {
  const serviceCallsViews =
    retailConfig?.dynamic_task_views?.filter(
      (taskView) => taskView?.service_request_config && !isEmpty(taskView.service_request_config)
    ) ?? []

  return serviceCallsViews
})

export const tasksViewsSelector = createSelector(retailConfigSelector, (retailConfig) => {
  const dynamicTasksViews =
    retailConfig?.dynamic_task_views?.filter((taskView) => isEmpty(taskView.service_request_config)) ?? []
  const defaultTasksViews =
    retailConfig?.default_task_views?.filter((taskView) => isEmpty(taskView.service_request_config)) ?? []

  return [...defaultTasksViews, ...dynamicTasksViews]
})
