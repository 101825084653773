import { NoFocusButton } from 'components/CommonComponents/NoFocusButton'
import { RootState } from 'constants/interfaces'
import React from 'react'
import { useSelector } from 'react-redux'
import { Spinner, Text } from '@chakra-ui/core'

interface Props {
  isLoading: boolean
  onClick?: () => void
  icon: React.ReactNode
  btnText: string
  isDataFetching?: boolean
}
export const DashboardMenuButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ isLoading, onClick, icon, btnText, isDataFetching, ...props }, ref) => {
    const theme = useSelector((state: RootState) => state?.general?.theme)
    return (
      <NoFocusButton
        ref={ref}
        color="white"
        borderRadius="30px"
        mb="15px"
        bg={theme?.elementsColor}
        cursor={isLoading ? 'not-allowed' : 'pointer'}
        pointerEvents={isLoading ? 'none' : 'auto'}
        style={{ columnGap: '10px' }}
        opacity={isLoading ? 0.4 : 1}
        _hover={{ opacity: 0.6, bg: theme?.elementsColor }}
        _focus={{ boxShadow: 'none' }}
        onClick={onClick}
        {...props}
      >
        <Text>{btnText}</Text>
        {isDataFetching ? <Spinner size="sm" /> : icon}
      </NoFocusButton>
    )
  }
)
