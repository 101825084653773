import React, { useEffect, useState, useMemo } from 'react'
import { Flex, Text } from '@chakra-ui/core'
import { useFormContext, Controller } from 'react-hook-form'
import { DayCircle } from './DayCircle'
import useLocaleText from 'components/useLocaleText'
import { DAYS_OF_WEEK_ORDERED } from './utils'
import dayjs from 'dayjs'

export const WeeklySelector = () => {
  const { watch, setValue, errors, clearError, control, triggerValidation } = useFormContext()
  const t_choose_range = useLocaleText('t_choose_range')

  // Retrieve the current state of the form
  const daysOfWeek = watch('days_of_week') ?? []
  const fromTs = dayjs(watch('from_ts'))

  // Calculate the starting day index from `from_ts`
  const startDayIndex = useMemo(() => {
    return fromTs ? fromTs.day() : dayjs().day()
  }, [fromTs])

  // Local state for range selection
  const [range, setRange] = useState([startDayIndex, daysOfWeek.length > 1 ? daysOfWeek[1] : null])
  const [initialized, setInitialized] = useState(false)

  // Handle day click for range selection
  const handleDayClick = async (dayIndex: number) => {
    if (range[0] === null || range[1] !== null) {
      // If the start day is not set or the range is complete, start a new selection
      setRange([dayIndex, null])
      setValue('days_of_week', [dayIndex, null])
    } else {
      // If the start day is not set or the range is complete, start a new selection
      setRange([range[0], dayIndex])
      setValue('days_of_week', [range[0], dayIndex])
    }

    // If the start day is not set or the range is complete, start a new selection
    await triggerValidation()
    if (errors?.days_of_week) {
      clearError('days_of_week')
    }
  }

  // Check if a day is within the selected range
  const isDayInRange = (dayIndex: number): boolean => {
    if (range[0] === null || range[1] === null) return false

    // Check for a normal range (start < end)
    if (range[0] < range[1]) {
      return dayIndex > range[0] && dayIndex < range[1]
    }

    // Check for a cyclic range (start > end)
    return dayIndex > range[0] || dayIndex < range[1]
  }

  // Check if a day is a boundary day (start or end of the range)
  const isBoundaryDay = (dayIndex: number): boolean => {
    return dayIndex === range[0] || dayIndex === range[1]
  }

  // Check if a day is a boundary day (start or end of the range)
  useEffect(() => {
    if (!initialized && daysOfWeek.length > 0) {
      setRange([daysOfWeek[0], daysOfWeek[1] ?? null])
      setInitialized(true) // Mark initialization as complete
    }
  }, [daysOfWeek, initialized])

  return (
    <>
      <Text fontSize="12px">{t_choose_range}</Text>
      <Controller
        control={control}
        name="days_of_week"
        rules={{
          validate: (value) => {
            if (!Array.isArray(value) || value.length === 0 || value.some((day) => day === null)) {
              return 'Please select range'
            }
            return true
          },
        }}
        as={
          <Flex justifyContent="space-between" position="relative" my="30px">
            {DAYS_OF_WEEK_ORDERED.map(({ index, label }) => (
              <DayCircle
                key={index}
                day={label}
                isSelected={isBoundaryDay(index)}
                isInRange={isDayInRange(index)}
                onClick={() => handleDayClick(index)}
              />
            ))}
          </Flex>
        }
      />
    </>
  )
}
