import { Flex, FlexProps } from '@chakra-ui/core'
import React from 'react'
import { Check } from 'lucide-react'
import { useSelector } from 'react-redux'
import { isRtlSelector } from 'redux/selectors/general'

interface IStatusCircleProps extends FlexProps {
  color: string
  showIcon?: boolean
}

export const StatusCircle: React.FC<IStatusCircleProps> = ({ color, showIcon = false, ...props }) => {
  const isRtl = useSelector(isRtlSelector)

  return (
    <Flex
      w="10px"
      h="10px"
      bg={color}
      borderRadius="full"
      alignItems="center"
      justifyContent="center"
      pos="absolute"
      top="19px"
      left={isRtl ? 'unset' : '15px'}
      right={isRtl ? '15px' : 'unset'}
      {...props}
    >
      {showIcon && <Check color="white" size={16} />}
    </Flex>
  )
}
