import { Avatar, Box, Flex, Text } from '@chakra-ui/core'
import { TooltipList } from 'components/CommonComponents/TooltipList'
import { DateToLocaleValue } from 'components/TasksV2/UI/DateToLocaleValue'
import useLocaleText from 'components/useLocaleText'
import { RootState } from 'constants/interfaces'
import keys from 'constants/keys'
import dayjs from 'dayjs'
import useAvatar from 'hooks/useAvatar'
import React, { useMemo } from 'react'
import { HiChevronDoubleRight } from 'react-icons/hi'
import { useSelector } from 'react-redux'
import { isRtlSelector } from 'redux/selectors/general'

interface IProps {
  created_by: string
  users: string[]
  groups: string[]
  tags: string[]
  ts: string
}
export const CreatorRecieverInfo = ({ created_by, users, tags, groups, ts }: IProps) => {
  const avatar = useAvatar(created_by)
  const retailUsersObject = useSelector((state: RootState) => state.config.config.retailUsersObject)
  const { tags: retailTags, groups: retailGroups } = useSelector((state: RootState) => state.config.config)
  const isRtl = useSelector(isRtlSelector)
  const t_task_removal = useLocaleText('t_task_removal')

  const usersNames = useMemo(() => {
    if (retailUsersObject && users.length > 0) {
      return users
        .filter((user) => Object.keys(retailUsersObject).includes(user))
        .map(
          (user) =>
            `${retailUsersObject?.[user].first_name} ${
              retailUsersObject?.[user].last_name ? retailUsersObject?.[user].last_name : ''
            }`
        )
    }
    return []
  }, [retailUsersObject, users])

  const tagsNames = useMemo(() => {
    if (tags.length && retailTags) {
      return retailTags.reduce((acc, tag) => {
        if (tags.includes(tag.sk)) {
          acc.push(tag.name)
        }
        return acc
      }, [] as string[])
    }
    return []
  }, [retailTags, tags])

  const groupNames = useMemo(() => {
    if (groups.length && retailGroups) {
      return groups
        .filter((group) => Object.keys(retailGroups).includes(group))
        .map((group) => `${retailGroups?.[group].name}`)
    }
    return []
  }, [groups, retailGroups])

  const mergedGroupsUsersTagsNames = [...groupNames, ...usersNames, ...tagsNames]

  const summeryNames = useMemo(() => {
    return mergedGroupsUsersTagsNames.length < 2
      ? mergedGroupsUsersTagsNames
      : `${mergedGroupsUsersTagsNames[0]} + ${mergedGroupsUsersTagsNames.length - 1}`
  }, [mergedGroupsUsersTagsNames])

  const postCreator = useMemo(() => {
    if (retailUsersObject && retailUsersObject?.[created_by]) {
      const firstName = retailUsersObject[created_by].first_name
        ? retailUsersObject[created_by].first_name
        : keys.DEFAULT_ADMIN_USER_NAME
      const lastName = retailUsersObject[created_by].last_name ? retailUsersObject[created_by].last_name : ''
      return firstName + ' ' + lastName
    }
    return keys.COMPANY_NAME
  }, [created_by, retailUsersObject])

  return (
    <Flex alignItems="center">
      <Avatar style={{ width: '50px', height: '50px' }} src={avatar} />
      <Box mx="10px">
        <Flex alignItems="center" flexWrap={'wrap'} lineHeight="18px">
          <TooltipList items={mergedGroupsUsersTagsNames}>
            <Text fontSize="14px" fontWeight="bold">
              {postCreator}
            </Text>
          </TooltipList>
          &nbsp;
          <HiChevronDoubleRight style={{ transform: `rotate(${180 * +isRtl}deg)` }} />
          &nbsp;
          <TooltipList items={mergedGroupsUsersTagsNames}>
            <Text fontSize="14px" cursor="pointer" fontWeight="bold">
              {summeryNames}
            </Text>
          </TooltipList>
        </Flex>
        <Flex alignItems="center" style={{ columnGap: '5px' }}>
          <Text fontSize="14px">{t_task_removal}</Text>
          <DateToLocaleValue
            date={dayjs(ts).add(dayjs().utcOffset(), 'minute').toDate()}
            placeholder={''}
            fontSize="14px"
          />
        </Flex>
      </Box>
    </Flex>
  )
}
