import React, { memo } from 'react'
import keys from 'constants/keys'
import { Subtask } from 'constants/interfaces'
import { ImageSubtask } from './ImageSubtask'
import { LinkSubtask } from './LinkSubtask'
import { VideoSubtask } from './VideoSubtask'

interface Props {
  subtask: Subtask
}

export const MediaSubtasks = memo(({ subtask }: Props) => {
  const { type } = subtask

  return (
    <>
      {type === keys.SUBTASK_TYPES.IMAGE_SUBTASK && <ImageSubtask subtask={subtask} />}
      {type === keys.SUBTASK_TYPES.LINK_SUBTASK && <LinkSubtask subtask={subtask} />}
      {type === keys.SUBTASK_TYPES.VIDEO_SUBTASKS && <VideoSubtask subtask={subtask} />}
    </>
  )
})
