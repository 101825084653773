import React from 'react'
import TaskCreationStep from '../Steps/SubtaskCreationStep'
import TaskSettingStep from '../Steps/TaskSettingStep'
import TimeFrameSelection from '../Steps/TimeFrameSelection'
import { StepType, useFormStepContext } from '../FormStepContext'

export const Content: React.FC = () => {
  const { stepStack } = useFormStepContext()
  const currentStep = stepStack[stepStack.length - 1]

  return (
    <>
      {currentStep === StepType.SUBTASK_TASK_CREATION && <TaskCreationStep />}
      {currentStep === StepType.TASK_SETTINGS && <TaskSettingStep />}
      {currentStep === StepType.TIME_FRAME_SELECTION && <TimeFrameSelection />}
    </>
  )
}
