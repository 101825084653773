import { Flex, Text, Menu, MenuButton, MenuItem, MenuList, FlexProps } from '@chakra-ui/core'
import LocaleText from 'components/LocaleText'
import { StatusCircle } from 'components/TasksV2/UI/StatusCircle'
import { WidgetWrapper } from 'components/TasksV2/UI/WidgetWrapper'
import { ChevronDown } from 'lucide-react'
import React from 'react'
import { Status } from 'redux/actions/serviceCall'

interface IProps extends FlexProps {
  statuses: Status[]
  statusValue: number
  handleChangeStatus: (value: number) => void
  errors?: any
}
export const StatusesMenu: React.FC<IProps> = ({ statuses, statusValue, handleChangeStatus, errors, ...props }) => {
  const statusColor = statuses.find((st) => st.value === statusValue)?.color ?? ''
  const statusName = statuses.find((st) => st.value === statusValue)?.name ?? ''

  return (
    <Menu>
      <MenuButton as={Flex} w="100%" style={{ columnGap: '10px' }} alignItems="center">
        <WidgetWrapper
          p="10px"
          borderRadius="10px"
          alignItems="center"
          justifyContent="center"
          cursor="pointer"
          w="100%"
          isError={!!errors}
          {...props}
        >
          <Flex alignItems="center" flexDir="row" justifyContent="space-between" w="100%" style={{ columnGap: '10px' }}>
            <Flex style={{ columnGap: '10px' }} alignItems="center">
              <StatusCircle color={statusColor} pos="relative" top="unset" left="unset" right="unset" bottom="unset" />
              <Text>
                <LocaleText text={statusName} />
              </Text>
            </Flex>

            <ChevronDown />
          </Flex>
        </WidgetWrapper>
      </MenuButton>
      <MenuList
        maxH="300px"
        h="max-content"
        overflowY="auto"
        fontSize="14px"
        fontWeight="500"
        borderRadius="10px"
        placement="bottom-start"
      >
        {statuses.map((status, index) => {
          const { name, value } = status
          return (
            <MenuItem
              key={index}
              py="10px"
              style={{ columnGap: '10px' }}
              onClick={() => handleChangeStatus(value)}
              flexDir="row"
              alignItems="center"
            >
              <StatusCircle color={status.color} pos="relative" top="unset" left="unset" right="unset" bottom="unset" />
              <LocaleText text={name} />
            </MenuItem>
          )
        })}
      </MenuList>
    </Menu>
  )
}
