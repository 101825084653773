import { Input, InputGroup, InputRightElement, InputProps, InputLeftElement } from '@chakra-ui/core'
import React, { forwardRef } from 'react'
import { useSelector } from 'react-redux'
import { isRtlSelector } from 'redux/selectors/general'

interface TaskInputProps extends InputProps {
  isError?: boolean
  inputElement?: React.ReactNode
}

export const TaskInput = forwardRef<HTMLInputElement, TaskInputProps>(({ isError, inputElement, ...props }, ref) => {
  const isRTL = useSelector(isRtlSelector)

  return (
    <InputGroup w="100%">
      <Input
        ref={ref}
        borderRadius="15px"
        maxLength={100}
        boxShadow={isError ? '0px 0px 0px 3px #EA9999' : '0px 2px 6px 0px #dcdcdc'}
        _active={{ borderColor: 'none' }}
        _focus={{ borderColor: 'none' }}
        {...props}
      />
      {inputElement &&
        (isRTL ? (
          <InputLeftElement>{inputElement}</InputLeftElement>
        ) : (
          <InputRightElement>{inputElement}</InputRightElement>
        ))}
    </InputGroup>
  )
})
