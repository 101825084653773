import { Button, ButtonProps } from '@chakra-ui/core'
import colors from 'constants/colors'
import { RootState } from 'constants/interfaces'
import React from 'react'
import { useSelector } from 'react-redux'
import Color from 'color'

const lightenColor = (color: string, ratio = 0.2) => {
  return Color(color).lighten(ratio).hex()
}

interface Props extends ButtonProps {
  children: React.ReactNode
}
export const SideBarButton: React.FC<Props> = ({ children, ...props }) => {
  const theme = useSelector((state: RootState) => state?.general?.theme)
  return (
    <Button
      borderRadius="10px"
      h="35px"
      color={colors.blackLight}
      borderColor={colors.greyLight}
      justifyContent="flex-start"
      backgroundColor={theme?.elementsColor}
      minW="180px"
      maxW="250px"
      width="max-content"
      variant="outline"
      border="none"
      fontSize="16px"
      fontWeight="400"
      style={{ columnGap: '10px' }}
      _disabled={{
        background: colors.greyLight,
        color: colors.greyLight2,
      }}
      _active={{ bg: lightenColor(theme?.elementsColor || 'grey', 0.4) }}
      _hover={{ bg: lightenColor(theme?.elementsColor || 'grey', 0.4) }}
      _focus={{ boxShadow: 'none' }}
      {...props}
    >
      {children}
    </Button>
  )
}
