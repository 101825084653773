import { Flex, Text } from '@chakra-ui/core'
import { ControlledCustomSwitch } from 'components/TasksV2/UI/ControlledCustomSwitch'
import useLocaleText from 'components/useLocaleText'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useFormStepContext } from '../../FormStepContext'

export const IndividualResultsSwitch = () => {
  const { control, watch } = useFormContext()
  const { isEdit } = useFormStepContext()

  const t_individualResults = useLocaleText('t_individualResults')

  return (
    <Flex
      alignItems="center"
      style={{ columnGap: '10px' }}
      pointerEvents={isEdit ? 'none' : 'all'}
      opacity={isEdit ? 0.5 : 1}
    >
      <Controller
        as={ControlledCustomSwitch}
        name="is_individual_results"
        control={control}
        key="is_individual_results"
        defaultValue={watch('is_individual_results')}
        valueName="isChecked"
        size="lg"
        onChange={([event]) => {
          return event.target.checked
        }}
      />
      <Text>{t_individualResults}</Text>
    </Flex>
  )
}
