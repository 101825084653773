import { Collapse, Divider, Flex, Icon, Stack, Text } from '@chakra-ui/core'
import { getAvatarSrc } from 'components/Tasks/utils'
import colors from 'constants/colors'
import { RootState } from 'constants/interfaces'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import ContextInfo from 'components/TasksV2/UI/ContextInfo'
import { getContextName } from 'hooks/useContextName'

interface IProps {
  participants: {
    id: string
    date?: string
    isClickable?: boolean
    insights?: {
      items: any[]
      typeCounts: {
        [type: number]: number
      }
    }
  }[]
}

export const VotedParticipants = ({ participants }: IProps) => {
  const { groups } = useSelector((state: RootState) => state.config.config)
  const { retailUsersObject } = useSelector((state: RootState) => state.config.config)
  const [showAll, setShowAll] = useState(false)

  const visibleParticipants = participants.slice(0, 7)
  const collapedParticipants = participants.slice(7)

  const handleToggle = () => setShowAll(!showAll)

  return (
    <Stack direction="column" width="100%">
      {visibleParticipants.map(({ id, date }, index) => {
        const avatar = getAvatarSrc({ id: id, groups: groups, retailUsersObject: retailUsersObject! })
        const participantName = getContextName(id, groups, retailUsersObject!)

        return (
          <React.Fragment key={`${id}-${index}`}>
            <ContextInfo id={id} avatar={avatar} name={participantName} date={date} />
            <Divider />
          </React.Fragment>
        )
      })}
      {participants.length > 7 && (
        <>
          <Collapse isOpen={showAll}>
            {collapedParticipants.map(({ id, date }, index) => {
              const avatar = getAvatarSrc({ id: id, groups: groups, retailUsersObject: retailUsersObject! })
              const participantName = getContextName(id, groups, retailUsersObject!)

              return (
                <React.Fragment key={`${id}-${index}`}>
                  <ContextInfo id={id} avatar={avatar} name={participantName} date={date} />
                  {index !== collapedParticipants.length - 1 && <Divider />}
                </React.Fragment>
              )
            })}
          </Collapse>
          <Flex
            cursor="pointer"
            justifyContent="space-between"
            alignItems="center"
            onClick={handleToggle}
            width="100%"
            pt="3px"
          >
            <Text color={colors.greyMain} fontWeight="500">
              {showAll ? 'Show Less' : 'Show More'}
            </Text>
            <Icon name="chevron-right" />
          </Flex>
        </>
      )}
    </Stack>
  )
}
