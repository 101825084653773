import keys from 'constants/keys'
import dayjs from 'dayjs'
import React, { createContext, useCallback, useContext, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getUTCOffset } from 'utils'
import { calculateDaysOfWeek, calculateRecurrenceDeadline, calculateRecurrenceFrequency, calculateTots } from './utils'
import { AppDispatch } from 'config/redux'
import { createTask, editTask } from 'redux/actions/tasks_v2'
import { FrequencyType } from 'components/NewsFeed/PollTaskForm/utils'
import { setResponseStatus } from 'redux/actions/general'

/**
 * Finds the next valid date based on the given `from_ts` and `days_of_week`.
 * @param from_ts - The original timestamp (string or dayjs object).
 * @param days_of_week - Array of valid day indices (0 - Sunday, 6 - Saturday).
 * @returns A dayjs object with the updated date.
 */
function getNextValidDate(from_ts: string, days_of_week: number[]): dayjs.Dayjs {
  const fromDate = dayjs(from_ts)
  const currentDayIndex = fromDate.day()

  // Check if the current day index is in the allowed days
  if (days_of_week.includes(currentDayIndex)) {
    return fromDate // Return original date if it's valid
  }

  // Find the next valid day
  const nextValidDayIndex = days_of_week.find((day) => day > currentDayIndex) ?? days_of_week[0]

  // Calculate the difference in days
  const daysToAdd =
    nextValidDayIndex > currentDayIndex ? nextValidDayIndex - currentDayIndex : 7 - currentDayIndex + nextValidDayIndex

  // Return the updated date
  return fromDate.add(daysToAdd, 'day')
}

export const StepType = {
  SUBTASK_TASK_CREATION: 'SUBTASK_TASK_CREATION',
  TASK_SETTINGS: 'TASK_SETTINGS',
  TIME_FRAME_SELECTION: 'TIME_FRAME_SELECTION',
} as const

type StepType = typeof StepType[keyof typeof StepType]

export interface SubtaskBase {
  is_required?: boolean
  title: string
  type: number
  order_index: number
  id?: string
}

export interface LinkSubtask extends SubtaskBase {
  external_link?: string
}

export interface PollSubtask extends SubtaskBase {
  is_multi_choice?: boolean
  options?: string[]
}

export interface OpenQuestionSubtask extends SubtaskBase {
  is_numeric: boolean
}

export interface Content {
  id?: string
  type: number
  text: string | null
  images: string[] | null
  videos: string[] | null
  files: string[] | null
  index_ref: number
}

export type Subtask = SubtaskBase & LinkSubtask & PollSubtask

export type TaskAudience = {
  groups: string[]
  users: string[]
  tags: string[]
}

export type TaskTimeFrame = {
  from_ts: string
  deadline: string | null
  frequency_time: string
  is_recurring: boolean
  monthly_range: string[]
  recurrence_frequency: number | null
  days_of_week: number[] | null
}

export type TaskFormData = {
  SUBTASK_TASK_CREATION: {
    title?: string
    sub_tasks: Subtask[]
  }
  TASK_SETTINGS: {
    audience: TaskAudience
    view: number | null
    is_individual_results: boolean
    from_ts: string
    deadline: string
  }
  TIME_FRAME_SELECTION: TaskTimeFrame
}

export type SubtaskActions = {
  append?: (data: Subtask | Content) => void
  remove?: (index: number) => void
  move?: (from: number, to: number) => void
}

type FormStepContextType = {
  currentFormSubmit: (() => void) | null
  setCurrentFormSubmit: React.Dispatch<React.SetStateAction<(() => void) | null>>
  formData: Partial<TaskFormData>
  updateFormData: <T extends keyof TaskFormData>(step: T, data: TaskFormData[T]) => void
  stepStack: StepType[]
  setStepStack: React.Dispatch<React.SetStateAction<StepType[]>>
  sumbitStep: () => void
  goToPreviousStep: () => void
  submitAllForms: () => void
  subtaskActions: SubtaskActions
  setSubtaskActions: React.Dispatch<React.SetStateAction<SubtaskActions>>
  isMediaUploading: boolean
  setIsMediaUploading: React.Dispatch<React.SetStateAction<boolean>>
  isEdit: boolean
}

const FormStepContext = createContext<FormStepContextType | null>(null)

export const FormStepProvider: React.FC<{
  children: React.ReactNode
  onClose?: VoidFunction
  initialData?: Partial<TaskFormData>
  isEdit: boolean
  tid?: string
}> = ({ children, onClose, initialData = {}, isEdit, tid }) => {
  const dispatch: AppDispatch = useDispatch()
  const [currentFormSubmit, setCurrentFormSubmit] = useState<(() => void) | null>(null)
  const [formData, setFormData] = useState<Partial<TaskFormData>>(initialData)
  const [stepStack, setStepStack] = useState<StepType[]>([StepType.SUBTASK_TASK_CREATION])
  const [isMediaUploading, setIsMediaUploading] = useState(false)

  const formDataRef = useRef<Partial<TaskFormData>>({})

  const [subtaskActions, setSubtaskActions] = useState<SubtaskActions>({})

  const updateFormData = useCallback(<T extends keyof TaskFormData>(step: T, data: TaskFormData[T]) => {
    setFormData((prev) => {
      const updatedData = { ...prev, [step]: data }
      formDataRef.current = updatedData
      return updatedData
    })
  }, [])

  const sumbitStep = useCallback(() => {
    if (currentFormSubmit) {
      currentFormSubmit()
    }
  }, [currentFormSubmit])

  const goToPreviousStep = useCallback(() => {
    setStepStack((prev) => prev.slice(0, -1))
  }, [])

  const normalizeContent = useCallback((subtask: Content) => {
    const { type, ...rest } = subtask
    return {
      ...rest,
      text: subtask.text?.trim() || null,
      files: subtask.files?.length ? subtask.files : null,
      images: subtask.images?.length ? subtask.images : null,
      videos: subtask.videos?.length ? subtask.videos : null,
      index_ref: subtask.index_ref,
    }
  }, [])

  const getValidContents = useCallback(
    (subtasks: (Subtask | Content)[]): Omit<Content, 'type'>[] => {
      return subtasks.reduce<Omit<Content, 'type'>[]>((acc, subtask) => {
        if (!('index_ref' in subtask)) return acc

        const content = subtask as Content
        const normalizedSubtask = normalizeContent(content)

        const hasContent =
          normalizedSubtask.text !== null ||
          normalizedSubtask.files !== null ||
          normalizedSubtask.images !== null ||
          normalizedSubtask.videos !== null

        if (hasContent) {
          acc.push(normalizedSubtask)
        }

        return acc
      }, [])
    },
    [normalizeContent]
  )

  const separateSubtasks = useCallback(
    (subtasks: (Subtask | Content)[]) => {
      const contents = getValidContents(subtasks) as Content[]
      const regularSubtasks = subtasks.filter((subtask) => !('index_ref' in subtask)) as Subtask[]

      return {
        contents,
        regularSubtasks,
      }
    },
    [getValidContents]
  )

  const submitAllForms = useCallback(async () => {
    const allData = formDataRef.current

    const { title, sub_tasks } = allData?.SUBTASK_TASK_CREATION as {
      title: string
      sub_tasks: (Subtask | Content)[]
    }

    const { groups, users, tags } = allData?.TASK_SETTINGS?.audience as TaskAudience

    const {
      is_individual_results,
      view,
      from_ts: taskSettingsFromTs,
      deadline: taskSettingsDeadline,
    } = allData?.TASK_SETTINGS as {
      is_individual_results: boolean
      view: number | null
      from_ts: string
      deadline: string
    }

    const {
      from_ts = taskSettingsFromTs,
      deadline = taskSettingsDeadline,
      frequency_time = '',
      monthly_range = [],
      is_recurring = false,
      days_of_week = [],
      recurrence_frequency = null,
    } = allData?.TIME_FRAME_SELECTION || {}

    try {
      const calculateFromTs = () => {
        if (!is_recurring) {
          return from_ts
        }
        if (recurrence_frequency === FrequencyType.DAY) {
          if (days_of_week?.length === 7) {
            return from_ts
          }
          return getNextValidDate(from_ts, days_of_week ?? [])
        }
        if (recurrence_frequency === FrequencyType.WEEK && days_of_week) {
          const fromDate = dayjs(from_ts)
          const currentDayIndex = fromDate.day()

          const startDay = days_of_week[0] // First day in the range
          const endDay = days_of_week[days_of_week.length - 1] // Last day in the range

          // Case 1: If `currentDayIndex` is within the range (including boundaries)
          if (
            (startDay <= endDay && currentDayIndex >= startDay && currentDayIndex <= endDay) || // Normal range
            (startDay > endDay && (currentDayIndex >= startDay || currentDayIndex <= endDay)) // Cyclic range
          ) {
            // Always return the startDay (adjusted to the previous week if needed)
            const daysToSubtract =
              currentDayIndex >= startDay ? currentDayIndex - startDay : 7 - (startDay - currentDayIndex)

            const adjustedDate = fromDate.subtract(daysToSubtract, 'day')
            return adjustedDate.hour(fromDate.hour()).minute(fromDate.minute()).second(fromDate.second())
          }

          // Case 2: If `currentDayIndex` is not within range
          const nextDay = days_of_week.find((day) => day > currentDayIndex) ?? days_of_week[0]
          const daysToAdd = nextDay > currentDayIndex ? nextDay - currentDayIndex : 7 - currentDayIndex + nextDay // Cycle to the next week

          const nextValidDay = fromDate.add(daysToAdd, 'day')
          return nextValidDay.hour(fromDate.hour()).minute(fromDate.minute()).second(fromDate.second())
        }

        if (recurrence_frequency === FrequencyType.MONTH) {
          const datePart = dayjs(monthly_range[0])
          const timePart = dayjs(from_ts)
          return datePart.hour(timePart.hour()).minute(timePart.minute()).second(timePart.second())
        }
      }

      const formattedFromTs = dayjs(calculateFromTs()).format(keys.DEFAULT_STRING_FORMAT_TIMESTAMP)
      const formattedDeadline = dayjs(deadline).format(keys.DEFAULT_STRING_FORMAT_TIMESTAMP)

      const recurrence_deadline = calculateRecurrenceDeadline({
        is_recurring,
        recurrence_frequency,
        from_ts: formattedFromTs,
        frequency_time,
        monthly_range,
        days_of_week,
      })
      const { contents, regularSubtasks } = separateSubtasks(sub_tasks)

      if (isEdit && tid) {
        const editTaskPayload = {
          tid: tid,
          utc_offset: getUTCOffset(),
          updated_to_ts: calculateTots({ is_recurring, deadline, formattedDeadline }),
          updated_deadline: formattedDeadline.toString(),
          updated_task_users: users,
          updated_task_groups: groups,
          updated_task_tags: tags,
          task_data: {
            title,
            view: view!,
            allowed_overdue_days: null,
            ...(contents.length > 0 && { contents }),
          },
        }
        const isOk = await dispatch(editTask(editTaskPayload))
        if (isOk) {
          onClose?.()
          dispatch(setResponseStatus(true))
        }
      } else {
        const data = {
          title,
          is_recurring,
          recurrence_frequency: calculateRecurrenceFrequency({
            frequency: recurrence_frequency,
            days_of_week: days_of_week,
          }),
          recurrence_interval: is_recurring ? 1 : null,
          recurrence_days_of_week: calculateDaysOfWeek({ recurrence_frequency, days_of_week }),
          recurrence_start_time: is_recurring ? formattedFromTs.slice(8) : null,
          view,
          categories: [],
          type: 0,
          is_individual_results,
          ...(contents.length > 0 && { contents }),
        }

        const createTaskPayload = {
          from_ts: formattedFromTs,
          to_ts: calculateTots({ is_recurring, deadline, formattedDeadline }),
          deadline: formattedDeadline.toString(),
          recurrence_deadline,
          groups,
          users,
          tags,
          utc_offset: getUTCOffset(),
          send_schedule_push: true,
          send_campaign_start_push: true,
          data,
          sub_tasks: regularSubtasks,
        }
        const isOk = await dispatch(createTask(createTaskPayload))
        if (isOk) {
          onClose?.()
          dispatch(setResponseStatus(true))
        }
      }
    } catch (e) {
      console.log(e)
    }
  }, [dispatch, separateSubtasks, isEdit, onClose, tid])

  return (
    <FormStepContext.Provider
      value={{
        currentFormSubmit,
        setCurrentFormSubmit,
        formData,
        updateFormData,
        stepStack,
        sumbitStep,
        goToPreviousStep,
        setStepStack,
        subtaskActions,
        setSubtaskActions,
        submitAllForms,
        isMediaUploading,
        setIsMediaUploading,
        isEdit,
      }}
    >
      {children}
    </FormStepContext.Provider>
  )
}

export const useFormStepContext = () => {
  const context = useContext(FormStepContext)
  if (!context) {
    throw new Error('useFormStepContext must be used within a FormStepProvider')
  }
  return context
}
