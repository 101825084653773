import { NoFocusButton } from 'components/CommonComponents/NoFocusButton'
import useLocaleText from 'components/useLocaleText'
import { AppDispatch } from 'config/redux'
import keys from 'constants/keys'
import React, { memo } from 'react'
import { useDispatch } from 'react-redux'
import { updateResults } from 'redux/actions/tasks_v2'
import { useTaskOverviewContext } from '../TasksOverview/TaskOverviewStackProvider'

interface IProps {
  isRequired: boolean
  isSkipped: boolean
  isResult: boolean
  subtaskType: number
  tid: string
  st_id: string
  resultContextId: string
}
export const SkipSubtaskButton: React.FC<IProps> = memo(
  ({ isRequired, isSkipped, isResult, subtaskType, tid, st_id, resultContextId }) => {
    const dispatch: AppDispatch = useDispatch()
    const { isReadMode } = useTaskOverviewContext()
    const t_subtask_skip = useLocaleText('skip_st')
    const t_skipped = useLocaleText('skipped_st')

    const buttonText = isSkipped ? t_skipped : t_subtask_skip

    const handleSkip = () => {
      dispatch(
        updateResults({
          tid,
          st_id,
          type: subtaskType,
          resultContextId,
          status: keys.TASK_STATUSES.SKIPPED.value,
        })
      )
    }

    if (isResult || isRequired || isReadMode) {
      return null
    }
    return (
      <NoFocusButton
        borderRadius="8px"
        w="max-content"
        fontSize="12px"
        color="#EA9999"
        h="30px"
        border={isSkipped ? 'none' : '1px solid #EA9999'}
        pointerEvents={isSkipped ? 'none' : 'auto'}
        isDisabled={isSkipped}
        onClick={handleSkip}
        _hover={{ bg: '#fff8fb' }}
      >
        {buttonText}
      </NoFocusButton>
    )
  }
)
