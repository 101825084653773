import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { ValueType } from 'react-select'
import { Box, Button, Flex, Text } from '@chakra-ui/core'

import BaseModal from 'components/CommonComponents/BaseModal'
import LocaleText from 'components/LocaleText'
import Search from './Search'
import { SearchFilterParams } from 'components/NewsFeed/SearchFilter/SearchFilterParams'
import { IOption, RootState } from 'constants/interfaces'
import keys from 'constants/keys'
import colors from 'constants/colors'
import useLocaleText from 'components/useLocaleText'
import { initialDate } from 'utils'
import { useSelector } from 'react-redux'

interface IProps {
  isOpen: boolean
  onClose: VoidFunction
}

const HeaderContent = () => (
  <Flex justifyContent="center">
    <Text>
      <LocaleText text="search_feed_search_by_title" />
    </Text>
  </Flex>
)

const BodyContent = ({ onClose }: { onClose: VoidFunction }) => {
  const navigate = useNavigate()
  const [creator, setCreator] = useState('')
  const [mediaFilter, setMediaFilter] = useState('')
  const [dateMode, setDateMode] = useState('')
  const [search, setSearch] = useState('')
  const theme = useSelector((state: RootState) => state?.general?.theme)

  const [date, setDate] = useState<{ from_ts: number; to_ts: number }>(initialDate)

  const searchPlaceHolder = useLocaleText('search_feed_filter_text')

  const handleCreator = (data: ValueType<IOption, false>) => {
    if (data) {
      setCreator((prevUser) => (prevUser === data.id ? '' : data.id))
    }
  }

  const handleMedia = (data: ValueType<IOption, false>) => {
    if (data) {
      setMediaFilter((prevFilter) => (prevFilter === data.id ? '' : data.id))
    }
  }

  const handleDate = (date: any) => {
    setDate({ from_ts: date.from_ts, to_ts: date.to_ts })
    setDateMode(date.dateMode)
  }

  const navigateToSearchPage = () => {
    let searchQuery = ''
    if (creator) {
      searchQuery += `created_by=${creator}&`
    }
    if (mediaFilter) {
      searchQuery += `media=${mediaFilter}&`
    }
    if (dateMode && date.from_ts && date.to_ts) {
      searchQuery += `dateMode=${dateMode}&from_ts=${date.from_ts}&to_ts=${date.to_ts}&`
    }

    if (search) {
      searchQuery += `query=${search}`
    }
    navigate({ pathname: keys.ROUTE_NAMES.NEWS_FEED_SEARCH, search: searchQuery })
    onClose()
  }

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value)
  }

  const isButtonDisabled = useMemo(() => {
    return !creator && !mediaFilter && !search && !dateMode && !date.from_ts && !date.to_ts
  }, [creator, date.from_ts, date.to_ts, dateMode, mediaFilter, search])

  const resetMediaFilter = () => {
    setMediaFilter('')
  }

  const resetPostCreator = () => {
    setCreator('')
  }

  return (
    <Box px="40px" mb="24px" overflow="hidden">
      <Box my="10px" mt="30px">
        <Search value={search} onChange={handleChangeSearch} autoFocus placeholder={searchPlaceHolder} />
      </Box>
      <Flex justifyContent="space-between" mt="20px" flexWrap="wrap" gridGap="10px">
        <SearchFilterParams
          handleMediaFilter={handleMedia}
          mediaFilter={mediaFilter}
          changeCreatedBy={handleCreator}
          createdBy={creator}
          changeDate={handleDate}
          resetMediaFilter={resetMediaFilter}
          resetPostCreator={resetPostCreator}
        />
      </Flex>
      <Flex justifyContent="flex-end" mt="35px">
        <Button
          height="40px"
          borderRadius="20px"
          width="125px"
          onClick={navigateToSearchPage}
          background={theme?.elementsColor}
          color={colors.tealLight}
          isDisabled={isButtonDisabled}
          _hover={{ background: theme?.backgroundMedium }}
        >
          <LocaleText text="search_feed_search_button" />
        </Button>
      </Flex>
    </Box>
  )
}

export const SearchModal = ({ isOpen, onClose }: IProps) => {
  return (
    <BaseModal
      isOpen={isOpen}
      onClose={onClose}
      headerContent={<HeaderContent />}
      bodyContent={<BodyContent onClose={onClose} />}
    />
  )
}
