import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'constants/interfaces'
import { setCommentsCounter, setViewedCommentsIds } from 'redux/actions/comments'
import { httpAuth } from 'config/apiClient'
import endpoints from 'constants/endpoints'
import { getUrlParamsString } from 'utils'

export const useContextCommentsCounter = (oids: string[]) => {
  const dispatch = useDispatch()
  const commentsCounter = useSelector((state: RootState) => state.comments.commentsCounter)
  const [loading, setLoading] = useState(false)

  const fetchedOids = useRef<Set<string>>(new Set())

  const oidsToFetch = useMemo(() => {
    return oids.filter((oid) => !(oid in commentsCounter) && !fetchedOids.current.has(oid))
  }, [oids, commentsCounter])

  const fetchCommentsCounterByListIds = useCallback(async () => {
    if (oidsToFetch.length === 0) return

    try {
      setLoading(true)
      for (const oid of oidsToFetch) {
        const url = endpoints.commentsCounter
        const params = getUrlParamsString({ oid })
        const { data } = await httpAuth.get(url + params)

        if (oid in data) {
          dispatch(
            setCommentsCounter({
              count: data[oid].num_of_comments,
              key: oid,
            })
          )
          dispatch(setViewedCommentsIds({ key: oid, ids: data[oid].viewed_cids }))
          fetchedOids.current.add(oid)
        }
      }
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [fetchedOids])

  useEffect(() => {
    fetchCommentsCounterByListIds()
    // eslint-disable-next-line
  }, [fetchedOids])

  const totalCommentsCount = useMemo(
    () =>
      oids.reduce((total, oid) => {
        const count = commentsCounter[oid] || 0
        return total + count
      }, 0),

    [commentsCounter, oids]
  )

  return { totalCommentsCount, loading }
}
