import { Switch, SwitchProps } from '@chakra-ui/core'
import styled from '@emotion/styled'
import { RootState } from 'constants/interfaces'
import React from 'react'
import { useSelector } from 'react-redux'

const StyledSwitch = styled(Switch)<{ switchColorActive?: string; switchColorInactive?: string }>`
  & > input:checked + div {
    background-color: ${({ switchColorActive }) => switchColorActive || 'teal'} !important;
  }
  & > input + div {
    background-color: ${({ switchColorInactive }) => switchColorInactive || '#dadada'};
  }

  & > input[type='checkbox']:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  & {
    outline: none !important;
    box-shadow: none !important;
  }

  & > div {
    box-shadow: none !important;
  }
  & > div:focus {
    box-shadow: none !important;
  }

  & > input[type='checkbox']::-moz-focus-inner {
    border: 0 !important;
  }
  margin-bottom: 0 !important;
`

interface ControlledCustomSwitchProps extends SwitchProps {
  isChecked?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const ControlledCustomSwitch: React.FC<ControlledCustomSwitchProps> = ({ isChecked, onChange, ...props }) => {
  const theme = useSelector((state: RootState) => state?.general?.theme)

  return (
    <StyledSwitch
      size="sm"
      dir="ltr"
      isChecked={isChecked}
      onChange={onChange}
      switchColorActive={theme?.elementsColor}
      {...props}
    />
  )
}
