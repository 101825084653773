import { Text } from '@chakra-ui/core'
import React from 'react'

interface Props {
  label: string
}
export const FormInputLabel: React.FC<Props> = ({ label }) => {
  return (
    <Text pb="5px" px="10px" fontSize="12px">
      {label}
    </Text>
  )
}
