import { Box, Flex, useToast } from '@chakra-ui/core'
import { isEmpty } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { TableComponent } from 'components/Reports/components/DeepDive/Table'
import { ComponentsTypes } from 'components/Reports/enums'
import { extractValueByIndexType, generateReportKey } from 'components/Reports/utils'
import { RootState } from 'constants/interfaces'
import {
  fetchAndSetReportData,
  getReportDynamicParams,
  getReportsPermittedUsers,
  setDeepDiveDataFilter,
  setDeepDiveReportDynamicParams,
  setDeepDiveReportRangeType,
  setDeepDiveSelectedTag,
  setDeepDiveSelectetUsers,
} from 'redux/actions/reports'
import { IDeepDive } from 'redux/reducers/reports'
import keys from 'constants/keys'
import { convertDateReportStringToDate } from 'utils'
import { SkeletonText } from 'components/CommonComponents/SkeletonText'
import { LastUpdate } from 'components/Reports/ui/LastUpdate'
import { ReportFilters } from 'components/Reports/filters'
import { CustomSkeleton } from 'components/Elements'
import { DateFilter } from 'components/Reports/filters/DateFilter'
import { BackButton } from 'components/Reports/ui/BackButton'
import { DeepDiveReportData } from 'components/Reports/commonTypes'
import { isRtlSelector } from 'redux/selectors/general'
import { NothingToShow } from 'components/Reports/ui/NothingToShow'
import { SecretID } from 'components/CommonComponents/SecretID'
import { FadeReportMessage } from 'components/Reports/components/FadeReportMessage'

export const DeepDiveReport = () => {
  const toast = useToast()
  const dispatch = useDispatch()
  const { targetGroupId, range_type, component_Id, sub_component_id, component_filter } = useParams()

  const isRtl = useSelector(isRtlSelector)
  const {
    deepDiveReportConfig,
    isReportLoading,
    reports,
    permittedUsersList,
    deepDiveSelectedUsersIds,
    selectedDeepDiveReportRangeType,
    tagsList,
    deepDiveSelectedTag,
    reportError,
    selectedDeepDiveDataFilter,
    dynamicParams,
    deepDiveReportDynamicParams,
  } = useSelector((state: RootState) => state.reports)
  const { isConfigReady, retailConfig, isConfigLoading, encodedUID } = useSelector((state: RootState) => state.config)

  const activeReportKey = generateReportKey({
    targetGroupId,
    rangeType: range_type,
    componentId: component_Id,
    subComponentId: sub_component_id,
    componentFilter: component_filter,
    deepDiveSelectedUsersIds,
    deepDiveSelectedTag,
    selectedDeepDiveDataFilter,
  })

  const [reportKey, setReportKey] = useState(activeReportKey)
  const [title, setTitle] = useState('')

  const activeReport = reports?.[reportKey]?.[0]
  const isNothingToShow = isEmpty(deepDiveReportConfig) || !activeReport || reportError.isError

  const handleDeepDiveReportData = useCallback(
    async ({
      startDate,
      endDate,
      selectedUsers,
      rangeType,
      argTag,
      argDataFilter,
      argDynamicParams,
    }: DeepDiveReportData) => {
      if (selectedDeepDiveReportRangeType?.type === undefined || selectedDeepDiveReportRangeType?.type === null) return
      const targetRangeType = rangeType !== undefined ? rangeType : selectedDeepDiveReportRangeType?.type
      const isReportByDate = targetRangeType === keys.REPORT_DATE_NAMES.CUSTOM_DATE

      const _dynamicParams = argDynamicParams || deepDiveReportDynamicParams

      const _startDate = isReportByDate
        ? startDate
          ? startDate
          : convertDateReportStringToDate(selectedDeepDiveReportRangeType.title.split('-')[0])
        : undefined
      const _endDate = isReportByDate
        ? endDate
          ? endDate
          : convertDateReportStringToDate(selectedDeepDiveReportRangeType.title.split('-')[1])
        : undefined

      const _selectedUsersIds = selectedUsers && selectedUsers.length ? selectedUsers : deepDiveSelectedUsersIds
      const _tag = argTag !== undefined ? argTag : deepDiveSelectedTag
      const _dataFilter = argDataFilter !== undefined ? argDataFilter : selectedDeepDiveDataFilter

      const newActiveReportKey = generateReportKey({
        targetGroupId,
        rangeType: targetRangeType,
        componentId: component_Id,
        subComponentId: sub_component_id,
        componentFilter: component_filter,
        deepDiveSelectedUsersIds: _selectedUsersIds,
        deepDiveSelectedTag: _tag,
        selectedDeepDiveDataFilter: _dataFilter,
      })
      setReportKey(newActiveReportKey)

      const queryParams = {
        reportDataKey: newActiveReportKey,
        groupId: targetGroupId!,
        report_range_type: targetRangeType,
        sub_retail_id: retailConfig?.sub_retail_id,
        componentId: component_Id !== 'null' ? Number(component_Id) : null,
        is_deep_dive: true,
        ...(component_filter !== 'null' ? { componentFilter: component_filter } : {}),
        ...(sub_component_id !== 'null' ? { subComponentId: sub_component_id } : {}),
        ...(_startDate ? { startDate: _startDate } : {}),
        ...(_endDate ? { endDate: _endDate } : {}),
        ...(_selectedUsersIds && _selectedUsersIds.length ? { users: _selectedUsersIds } : {}),
        ...(_tag ? { tag: _tag.sk } : {}),
        ...(_dataFilter ? { dataFilter: _dataFilter } : {}),
        ...(_dynamicParams ? { dynamic_filter_mapping: _dynamicParams } : {}),
      }

      dispatch(fetchAndSetReportData(queryParams))
    },
    [
      component_Id,
      component_filter,
      deepDiveReportDynamicParams,
      deepDiveSelectedTag,
      deepDiveSelectedUsersIds,
      dispatch,
      retailConfig,
      selectedDeepDiveDataFilter,
      selectedDeepDiveReportRangeType,
      sub_component_id,
      targetGroupId,
    ]
  )

  const renderDeepDiveReportByType = useCallback(
    (deepDiveReportConfig: IDeepDive) => {
      if (!activeReport) return
      const { type, properties } = deepDiveReportConfig
      switch (type) {
        case ComponentsTypes.table: {
          const { data_source } = properties
          if (!data_source || !activeReport[data_source]) return <></>

          return (
            <>
              {activeReport[data_source].length > 0 && (
                <Box>
                  <TableComponent properties={properties} reports={activeReport[data_source]} />
                </Box>
              )}
            </>
          )
        }

        default:
          break
      }
    },
    [activeReport]
  )
  useEffect(() => {
    if (isConfigReady && !isConfigLoading && !isReportLoading && !activeReport && !reportError.isError) {
      handleDeepDiveReportData({})
      if (!permittedUsersList) {
        dispatch(getReportsPermittedUsers())
      }
      if (!dynamicParams) {
        dispatch(getReportDynamicParams())
      }
    }
    // eslint-disable-next-line
  }, [
    activeReport,
    dispatch,
    handleDeepDiveReportData,
    isConfigReady,
    isConfigLoading,
    isReportLoading,
    permittedUsersList,
  ])

  useEffect(() => {
    if (deepDiveReportConfig && activeReport) {
      const { properties } = deepDiveReportConfig
      const { data_source, title } = properties
      if (data_source) {
        const titleText: string | null = extractValueByIndexType(
          title?.properties?.text_val,
          activeReport[data_source][0]
        )
        if (titleText) setTitle(titleText)
      }
    }
  }, [activeReport, deepDiveReportConfig])

  useEffect(() => {
    if (reportError.isError && !isReportLoading) {
      const isWarning = reportError.statusCode === 403
      const message =
        reportError.statusCode === 403
          ? // eslint-disable-next-line quotes
            "You don't have permission to view this report"
          : 'Get report data failed. Please try again later'
      toast({
        description: message,
        status: isWarning ? 'warning' : 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }, [isReportLoading, reportError.isError, reportError.statusCode, toast])

  return (
    <>
      <Box
        p="10px 20px"
        fontFamily='"Asap Condensed", sans-serif'
        pos="relative"
        style={{
          filter: retailConfig?.real_report ? 'none' : 'blur(10px)',
          pointerEvents: retailConfig?.real_report ? 'auto' : 'none',
        }}
      >
        <BackButton isReportError={reportError.isError} title={title} />
        <Box marginRight={isRtl ? '23px' : '0'} marginLeft={isRtl ? '0' : '23px'}>
          <Flex
            alignItems="center"
            flexWrap="wrap"
            mt="27px"
            mb="15px"
            mx="3px"
            w="100%"
            justifyContent="space-between"
          >
            <SkeletonText width="max-content" isLoaded={!isReportLoading}>
              {!isNothingToShow ? (
                <Flex alignItems="center" style={{ columnGap: '5px' }}>
                  <LastUpdate lastUpdate={activeReport?.last_update || activeReport?.title} />
                  <SecretID value={encodedUID} />
                </Flex>
              ) : (
                <NothingToShow />
              )}
            </SkeletonText>
            <Flex alignItems="center" style={{ columnGap: '10px' }} w="100%" justifyContent="flex-end" pos="relative">
              <ReportFilters
                handleDeepDiveReportData={handleDeepDiveReportData}
                reportData={activeReport}
                selectedUsers={deepDiveSelectedUsersIds}
                permittedUsersList={permittedUsersList ?? []}
                isReportLoading={isReportLoading}
                setSelectedUsers={setDeepDiveSelectetUsers}
                setRangeType={setDeepDiveReportRangeType}
                tagsList={tagsList}
                selectedTag={deepDiveSelectedTag}
                setSelectedTag={setDeepDiveSelectedTag}
                selectedDataFilter={selectedDeepDiveDataFilter}
                setSelectedDataFilter={setDeepDiveDataFilter}
                dataFiltersList={retailConfig?.data_filters}
                reportDynamicParams={deepDiveReportDynamicParams ?? []}
                setReportDynamicParams={setDeepDiveReportDynamicParams}
              />

              <DateFilter
                reportData={activeReport}
                handleGetReportData={handleDeepDiveReportData}
                setRangeType={setDeepDiveReportRangeType}
                isReportLoading={isReportLoading}
                selectedReportRangeType={selectedDeepDiveReportRangeType}
                retailConfig={retailConfig}
              />
            </Flex>
          </Flex>
          {!isNothingToShow && deepDiveReportConfig && !isReportLoading ? (
            renderDeepDiveReportByType(deepDiveReportConfig)
          ) : (
            <CustomSkeleton h="800px" isLoaded={!isReportLoading} borderRadius="10px" />
          )}
        </Box>
      </Box>
      {!retailConfig?.real_report && <FadeReportMessage text="real_report" />}
    </>
  )
}
