import { actionTypes, IDynamicParameter } from '../actions/reports'
import { IReportsState } from 'constants/interfaces'

export type ValueOrDataMap = number | DataMap
export type TextValue = string | DataMap

export type ByRow = {
  type: string
  queryName: string
  attributeColumnIndex: number
  rowStartingIndex: number
}

export type Lookup = {
  type: string
  queryName: string
  index: number[]
}

export type DataMap = ByRow | Lookup
export type TableHeader = {
  text_val: string
  text_is_literal: boolean
}

export interface IDeepDive {
  type: number
  properties: DeepDiveProperties
  sub_components: IDeepDive[]
}
export interface DeepDiveProperties {
  table_header: TableHeader[]
  table_row: ReportConfig[]
  data_source?: string | null
  sub_retail_id?: TextValue | null
  group_id?: TextValue | null

  title?: {
    type: number
    properties: Properties
    sub_components: ReportConfig[]
  }
}

export interface Properties {
  num_is_round?: boolean
  num1_is_round?: boolean
  num2_is_round?: boolean
  num3_is_round?: boolean
  num4_is_round?: boolean
  num5_is_round?: boolean
  num_val_is_round?: boolean
  num_val_qty_is_round?: boolean

  text_val?: TextValue
  text1_val?: TextValue
  text2_val?: TextValue
  text3_val?: TextValue

  num_val?: ValueOrDataMap
  num1_val?: ValueOrDataMap
  num2_val?: ValueOrDataMap
  num3_val?: ValueOrDataMap
  num4_val?: ValueOrDataMap
  num5_val?: ValueOrDataMap
  num_val_val?: DataMap | null
  num_val_qty_val?: DataMap | null

  num_curr_symbol?: DataMap | null
  num1_curr_symbol?: DataMap | null
  num2_curr_symbol?: DataMap | null
  num3_curr_symbol?: DataMap | null
  num4_curr_symbol?: DataMap | null
  num5_curr_symbol?: DataMap | null
  num_val_curr_symbol?: DataMap | null
  num_val_qty_curr_symbol?: DataMap | null

  num_symbol_type?: number | null
  num1_symbol_type?: number | null
  num2_symbol_type?: number | null
  num3_symbol_type?: number | null
  num4_symbol_type?: number | null
  num5_symbol_type?: number | null
  num_val_symbol_type?: number | null
  num_val_qty_symbol_type?: number | null

  compare_val?: ValueOrDataMap
  compare_curr_symbol?: DataMap | null
  compare_symbol_type?: number | null
  compare_offset?: number
  offset?: number
  is_full_width?: boolean
  is_block?: boolean
  is_show_more?: boolean
  bul_color?: string | null
  bul_color1?: string | null
  bul_color2?: string | null
  image_url?: TextValue | null
  title?: TextValue | null
  is_inline?: boolean | null
  icon_url?: TextValue | null
  icon_bg_color?: string | null
  color?: string
  text_is_literal?: boolean
  bg_color?: string
  deep_dive_component?: IDeepDive | null
  component_id?: number | null
  sub_component_id?: string | null
  component_filter?: DataMap | null
  is_repeatable?: boolean
  data_source?: string | null
  report_range_type?: number | null
  is_exportable?: boolean
}
export interface ReportConfig {
  type: number
  properties: Properties
  sub_components: ReportConfig[]
}

export const initialState: IReportsState = {
  reports: {},
  isReportLoading: false,
  tagsList: [],
  selectedTag: null,
  deepDiveSelectedTag: null,
  storeSelectedTag: null,
  permittedUsersList: null,
  selectedUsersIds: [],
  storeSelectedUsersIds: [],
  deepDiveSelectedUsersIds: [],
  selectedReportRangeType: null,
  selectedDeepDiveReportRangeType: null,
  deepDiveReportConfig: null,
  reportError: {
    isError: false,
  },
  storeRangeType: null,
  selectedDataFilter: null,
  selectedDeepDiveDataFilter: null,
  selectedStoreDataFilter: null,
  dynamicParams: null,
  reportDynamicParams: null,
  deepDiveReportDynamicParams: null,
  storeReportDynamicParams: null,
}

const generalReducer = (state = initialState, action: any): IReportsState => {
  switch (action.type) {
    case actionTypes.RESET_REPORTS:
      return {
        ...initialState,
      }

    case actionTypes.SET_REPORTS:
      return {
        ...state,
        reports: { ...state.reports, [action.payload.key]: action.payload.reports },
      }

    case actionTypes.SET_IS_REPORT_LOADING:
      return {
        ...state,
        isReportLoading: action.payload,
      }

    case actionTypes.SET_REPORT_RANGE_TYPE:
      return {
        ...state,
        selectedReportRangeType: action.payload,
      }
    case actionTypes.SET_DEEP_DIVE_CONFIG:
      return {
        ...state,
        deepDiveReportConfig: action.payload,
      }
    case actionTypes.SET_REPORTS_TAGS_LIST:
      return {
        ...state,
        tagsList: action.payload,
      }
    case actionTypes.SET_PERMITTED_USERS:
      return {
        ...state,
        permittedUsersList: action.payload,
      }

    case actionTypes.SET_SELECTED_USERS_IDS:
      return {
        ...state,
        selectedUsersIds: action.payload,
      }
    case actionTypes.SET_DEEP_DIVE_REPORT_RANGE_TYPE:
      return {
        ...state,
        selectedDeepDiveReportRangeType: action.payload,
      }
    case actionTypes.SET_STORE_REPORT_RANGE_TYPE: {
      return {
        ...state,
        storeRangeType: action.payload,
      }
    }
    case actionTypes.SET_DEEP_DIVE_SELECTED_USERS_IDS:
      return {
        ...state,
        deepDiveSelectedUsersIds: action.payload,
      }
    case actionTypes.SET_STORE_SELECTED_USERS_IDS: {
      return {
        ...state,
        storeSelectedUsersIds: action.payload,
      }
    }
    case actionTypes.SET_IS_REPORT_ERROR:
      const { isError, statusCode } = action.payload
      return {
        ...state,
        reportError: {
          isError: isError,
          statusCode: statusCode,
        },
      }
    case actionTypes.SET_SELECTED_TAG:
      return {
        ...state,
        selectedTag: action.payload,
      }
    case actionTypes.SET_DEEP_DIVE_SELECTED_TAG:
      return {
        ...state,
        deepDiveSelectedTag: action.payload,
      }
    case actionTypes.SET_STORE_SELECTED_TAG: {
      return {
        ...state,
        storeSelectedTag: action.payload,
      }
    }
    case actionTypes.SET_DATA_FILTER: {
      return {
        ...state,
        selectedDataFilter: action.payload,
      }
    }
    case actionTypes.SET_DEEP_DIVE_DATA_FILTER: {
      return {
        ...state,
        selectedDeepDiveDataFilter: action.payload,
      }
    }
    case actionTypes.SET_STORE_DATA_FILTER: {
      return {
        ...state,
        selectedStoreDataFilter: action.payload,
      }
    }
    case actionTypes.SET_REPORT_DYNAMIC_PARAMS: {
      return {
        ...state,
        reportDynamicParams: action.payload,
      }
    }
    case actionTypes.SET_DEEP_DIVE_REPORT_DYNAMIC_PARAMS: {
      return {
        ...state,
        deepDiveReportDynamicParams: action.payload,
      }
    }
    case actionTypes.SET_STORE_REPORT_DYNAMIC_PARAMS: {
      return {
        ...state,
        storeReportDynamicParams: action.payload,
      }
    }
    case actionTypes.SET_DYNAMIC_PARAMS: {
      return {
        ...state,
        dynamicParams: action.payload,
      }
    }

    default:
      return state
  }
}
export default generalReducer

export const groupDynamicParamsByParameter = (params: IDynamicParameter[]) => {
  const result: { [key: string]: IDynamicParameter[] } = {}

  params.forEach((param) => {
    const { parameter } = param

    if (!result[parameter]) {
      result[parameter] = []
    }

    result[parameter].push(param)
  })

  return result
}

export const groupDynamicParamsBySegments = (params: IDynamicParameter[]) => {
  const result: { [key: string]: string[] } = {}

  params.forEach((param) => {
    const { parameter, segment } = param

    if (!segment) return

    if (!result[parameter]) {
      result[parameter] = []
    }

    if (!result[parameter].includes(segment)) {
      result[parameter].push(segment)
    }
  })

  return result
}

export const dynamicParamsToUrlParams = (params: IDynamicParameter[]) => {
  const groupedParams = groupDynamicParamsBySegments(params)

  const jsonParams: { [key: string]: string[] } = {}

  Object.entries(groupedParams).forEach(([parameter, segments]) => {
    jsonParams[parameter] = segments
  })

  return encodeURIComponent(JSON.stringify(jsonParams))
}
