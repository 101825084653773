import { NoFocusButton } from 'components/CommonComponents/NoFocusButton'
import { ButtonProps } from '@chakra-ui/core'
import React, { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'

interface NavigationButtonProps extends Omit<ButtonProps, 'children'> {
  navigateTo: string
  buttonText: string
  icon?: ReactNode
}

export default function NavigationButton({ navigateTo, buttonText, icon, ...buttonProps }: NavigationButtonProps) {
  const navigate = useNavigate()

  const handleNavigation = () => {
    navigate(navigateTo)
  }

  return (
    <NoFocusButton
      style={{ borderRadius: '20px', width: '100%', ...(icon && { columnGap: '10px' }) }}
      border="1px solid"
      borderColor="black"
      variant="outline"
      bg="white"
      color="black"
      onClick={handleNavigation}
      {...buttonProps}
    >
      {icon}
      {buttonText}
    </NoFocusButton>
  )
}
