import { Box, useDisclosure } from '@chakra-ui/core'
import React, { useState } from 'react'
import { AudienceModalOptions } from './AudienceModalOptions'
import useLocaleText from 'components/useLocaleText'
import { Controller, useFormContext } from 'react-hook-form'
import { hasNonEmptyArray } from './utils'
import { FormInputLabel } from 'components/TasksV2/UI/FormInputLabel'

const ChooseAudience = () => {
  const { onClose } = useDisclosure()
  const [isAudienceOptionsModalOpen, setIsAudienceOptionsModalOpen] = useState(false)

  const create_task_assignee_placeholder = useLocaleText('create_task_assignee_placeholder')
  const { control } = useFormContext()

  return (
    <Box>
      <FormInputLabel label={create_task_assignee_placeholder} />

      <Controller
        name="audience"
        control={control}
        rules={{
          validate: (value) => {
            return hasNonEmptyArray(value) || 'Please select audience'
          },
        }}
        as={
          <AudienceModalOptions
            isAudienceOptionsModalOpen={isAudienceOptionsModalOpen}
            setIsAudienceOptionsModalOpen={setIsAudienceOptionsModalOpen}
            onClose={onClose}
          />
        }
      />
    </Box>
  )
}

export default ChooseAudience
