import React from 'react'
import { Box, Flex } from '@chakra-ui/core'
import { css } from '@emotion/core'
import { CameraIcon, LinkIcon, PollIcon, VideoIcon } from 'components/CommonComponents/JSXIcons'
import keys from 'constants/keys'
import { Content, OpenQuestionSubtask, PollSubtask, StepType, Subtask, useFormStepContext } from '../FormStepContext'
import QuestionIcon from 'components/CommonComponents/JSXIcons/QuestionIcon'
import DescriptionIcon from 'components/CommonComponents/JSXIcons/DescriptionIcon'

export const Footer = () => {
  const { subtaskActions, stepStack, isEdit } = useFormStepContext()

  const iconHoverEffect = css`
    display: flex;
    align-items: center;
    column-gap: 30px;

    & > * {
      transition: transform 0.3s ease, margin 0.3s ease;
    }

    & > *:hover {
      transform: scale(1.2);
    }
  `

  const handleAddSubtask = (type: number) => {
    switch (type) {
      case keys.SUBTASK_TYPES.POLL_SUBTASK:
        subtaskActions.append?.({
          title: '',
          is_required: true,
          is_multi_choice: false,
          type: keys.SUBTASK_TYPES.POLL_SUBTASK,
          options: ['', ''],
        } as PollSubtask)
        break
      case keys.SUBTASK_TYPES.IMAGE_SUBTASK:
        subtaskActions.append?.({
          title: '',
          is_required: true,
          type: keys.SUBTASK_TYPES.IMAGE_SUBTASK,
        } as Subtask)
        break
      case keys.SUBTASK_TYPES.VIDEO_SUBTASKS:
        subtaskActions.append?.({
          title: '',
          is_required: true,
          type: keys.SUBTASK_TYPES.VIDEO_SUBTASKS,
        } as Subtask)
        break
      case keys.SUBTASK_TYPES.LINK_SUBTASK:
        subtaskActions.append?.({
          title: '',
          is_required: true,
          type: keys.SUBTASK_TYPES.LINK_SUBTASK,
          external_link: '',
        } as Subtask)
        break
      case keys.SUBTASK_TYPES.OPEN_QUESTION:
        subtaskActions.append?.({
          title: '',
          is_required: true,
          is_numeric: false,
          type: keys.SUBTASK_TYPES.OPEN_QUESTION,
        } as OpenQuestionSubtask)
        break
      case keys.SUBTASK_TYPES.DESCRIPTION:
        subtaskActions.append?.({
          type: keys.SUBTASK_TYPES.DESCRIPTION,
          text: '',
          images: [],
          videos: [],
          files: [],
          index_ref: 0,
        } as Content)
        break
      default:
        break
    }
  }

  return (
    <>
      {stepStack[stepStack.length - 1] === StepType.SUBTASK_TASK_CREATION && !isEdit ? (
        <Flex css={iconHoverEffect} bg="transparent">
          <DescriptionIcon cursor="pointer" onClick={() => handleAddSubtask(keys.SUBTASK_TYPES.DESCRIPTION)} />
          <PollIcon cursor="pointer" onClick={() => handleAddSubtask(keys.SUBTASK_TYPES.POLL_SUBTASK)} />
          <LinkIcon cursor="pointer" onClick={() => handleAddSubtask(keys.SUBTASK_TYPES.LINK_SUBTASK)} />
          <CameraIcon cursor="pointer" onClick={() => handleAddSubtask(keys.SUBTASK_TYPES.IMAGE_SUBTASK)} />
          <VideoIcon cursor="pointer" onClick={() => handleAddSubtask(keys.SUBTASK_TYPES.VIDEO_SUBTASKS)} />
          <QuestionIcon cursor="pointer" onClick={() => handleAddSubtask(keys.SUBTASK_TYPES.OPEN_QUESTION)} />
        </Flex>
      ) : (
        <Box padding="20px" />
      )}
    </>
  )
}
