import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { IConfigRetailUser, IConfigStateGroups, RootState } from 'constants/interfaces'

export const getContextName = (
  contextId: string,
  groups: IConfigStateGroups,
  retailUsersObject: { [key: string]: IConfigRetailUser }
) => {
  if (groups?.[contextId]?.name) {
    return groups[contextId].name
  }

  if (retailUsersObject?.[contextId]) {
    const { first_name, last_name } = retailUsersObject[contextId]
    return `${first_name} ${last_name ?? ''}`.trim()
  }

  return ''
}

export const useContextName = (contextId: string) => {
  const {
    config: { groups, retailUsersObject },
  } = useSelector((state: RootState) => state.config)

  const resultName = useMemo(() => {
    if (groups?.[contextId]?.name) {
      return groups[contextId].name
    }

    if (retailUsersObject?.[contextId]) {
      const { first_name, last_name } = retailUsersObject[contextId]
      return `${first_name} ${last_name ?? ''}`.trim()
    }

    return ''
  }, [contextId, groups, retailUsersObject])

  return resultName
}
