export const baseURL: any = process.env.REACT_APP_API_ENDPOINT
export const chatWsBaseURL: any = process.env.REACT_APP_CHAT_API_ENDPOINT
export const mediaUrl: any = process.env.REACT_APP_MEDIA_API_ENDPOINT
export const privacyPolicy = 'https://sales-media.checkout-apps.com/privacy.html'
export const termsOfUse = 'https://sales-media.checkout-apps.com/terms.html'
const urlEnv = 'temp-v1'

export default {
  tasks: (rid: string, groups: string) => `${baseURL}/${urlEnv}_tasks-ms/?rid=${rid}&groups=${groups}`,
  getReport: `${baseURL}/temp-v1_data-ms/data/reports?`,
  getReportDynamicParams: `${baseURL}/temp-v1_data-ms/data/dynamic_parameters`,
  deleteTask: `${baseURL}/${urlEnv}_tasks-ms/`,
  getInsights: `${baseURL}/${urlEnv}_tasks-ms/insights/`, // POST request
  getSubtasks: (tids: string[], groups: string[]) =>
    `${baseURL}/${urlEnv}_tasks-ms/?tids=${tids.join(',')}&groups=${groups.join(',')}`,
  createTask: `${baseURL}/${urlEnv}_tasks-ms/`,
  initialTranslations: `${mediaUrl}/translations.json`,
  config: `${baseURL}/${urlEnv}_users-ms/config`,
  amazonUploadingLink: `${baseURL}/${urlEnv}_media/s3/signed_url`,
  updateSubtask: `${baseURL}/${urlEnv}_tasks-ms/sub_tasks`,
  updateUser: `${baseURL}/${urlEnv}_users-ms/users/`,
  getPosts: `${baseURL}/${urlEnv}_posts-ms/posts`,
  post: `${baseURL}/${urlEnv}_posts-ms/posts/post/`,
  createPost: `${baseURL}/${urlEnv}_posts-ms/posts`,
  likes: (postId: string) => `${baseURL}/${urlEnv}_posts-ms/likes?post_id=${postId}`,
  sentryDSN: 'https://4081af403cba4b2abdde0f7baae7f936@sentry.io/1882800',
  privacy: 'https://sales-media.checkout-apps.com/privacy.html',
  terms: 'https://sales-media.checkout-apps.com/terms.html',
  appStore: 'https://apps.apple.com/us/app/store-e/id1497463008?ls=1',
  googlePlay: 'https://play.google.com/store/apps/details?id=com.storee.checkout.app',
  comments: `${baseURL}/${urlEnv}_comments-ms/comments?`,
  commentsCounter: `${baseURL}/${urlEnv}_comments-ms/comments/count?`,
  resetPassword: `${baseURL}/${urlEnv}_users-ms/reset_password?`,
  getUserWritePermittedContext: `/${urlEnv}_posts-ms/permission/write`,
  getUserTaskPermissions: `/${urlEnv}_tasks-ms/permission/write?`,
  signedUrl: `${baseURL}/${urlEnv}_media/s3/signed_url`,
  pingUsers: `${baseURL}/${urlEnv}_posts-ms/ping`,
  cloudViewer: (fileUrl: string) => `https://docs.google.com/gview?embedded=true&url=${encodeURIComponent(fileUrl)}`,
  getRanking: `${baseURL}/${urlEnv}_data-ms/data/ranking`,
  searchPosts: `${baseURL}/${urlEnv}_posts-ms/search_posts`,
  pop: `${baseURL}/${urlEnv}_pops-ms/pops`,
  submitPop: `${baseURL}/${urlEnv}_pops-ms/pops/ack`,
  submitRePop: `${baseURL}/${urlEnv}_pops-ms/pops/repop`,
  getRetailUsers: (rid: string) => `${baseURL}/${urlEnv}_users-ms/retail_users?rid=${rid}`,
  users: `${baseURL}/${urlEnv}_users-ms/users?`,
  getUser: (auth_context: number, uid?: string) =>
    `${baseURL}/${urlEnv}_users-ms/users?uid=${uid}&auth_context=${auth_context}`,
  updateGroup: `${baseURL}/${urlEnv}_users-ms/group?`,
  readReportsPermissions: (group: string) =>
    `${baseURL}/${urlEnv}_data-ms/data/read_reports_permission_users?group=${group}`,
  pinManager: `${baseURL}/${urlEnv}_posts-ms/update_pin_post`,
  views: (postId: string) => `${baseURL}/${urlEnv}_posts-ms/views?post_id=${postId}`,
  updateViews: `${baseURL}/${urlEnv}_comments-ms/comments/views`,
  socketGPT: (token: any) => `${chatWsBaseURL}v1_chat_gpt/?Auth=${token}`,
  getActivities: (oid: string) => `${baseURL}/${urlEnv}_comments-ms/comments/activities?oid=${oid}`,
  updateLikes: `${baseURL}/${urlEnv}_comments-ms/comments/update_likes`,
  updateCommentViews: `${baseURL}/${urlEnv}_comments-ms/comments/update_views`,
  getSiteMetaData: (url: string) => `${baseURL}/${urlEnv}_media/preview?url=${url}`,
  tasksV2: `${baseURL}/temp-v2_tasks-ms/`,
  getTaskV2: `${baseURL}/temp-v2_tasks-ms/task`,
  getAllResults: (contextId: string) => `${baseURL}/temp-v2_tasks-ms/results/all?context_id=${contextId}`,
  updateResults: `${baseURL}/temp-v2_tasks-ms/results`,
  getSubtasksResultsOverview: `${baseURL}/temp-v2_tasks-ms/results/context`,
  getProvider: (email: string) => `${baseURL}/temp-v1_users-ms/provider?email=${email}`,
  getTaskInsights: `${baseURL}/temp-v2_tasks-ms/insights/task`,
  getInsightsV2: `${baseURL}/temp-v2_tasks-ms/insights/`,
  getTaskData: `${baseURL}/temp-v2_tasks-ms/task-data`,
  deleteCommentsOfImage: `${baseURL}/temp-v1_comments-ms/comments/parent_cid`,
  getResultsOfRecurringTaskInsights: `${baseURL}/temp-v2_tasks-ms/insights/results`,
  serviceCalls: `${baseURL}/temp-v1_service_calls-ms`,
  serviceCall: `${baseURL}/temp-v1_service_calls-ms/service_call`,
  serviceCallsDashboard: `${baseURL}/temp-v1_service_calls-ms/dashboard`,
  resetResults: `${baseURL}/temp-v2_tasks-ms/results`,
  createServiceCallChatToken: `${baseURL}/temp-v1_service_calls-ms/create_chat_token `,
  OTP: `${baseURL}/temp-v1_users-ms/otp`,
  loginOTP: `${baseURL}/temp-v1_users-ms/otp-login`,
}
