import React, { useMemo, useRef, useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper'
import ReactPlayer from 'react-player'
import { Flex, Image, useDisclosure } from '@chakra-ui/core'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import { v4 as uuidv4 } from 'uuid'
import placeholderImage from 'assets/placeholder-img.jpg'

import 'swiper/swiper-bundle.css'
import { getFileExtension } from 'utils'
import strings from 'constants/strings'
import FsLightbox from 'fslightbox-react'
import { isNumber } from 'lodash'

interface MediaSliderProps {
  media: string[]
  openImageModalHandler?: (imageIdx: number) => void
  onMediaChange?: (index: number) => void
  currentMediaIndex?: number
  isAllowedToOpenModalOverview?: boolean
  borderRadius?: string
}

const ArrowButton = ({
  direction,
  className,
  isVisible,
}: {
  direction: 'prev' | 'next'
  className: string
  isVisible: boolean
}) => {
  const isPrev = direction === 'prev'

  return (
    <Flex
      className={className}
      align="center"
      justify="center"
      position="absolute"
      top="50%"
      transform="translateY(-50%)"
      left={isPrev ? '10px' : 'auto'}
      right={isPrev ? 'auto' : '10px'}
      w="30px"
      h="30px"
      bg="white"
      borderRadius="50%"
      boxShadow="0 2px 5px rgba(0, 0, 0, 0.2)"
      cursor={'pointer'}
      zIndex={10}
      display={isVisible ? 'flex' : 'none'}
      pointerEvents={isVisible ? 'auto' : 'none'}
      userSelect="none"
      onClick={(e) => e.stopPropagation()}
    >
      {isPrev ? <ChevronLeft /> : <ChevronRight />}
    </Flex>
  )
}

const MediaSlider: React.FC<MediaSliderProps> = ({
  media,
  currentMediaIndex,
  openImageModalHandler,
  onMediaChange,
  borderRadius = '10px',
  isAllowedToOpenModalOverview = true,
}) => {
  const uniqueId = useMemo(() => `id-${uuidv4()}`, [])
  const swiperRef = useRef<any>(null)

  const { isOpen: isOpenLightBox, onToggle: onToggleLightBox } = useDisclosure()

  const mediaTypesForSwiper = useMemo(() => {
    if (!media.length) {
      return [
        {
          type: 'image',
          url: placeholderImage,
        },
      ]
    }
    return media?.map((url) => {
      const fileExt = getFileExtension(url)
      return {
        type: strings.SERVER_VIDEO_FORMATS.includes(fileExt) ? 'video' : 'image',
        url: url ? url : placeholderImage,
      }
    })
  }, [media])

  const mediaTypes = useMemo(
    () =>
      media?.map((el) => {
        const fileExt = getFileExtension(el)
        return strings.SERVER_VIDEO_FORMATS.includes(fileExt) ? 'video' : 'image'
      }),
    [media]
  )

  const playersRef = useRef<(ReactPlayer | null)[]>([])

  const stopAllVideos = () => {
    playersRef.current.forEach((player) => {
      if (player && player.getInternalPlayer) {
        const internalPlayer = player.getInternalPlayer()
        if (internalPlayer.pause) {
          internalPlayer.pause()
        }
      }
    })
  }

  useEffect(() => {
    if (swiperRef.current && currentMediaIndex !== undefined) {
      swiperRef.current.slideTo(currentMediaIndex)
    }
  }, [currentMediaIndex])

  return (
    <>
      <Flex position="relative" w="100%" h="100%" borderRadius={borderRadius} overflow="hidden">
        <>
          <ArrowButton
            direction="prev"
            className={`${uniqueId}-prev`}
            isVisible={isNumber(currentMediaIndex) && currentMediaIndex > 0}
          />
          <ArrowButton
            direction="next"
            className={`${uniqueId}-next`}
            isVisible={isNumber(currentMediaIndex) && currentMediaIndex < mediaTypesForSwiper.length - 1}
          />
        </>

        {/* Swiper */}
        <Swiper
          modules={[Navigation, Pagination]}
          spaceBetween={10}
          slidesPerView={1}
          navigation={{
            prevEl: `.${uniqueId}-prev`,
            nextEl: `.${uniqueId}-next`,
          }}
          pagination={{ clickable: true }}
          style={{ width: '100%', height: '100%' }}
          onSlideChange={(swiper) => {
            stopAllVideos()
            onMediaChange?.(swiper.activeIndex)
          }}
          onSwiper={(swiper) => {
            swiperRef.current = swiper
          }}
        >
          {mediaTypesForSwiper.map((item, index) => (
            <SwiperSlide key={index}>
              {(() => {
                switch (item.type) {
                  case 'image':
                    return (
                      <Image
                        src={item.url}
                        alt={`media ${index + 1}`}
                        objectFit="cover"
                        width="100%"
                        h="300px"
                        onClick={() => {
                          openImageModalHandler && openImageModalHandler(index)
                          isAllowedToOpenModalOverview && onToggleLightBox()
                        }}
                      />
                    )
                  case 'video':
                    return (
                      <ReactPlayer
                        ref={(el) => (playersRef.current[index] = el)}
                        playing={false}
                        url={item.url || placeholderImage}
                        controls={isAllowedToOpenModalOverview ? true : false}
                        width="100%"
                        height="300px"
                        onClick={() => {
                          openImageModalHandler && openImageModalHandler(index)
                          isAllowedToOpenModalOverview && onToggleLightBox()
                        }}
                        config={{
                          file: {
                            attributes: {
                              style: {
                                objectFit: 'none',
                                height: '100%',
                                width: '100%',
                              },
                              controlsList: 'nofullscreen',
                            },
                          },
                        }}
                      />
                    )
                  default:
                    return <div>Unsupported format</div>
                }
              })()}
            </SwiperSlide>
          ))}
        </Swiper>
      </Flex>
      <FsLightbox
        key={JSON.stringify(media)}
        toggler={isOpenLightBox}
        sources={media}
        sourceIndex={currentMediaIndex}
        types={mediaTypes}
      />
    </>
  )
}

export default MediaSlider
