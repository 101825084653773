import { Box } from '@chakra-ui/core'
import { StatusesMenu } from 'components/ServiceCalls/StatusesMenu'
import colors from 'constants/colors'
import React, { useCallback } from 'react'

const STATUSES = [
  { color: 'transparent', name: 't_all_tasks', value: -1 },
  { color: colors.analyticGrey, name: 'task_status_todo', value: 0 },
  { color: colors.yellow, name: 'task_status_inprogress', value: 1 },
  { color: colors.analyticGreen, name: 'task_status_done', value: 2 },
]

interface IProps {
  selectedStatusValue: number
  setSelectedStatusValue: React.Dispatch<React.SetStateAction<number>>
}
export const TasksStatusesMenu: React.FC<IProps> = ({ selectedStatusValue, setSelectedStatusValue }) => {
  const handleChangeStatus = useCallback(
    (value: number) => {
      setSelectedStatusValue(value)
    },
    [setSelectedStatusValue]
  )
  return (
    <Box maxW="200px" w="100%" isTruncated>
      <StatusesMenu
        statuses={STATUSES}
        statusValue={selectedStatusValue}
        handleChangeStatus={handleChangeStatus}
        boxShadow="none"
        border="1px solid #E2E8F0"
        h="40px"
      />
    </Box>
  )
}
