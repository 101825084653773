import { Box, Flex, Text } from '@chakra-ui/core'
import LocaleText from 'components/LocaleText'
import { WidgetWrapper } from 'components/TasksV2/UI/WidgetWrapper'
import React from 'react'
import { ConfigOption } from 'redux/actions/serviceCall'

interface IProps {
  options: ConfigOption[]
}
export const ServiceCallTypesOverview: React.FC<IProps> = ({ options }) => {
  return (
    <Flex flexDir="column" style={{ rowGap: '15px' }} w="100%">
      {options.map((option, index) => {
        const currentValue = option.option
        const optionName = option.name
        return (
          <Box key={index} w="100%" mx={'auto'}>
            <Text px="10px" pb="5px">
              {optionName}
            </Text>
            <WidgetWrapper p="10px" borderRadius="10px" w="100%">
              <Flex
                alignItems="center"
                minW="200px"
                flexDir="row"
                justifyContent="flex-start"
                style={{ columnGap: '10px' }}
              >
                <Text>
                  <LocaleText text={currentValue} />
                </Text>
              </Flex>
            </WidgetWrapper>
          </Box>
        )
      })}
    </Flex>
  )
}
