import useLocaleText from 'components/useLocaleText'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import TextareaAutosize from 'react-textarea-autosize'
import { Box, Text } from '@chakra-ui/core'

interface INotesProps {
  isAllowToUpdate: boolean
}
export const Notes: React.FC<INotesProps> = ({ isAllowToUpdate }) => {
  const { control, watch } = useFormContext()
  const notes = watch('notes')
  const t_notes = useLocaleText('notes_to_asignee')

  return (
    <Box pointerEvents={isAllowToUpdate ? 'all' : 'none'} opacity={isAllowToUpdate ? 1 : 0.7}>
      <Text px="5px">{t_notes}</Text>
      <Controller
        as={TextareaAutosize}
        name={'notes'}
        control={control}
        placeholder={t_notes}
        defaultValue={notes ?? ''}
        minRows={1}
        tabIndex={-1}
        style={{
          transition: 'all 0.3s ease',
          backgroundColor: 'white',
          padding: '10px',
          width: '100%',
          resize: 'none',
          color: 'black',
          outline: 'none',
          borderRadius: '10px',
          border: 'none',
          boxShadow: '0px 2px 6px 0px  #dcdcdc',
        }}
        value={notes ?? ''}
        onChange={([e]) => {
          return e.target.value
        }}
      />
    </Box>
  )
}
