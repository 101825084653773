/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Flex, Heading, useDisclosure, Text } from '@chakra-ui/core'
import isSolid from 'is-solid'
import { getSearchPost, handleGetPosts, setIsAllPostsCollapsed, setSelectedPost } from 'redux/actions/feed'
import colors from 'constants/colors'
import { RootState } from 'constants/interfaces'
import keys from 'constants/keys'
import { useLocation, useNavigationType, useSearchParams } from 'react-router-dom'
import { SearchFilter } from '../components/NewsFeed/SearchFilter'
import { BackArrow, NavTitleItem } from 'components/Sidebar/SidebarStyles'
import { Loader } from 'components/CommonComponents/Loader'
import LocaleText from '../components/LocaleText'
import { isRtlSelector } from '../redux/selectors/general'
import { SearchModal } from 'components/CommonComponents/Search/SearchModal'
import { getPostsIdsFromLocalStorage } from 'components/Post/utils'
import { PostWrapper } from 'components/Post'
import { wasBrowserRefreshed } from 'utils'
import { CustomSwitch } from 'components/CommonComponents/CustomSwitch'
import { Post } from 'redux/reducers/feed'
import { PostFilters } from 'components/NewsFeed/PostFilters'
import { CustomInfiniteScroll } from 'components/helperStyles'
import Search from 'components/CommonComponents/Search/Search'
import { DEVICE_TYPES } from 'constants/UI'
import useLocaleText from 'components/useLocaleText'
import useWindowDimensions from 'hooks/useWindowDimensions'
import ChatsPreviewsContainer from 'components/ChatsPreviews'
import { DraggableTasks } from 'components/NewsFeed/DraggableTasks'

const NewsFeed = () => {
  const { windowWidth } = useWindowDimensions()
  const navigate = useNavigate()
  const location = useLocation()
  const navigationType = useNavigationType()
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const [offset] = useState(0)
  const [search, setSearch] = useState('')
  const [sortedPostsIds, setSortedPostsIds] = useState<string[] | null>(null)
  const { isOpen: isOpenSearchModal, onOpen: onOpenSearchModal, onClose: onCloseSearchModal } = useDisclosure()
  const { uid } = useSelector((state: RootState) => state.auth)
  const {
    fetching,
    posts,
    hasMorePosts,
    searchPosts,
    isSearchFetching,
    isAllPostCollapsed,
    selectedPostID,
    isShowAllMyPosts,
    consolidationFilterOptions,
  } = useSelector((state: RootState) => state.feed)
  const {
    isConfigReady,
    userWritePermittedContext,
    config: { user_groups },
  } = useSelector((state: RootState) => state.config)
  const isRtl = useSelector(isRtlSelector)

  const isSearchPage = useMemo(() => location.pathname === keys.ROUTE_NAMES.NEWS_FEED_SEARCH, [location])
  const isMainPage = useMemo(() => location.pathname === keys.ROUTE_NAMES.NEWS_FEED, [location])

  const searchPlaceholder = useLocaleText('search_feed_web_placeholder')

  useEffect(() => {
    if (isSearchPage) {
      const searchQuery = searchParams.get('query')
      setSearch(searchQuery || '')
      return () => {
        setSearch('')
      }
    }
  }, [isSearchPage])

  useEffect(() => {
    if (isSearchPage) {
      const oldParams = Object.fromEntries(searchParams)
      setSearchParams({ ...oldParams, query: search })
    }
  }, [search])

  const openSearchModalHandler = useCallback(() => {
    if (isMainPage) {
      onOpenSearchModal()
    }
  }, [isMainPage, onOpenSearchModal])

  const fetchTasksAnsPosts = useCallback(() => {
    if (!isConfigReady || user_groups === null) return
    const currentEpochTimeUTC = Math.floor(new Date().getTime() / 1000).toString()
    const pinedPostsObject = getPostsIdsFromLocalStorage()
    const handleGetPostsParams = {
      ts: currentEpochTimeUTC,
      is_init: true,
      isRefresh: true,
      ...(pinedPostsObject[uid!] ? { postIds: pinedPostsObject[uid!] } : {}),
    }
    dispatch(handleGetPosts(handleGetPostsParams))
  }, [uid, dispatch, isConfigReady])

  useEffect(() => {
    if (!isConfigReady) return
    if (!isSolid(posts)) sessionStorage.setItem('wasRefreshed', 'true')

    if (isSearchPage && navigationType !== 'POP') {
      const created_by = searchParams.get('created_by') || ''
      const from_ts = searchParams.get('from_ts')
      const to_ts = searchParams.get('to_ts')
      const media = searchParams.get('media')
      dispatch(
        getSearchPost({
          created_by,
          date: { ...(from_ts ? { from_ts: +from_ts } : {}), ...(to_ts ? { to_ts: +to_ts } : {}) },
          is_contain_media: !!media,
          search: searchParams.get('query') || '',
        })
      )
    }
    if (!isSearchPage && wasBrowserRefreshed()) {
      fetchTasksAnsPosts()
    }

    return () => {
      dispatch(setSelectedPost(''))
    }
  }, [isSearchPage, isConfigReady, fetchTasksAnsPosts])

  const handleChangeSearch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value)
  }, [])

  useEffect(() => {
    if (!isShowAllMyPosts) {
      setSortedPostsIds(
        posts
          .filter((post: Post) => {
            const userCanViewPost = Object.keys(post.viewed).includes(uid!) && post.created_by !== uid!
            const isPostViewed = post.viewed[uid!]

            return userCanViewPost && !isPostViewed
          })
          .map((post: Post) => post.post_id)
      )
    } else {
      setSortedPostsIds(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowAllMyPosts, uid])

  const fetchPosts = useCallback(async () => {
    if (fetching || !isSolid(posts) || !hasMorePosts) return
    sessionStorage.setItem('wasRefreshed', 'true')
    const lastPostItem = posts.reduceRight((a: any) => a)
    const { created_at_ts } = lastPostItem
    const pinedPostsObject = getPostsIdsFromLocalStorage()
    const handleGetPostsParams = {
      ts: created_at_ts.toString(),
      ...(pinedPostsObject[uid!] ? { postIds: pinedPostsObject[uid!] } : {}),
      ...(consolidationFilterOptions
        ? consolidationFilterOptions.includes('1')
          ? {}
          : { groups: consolidationFilterOptions }
        : {}),
    }

    try {
      const fetchedPosts: any = await dispatch(handleGetPosts(handleGetPostsParams))
      if (!isShowAllMyPosts) {
        setSortedPostsIds((currentSortedPostsIds) => [
          ...(currentSortedPostsIds || []),
          ...fetchedPosts.map((post: Post) => post.post_id),
        ])
      }
    } catch (err) {
      console.log(err)
    }
  }, [dispatch, fetching, hasMorePosts, consolidationFilterOptions])

  const handleOpenSelectedPost = useCallback(
    (postId: string) => {
      if (!posts || selectedPostID === postId) return
      const currentPost = posts.find((post) => post.post_id === postId)
      if (!currentPost) return
      dispatch(setSelectedPost(currentPost?.post_id))
    },
    [posts, selectedPostID]
  )

  const selectedPost = useMemo(() => {
    return posts.find((post) => post.post_id === selectedPostID)
  }, [posts, selectedPostID])

  const onToggle = useCallback(() => {
    dispatch(setIsAllPostsCollapsed(!isAllPostCollapsed))
    if (selectedPost) dispatch(setSelectedPost(''))
  }, [isAllPostCollapsed, selectedPost])

  return (
    <Flex
      flexDir="column"
      py="20px"
      px="4vw"
      minW="685px"
      width={{
        lg: isAllPostCollapsed ? '100%' : '48.5vw',
        base: '100%',
        md: '100%',
      }}
    >
      {isSearchPage && (
        <Search
          onClick={openSearchModalHandler}
          value={search}
          onChange={handleChangeSearch}
          {...(!DEVICE_TYPES.DESKTOP(windowWidth)
            ? {
                order: -1,
                width: '100%',
                _focus: { width: '200px', transition: 'all ease .3s' },
              }
            : { maxW: '600px' })}
          placeholder={searchPlaceholder}
        />
      )}
      <Flex
        flexDir="row"
        alignItems="center"
        justifyContent="space-between"
        mt="15px"
        mb="10px"
        w="100%"
        minW="600px"
        style={{ columnGap: '50px' }}
        h="40px"
      >
        {!isSearchPage && isAllPostCollapsed !== null && (
          <Flex style={{ columnGap: '10px' }} alignItems="center">
            <Text color={colors.greyDark} fontSize="18px">
              <LocaleText text="posts_view_mode" />
            </Text>
            <CustomSwitch isChecked={!isAllPostCollapsed} onToggle={onToggle} />
          </Flex>
        )}
        {!isSearchPage && user_groups !== null && consolidationFilterOptions && (
          <PostFilters
            uid={uid!}
            user_groups={user_groups}
            consolidationFilterOptions={consolidationFilterOptions}
            userWritePermittedContext={userWritePermittedContext}
            isShowAllMyPosts={isShowAllMyPosts}
            openSearchModalHandler={openSearchModalHandler}
            isDisabled={!posts.length || fetching}
          />
        )}
      </Flex>
      <ChatsPreviewsContainer />
      {isSearchPage && (
        <Box mx="50px" mb="10px" position="relative">
          <Flex alignItems="center" height="36px" mb="22px">
            <NavTitleItem
              onClick={() => navigate(keys.ROUTE_NAMES.NEWS_FEED)}
              position="absolute"
              top="0"
              {...(isRtl ? { right: '-70px' } : { left: '-70px' })}
            >
              <BackArrow />
            </NavTitleItem>
            <Heading size="md">
              <LocaleText text="search_feed_result_title" /> {search && <>&ldquo;{search}&rdquo;</>}
            </Heading>
          </Flex>
          <SearchFilter />
        </Box>
      )}

      {!isSearchPage && isAllPostCollapsed !== null && (
        <Flex
          flexDir="row"
          background={isAllPostCollapsed ? 'white' : 'transparent'}
          borderRadius={isAllPostCollapsed ? '12px' : 'none'}
          w="100%"
          pos="relative"
          h={isAllPostCollapsed ? '76vh' : '100%'}
          minW={isAllPostCollapsed ? 'unset' : '600px'}
        >
          <Box pos="fixed" top="325px" zIndex={1000}>
            <DraggableTasks />
          </Box>

          <Box
            w={isAllPostCollapsed ? '40%' : 'inherit'}
            minW={isAllPostCollapsed ? 'unset' : '600px'}
            background="transparent"
            margin={isAllPostCollapsed ? '5px' : 0}
            height={isAllPostCollapsed ? '75vh' : '100%'}
            overflowY={isAllPostCollapsed ? 'scroll' : 'hidden'}
          >
            {!posts.length && !fetching ? (
              <Text color="#b7b8b8" fontSize="26px" fontWeight="700" mt="25%" textAlign="center">
                <LocaleText text="no_posts_found" />
              </Text>
            ) : (
              <CustomInfiniteScroll
                pageStart={offset}
                loadMore={fetchPosts}
                hasMore={hasMorePosts}
                threshold={500}
                loader={<Loader key="unique-loader-key" />}
                isRtl={isRtl}
                isAllPostCollapsed={isAllPostCollapsed}
                useWindow={isAllPostCollapsed ? false : true}
              >
                {posts
                  .filter((post) => (sortedPostsIds ? sortedPostsIds.includes(post.post_id) : true))
                  .map((post, index) => (
                    <PostWrapper
                      key={post.post_id}
                      index={index}
                      {...post}
                      handleOpenSelectedPost={handleOpenSelectedPost}
                      isAllPostCollapsed={isAllPostCollapsed}
                      posts={posts}
                      isDoublePost={false}
                      selectedPostID={selectedPostID === post.post_id ? selectedPostID : undefined}
                    />
                  ))}
              </CustomInfiniteScroll>
            )}
          </Box>
          {isAllPostCollapsed && (
            <Box
              border="1px solid #efefef"
              borderRadius="12px"
              w="59%"
              ml={isRtl ? '6px' : '0'}
              mr={isRtl ? '0' : '6px'}
              h="75vh"
              overflowY="auto"
              my="6px"
            >
              {selectedPost ? (
                <PostWrapper
                  {...selectedPost}
                  handleOpenSelectedPost={handleOpenSelectedPost}
                  isDoublePost={isAllPostCollapsed ? true : false}
                  isAllPostCollapsed={isAllPostCollapsed}
                  selectedPostID={selectedPostID === selectedPost.post_id ? selectedPostID : undefined}
                  posts={posts}
                />
              ) : (
                <Text color="#b7b8b8" fontSize="26px" fontWeight="700" mt="17%" textAlign="center">
                  <LocaleText text="no_conversation_selected" />
                </Text>
              )}
            </Box>
          )}
        </Flex>
      )}

      {isSearchFetching && <Loader />}
      {isSearchPage && !isSearchFetching && searchPosts.length > 0 && (
        <Box
          mx="60px"
          width={{
            lg: '48.5vw',
            base: '100%',
            md: '100vw',
          }}
        >
          {searchPosts?.map((post) => (
            <PostWrapper key={post.post_id} {...post} isAllPostCollapsed={false} posts={searchPosts} />
          ))}
        </Box>
      )}

      {isSearchPage && !isSearchFetching && !searchPosts.length && (
        <Flex justifyContent="center" mt="25px" maxWidth="600px">
          <Heading size="md">
            <LocaleText text="search_feed_no_results" />
          </Heading>
        </Flex>
      )}
      <SearchModal isOpen={isOpenSearchModal} onClose={onCloseSearchModal} />
    </Flex>
  )
}

export default NewsFeed
