import React from 'react'
import { IconProps } from './types'
import { Flex } from '@chakra-ui/core'
import { ExternalLink } from 'lucide-react'

const LinkIcon: React.FC<IconProps> = ({
  iconWidth = 24,
  iconHeight = 24,
  width = '40px',
  height = '40px',
  iconColor = '#D067A8',
  bgColor = '#F8EBF4',
  ...props
}) => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      bg={bgColor}
      width={width}
      height={height}
      borderRadius="full"
      {...props}
    >
      <ExternalLink width={iconWidth} height={iconHeight} color={iconColor} />
    </Flex>
  )
}

export default LinkIcon
