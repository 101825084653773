import { IConfigRetailUser, IConfigStateGroups, ITag, Result, Results, Subtask, Task } from 'constants/interfaces'
import keys from 'constants/keys'
import dayjs from 'dayjs'
import { calculateResultContextID, DeepDiveInsights } from 'redux/actions/tasks_v2'
import { extractMediaIdentifier, extractSubstringAfterDelimiter, getTokenFromCookie } from 'utils'
import { AppDispatch, store } from 'config/redux'
import { updateResults } from 'redux/actions/tasks_v2'
import { deleteCommentsOfMedia } from 'redux/actions/comments'
import { Content } from '../TaskCreation/FormStepContext'

interface IBuildPollOptions {
  options: string[]
  pollResults: Result[]
  permittedUsers: string[]
  permittedGroups: IConfigStateGroups
  deepDiveInsights?: DeepDiveInsights
  groups: IConfigStateGroups
  retailUsersObject: { [key: string]: IConfigRetailUser }
}
export const buildPollOptions = ({
  options,
  pollResults,
  permittedUsers,
  permittedGroups,
  deepDiveInsights,
  groups,
  retailUsersObject,
}: IBuildPollOptions) => {
  return Object.entries(options).map(([key, label]) => {
    const currentQuestionResults = pollResults.filter(
      (result) =>
        result?.poll_selections?.includes(parseInt(key, 10)) &&
        (Object.keys(retailUsersObject).includes(result.context_id) || Object.keys(groups).includes(result.context_id))
    )

    const count = currentQuestionResults.length

    const currentQuestionParticipants = currentQuestionResults.map((result) => {
      return {
        id: result.context_id,
        date: result.updated_at_ts ? dayjs.unix(result.updated_at_ts).toISOString() : '',
        isClickable:
          permittedUsers.includes(result.context_id) || Object.keys(permittedGroups).includes(result.context_id),
        ...(deepDiveInsights ? { insights: deepDiveInsights?.insights?.[result.context_id] } : {}),
      }
    })

    return { label, count, currentQuestionParticipants }
  })
}

export const isExternalLink = (link: string) =>
  link.includes(keys.EXTERNAL_LINK_PHRASE) || link.includes(keys.FORMS_STOREE)

export const isSubtaskAlreadyProcessed = ({
  tid,
  st_id,
  contextId,
  userResults,
}: {
  tid: string
  st_id: string
  userResults: Results
  contextId?: string | null
}) => {
  const subtasks = userResults[tid]
  if (!subtasks) return false

  return subtasks.some((subtask) => {
    if (contextId) {
      return subtask.st_id === st_id && subtask.context_id === contextId
    }
    return subtask.st_id === st_id
  })
}
type getFormattedLinkProps = {
  link: string
  tasks: Task[]
  tid: string
  st_id: string
  contextId: string
  uid: string
  userResults: Results
  rid: string
  tags: ITag[]
  first_name: string
  last_name: string
  default_group: string | null
  groups: IConfigStateGroups
  activeGroupID: string
}
export const getFormattedLink = ({
  link,
  tasks,
  tid,
  st_id,
  contextId,
  uid,
  userResults,
  rid,
  tags,
  first_name,
  last_name,
  default_group,
  groups,
  activeGroupID,
}: getFormattedLinkProps) => {
  if (!isExternalLink(link)) {
    return link
  }
  const task = tasks.find((task) => task.tid === tid)
  if (!task) {
    throw new Error('Task not found')
  }
  const token = getTokenFromCookie()
  const version = 2
  const utc_offset = 3
  const { recipient_type, recurrence_start_time, is_individual_results, retail_id_context_id, recurrence_ts } = task
  const resultContextId = calculateResultContextID({
    recipient_type,
    is_individual_results,
    uid,
    groupId: activeGroupID,
    tagId: recipient_type === 2 ? extractSubstringAfterDelimiter(retail_id_context_id, rid) : null,
    retail_id_context_id,
    rid,
    tags,
  })
  const userResultOfCurrentSubtask = userResults?.[tid]?.find(
    (result) => result.st_id === st_id && result.context_id === contextId
  )
  const task_context_id = extractSubstringAfterDelimiter(task.retail_id_context_id, rid)

  const isFirstResult = !userResultOfCurrentSubtask
  const recurrenceTS = recurrence_ts

  const params = {
    token,
    task_id: tid,
    subtask_id: st_id,
    version,
    is_individual_results,
    context_id: resultContextId,
    is_first_result: isFirstResult,
    recurrence_ts: recurrenceTS,
    recurrence_start_time: recurrence_start_time,
    utc_offset,
    recipient_type,
    first_name: first_name,
    task_context_id,
    last_name: last_name ?? '',
    branch_name: default_group ? groups?.[default_group].name : '',
  }

  const queryString = Object.keys(params)
    .filter((key) => params[key] !== null && params[key] !== undefined) // Filter out null or undefined
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`) // Encode and map to key=value
    .join('&')

  return `${link}?${queryString}`
}

interface ResetSubtasksParams {
  tid: string
  contextId: string
  filteredResultsBySubtasksIds: Result[]
  dispatch: AppDispatch
  recurrence_ts?: string
}

export const resetSubtasksResults = ({
  tid,
  contextId,
  filteredResultsBySubtasksIds,
  dispatch,
  recurrence_ts,
}: ResetSubtasksParams) => {
  const { cdn, rid } = store.getState().config.config
  const contextIdRecurrenceTs = `${contextId}${recurrence_ts ? '_' + recurrence_ts : ''}`

  const processMediaSubtask = (result: any, mediaType: 'images' | 'videos') => {
    const mediaURLs: string[] = result[mediaType] || []
    const oids: string[] = []
    const mediaHashes: string[] = []
    const stIdContextIdRecurrenceTsArr: string[] = []

    mediaURLs.forEach((url) => {
      const mediaHash = extractMediaIdentifier({ url, cdn, rid, uid: result.updated_by! })
      if (mediaHash?.trim()) {
        const stIdContextIdRecurrenceTs = `${result.st_id}_${contextIdRecurrenceTs}`
        const oid = `${result.st_id}_${contextIdRecurrenceTs}-${mediaHash}`

        oids.push(oid)
        mediaHashes.push(mediaHash)
        stIdContextIdRecurrenceTsArr.push(stIdContextIdRecurrenceTs)
      }
    })

    if (oids.length > 0) {
      dispatch(deleteCommentsOfMedia(oids, mediaHashes, stIdContextIdRecurrenceTsArr, true))
    }

    dispatch(
      updateResults({
        [mediaType]: [],
        tid,
        st_id: result.st_id,
        type: keys.SUBTASK_TYPES[mediaType === 'images' ? 'IMAGE_SUBTASK' : 'VIDEO_SUBTASKS'],
        resultContextId: contextId,
        status: keys.TASK_STATUSES.TODO.value,
        useOptimisticOnly: true,
      })
    )
  }

  filteredResultsBySubtasksIds.forEach((result) => {
    switch (result.sub_task_type) {
      case keys.SUBTASK_TYPES.POLL_SUBTASK:
        dispatch(
          updateResults({
            votes: [],
            tid,
            st_id: result.st_id,
            type: keys.SUBTASK_TYPES.POLL_SUBTASK,
            resultContextId: contextId,
            status: keys.TASK_STATUSES.TODO.value,
            useOptimisticOnly: true,
          })
        )
        break

      case keys.SUBTASK_TYPES.OPEN_QUESTION:
        dispatch(
          updateResults({
            tid,
            st_id: result.st_id,
            type: keys.SUBTASK_TYPES.OPEN_QUESTION,
            resultContextId: contextId,
            status: keys.TASK_STATUSES.TODO.value,
            answer: null,
            isNumeric: result.is_numeric,
            useOptimisticOnly: true,
          })
        )
        break

      case keys.SUBTASK_TYPES.IMAGE_SUBTASK:
        processMediaSubtask(result, 'images')
        break

      case keys.SUBTASK_TYPES.VIDEO_SUBTASKS:
        processMediaSubtask(result, 'videos')
        break

      case keys.SUBTASK_TYPES.LINK_SUBTASK:
        dispatch(
          updateResults({
            tid,
            st_id: result.st_id,
            type: keys.SUBTASK_TYPES.LINK_SUBTASK,
            isExternalLink: false,
            resultContextId: contextId,
            status: keys.TASK_STATUSES.TODO.value,
            useOptimisticOnly: true,
          })
        )
        break

      default:
        break
    }
  })
}

export const sortContentAndSubtasks = (subtasks: Subtask[] = [], contents: Content[] = []) => {
  const subtasksList = subtasks.sort((a, b) => a.order_index - b.order_index)

  if (!contents || contents.length === 0) {
    return subtasksList
  }

  // Сортируем контент по index_ref
  const sortedContents = contents.sort((a, b) => a.index_ref - b.index_ref)
  const result: (Content | Subtask)[] = []
  let subtaskIndex = 0

  // Добавляем контент с index_ref = 0 в начало, если есть
  const firstContent = sortedContents.find((content) => content.index_ref === 0)
  if (firstContent) {
    result.push(firstContent)
  }

  // Проходим по оставшимся контентам
  for (let i = 0; i < sortedContents.length; i++) {
    const currentContent = sortedContents[i]
    if (currentContent.index_ref === 0) continue // Пропускаем, если уже добавили

    // Добавляем сабтаски между контентами
    while (subtaskIndex < subtasksList.length && result.length < currentContent.index_ref) {
      result.push(subtasksList[subtaskIndex])
      subtaskIndex++
    }

    result.push(currentContent)
  }

  // Добавляем оставшиеся сабтаски
  while (subtaskIndex < subtasksList.length) {
    result.push(subtasksList[subtaskIndex])
    subtaskIndex++
  }

  return result
}
