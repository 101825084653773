import React from 'react'
import { BoxProps, Text } from '@chakra-ui/core'
import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import 'dayjs/locale/en'
import { useSelector } from 'react-redux'
import { RootState } from 'constants/interfaces'
import { useDateLocale } from 'hooks/useDateLocale'

dayjs.extend(localeData)

interface IProps extends BoxProps {
  date: Date | null
  placeholder: string
  format?: string
}

export const DateToLocaleValue: React.FC<IProps> = ({ date, placeholder, format = 'DD MMMM YYYY HH:mm', ...props }) => {
  const locale = useSelector((state: RootState) => state.general.locale)

  useDateLocale(locale)

  return <Text {...props}>{date ? dayjs(date).locale(locale).format(format) : placeholder}</Text>
}
