import { IConfigRetailUser, IConfigStateGroups, Result } from 'constants/interfaces'
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import { getContextName } from 'hooks/useContextName'

interface IProps {
  currentPollResults: Result[]
  options: string[]
  fileName: string
  groups: IConfigStateGroups
  retailUsersObject: { [key: string]: IConfigRetailUser }
}
export const exportPollResultsToExcel = ({
  currentPollResults,
  options,
  fileName,
  groups,
  retailUsersObject,
}: IProps) => {
  const workbook = new ExcelJS.Workbook()
  const sanitizedFileName = fileName.replace(/[\\/*?:[\]]/g, '_')
  const worksheet = workbook.addWorksheet(sanitizedFileName)

  const columns = [{ header: 'Store Name', key: 'context_id', width: 30 }]
  options.forEach((option, index) => {
    columns.push({ header: option, key: `option_${index}`, width: 15 })
  })
  worksheet.columns = columns

  worksheet.getRow(1).font = { bold: true }
  worksheet.getRow(1).alignment = { horizontal: 'center' }
  worksheet.getRow(1).height = 20

  currentPollResults.forEach((item) => {
    const rowData = { context_id: getContextName(item.context_id, groups, retailUsersObject!) }

    options.forEach((_, index) => {
      rowData[`option_${index}`] = item?.poll_selections?.includes(index) ? 'X' : ''
    })

    const row = worksheet.addRow(rowData)

    row.eachCell((cell, colNumber) => {
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true,
      }
      cell.font = {
        size: 12,
        name: 'Arial',
      }
      if (colNumber === 1) {
        cell.font.bold = true
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFF1F1F1' },
        }
      }
      if (cell.value === 'X') {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FF90EE90' },
        }
      }
      cell.border = {
        top: { style: 'thin', color: { argb: 'FFB0B0B0' } },
        left: { style: 'thin', color: { argb: 'FFB0B0B0' } },
        bottom: { style: 'thin', color: { argb: 'FFB0B0B0' } },
        right: { style: 'thin', color: { argb: 'FFB0B0B0' } },
      }
    })
  })

  workbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(new Blob([buffer]), `${fileName}.xlsx`)
  })
}
