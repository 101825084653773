import React, { useEffect } from 'react'
import { useMediaState } from 'hooks/useMediaState'
import { Controller, useFormContext } from 'react-hook-form'
import { FormControl } from '@chakra-ui/core'
import MediaGallery from 'components/TasksV2/UI/MediaGallery'
import HiddenFileInput from 'components/TasksV2/UI/HiddenInut'
import { WidgetWrapper } from 'components/TasksV2/UI/WidgetWrapper'
import useLocaleText from 'components/useLocaleText'
import DescriptionTextarea from 'components/TasksV2/TaskCreation/Steps/SubtaskCreationStep/Description/DescriptionTextarea'
import { AddMediaButtonts } from 'components/TasksV2/TaskCreation/Steps/SubtaskCreationStep/Description/AddMediaButtonts'

interface DescriptionProps {
  descPath: string
  imagesPath: string
  videosPath: string
  filesPath: string
}

const Description: React.FC<DescriptionProps> = ({ descPath, imagesPath, videosPath, filesPath }) => {
  const { getValues, setValue, control, register } = useFormContext()
  const t_say_something = useLocaleText('t_say_something')

  const {
    uploadedImagesURL,
    uploadedVideosURL,
    uploadedFilesURL,
    handleMediaUploading,
    handleDeleteMedia,
    deleteFileHandler,
    handleFileUpload: uploadFile,
    imageInputRef,
    fileInputRef,
    isUploadLoading,
  } = useMediaState({
    images: getValues(imagesPath) || [],
    files: getValues(filesPath) || [],
    videos: getValues(videosPath) || [],
  })

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const data = await handleMediaUploading(event)
    if (!data || (!data.uploadedImages.length && !data.uploadedVideos.length)) {
      return
    }

    if (data.uploadedImages.length) {
      const newImages = [...uploadedImagesURL, ...data.uploadedImages]
      setValue(imagesPath, newImages)
    }
    if (data.uploadedVideos.length) {
      const newVideos = [...uploadedVideosURL, ...data.uploadedVideos]
      setValue(videosPath, newVideos)
    }
  }

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const data = await uploadFile(event)
    if (!data || !data.fileName || !data.fileUrl) {
      return
    }
    const newFiles = [...uploadedFilesURL, data.fileUrl]
    setValue(filesPath, newFiles)
  }

  const handleRemoveMedia = (index: number) => {
    const { filteredImages, filteredVideos } = handleDeleteMedia(index)
    setValue(videosPath, filteredVideos)
    setValue(imagesPath, filteredImages)
  }

  const handleRemoveFile = (url: string) => {
    const updatedFiles = deleteFileHandler(url)
    setValue(filesPath, updatedFiles)
  }

  const handleMediaUploadClick = () => {
    imageInputRef.current?.click()
  }

  const handleFileUploadClick = () => {
    fileInputRef.current?.click()
  }

  useEffect(() => {
    register(imagesPath)
    register(videosPath)
    register(filesPath)
  }, [filesPath, imagesPath, register, videosPath])

  return (
    <FormControl mb={4} w="100%">
      <WidgetWrapper flexDir={'column'} pos="relative" p="15px">
        <Controller
          name={descPath}
          control={control}
          defaultValue={getValues(descPath) || ''}
          as={DescriptionTextarea}
          onChange={([event]) => event.target.value}
          onBlur={([event]) => event.target.value}
          value={getValues(descPath) as string}
          placeholder={t_say_something}
          onImageUploadClick={handleMediaUploadClick}
          onFileUploadClick={handleFileUploadClick}
        />
        {/* Media Gallery */}
        <MediaGallery
          attachedImages={uploadedImagesURL}
          attachedFiles={uploadedFilesURL}
          attachedVideos={uploadedVideosURL}
          onRemoveMedia={handleRemoveMedia}
          onRemoveFile={handleRemoveFile}
          isUploadLoading={isUploadLoading}
        />
        <AddMediaButtonts onFileUploadClick={handleFileUploadClick} onImageUploadClick={handleMediaUploadClick} />
        {/* Hidden File Inputs */}
        <HiddenFileInput accept="image/*, video/*" multiple onChange={handleImageUpload} ref={imageInputRef} />
        <HiddenFileInput accept="*/*" onChange={handleFileUpload} ref={fileInputRef} />
      </WidgetWrapper>
    </FormControl>
  )
}

export default Description
