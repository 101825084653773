import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box } from '@chakra-ui/core'
import GroupsTree from 'components/CommonComponents/GroupsTree'
import { IConfigStateGroups, RootState } from 'constants/interfaces'
import strings from 'constants/strings'
import keys from 'constants/keys'
import { handleGroupChange } from 'redux/actions/config'
import { handleTasks } from 'redux/actions/tasks'
import { resetReports } from 'redux/actions/reports'
import { getInsightsV2, getTasks } from 'redux/actions/tasks_v2'
import { getServiceCalls } from 'redux/actions/serviceCall'

interface IProps {
  isOpen: boolean
  onClose: () => void
}

export const ChooseContext = ({ isOpen, onClose }: IProps) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const {
    config: { groups, read_permitted_groups_ids },
    activeGroupID,
    retailConfig,
  } = useSelector((state: RootState) => state.config)

  const [selectedGroupID, setSelectedGroupID] = useState<string[]>(activeGroupID ? [activeGroupID] : [])
  const [showGroupList, setGroupList] = useState(isOpen)

  const allowedGroups = useMemo(() => {
    if (!Object.keys(groups).length) return {}

    if (read_permitted_groups_ids && read_permitted_groups_ids.length > 0) {
      return Object.keys(groups)
        .filter((key) => read_permitted_groups_ids.includes(key))
        .reduce((acc, key) => {
          acc[key] = groups[key]
          return acc
        }, {} as IConfigStateGroups)
    }

    return groups
  }, [groups, read_permitted_groups_ids])

  const handleChangeContext = useCallback(
    (groupID: string) => {
      if (groupID !== activeGroupID) {
        dispatch(handleGroupChange(groupID))
        onClose()

        if (location.pathname.includes('reports') && retailConfig) {
          dispatch(resetReports())
          navigate(`${keys.ROUTE_NAMES.REPORTS}/${groupID}`)
        }

        dispatch(handleTasks(groupID))
        dispatch(getTasks())
        dispatch(getInsightsV2({}))
        dispatch(getServiceCalls(groupID!))

        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      } else {
        onClose()
      }
    },
    [activeGroupID, dispatch, onClose, location.pathname, retailConfig, navigate]
  )

  useEffect(() => {
    if (selectedGroupID[0] !== activeGroupID && location.pathname.includes('ranking')) {
      navigate('/')
    }
  }, [activeGroupID, location.pathname, navigate, selectedGroupID])

  useEffect(() => {
    if (activeGroupID && !selectedGroupID) setSelectedGroupID([activeGroupID])
  }, [activeGroupID, selectedGroupID])

  return (
    <Box px="6">
      <GroupsTree
        isShowAnimation={true}
        isOpen={showGroupList}
        onClose={onClose}
        mode={strings.SINGLE_MODE_CONTEXT}
        setGroupList={setGroupList}
        selectedGroupID={selectedGroupID}
        setSelectedGroupID={setSelectedGroupID}
        handleChangeContext={handleChangeContext}
        treeGroups={allowedGroups}
      />
    </Box>
  )
}
