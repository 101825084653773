// PollQuestion.tsx

import React, { memo } from 'react'
import { Flex, Text } from '@chakra-ui/core'
import { Controller, useFormContext } from 'react-hook-form'
import { ControlledCustomSwitch } from 'components/TasksV2/UI/ControlledCustomSwitch'
import { TaskInput } from 'components/TasksV2/UI/TaskInput'
import useLocaleText from 'components/useLocaleText'
import { Subtask } from 'components/TasksV2/TaskCreation/FormStepContext'
import colors from 'constants/colors'

interface PollQuestionProps {
  subtaskPath: string
  index: number
  field: Subtask
}

const PollQuestion: React.FC<PollQuestionProps> = memo(({ subtaskPath, field }) => {
  const { control } = useFormContext()
  const t_multipleAnswers = useLocaleText('t_multipleAnswers')
  const t_questionLabel = useLocaleText('t_questionLabel')

  const questionName = `${subtaskPath}.title`

  return (
    <Flex direction="column" mt="20px">
      <Flex alignItems="center" justifyContent="space-between">
        <Text px="15px" fontSize="14px">
          {t_questionLabel}
        </Text>
        <Flex alignItems="center" style={{ columnGap: '10px' }} mb="5px">
          <Text fontSize="12px" color={colors.analyticGrey}>
            {t_multipleAnswers}
          </Text>
          <Controller
            as={ControlledCustomSwitch}
            name={`${subtaskPath}.is_multi_choice`}
            control={control}
            defaultValue={false}
            valueName="isChecked"
            onChange={([event]) => {
              return event.target.checked
            }}
          />
        </Flex>
      </Flex>
      <Controller
        as={TaskInput}
        name={questionName}
        control={control}
        placeholder={t_questionLabel}
        defaultValue={field.title ?? ''}
        rules={{
          required: 'Title is required',
          validate: (value) => {
            return value.trim() !== '' || 'Title is required'
          },
        }}
        key={questionName}
        boxShadow="none"
        borderRadius="10px"
        borderColor="#f0f0f0"
        border="2px solid #f0f0f0"
      />
    </Flex>
  )
})

export default PollQuestion
