import React, { useEffect, useMemo } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import dayjs from 'dayjs'
import styles from './MonthlyRangePicker.module.css'
import { Controller, useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { RootState } from 'constants/interfaces'
import { useDatePickerLocale } from 'hooks/useDatePickerLocale'

export const MonthlyRangePicker = () => {
  const { watch, setValue, control, triggerValidation } = useFormContext()

  const fromTsValue = watch('from_ts')
  const monthlyRange = watch('monthly_range') ?? [null, null]

  const fromTs = useMemo(() => (fromTsValue ? new Date(fromTsValue) : new Date()), [fromTsValue])

  const startDateValue = useMemo(() => {
    return monthlyRange[0] ? new Date(monthlyRange[0]) : null
  }, [monthlyRange])

  const endDateValue = useMemo(() => {
    return monthlyRange[1] ? new Date(monthlyRange[1]) : null
  }, [monthlyRange])

  const minDate = fromTs
  const maxDate = dayjs(fromTs).add(30, 'day').toDate()

  const locale = useSelector((state: RootState) => state.general.locale)
  const datePickerLocale = useDatePickerLocale(locale)

  const handleDateChange = async (date: Date) => {
    console.log('date', date)

    const [start, end] = monthlyRange
    const startDate = start ? new Date(start) : null
    const endDateVal = end ? new Date(end) : null

    if (startDate && endDateVal) {
      setValue('monthly_range', [null, null])
      console.log('1')

      await triggerValidation('deadline')
      return
    }

    if (startDate && !endDateVal) {
      if (dayjs(date).isSame(startDate, 'day')) {
        console.log('2')

        setValue('monthly_range', [null, null])
        await triggerValidation('deadline')
        return
      } else {
        console.log('3')
        setValue('monthly_range', [startDate.toISOString(), date.toISOString()])
        await triggerValidation('deadline')
        return
      }
    }

    if (!startDate && !endDateVal) {
      console.log('4')
      setValue('monthly_range', [date.toISOString(), null])
      await triggerValidation('deadline')
      return
    }
  }

  const dayClassName = (date: Date) => {
    if (startDateValue && dayjs(date).isSame(startDateValue, 'day')) {
      return styles['react-datepicker__day--range-start']
    }
    if (endDateValue && dayjs(date).isSame(endDateValue, 'day')) {
      return styles['react-datepicker__day--range-end']
    }
    if (startDateValue && endDateValue && dayjs(date).isAfter(startDateValue) && dayjs(date).isBefore(endDateValue)) {
      return styles['react-datepicker__day--range-middle']
    }
    return ''
  }

  useEffect(() => {
    if (!monthlyRange[0] && !monthlyRange[1]) {
      setValue('monthly_range', [fromTs.toISOString(), null])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Controller
      name="monthly_range"
      control={control}
      rules={{
        validate: (value) => {
          if (!Array.isArray(value) || value.length !== 2) {
            return 'Please select a start and end date.'
          }
          const [start, end] = value
          if (!start || !end) {
            return 'Please select a start and end date.'
          }
          const startD = new Date(start)
          const endD = new Date(end)
          if (isNaN(startD.getTime()) || isNaN(endD.getTime())) {
            return 'Please select a valid start and end date.'
          }
          return true
        },
      }}
      as={
        <div className={styles.datepicker}>
          <DatePicker
            selected={endDateValue || startDateValue || null}
            onSelect={handleDateChange}
            startDate={startDateValue}
            endDate={endDateValue}
            onChange={() => {}}
            selectsStart
            minDate={minDate}
            maxDate={maxDate}
            locale={datePickerLocale}
            inline
            dayClassName={dayClassName}
          />
        </div>
      }
    />
  )
}

export default MonthlyRangePicker
