import { Box, Link } from '@chakra-ui/core'
import { IComment, IConfigRetailUser, RootState } from 'constants/interfaces'
import keys from 'constants/keys'
import React, { memo } from 'react'
import { Post } from 'redux/reducers/feed'
import * as linkify from 'linkifyjs'
import { useSelector } from 'react-redux'
import { LinkPreview } from 'components/LinkPreview'

interface IProps {
  post: Post
  lastComment?: IComment
  retailUsersObject:
    | {
        [key: string]: IConfigRetailUser
      }
    | undefined
  hasNonEmptyMedia?: boolean
  isDoublePost: boolean
  isShowPreview?: boolean
}
export const renderTextWithParagraphsAndEllipsis = (text: string) => {
  const paragraphs = text.split('\n').map((paragraph, index) => (
    <React.Fragment key={index}>
      {paragraph}
      <br />
    </React.Fragment>
  ))

  return (
    <Box
      style={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre-line',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
      }}
    >
      {paragraphs}
    </Box>
  )
}
interface IRenderTextWithLineBreaks {
  text: string
  isShowPreview?: boolean
}

export const renderTextWithLineBreaks = ({ text, isShowPreview = true }: IRenderTextWithLineBreaks) => {
  const paragraphs = text.split(/\r?\n/)

  const linkedParagraphs = paragraphs.map((paragraph, paragraphIndex) => {
    const links = linkify.find(paragraph)
    let lastIndex = 0
    const paragraphParts = []

    links.forEach((link, index) => {
      const textBeforeLink = paragraph.substring(lastIndex, link.start)
      if (textBeforeLink) {
        paragraphParts.push(textBeforeLink)
      }

      paragraphParts.push(
        <Link key={`link-${index}`} href={link.href} color="blue.500" target="_blank" rel="noopener noreferrer">
          {link.value}
        </Link>
      )

      lastIndex = link.end

      const nextLink = links[index + 1]
      const textAfterLink = paragraph.substring(lastIndex, nextLink ? nextLink.start : paragraph.length)
      if (textAfterLink) {
        paragraphParts.push(textAfterLink)
      }

      if (isShowPreview) {
        paragraphParts.push(<LinkPreview key={`preview-${index}`} url={link.href} />)
      }

      lastIndex += textAfterLink.length
    })

    if (lastIndex < paragraph.length) {
      paragraphParts.push(paragraph.substring(lastIndex))
    }

    return (
      <React.Fragment key={paragraphIndex}>
        {paragraphParts}
        <br />
      </React.Fragment>
    )
  })

  return <>{linkedParagraphs}</>
}
export const PostTitle = memo(
  ({ post, lastComment, retailUsersObject, hasNonEmptyMedia = false, isDoublePost, isShowPreview = true }: IProps) => {
    const { isAllPostCollapsed, selectedPostID } = useSelector((state: RootState) => state.feed)
    if (!lastComment) {
      return post.type !== keys.POST_TYPE.GROUP_POST ? (
        <Box>{renderTextWithLineBreaks({ text: post?.title, isShowPreview: isShowPreview })}</Box>
      ) : null
    }
    const lastCommentCreator = retailUsersObject?.[lastComment.created_by]
    const lastCommentCreatorFullName = `${lastCommentCreator?.first_name ?? ''} ${
      lastCommentCreator?.last_name ?? ''
    }`.trim()

    if (
      (post.type === keys.POST_TYPE.GROUP_POST && isDoublePost) ||
      (!isAllPostCollapsed && selectedPostID === post.post_id)
    ) {
      return <></>
    }
    const messageContent = hasNonEmptyMedia ? '' : lastComment.message || ''

    return (
      <Box>
        {post.type !== keys.POST_TYPE.GROUP_POST ? (
          renderTextWithLineBreaks({ text: post?.title, isShowPreview: isShowPreview })
        ) : (
          <>
            <Box as={'span'} style={{ fontWeight: 'bold', color: '#616770' }}>
              {lastCommentCreatorFullName}:{' '}
            </Box>
            {messageContent ? renderTextWithLineBreaks({ text: post?.title, isShowPreview: isShowPreview }) : <></>}
          </>
        )}
      </Box>
    )
  }
)
