import { RootState, Subtask } from 'constants/interfaces'
import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Votes } from './Votes'
import { WidgetWrapper } from 'components/TasksV2/UI/WidgetWrapper'
import { WidgetIcon } from 'components/TasksV2/UI/WidgetIcon'
import { Box, Divider, Flex, Text } from '@chakra-ui/core'
import { SelectionPrompt } from 'components/TasksV2/UI/SelectionPrompt'
import { SubtaskTitile } from 'components/TasksV2/UI/SubtaskTitile'
import {
  parseRecurrenceTimestamp,
  TaskOverviewContentType,
  useTaskOverviewContext,
} from 'components/TasksV2/TasksOverview/TaskOverviewStackProvider'
import { StatusCircle } from 'components/TasksV2/UI/StatusCircle'
import colors from 'constants/colors'
import keys from 'constants/keys'
import useLocaleText from 'components/useLocaleText'
import { ResetMenu } from 'components/TasksV2/UI/ResetMenu'
import { isRtlSelector } from 'redux/selectors/general'
import { resetSubtasksResults } from 'components/TasksV2/TasksOverview/utils'
import { AppDispatch } from 'config/redux'
import { resetResults } from 'redux/actions/tasks_v2'
import { SkipSubtaskButton } from 'components/TasksV2/UI/SkipSubtaskButton'

interface Props {
  pollSubtask: Subtask
}
const Poll = ({ pollSubtask }: Props) => {
  const { allResults, allResultsLoading } = useSelector((state: RootState) => state.tasks_v2)
  const dispatch: AppDispatch = useDispatch()
  const isRtl = useSelector(isRtlSelector)
  const {
    openContent,
    resultContextId,
    task: { recurrence_ts, tid },
    isShowResetMenu,
  } = useTaskOverviewContext()

  const [isFetching, setIsFetching] = useState(false)

  const filteredResultBySubtasksIds = useMemo(
    () =>
      allResults[tid]?.find((result) => result.context_id === resultContextId && result.st_id === pollSubtask.st_id) ||
      null,
    [allResults, resultContextId, pollSubtask, tid]
  )

  const handleResetResults = async () => {
    setIsFetching(true)

    const isResOk = await dispatch(
      resetResults({
        tid,
        sub_tasks_ids: filteredResultBySubtasksIds?.st_id ? [filteredResultBySubtasksIds.st_id] : [],
        result_context_id: resultContextId,
        ...(recurrence_ts ? { recurrence_ts } : {}),
      })
    )
    if (!isResOk) {
      return
    }

    resetSubtasksResults({
      tid,
      filteredResultsBySubtasksIds: filteredResultBySubtasksIds ? [filteredResultBySubtasksIds] : [],
      contextId: resultContextId,
      dispatch,
      ...(recurrence_ts ? { recurrence_ts } : {}),
    })
    setIsFetching(false)
  }

  const t_view_votes = useLocaleText('t_view_votes')
  const reset_answer = useLocaleText('reset_answer')

  const pollAllResults = useMemo(
    () =>
      allResults?.[pollSubtask.tid]?.filter((result) => {
        if (!result || !result.updated_at_ts) return false
        const recurrenceUnix = recurrence_ts ? parseRecurrenceTimestamp(recurrence_ts) : null
        const createAtCheck =
          result?.updated_at_ts && recurrenceUnix !== null ? Number(result.updated_at_ts) >= recurrenceUnix : true

        return result.st_id === pollSubtask.st_id && createAtCheck
      }) ?? [],
    [allResults, pollSubtask, recurrence_ts]
  )

  const isPollSubtaskDone = !!pollAllResults.filter(
    (result) =>
      (result.status === keys.TASK_STATUSES.DONE.value || result.status === keys.TASK_STATUSES.SKIPPED.value) &&
      result.context_id === resultContextId
  ).length

  const handleViewVotesClick = () => {
    if (allResultsLoading) return
    openContent(TaskOverviewContentType.VOTES_OVERVIEW, { title: pollSubtask.title, selectedSubtask: pollSubtask })
  }

  const statusCurcleColor = useMemo(() => {
    if (filteredResultBySubtasksIds?.status === keys.TASK_STATUSES.SKIPPED.value) {
      return '#EA9999'
    }
    return colors.greenPoll
  }, [filteredResultBySubtasksIds])

  if (!pollSubtask || !pollSubtask.options) {
    return null
  }
  const hasPollSelections =
    filteredResultBySubtasksIds && filteredResultBySubtasksIds?.poll_selections
      ? filteredResultBySubtasksIds?.poll_selections?.length > 0
      : false

  return (
    <WidgetWrapper p="15px" pos="relative" pointerEvents={isFetching ? 'none' : 'all'} opacity={isFetching ? 0.5 : 1}>
      <Box pos="absolute" top="10px" left={isRtl ? 'unset' : '15px'} right={isRtl ? '15px' : 'unset'}>
        <SkipSubtaskButton
          isRequired={pollSubtask?.is_required}
          isSkipped={filteredResultBySubtasksIds?.status === keys.TASK_STATUSES.SKIPPED.value}
          isResult={hasPollSelections}
          subtaskType={pollSubtask.type}
          tid={tid}
          st_id={pollSubtask.st_id}
          resultContextId={resultContextId}
        />
      </Box>
      {isPollSubtaskDone && <StatusCircle color={statusCurcleColor} />}
      {isShowResetMenu && (
        <Box
          pos="absolute"
          top="3px"
          right={isRtl ? 'unset' : '25px'}
          left={isRtl ? '25px' : 'unset'}
          onClick={(e) => e.stopPropagation()}
        >
          <ResetMenu menuItemText={reset_answer} handleReset={handleResetResults} isLoading={isFetching} />
        </Box>
      )}

      <WidgetIcon subtaskType={pollSubtask.type} />

      <SubtaskTitile mt="30px" title={pollSubtask.title} />

      <Box my="10px">
        <SelectionPrompt isMuptiple={pollSubtask.is_multi_choice ?? false} />
      </Box>

      <Votes
        options={pollSubtask.options}
        isMultiple={pollSubtask.is_multi_choice ?? false}
        pollResults={pollAllResults}
        st_id={pollSubtask.st_id}
        tid={pollSubtask.tid}
      />

      <Box
        mt="30px"
        mx="-25px"
        mb="-25px"
        px="25px"
        pb="25px"
        cursor={allResultsLoading ? 'not-allowed' : 'pointer'}
        color={colors.analyticGrey}
        onClick={handleViewVotesClick}
        borderBottomLeftRadius="15px"
        borderBottomRightRadius="15px"
      >
        <Flex flexDir="column" alignItems="center" w="100%">
          <Divider w="calc(100% + 30px)" mx="-25px" my={0} borderBottom="3px solid" />
          <Text fontSize="16px" mt="15px">
            {t_view_votes}
          </Text>
        </Flex>
      </Box>
    </WidgetWrapper>
  )
}

export default Poll
