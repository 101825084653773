const colors = {
  greyLight: '#E5E8EB',
  greyLight2: '#aab0bc',
  greyLight3: '#e5e5e9',
  greyLight4: '#e2e8f0',
  greyMedium: '#f2f3f5',
  greyModal: '#F4F4F4',
  greyMedium2: '#DADDE1',
  greyDark: '#90949C',
  greyDark2: '#979797',
  greyBullet: '#d8dae2d9',
  greyMain: '#616770',
  mikelKors: '#e1c79c',
  greyExtraDark: '#5c626d',
  blackLight: '#1D2129',
  greenExtraLight: '#8de7c1',
  greenGrey: '#b6e2ce',
  greenLight: '#B7E9D2',
  greenLight2: '#1CCF83',
  greenLight3: '#2DCE85',
  greenMedium: '#009053',
  greenPoll: '#20CC92',
  greenMedium2: '#009151',
  greenBar: '#a9f5d2',
  greenDark: '#00d081',
  greenDark2: '#b2cbab',
  tealLight: '#FFFFFF',
  tealMedium: '#C6E1EB',
  yellowMedium: '#FFF6D8',
  yellowDark: '#FFC400',
  yellow: '#f0c22c',
  blueLight: '#8ca0f3',
  blueMedium: '#7683E3',
  blueMedium2: '#667de3',

  blueDark: '#1155cc',
  blueBullet: '#a8b0e8',
  blueDark2: '#3a4c9a',
  bluePurple: '#8698F5',
  pinkLight: '#FFEEF8',
  pinkMedium: '#fddff5',
  pinkMedium2: '#D3208B',
  pinkDark: '#D5158C',
  tomato: '#E9586A',
  redBar: '#ffc3c3',
  cyanMedium: '#5CB8D6',
  red: '#eb5668',
  red2: '#FF0800',
  violetLight: '#667bd5',
  violetLight2: '#e6e6fa',
  violetDark: '#d780d1',
  analyticGreen: '#D9EAD3',
  analyticYellow: '#FFF2CC',
  analyticGrey: '#aab0bc',
  analyticsGreyLight: '#EFEFEF',
  purpleLight: '#d0d7f3',
  peachLight: '#ffe7dd',
  peachMedium: '#ffccb8',
}

export default colors
