import React, { memo, useMemo } from 'react'
import BaseSubtask from './BaseSubtask'
import { Image } from '@chakra-ui/core'
import { WidgetIcon } from 'components/TasksV2/UI/WidgetIcon'
import { Subtask } from 'constants/interfaces'
import keys from 'constants/keys'
import { useResults } from 'components/TasksV2/hooks/useResults'
import { useTaskOverviewContext } from 'components/TasksV2/TasksOverview/TaskOverviewStackProvider'

interface IProps {
  subtask: Subtask
}
export const ImageSubtask: React.FC<IProps> = memo(({ subtask }) => {
  const { resultContextId } = useTaskOverviewContext()
  const subTaskResult = useResults({ subtask, resultContextId })

  const firstImageResult = subTaskResult?.images?.[0] ?? ''
  const isSubtaskDone =
    subTaskResult?.status === keys.TASK_STATUSES.DONE.value ||
    subTaskResult?.status === keys.TASK_STATUSES.SKIPPED.value

  const renderContent = useMemo(
    () =>
      firstImageResult ? (
        <Image
          borderRadius="full"
          src={firstImageResult}
          alt="subtask_image"
          objectFit="cover"
          pos="absolute"
          left="50%"
          w="70px"
          h="70px"
          transform="translateX(-50%)"
          top="-30px"
          boxShadow="0px 1px 3px 1px #949191"
        />
      ) : (
        <WidgetIcon subtaskType={subtask.type} />
      ),
    [firstImageResult, subtask.type]
  )

  return (
    <BaseSubtask
      subtask={subtask}
      subTaskResult={subTaskResult}
      renderContent={renderContent}
      isSubtaskDone={isSubtaskDone}
    />
  )
})
