import React from 'react'
import { Menu, MenuButton, MenuList, MenuItem, Button, Text } from '@chakra-ui/core'
import { CustomMenuProps } from './customMenu.types'
import { SkeletonText } from 'components/CommonComponents/SkeletonText'
import LocaleText from 'components/LocaleText'
import { RoundedCheckbox } from 'components/helperStyles'
import { useSelector } from 'react-redux'
import { RootState } from 'constants/interfaces'
import { ChevronDown } from 'lucide-react'

export const CustomMenu: React.FC<CustomMenuProps> = ({
  buttonLabel,
  menuItems,
  onMenuItemClick,
  isReportLoading,
  menuListProps,
  isRtl = false,
  isShowClearButton = false,
}) => {
  const theme = useSelector((state: RootState) => state.general.theme)
  return (
    <Menu>
      <MenuButton
        as={Button}
        fontSize="14px"
        bg="white"
        fontFamily='"Asap Condensed", sans-serif'
        h="inherit"
        borderRadius="10px"
        p="4px"
        minWidth="7rem"
        boxShadow="0 2px 4px 0 rgba(0,0,0,0.05)"
        pointerEvents={isReportLoading ? 'none' : 'auto'}
        opacity={isReportLoading ? 0.5 : 1}
        style={{ columnGap: '8px' }}
      >
        <SkeletonText isLoaded={!isReportLoading}>
          <Text as={'span'} fontFamily='"Asap Condensed", sans-serif !important'>
            {buttonLabel}
          </Text>
        </SkeletonText>
        <ChevronDown />
      </MenuButton>
      <MenuList {...menuListProps} style={{ zIndex: 5, direction: isRtl ? 'rtl' : 'ltr' }}>
        {isShowClearButton && menuItems.some(({ isChecked }) => isChecked) && (
          <MenuItem onClick={() => onMenuItemClick(null)}>
            <Button as={'p'} variant="unstyled" _focus={{ outline: 'none' }}>
              <LocaleText text="clear_filter" />
            </Button>
          </MenuItem>
        )}

        {menuItems.map(({ key, label, value, isChecked }) => (
          <MenuItem
            onClick={() => onMenuItemClick(value)}
            key={key}
            style={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}
          >
            <RoundedCheckbox
              onChange={() => onMenuItemClick(value)}
              isChecked={isChecked}
              style={{ marginBottom: 0 }}
              color={theme?.elementsColor}
            />
            <Text>{label}</Text>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}
