import { Box, Skeleton } from '@chakra-ui/core'
import colors from 'constants/colors'
import React from 'react'
import { useSelector } from 'react-redux'
import { isRtlSelector } from 'redux/selectors/general'

interface Props {
  width: string
  isLoading: boolean
}
export const PollBar: React.FC<Props> = ({ width, isLoading }) => {
  const isRtl = useSelector(isRtlSelector)

  return (
    <>
      {isLoading ? (
        <Skeleton
          h="14px"
          ml={isRtl ? 'unset' : '35px'}
          mr={isRtl ? '35px' : 'unset'}
          mx="25px"
          mt="8px"
          borderRadius="5px"
        />
      ) : (
        <Box
          bg="gray.200"
          h="14px"
          ml={isRtl ? 'unset' : '35px'}
          mr={isRtl ? '35px' : 'unset'}
          mt="8px"
          borderRadius="5px"
        >
          <Box bg={colors.greenPoll} h="14px" borderRadius="5px" width={width} transition="width 0.5s ease" />
        </Box>
      )}
    </>
  )
}
