import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Box,
  Divider,
  Text,
  Link,
} from '@chakra-ui/core'

import Avatar from 'components/CommonComponents/Avatar'
import useAvatar from 'hooks/useAvatar'
import { RootState } from 'constants/interfaces'
import { setLocale } from 'redux/actions/general'
import LocaleText from 'components/LocaleText'
import { CustomModalClose } from 'components/Elements'

import { MiniDrawer } from 'components/helperStyles'
import colors from 'constants/colors'
import { ReactComponent as IconMenuBack } from 'assets/icon-usermenu-back.svg'
import { ReactComponent as IconLocale } from 'assets/icon-usermenu-language.svg'
import { ReactComponent as IconNotification } from 'assets/icon-usermenu-notifications.svg'
import { ReactComponent as IconSettings } from 'assets/icon-usermenu-settings.svg'
import { ReactComponent as IconLogout } from 'assets/icon-usermenu-logout.svg'
import { ReactComponent as IconMore } from 'assets/icon-usermenu-more.svg'
import { ReactComponent as IconReports } from 'assets/icon-usermenu-repots.svg'
import { logoutHandler } from 'redux/actions/auth'
import keys from 'constants/keys'
import { privacyPolicy, termsOfUse } from 'constants/endpoints'
import { WhatsAppButton } from './WhatsAppButton'
import { userIdSelector, whatsAppUrlSelector } from 'redux/selectors/config'
import { UserMenuItem } from 'components/CommonComponents/UserMenu/UserMenuItem'
import { BsPuzzle } from 'react-icons/bs'
import { UserDetails } from './UserDetails'

const IconWrapper = ({ children }: any) => (
  <Flex
    bg={colors.greyMedium}
    width="36px"
    height="36px"
    borderRadius="50%"
    justifyContent="center"
    alignItems="center"
  >
    {children}
  </Flex>
)

const MobileUserMenu = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [showLocaleDrawer, setLocaleDrawer] = useState(false)
  const [showSettingsDrawer, setSettingsDrawer] = useState(false)

  const { locale } = useSelector((state: RootState) => state.general)
  const userId = useSelector(userIdSelector)
  const whatsAppUrl = useSelector(whatsAppUrlSelector)
  const { permission } = useSelector((state: RootState) => state.config.config)
  const { translationsKeys, retailConfig } = useSelector((state: RootState) => state.config)
  const avatar = useAvatar(userId)

  const { isOpen, onOpen, onClose } = useDisclosure()

  const handlerLocale = (item: string) => {
    dispatch(setLocale(item))
  }

  const handlerLogout = () => {
    dispatch(logoutHandler())
  }

  return (
    <>
      <Button onClick={onOpen} p="0" borderRadius="100%" variant="ghost">
        <Avatar src={avatar} width="40px" height="40px" />
      </Button>

      <Drawer isOpen={isOpen} size="xs" placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent overflow="hidden">
          <CustomModalClose />
          <DrawerHeader p="3">
            <UserDetails />
            <Divider borderColor="#e2e8f0" mx="12px" />
          </DrawerHeader>

          <DrawerBody py="0" px="15px">
            <MiniDrawer className="locale-drawer" isShow={showLocaleDrawer}>
              <Box px="15px">
                <Flex alignItems="center" cursor="pointer" pb="20px" onClick={() => setLocaleDrawer(false)}>
                  <IconMenuBack />
                  <Box fontSize="20px" fontWeight="900" mx="20px">
                    <LocaleText text="language" />
                  </Box>
                </Flex>
                {translationsKeys.map((item: string, index: number) => (
                  <Box
                    key={index}
                    onClick={() => handlerLocale(item)}
                    textTransform="capitalize"
                    cursor="pointer"
                    style={{
                      fontWeight: locale === item ? 'bold' : 'inherit',
                    }}
                  >
                    <LocaleText text={item} />
                  </Box>
                ))}
              </Box>
            </MiniDrawer>
            <MiniDrawer className="locale-drawer" isShow={showSettingsDrawer}>
              <Box px="15px">
                <Flex alignItems="center" cursor="pointer" pb="20px" onClick={() => setSettingsDrawer(false)}>
                  <IconMenuBack />
                  <Box fontSize="20px" fontWeight="900" mx="20px">
                    Admin Settings
                  </Box>
                </Flex>
                <Flex
                  alignItems="center"
                  onClick={() => navigate('/custom-reports')}
                  textTransform="capitalize"
                  cursor="pointer"
                >
                  <IconWrapper>
                    <IconReports />
                  </IconWrapper>
                  <Text mx="20px"> Custom Reports</Text>
                </Flex>
              </Box>
            </MiniDrawer>
            {whatsAppUrl && <WhatsAppButton px="0" href={whatsAppUrl} />}
            <UserMenuItem literal="language" onClick={() => setLocaleDrawer(true)} icon={<IconLocale />}>
              <IconMore style={{ transform: locale === 'he' ? 'rotate(180deg)' : 'none' }} />
            </UserMenuItem>
            <UserMenuItem icon={<IconNotification />} literal="enable_notification" />
            {permission?.users === 3 && (
              <NavLink to={`${keys.ROUTE_NAMES.ADMIN_SETTINGS}/users`}>
                <UserMenuItem icon={<IconSettings />} literal="admin_console">
                  <IconMore style={{ transform: locale === 'he' ? 'rotate(180deg)' : 'none' }} />
                </UserMenuItem>
              </NavLink>
            )}
            <UserMenuItem icon={<IconLogout />} literal="logout" onClick={handlerLogout} textColor={colors.red} />
            {!!retailConfig?.integrations?.length && (
              <UserMenuItem
                icon={<BsPuzzle />}
                literal="integrations"
                onClick={() => navigate(keys.ROUTE_NAMES.INTEGRATIONS)}
              />
            )}
            <Box px="1rem" pt="20px" pb="10px" fontSize="12px" color={colors.greyDark}>
              <Link href={privacyPolicy}>
                <LocaleText text="legal_privacy" />
              </Link>
              &nbsp;·&nbsp;
              <Link href={termsOfUse}>
                <LocaleText text="legal_terms" />
              </Link>
              &nbsp;·&nbsp;Version: 0.0.05
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default MobileUserMenu
