import endpoints from '../../constants/endpoints'
import { httpAuth } from '../../config/apiClient'
import { setLocale, setThemeConfig } from './general'
import {
  buildStoresByArea,
  encode,
  extractSID,
  getCurrentActiveGroupIdByMaxLevel,
  getGroupsMaxLevel,
  getStoresLength,
  handleError,
} from '../../utils'
import { handleGetPosts, setConsolidationFilter, setIsAllPostsCollapsed } from './feed'
import { logoutHandler } from './auth'
import {
  AppTheme,
  IConfigRetailUser,
  IConfigStateGroups,
  ITag,
  RootState,
  UserWritePermittedContext,
} from 'constants/interfaces'
import { AxiosError } from 'axios'
import { setUsersList } from './admin'
import { setReportsTagsList } from './reports'
import colors from 'constants/colors'
import { isEmpty } from 'lodash'
import keys from 'constants/keys'

export const theme: AppTheme = {
  elementsColor: colors.pinkDark,
  backgroundLight: colors.pinkLight,
  backgroundLight2: colors.pinkMedium,
  backgroundMedium: colors.pinkMedium,
}

export const actionTypes = {
  SET_CONFIG: '[CONFIG] SET_CONFIG',
  SET_RETAIL_CONFIG: '[CONFIG] SET_RETAIL_CONFIG',
  SET_STATUS_MENUS: '[CONFIG] SET_STATUS_MENUS',
  SET_TRANSLATIONS: '[CONFIG] SET_TRANSLATIONS',
  SET_ACTIVE_GROUP_ID: '[CONFIG] SET_ACTIVE_GROUP_ID',
  SET_USER_WRITE_PERMITTED_CONTEXT: '[CONFIG] SET_USER_WRITE_PERMITTED_CONTEXT',
  SET_USER_WRITE_TASK_PERMISSIONS: '[CONFIG] SET_USER_WRITE_TASK_PERMISSIONS',
  SET_IS_CONFIG_LOADING: 'SET_IS_CONFIG_LOADING',
  SET_RETAIL_USERS: 'SET_RETAIL_USERS',
  SET_RETAIL_USERS_OBJECT: '[CONFIG] SET_RETAIL_USERS_OBJECT',
  SET_FULL_USER_NAME: '[CONFIG] SET_FULL_USER_NAME',
  SET_ENCODED_UID: '[CONFIG] SET_ENCODED_UID',
  SET_MAPPED_RETAIL_USERS_BY_GROUPS: '[CONFIG] SET_MAPPED_RETAIL_USERS_BY_GROUPS',
  SET_MAPPED_GROUPS_BY_USERS: '[CONFIG] SET_MAPPED_GROUPS_BY_USERS',
  SET_CONFIG_ERROR_STATUS: '[CONFIG] SET_CONFIG_ERROR_STATUS',
}
export const setConfigErrorStatus = (status: number | null) => ({
  type: actionTypes.SET_CONFIG_ERROR_STATUS,
  payload: status,
})

export const setEncodedUID = (encodedUID: string) => ({ type: actionTypes.SET_ENCODED_UID, payload: encodedUID })

export const setMappedRetailUsersByGroups = (mappedRetailUsersByGroups: object) => ({
  type: actionTypes.SET_MAPPED_RETAIL_USERS_BY_GROUPS,
  payload: mappedRetailUsersByGroups,
})
export const setMappedGroupsByUsers = (mappedGroupsByUsers: object) => ({
  type: actionTypes.SET_MAPPED_GROUPS_BY_USERS,
  payload: mappedGroupsByUsers,
})
export const setRetailUsers = (users: IConfigRetailUser[]) => ({
  type: actionTypes.SET_RETAIL_USERS,
  payload: users,
})
type FullUserName = {
  firstName: string
  lastName?: string
}
export const setFullUserName = ({ firstName, lastName }: FullUserName) => ({
  type: actionTypes.SET_FULL_USER_NAME,
  payload: { firstName, lastName },
})
export const setRetailUsersObject = (users: object) => ({
  type: actionTypes.SET_RETAIL_USERS_OBJECT,
  payload: users,
})
const setIsConfigLoading = (isLoading: boolean) => ({
  type: actionTypes.SET_IS_CONFIG_LOADING,
  payload: isLoading,
})

const setTranslations = (translations: any) => ({
  type: actionTypes.SET_TRANSLATIONS,
  payload: translations,
})

const setRetailConfig = (config: any) => ({
  type: actionTypes.SET_RETAIL_CONFIG,
  payload: config,
})

export const setConfigActiveGroupId = (groupId: string | null) => ({
  type: actionTypes.SET_ACTIVE_GROUP_ID,
  payload: groupId,
})

export const setConfig = (config: any) => ({
  type: actionTypes.SET_CONFIG,
  config,
})

export const setStatusMenus = (menus: any) => ({
  type: actionTypes.SET_STATUS_MENUS,
  menus,
})

export const getConfig = async (activeGroupID: string | null) => {
  const {
    data: {
      user_config,
      status_menus,
      app_translations_file_url,
      retail_config,
      cdn,
      avatars_path,
      force_update,
      appstore_url,
      play_url,
      media_compression,
      profile_img_url,
      tags,
    },
  } = await httpAuth.get(endpoints.config, { params: { ...(activeGroupID ? { group: activeGroupID } : {}) } })

  const storesByArea = buildStoresByArea(user_config.stores_areas_details)
  user_config.storesSIDToStoreName = user_config.stores_details
  user_config.stores_details = extractSID(user_config)
  user_config.storesByArea = storesByArea
  user_config.storesByAreaToFetch = JSON.parse(JSON.stringify(storesByArea))
  user_config.storesByAreaLength = getStoresLength(storesByArea)
  user_config.cdn = cdn
  user_config.avatars_path = avatars_path
  user_config.force_update = force_update
  user_config.appstore_url = appstore_url
  user_config.play_url = play_url

  user_config.maxGroupLevel = getGroupsMaxLevel(user_config.groups)

  if (user_config.retail_users) {
    const retailUsersObject = {}
    user_config.retail_users.forEach((user: any) => {
      retailUsersObject[user.uid] = user
    })
    user_config.retailUsersObject = retailUsersObject
  }
  if (retail_config) {
    user_config.is_cover_image_contain = retail_config.is_cover_image_contain
  }
  if (media_compression) {
    user_config.media_compression = media_compression
  }
  if (profile_img_url) {
    user_config.profile_img_url = profile_img_url
  }
  if (user_config.tags) {
    user_config.user_tags = user_config.tags
  }
  if (tags) {
    user_config.tags = tags
  }
  const groups = user_config.groups
  const groupsKeys = Object.keys(user_config.groups)
  let currentActiveGroupID = user_config.default_group || activeGroupID

  if (!currentActiveGroupID || !groupsKeys.includes(currentActiveGroupID)) {
    currentActiveGroupID = getCurrentActiveGroupIdByMaxLevel(groups)
  }

  return { user_config, status_menus, app_translations_file_url, retail_config, activeGroupId: currentActiveGroupID }
}

const getTranslationTimestamp = (translationsURL: string) => {
  return translationsURL.slice(translationsURL.lastIndexOf('_') + 1, translationsURL.lastIndexOf('.'))
}

const checkUpdateTranslations = (translationsURL: string) => {
  const newTimestamp = getTranslationTimestamp(translationsURL)
  const currentTimestamp = localStorage.getItem('translationsTimestamp')

  if (currentTimestamp && newTimestamp) {
    if (+currentTimestamp < +newTimestamp) {
      localStorage.setItem('translationsTimestamp', newTimestamp)
      return true
    } else {
      return false
    }
  }
  return true
}

export const handleInitialTranslations = () => async (dispatch: any) => {
  try {
    const translations = await fetchTranslations(endpoints.initialTranslations)
    dispatch(setTranslations(translations))
  } catch (error) {
    console.warn('[handleInitialTranslations][ERROR]', error)
  }
}

const fetchTranslations = async (translationsURL: string) => {
  const translationsUrlResponse = await fetch(translationsURL, {
    mode: 'cors',
    cache: 'no-store',
  })
  const translationsJSON = await translationsUrlResponse.json()

  return translationsJSON
}

type MapUsersByGroupsArgs = {
  groups: IConfigStateGroups
  retailUsersObject: { [key: string]: IConfigRetailUser }
}
export const mapUsersByGroups = ({ groups, retailUsersObject }: MapUsersByGroupsArgs) => {
  try {
    const result: { [groupId: string]: string[] } = {}

    for (const groupId in groups) {
      const group = groups[groupId]
      const userIds = group?.uids ?? []

      if (userIds.length === 0) {
        result[groupId] = []
        continue
      }

      if (userIds.length === 1) {
        result[groupId] = [userIds[0]]
        continue
      }
      const avatarManagers: string[] = []
      const managers: string[] = []
      const others: string[] = []

      userIds.forEach((uid) => {
        const user = retailUsersObject[uid]
        if (!user) return
        const role = user?.user_group_role ?? keys.USER_GROUP_ROLES.WORKER
        if (role === keys.USER_GROUP_ROLES.AVATAR_MANAGER) {
          avatarManagers.push(uid)
        } else if (role === keys.USER_GROUP_ROLES.MANAGER) {
          managers.push(uid)
        } else {
          others.push(uid)
        }
      })

      avatarManagers.sort((a, b) => {
        const userA = retailUsersObject[a]
        const userB = retailUsersObject[b]

        const nameA = `${userA.first_name}  ${userA?.last_name || ''}`.toLowerCase()
        const nameB = `${userB.first_name} ${userB?.last_name || ''}`.toLowerCase()

        return nameA.localeCompare(nameB)
      })

      managers.sort((a, b) => {
        const userA = retailUsersObject[a]
        const userB = retailUsersObject[b]

        const nameA = `${userA.first_name}  ${userA?.last_name || ''}`.toLowerCase()
        const nameB = `${userB.first_name} ${userB?.last_name || ''}`.toLowerCase()

        return nameA.localeCompare(nameB)
      })

      others.sort((a, b) => {
        const userA = retailUsersObject[a]
        const userB = retailUsersObject[b]

        const nameA = `${userA.first_name}  ${userA?.last_name || ''}`.toLowerCase()
        const nameB = `${userB.first_name} ${userB?.last_name || ''}`.toLowerCase()

        return nameA.localeCompare(nameB)
      })

      result[groupId] = [...avatarManagers, ...managers, ...others]
    }

    return result
  } catch (error) {
    console.log('[mapUsersByGroups][ERROR]', error)
    return {}
  }
}

export const mapGroupsByUsers = (groups: IConfigStateGroups) => {
  const mappedGroupsByUsers = {}

  for (const groupId in groups) {
    const group = groups[groupId]
    const { name, uids } = group

    for (const uid of uids) {
      if (!mappedGroupsByUsers[uid]) {
        mappedGroupsByUsers[uid] = []
      }
      mappedGroupsByUsers[uid].push(name)
    }
  }

  return mappedGroupsByUsers
}

export const handleConfig = () => async (dispatch: any, getState: () => RootState) => {
  let translationsURL = null
  const { config, auth, feed } = getState()
  try {
    dispatch(setIsConfigLoading(true))
    const { user_config, status_menus, app_translations_file_url, retail_config, activeGroupId } = await getConfig(
      config.activeGroupID
    )
    translationsURL = app_translations_file_url
    dispatch(setLocale(user_config.locale))
    user_config.myUID = auth.uid

    if (!config.activeGroupID) {
      dispatch(setConfigActiveGroupId(activeGroupId))
    }
    if (feed.isAllPostCollapsed == null) {
      dispatch(setIsAllPostsCollapsed(retail_config.is_collapse_posts))
    }
    if (user_config.tags && retail_config.reports_tags) {
      const reportTagsKList = user_config.tags.filter((tag: ITag) => retail_config.reports_tags.includes(tag.sk))
      dispatch(setReportsTagsList(reportTagsKList))
    }

    dispatch(setRetailConfig(retail_config))
    dispatch(setConfig(user_config))
    dispatch(setStatusMenus(status_menus))
    dispatch(setConsolidationFilter(['1', ...user_config.user_groups]))
    dispatch(setUsersList(user_config.retail_users))

    if (!isEmpty(user_config.retailUsersObject) && !isEmpty(user_config.groups)) {
      const usersByGroups = mapUsersByGroups({
        groups: user_config.groups,
        retailUsersObject: user_config.retailUsersObject,
      })

      const mappedGroupsByUsers = mapGroupsByUsers(user_config.groups)
      dispatch(setMappedRetailUsersByGroups(usersByGroups))
      dispatch(setMappedGroupsByUsers(mappedGroupsByUsers))
    }

    if (!isEmpty(retail_config.theme_config)) {
      const newObject = {
        backgroundLight: retail_config.theme_config?.color_light,
        backgroundLight2: retail_config.theme_config?.color_light_2,
        backgroundMedium: retail_config.theme_config?.color_medium,
        elementsColor: retail_config.theme_config?.elements_color,
        logoUrl: retail_config.theme_config?.logo_image_url,
        avatarImgUrl: retail_config.theme_config?.avatar_image_url,
      }

      dispatch(setThemeConfig(newObject))
    } else {
      dispatch(setThemeConfig(theme))
    }
    if (auth.uid) {
      const encodedUID = encode(auth.uid)
      dispatch(setEncodedUID(encodedUID))
    }
    dispatch(setConfigErrorStatus(null))
  } catch (err) {
    const error: AxiosError | any = err
    if (error?.response?.status) {
      if (error?.response?.status === 401) {
        handleError(error?.response?.status)
      } else dispatch(setConfigErrorStatus(error?.response?.status))
    } else {
      return new Error(error)
    }
  } finally {
    dispatch(setIsConfigLoading(false))
  }

  try {
    if (translationsURL && checkUpdateTranslations(translationsURL)) {
      const translationsJSON = await fetchTranslations(translationsURL)
      localStorage.setItem('translations', JSON.stringify(translationsJSON))
      dispatch(setTranslations(translationsJSON))
      localStorage.setItem('translationsTimestamp', getTranslationTimestamp(translationsURL))
    } else if (localStorage.getItem('translations')) {
      const currentTranslations = JSON.parse(localStorage.getItem('translations')!)
      dispatch(setTranslations(currentTranslations))
    }
  } catch (error) {
    console.log(error)
  }
}

export const handleUserWritePermitted = () => async (dispatch: any) => {
  try {
    const url = endpoints.getUserWritePermittedContext
    const { data } = await httpAuth.get(url)

    dispatch({
      type: actionTypes.SET_USER_WRITE_PERMITTED_CONTEXT,
      payload: data,
    })
  } catch (err) {
    const error: AxiosError | any = err
    if (error?.response?.status) {
      handleError(error.response.status, () => dispatch(logoutHandler()))
    }
    return Promise.reject(error)
  }
}

export const handleGroupChange = (groupId: string, isRunGetPosts?: boolean) => async (dispatch: any) => {
  dispatch(setConfigActiveGroupId(groupId))
  dispatch(handleConfig())
  if (isRunGetPosts) {
    dispatch(handleGetPosts({ isRefresh: true }))
  }
}

export const setTaskPermissions = (data: UserWritePermittedContext) => ({
  type: actionTypes.SET_USER_WRITE_TASK_PERMISSIONS,
  payload: data,
})

export const handleSetTaskPermittedGroup = () => async (dispatch: any) => {
  try {
    const { data } = await httpAuth.get(endpoints.getUserTaskPermissions)
    dispatch(setTaskPermissions(data))
  } catch (err) {
    const error: AxiosError | any = err
    if (error?.response?.status) {
      handleError(error.response.status, () => dispatch(logoutHandler()))
    }
  }
}
