/* eslint-disable @typescript-eslint/no-unused-expressions */
// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Flex,
  Divider,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Text,
  FormControl,
  Input,
  useDisclosure,
} from '@chakra-ui/core'
import styled from '@emotion/styled'
import colors from '../../constants/colors'
import { useSelector, useDispatch } from 'react-redux'
import produce from 'immer'

import {
  handleRemoveCategory,
  setAutoRemovalTask,
  setDeadLineDate,
  setEndDate,
  setSelectedCategory,
  setStartDate,
  setTaskName,
  setTaskOwners,
} from 'redux/actions/createTask'
import 'react-datepicker/dist/react-datepicker.css'

import { ITaskName, RootState } from 'constants/interfaces'
import { ReactComponent as ArrowRightIcon } from 'assets/shevron-right.svg'
import LocaleText from 'components/LocaleText'
import useLocaleText from 'components/useLocaleText'
import keys from 'constants/keys'
import { NavItemBtn } from './SidebarStyles'
import CustomDatePicker from 'components/CommonComponents/CustomDatePicker'
import { DeleteButton } from 'components/CreateTask/DeleteButton'
import strings from 'constants/strings'
import { resetSecondsAndMS, spreadOutOfDate } from 'utils'
import { useTask } from 'hooks/useTasks'
import CustomSwitcher from 'components/CommonComponents/CustomSwitcher'
import dayjs from 'dayjs'
import { useValidateData } from 'hooks/useValidateDate'
import { setHours, setMinutes } from 'date-fns'
import { isEmpty } from 'lodash'
import GroupsTree from 'components/CommonComponents/GroupsTree'
import { UsersList } from 'components/CommonComponents/UsersListModal'
import AudiencePublicationsModal from 'components/CommonComponents/AudiencePublicationModal'

interface ICategoryName {
  value: string
  length: number
  isError: boolean
}

interface IBlockTitleProps {
  text: string
}

const BoxWrapper = styled(Box)`
  transition: 0.2s ease-in-out;
  border-radius: 12px;

  &:hover,
  &:focus {
    background-color: #e5e8eb;
    text-decoration: none;
  }
`

const StyledSidebar = styled(Box)`
  width: 25%;
  position: sticky;
  top: 0;
  z-index: 3;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100vh;
`

const SidebarContent = styled(Flex)`
  top: 4rem;
  position: relative;
`

const NavItem = styled(Flex)`
  align-items: center;
  cursor: pointer;
`

const BlockTitle: React.FC<IBlockTitleProps> = ({ text }) => {
  return (
    <Text fontSize="14px" fontWeight="500" color={colors.greyDark}>
      <LocaleText text={text} />
    </Text>
  )
}

const CreateTaskSidebar: React.FC = () => {
  const MAX_OWNERS_SHOW = 3
  const { createTaskHandler } = useTask()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { uid } = useSelector((state: RootState) => state.auth)
  const { locale, theme } = useSelector((state: RootState) => state?.general)
  const createTask = useSelector((state: RootState) => state.createTask)
  const {
    selectedCategory,
    title,
    taskOwners,
    from_ts,
    to_ts,
    categories,
    publishTaskIsLoading,
    deadlineDate,
    isAutoRemovalTask,
    isNewTask,
  } = createTask

  const {
    userWriteTaskPermissions: { groups: treeGroups, users: treeUsers },
    config: { retailUsersObject, retail_users },
  } = useSelector((state: RootState) => state.config)

  const category_default_name = useLocaleText('category_default_name')
  const create_task_add_cat_placeholder = useLocaleText('create_task_add_cat_placeholder')
  const create_task_st_title_placeholder = useLocaleText('create_task_name_placeholder')
  const create_task_start_date_placeholder = useLocaleText('create_task_start_date_placeholder')
  const create_task_end_date_placeholder = useLocaleText('create_task_end_date_placeholder')
  const create_task_removal_date_placeholder = useLocaleText('create_task_removal_date_placeholder')
  const create_task_removal_after_default_days = useLocaleText('create_task_removal_after_default_days')
  const create_task_tooltip_removal_date = useLocaleText('create_task_tooltip_removal_date')
  const create_task_end_date_error_placeholder = useLocaleText('to_ts_less_than_from_ts_warning_msg')

  const { validateEndDate, validateStartDate, validateDueDate } = useValidateData()
  const [isEndDateOpen, setIsEndDateOpen] = useState<boolean>(false)
  const [isStartDateOpen, setIsStartDateOpen] = useState<boolean>(false)
  const [isDeadLineOpen, setIsDeadLineOpen] = useState<boolean>(false)
  const [selectedGroupIDs, setSelectedGroupIDs] = useState<string[]>(taskOwners?.groups?.map((el) => el.value) ?? [])
  const [selectedUsersUids, setSelectedUsersUids] = useState<string[]>(taskOwners?.users?.map((el) => el.value) ?? [])

  const handleCloseDedLineDatePicker = () => {
    setIsDeadLineOpen(false)
    const isValid = validateDueDate(deadlineDate, from_ts, to_ts, create_task_end_date_error_placeholder)
    if (!isValid) {
      dispatch(setDeadLineDate(null))
    }
  }
  const handleOpenDeadLineDatePicker = () => {
    if (from_ts) {
      setIsDeadLineOpen(true)
      if (!deadlineDate) {
        const currentDate = new Date()
        handleSetDeadlineDate(setMinutes(setHours(from_ts, currentDate.getHours()), currentDate.getMinutes()))
      }
    }
  }

  const handleOpenEndDatePicker = () => {
    setIsEndDateOpen(true)
  }
  const handleEndDate = (date: Date | null) =>
    validateEndDate(date, deadlineDate, create_task_end_date_error_placeholder, (date) => dispatch(setEndDate(date)))

  const closeEndDatePicker = () => {
    const isValid = handleEndDate(to_ts)
    if (!isValid) {
      dispatch(setEndDate(null))
    }
    setIsEndDateOpen(false)
  }

  const handleOpenStartDatePicker = () => {
    setIsStartDateOpen(true)
    if (!from_ts) {
      dispatch(setStartDate(resetSecondsAndMS(new Date())))
    }
  }
  const handleStartDate = (date: Date | null) =>
    validateStartDate(date, deadlineDate, create_task_end_date_error_placeholder, (date) =>
      dispatch(setStartDate(date))
    )

  const closeStartDatePicker = () => {
    const isValid = handleStartDate(from_ts)
    if (!isValid) {
      dispatch(setStartDate(null))
    }
    if (deadlineDate && new Date(deadlineDate) < new Date(from_ts)) {
      dispatch(setDeadLineDate(null))
    }
    setIsStartDateOpen(false)
  }
  const handleSetStartDate = (date: Date) => {
    const { date: currentDay, hours, minutes } = spreadOutOfDate() // today
    const { date: selectedDay, fullYear, month } = spreadOutOfDate(date) // selected date
    if (selectedDay === currentDay && !from_ts) {
      const dateWithCurrentTime = new Date(fullYear, month, selectedDay, hours, minutes)
      dispatch(setStartDate(dateWithCurrentTime))
    } else {
      dispatch(setStartDate(resetSecondsAndMS(date)))
    }
  }

  const handleSetEndDate = (date: Date) => {
    dispatch(setEndDate(resetSecondsAndMS(date)))
  }

  const handleSetDeadlineDate = (date: Date) => {
    const nextWeekDade = new Date(date.getTime() + 7 * 24 * 60 * 60 * 1000)
    const diffInHours = dayjs(to_ts).diff(date, 'hours')
    dispatch(setDeadLineDate(resetSecondsAndMS(date)))
    if (to_ts === null || diffInHours < 168) dispatch(setEndDate(resetSecondsAndMS(nextWeekDade)))
  }

  const autoRemovalTaskHandler = () => {
    if (deadlineDate) {
      const nextWeekDade = new Date(deadlineDate.getTime() + 7 * 24 * 60 * 60 * 1000)
      dispatch(setEndDate(resetSecondsAndMS(nextWeekDade)))
      dispatch(setAutoRemovalTask(!isAutoRemovalTask))
    }
  }

  const DEFAULT_CATEGORY_NAME = category_default_name

  const { isOpen, onOpen, onClose } = useDisclosure()

  const popoverRef = useRef<HTMLInputElement>(null)
  const [tempCategory, setTempCategoryName] = useState<ICategoryName>({
    value: '',
    length: 0,
    isError: false,
  })
  const [taskNameData, setTaskNameData] = useState<ITaskName>({
    value: '',
    length: 0,
    isError: false,
  })
  const [isReadyToPublish, setIsReadyToPublish] = useState<boolean>(false)

  // const namesForTooltip = useMemo(() => {
  //   if (!isEmpty(treeGroups) && retailUsersObject) {
  //     let selectedGroupNames = selectedGroupIDs.map((key) => treeGroups[key].name) ?? []
  //     let selectedUserNames = selectedUsersUids.map(
  //       (uid) =>
  //         retailUsersObject?.[uid]?.first_name +
  //         ' ' +
  //         (retailUsersObject?.[uid]?.last_name ? retailUsersObject?.[uid].last_name : '')
  //     )

  //     return [...selectedGroupNames, ...selectedUserNames]
  //   }
  //   return []
  // }, [treeGroups, retailUsersObject, selectedGroupIDs, selectedUsersUids])

  // const groupNames = useMemo(() => {
  //   if (namesForTooltip && namesForTooltip?.length > 0)
  //     return namesForTooltip.length < 2 ? namesForTooltip[0] : namesForTooltip[0] + ` + ${namesForTooltip.length - 1}`
  // }, [namesForTooltip])

  const filteredRerailUsersByTreeUsers = useMemo(() => {
    const sortedUsers = retail_users
      .filter((user) => treeUsers.includes(user.uid) && user.uid !== uid)
      .sort((a: any, b: any) => {
        const firstNameComparison = a?.first_name?.localeCompare(b?.first_name)
        if (firstNameComparison === 0) {
          return a?.last_name?.localeCompare(b?.last_name)
        }

        return firstNameComparison
      })

    return sortedUsers
  }, [retail_users, treeUsers, uid])
  const checkForm = () => {
    let isValid = true

    // Task name
    isValid = title.length > 0 && isValid

    isValid = isNewTask
      ? !!taskOwners && (taskOwners?.groups?.length > 0 || taskOwners?.users.length > 0) && isValid
      : true

    isValid = !!from_ts && isValid

    isValid = !!to_ts && isValid

    isValid = !!deadlineDate && isValid

    isValid = new Date(from_ts) <= new Date(deadlineDate) && isValid

    isValid = new Date(deadlineDate) <= new Date(to_ts) && isValid

    setIsReadyToPublish(isValid)
  }

  useEffect(() => {
    setTaskNameData((prev) => ({
      ...prev,
      value: title,
      length: title.length,
    }))
  }, [title])

  useEffect(() => {
    checkForm()
    // eslint-disable-next-line
  }, [title, taskOwners, from_ts, to_ts, deadlineDate])

  useEffect(() => {
    if (DEFAULT_CATEGORY_NAME) {
      const defaultValue = isNewTask ? DEFAULT_CATEGORY_NAME + ' 2' : ''
      setTempCategoryName((prev) => ({
        ...prev,
        value: defaultValue,
        length: defaultValue.length,
        isError: defaultValue.length > keys.CREATE_TASK_CONSTANTS.MAX_CATEGORY_NAME_LENGTH,
      }))
    }
  }, [DEFAULT_CATEGORY_NAME, isNewTask])
  useEffect(() => {
    if (treeGroups) {
      const groups = selectedGroupIDs?.map((id: string) => {
        return {
          label: treeGroups?.[id]?.name,
          value: id,
        }
      })
      const users = selectedUsersUids?.map((id: string) => {
        return {
          label: retailUsersObject?.[id]?.first_name + ' ' + retailUsersObject?.[id]?.last_name ?? '',
          value: id,
        }
      })
      const taskOwners = {
        groups: groups,
        users: users,
      }
      if (taskOwners) {
        dispatch(setTaskOwners(taskOwners))
      }
    }
    // eslint-disable-next-line
  }, [selectedGroupIDs, selectedUsersUids])

  const publishTask = async () => {
    await createTaskHandler()
  }

  const handleCategoryAddition = () => {
    const nextState = produce(categories, (draftState: {}) => {
      draftState[tempCategory.value.trim()] = {
        dialog_box: null,
        images: null,
        sub_tasks_order: [],
        done: [],
      }
    })

    const nextDefaultValue = `${DEFAULT_CATEGORY_NAME} ${Object.keys(nextState).length + 1}`

    setTempCategoryName((prev) => ({
      ...prev,
      value: nextDefaultValue,
      length: nextDefaultValue.length,
      isError: nextDefaultValue.length < keys.CREATE_TASK_CONSTANTS.MAX_CATEGORY_NAME_LENGTH,
    }))
    dispatch({ type: 'SET_CATEGORY', payload: nextState })
  }

  const handleTaskName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = e.target.value
    const valueLength: number = value.length
    const errorCondition = valueLength === 0 || valueLength >= keys.CREATE_TASK_CONSTANTS.MAX_TASK_NAME_LENGTH
    setTaskNameData((prev) => ({
      ...prev,
      value: valueLength >= keys.CREATE_TASK_CONSTANTS.MAX_TASK_NAME_LENGTH ? prev.value : value,
      length: valueLength,
      isError: errorCondition,
    }))
  }

  const handleOnBlurTaskName = () => {
    if (!taskNameData.isError) {
      dispatch(setTaskName(taskNameData.value))
    } else {
      setTaskNameData((prev) => ({
        ...prev,
        value: title,
        isError: false,
        length: title.length,
      }))
    }
  }

  const handleCategoryName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = e.target.value

    let isDuplicate = false
    for (let cat of Object.keys(categories)) {
      if (cat === e.target.value) {
        isDuplicate = true
        return
      }
    }

    const valueLength: number = value.length

    const errorCondition =
      valueLength === 0 || valueLength >= keys.CREATE_TASK_CONSTANTS.MAX_CATEGORY_NAME_LENGTH || isDuplicate
    setTempCategoryName((prev) => ({
      ...prev,
      value,
      length: valueLength,
      isError: errorCondition,
    }))
  }

  const handleCategoryFocus = (): void => {
    if (tempCategory.value.indexOf(DEFAULT_CATEGORY_NAME)) {
      return popoverRef?.current?.select()
    }
  }

  const handleDeleteCategory = (e: React.SyntheticEvent<HTMLButtonElement>, categoryId: string) => {
    const isConfirm = window.confirm(strings.CONFIRM_MESSAGE.confirmDeleteSubTask)
    if (isConfirm) {
      dispatch(handleRemoveCategory(categoryId))
    }
  }
  const isUidsOfSelectedGroupsInSelectedUsers = useMemo(() => {
    return selectedGroupIDs?.some((id) => {
      return treeGroups?.[id]?.uids.some((uid) => selectedUsersUids.includes(uid))
    })
  }, [selectedGroupIDs, selectedUsersUids, treeGroups])

  const isSelectedUidsInGroups = useMemo(() => {
    return selectedUsersUids.some((uid) => {
      return selectedGroupIDs.some((id) => {
        return treeGroups[id].uids.includes(uid)
      })
    })
  }, [selectedGroupIDs, selectedUsersUids, treeGroups])

  const renderViewOptionModal = useCallback(
    (viewOption: number, setViewOption: React.Dispatch<React.SetStateAction<number>> | any) => {
      switch (viewOption) {
        case 0:
          return (
            <GroupsTree
              isOpen={isOpen}
              selectedGroupID={selectedGroupIDs}
              mode={strings.PARTIAL_MULTILPE_MODE}
              setViewOption={setViewOption}
              setSelectedGroupID={setSelectedGroupIDs}
              isAllowSubmitWhithoutGroups={true}
              treeGroups={treeGroups}
            />
          )
        case 1:
          return (
            <UsersList
              isOpen={isOpen}
              onClose={onClose}
              setViewOption={setViewOption}
              selectedUsersUids={selectedUsersUids}
              setSelectedUsersUids={setSelectedUsersUids}
              treeUsers={filteredRerailUsersByTreeUsers}
            />
          )

        default:
          break
      }
    },
    [filteredRerailUsersByTreeUsers, isOpen, onClose, selectedGroupIDs, selectedUsersUids, treeGroups]
  )

  return (
    <>
      <StyledSidebar
        as="aside"
        bg={colors.greyMedium}
        m={4}
        width={{
          md: '350px',
        }}
      >
        <SidebarContent
          position="relative"
          justifyContent="space-between"
          flexDirection="column"
          mt="20px"
          pl={locale !== 'he' ? '40px' : 0}
          pr={locale === 'he' ? '40px' : 0}
          maxWidth="300px"
        >
          <Flex
            left={locale === 'he' ? 'auto' : '0'}
            right={locale === 'he' ? '0' : 'auto'}
            alignItems="center"
            position="relative"
            height="60px"
          >
            <NavItem
              onClick={() => (isNewTask ? navigate('/') : navigate(-1))}
              fontWeight="bold"
              position="absolute"
              display="block"
              top="50%"
              transform="translateY(-50%)"
              left="-15%"
            >
              <Box width={36} height={36} p="8px" borderRadius="100%" backgroundColor={colors.greyMedium2}>
                <ArrowRightIcon style={{ marginLeft: '3px', transform: locale === 'he' ? 'rotate(180deg)' : 'none' }} />
              </Box>
            </NavItem>

            <Text ml="1" p="10px">
              Update store entrance
            </Text>
          </Flex>
          <Box>
            {Object.keys(categories).map((item, index) => (
              <Box key={index} position="relative" mb="4px">
                {Object.keys(categories).length > 1 && (
                  <DeleteButton
                    onClick={(e) => handleDeleteCategory(e, item)}
                    left={locale !== 'he' ? '-32px' : null}
                    right={locale === 'he' ? '-32px' : null}
                  />
                )}
                <NavItemBtn
                  onClick={() => dispatch(setSelectedCategory(item))}
                  locale={locale}
                  variant="unstyled"
                  className={selectedCategory === item ? 'active' : ''}
                >
                  {item}
                </NavItemBtn>
              </Box>
            ))}
          </Box>
          <Flex color={colors.greyMain} cursor="pointer">
            <Popover initialFocusRef={popoverRef} closeOnBlur={false}>
              {({ onClose }) => (
                <>
                  <PopoverTrigger>
                    <NavItemBtn
                      py="10px !important"
                      display="flex"
                      justifyContent="flex-start"
                      flexWrap="nowrap"
                      locale={locale}
                      variant="unstyled"
                    >
                      <Text
                        as="span"
                        backgroundColor={theme?.elementsColor}
                        borderRadius="100%"
                        width="16px"
                        height="16px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        color="#fff"
                        fontSize="12px"
                        mr={locale !== 'he' ? 3 : 0}
                        ml={locale === 'he' ? 3 : 0}
                      >
                        +
                      </Text>
                      <Text as="span" fontSize="1rem" fontWeight="500" color={colors.greyMain}>
                        <LocaleText text="create_task_add_cat_btn" />
                      </Text>
                    </NavItemBtn>
                  </PopoverTrigger>
                  <PopoverContent zIndex={4}>
                    <PopoverArrow />
                    <PopoverBody>
                      <Box mb="2rem" position="relative">
                        <FormControl>
                          <Input
                            onChange={handleCategoryName}
                            value={tempCategory.value}
                            isInvalid={tempCategory.isError}
                            placeholder={create_task_add_cat_placeholder}
                            my="1rem"
                            ref={popoverRef}
                            onFocus={handleCategoryFocus}
                            variant="flushed"
                          />
                          <Box
                            position="absolute"
                            top="10px"
                            right={locale === 'he' ? 'auto' : '0px'}
                            left={locale === 'he' ? '0px' : 'auto'}
                          >
                            <Text>
                              {tempCategory.length}/{keys.CREATE_TASK_CONSTANTS.MAX_CATEGORY_NAME_LENGTH}
                            </Text>
                          </Box>
                        </FormControl>
                      </Box>
                      <Button
                        onClick={() => {
                          handleCategoryAddition()
                          onClose!()
                        }}
                        isDisabled={tempCategory.isError}
                        mb="1rem"
                      >
                        <LocaleText text="submit_btn" />
                      </Button>
                    </PopoverBody>
                  </PopoverContent>
                </>
              )}
            </Popover>
          </Flex>

          <Divider borderColor={colors.greyMedium2} />

          <Box mb="20px" borderRadius="10px">
            <BoxWrapper mb="2" p="20px">
              <BlockTitle text="create_task_name_placeholder" />
              <Input
                fontSize="1rem"
                fontWeight="500"
                color={colors.blackLight}
                onChange={handleTaskName}
                onBlur={handleOnBlurTaskName}
                value={taskNameData.value}
                backgroundColor="transparent"
                // border="none"
                variant="flushed"
                isInvalid={taskNameData.isError}
                placeholder={create_task_st_title_placeholder}
              />
            </BoxWrapper>
            {isNewTask && (
              <BoxWrapper mb="2" p="20px" onClick={onOpen}>
                <Flex alignItems="center" mt="7px">
                  {/* TODO: When adding avatars return that */}
                  {/* <Avatar
                       size="sm"
                       name="Kent Dodds"
                       src="https://bit.ly/kent-c-dodds"
                       ml={locale === 'he' ? 3 : 0}
                       mr={locale !== 'he' ? 3 : 0}
       
                     /> */}
                </Flex>
                <Box mb="10px">
                  <BlockTitle text="create_task_owners" />
                  {taskOwners &&
                    taskOwners?.groups?.length > 0 &&
                    taskOwners.groups.map((owner, index: number) => {
                      if (index < MAX_OWNERS_SHOW) {
                        return <Text key={owner.value}>{owner.label}</Text>
                      } else if (index === MAX_OWNERS_SHOW) {
                        return (
                          <Text color={theme?.elementsColor} key={owner.value}>
                            + {taskOwners.groups.length - MAX_OWNERS_SHOW} <LocaleText text="owners_more_stub" />
                          </Text>
                        )
                      } else {
                        return <></>
                      }
                    })}
                </Box>
                <Box mb="10px">
                  <BlockTitle text="Task users" />
                  {taskOwners &&
                    taskOwners?.users?.length > 0 &&
                    taskOwners.users.map((owner, index: number) => {
                      if (index < MAX_OWNERS_SHOW) {
                        return <Text key={owner.value}>{owner.label}</Text>
                      } else if (index === MAX_OWNERS_SHOW) {
                        return (
                          <Text color={theme?.elementsColor} key={owner.value}>
                            + {taskOwners.users.length - MAX_OWNERS_SHOW} <LocaleText text="owners_more_stub" />
                          </Text>
                        )
                      } else {
                        return <></>
                      }
                    })}
                </Box>

                {isEmpty(taskOwners) ||
                  (!taskOwners?.groups.length && !taskOwners?.users.length && (
                    <Flex alignItems="center">
                      <Text
                        as="span"
                        backgroundColor={theme?.elementsColor}
                        borderRadius="100%"
                        minWidth="16px"
                        height="16px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        color="#fff"
                        fontSize="12px"
                        mr={locale !== 'he' ? 3 : 0}
                        ml={locale === 'he' ? 3 : 0}
                      >
                        +
                      </Text>
                      <Text as="span" fontSize="1rem" fontWeight="500" color={colors.greyMain}>
                        <LocaleText text="create_task_add_owners_btn" />
                      </Text>
                    </Flex>
                  ))}
              </BoxWrapper>
            )}

            <BoxWrapper ml="10px" p="10px">
              <BlockTitle text="create_task_start_date_title" />
              <CustomDatePicker
                selected={from_ts && new Date(from_ts)}
                onChange={handleSetStartDate}
                placeholderText={create_task_start_date_placeholder}
                minDate={new Date()}
                selectsStart
                showTimeInput={true}
                onInputClick={handleOpenStartDatePicker}
                onClickOutside={closeStartDatePicker}
                open={isStartDateOpen}
                isShowYearPicker={true}
                isShowMonthPicker={true}
              />
            </BoxWrapper>
            <BoxWrapper ml="10px" p="10px">
              <BlockTitle text="create_task_end_date_title" />
              <CustomDatePicker
                selected={deadlineDate && new Date(deadlineDate)}
                onChange={handleSetDeadlineDate}
                placeholderText={create_task_end_date_placeholder}
                selectsEnd
                minDate={from_ts && new Date(from_ts)}
                popperPlacement="top"
                disabled={!from_ts}
                showTimeInput={true}
                open={isDeadLineOpen}
                onInputClick={handleOpenDeadLineDatePicker}
                onClickOutside={handleCloseDedLineDatePicker}
                isShowYearPicker={true}
                isShowMonthPicker={true}
              />
            </BoxWrapper>
            <BoxWrapper ml="10px" p="10px">
              <Flex mb="10px">
                <CustomSwitcher
                  mb="0.5rem"
                  isChecked={isAutoRemovalTask}
                  onChange={autoRemovalTaskHandler}
                  labelText={create_task_removal_after_default_days}
                />
              </Flex>
              {!isAutoRemovalTask && (
                <CustomDatePicker
                  selected={to_ts && new Date(to_ts)}
                  onChange={handleSetEndDate}
                  placeholderText={create_task_removal_date_placeholder}
                  selectsEnd
                  minDate={deadlineDate && new Date(deadlineDate)}
                  showTimeInput={true}
                  disabled={!deadlineDate}
                  open={deadlineDate && isEndDateOpen}
                  onInputClick={handleOpenEndDatePicker}
                  onClickOutside={closeEndDatePicker}
                  tooltipLabel={create_task_tooltip_removal_date}
                />
              )}
            </BoxWrapper>
            <Divider borderColor={colors.greyMedium2} mb="20px" />

            <Flex>
              <Button
                isDisabled={!isReadyToPublish}
                borderRadius="20px"
                mx="1rem"
                color="white"
                bg={theme?.elementsColor}
                variant="solid"
                onClick={publishTask}
                type="submit"
                isLoading={publishTaskIsLoading}
                loadingText="Publishing"
              >
                <LocaleText text="publish_task_btn" />
              </Button>
            </Flex>
          </Box>
        </SidebarContent>
      </StyledSidebar>

      <AudiencePublicationsModal
        groups={treeGroups}
        users={treeUsers}
        isOpen={isOpen}
        onClose={onClose}
        setShowOptionsList={onClose}
        renderViewOptionModal={renderViewOptionModal}
        selectedUsersUids={selectedUsersUids}
        groupsToPayload={selectedGroupIDs}
        isShowWarning={isUidsOfSelectedGroupsInSelectedUsers || isSelectedUidsInGroups}
      />
    </>
  )
}

export default CreateTaskSidebar
