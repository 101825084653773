import { Box, BoxProps, Flex } from '@chakra-ui/core'
import colors from 'constants/colors'
import React from 'react'

interface Props extends BoxProps {
  value: string
  isChecked: boolean
  onChange: () => void
  disabled?: boolean
  fillColor?: string
}
export const RadioCheckbox: React.FC<Props> = ({
  value,
  isChecked,
  onChange,
  children,
  disabled = false,
  fillColor = colors.greenPoll,
  ...props
}) => {
  return (
    <Flex alignItems="center" style={{ columnGap: '10px' }}>
      <Flex
        as="label"
        display="flex"
        alignItems="center"
        cursor={disabled ? 'not-allowed' : 'pointer'}
        position="relative"
        mb="0px"
      >
        <input
          type="checkbox"
          value={value}
          checked={isChecked}
          disabled={disabled}
          onChange={onChange}
          style={{ display: 'none' }}
        />
        <Box
          width="20px"
          height="20px"
          borderRadius="full"
          borderWidth="1px"
          borderStyle="solid"
          borderColor={isChecked ? fillColor : 'gray.400'}
          backgroundColor={isChecked ? fillColor : 'white'}
          alignItems="center"
          justifyContent="center"
          opacity={disabled ? 0.5 : 1}
          {...props}
        />
      </Flex>

      {children}
    </Flex>
  )
}
