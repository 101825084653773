import React, { memo } from 'react'
import { StoreeEditor } from '../StoreeEditor'
import { Flex } from '@chakra-ui/core'
import { MessageInput } from '../MessageInput'
import { OptionType } from '../constants'
import { TitleInput } from '../TitleInput'
import keys from 'constants/keys'

interface IProps {
  selectedOptionType: string
  editorValue: string
  setEditorValue: (text: string) => void
  setTitle: (text: string) => void
  title: string
  placeholder: string
  message: string
  setMessage: (text: string) => void
  postType?: number
  isEditing: boolean
}
export const InputBlock = memo(
  ({
    selectedOptionType,
    editorValue,
    setEditorValue,
    setTitle,
    title,
    placeholder,
    message,
    setMessage,
    postType,
    isEditing,
  }: IProps) => {
    if (postType !== undefined) {
      switch (postType) {
        case keys.POST_TYPE.REGULAR:
        case keys.POST_TYPE.LINKED:
          return <StoreeEditor editorValue={editorValue} setEditorValue={setEditorValue} placeholder={placeholder} />

        default:
          return (
            <Flex flexDir="column" minH="150px">
              <TitleInput setTitle={setTitle} title={title} selectedOptionType={selectedOptionType} />
              {!isEditing && (
                <MessageInput message={message} setMessage={setMessage} selectedOptionType={selectedOptionType} />
              )}
            </Flex>
          )
      }
    } else {
      switch (selectedOptionType) {
        case OptionType.POST:
          return <StoreeEditor editorValue={editorValue} setEditorValue={setEditorValue} placeholder={placeholder} />
        default:
          return (
            <Flex flexDir="column" minH="150px">
              <TitleInput setTitle={setTitle} title={title} selectedOptionType={selectedOptionType} />
              {!isEditing && (
                <MessageInput message={message} setMessage={setMessage} selectedOptionType={selectedOptionType} />
              )}
            </Flex>
          )
      }
    }
  }
)
