import React from 'react'
import { Text, Button, Flex, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/core'
import LocaleText from 'components/LocaleText'
import { frequencyLabel, FrequencyType } from 'components/NewsFeed/PollTaskForm/utils'
import useLocaleText from 'components/useLocaleText'
import { Controller, useFormContext } from 'react-hook-form'
import { useFormStepContext } from '../../FormStepContext'

const inputStyles = {
  borderRadius: '10px',
  bg: 'white',
  border: '1px solid #E2E8F0',
  fontWeight: '500',
  fontSize: '14px',
  px: '5px',
  h: '30px',
}

export const FrequencyMenu = () => {
  const { control, setValue, watch } = useFormContext()
  const { isEdit } = useFormStepContext()

  const recurrenceFrequency = watch('recurrence_frequency')
  const isRecurring = watch('is_recurring')

  const t_day = useLocaleText('t_day')
  const t_week = useLocaleText('t_week')
  const t_month = useLocaleText('t_month')
  const t_recurring = useLocaleText('t_recurring')

  const handleSelect = (value: FrequencyType) => {
    setValue('recurrence_frequency', value)
    setValue('days_of_week', null)
  }

  return (
    <Flex
      display={isRecurring ? 'flex' : 'none'}
      alignItems="center"
      style={{ columnGap: '10px' }}
      pointerEvents={isEdit ? 'none' : 'auto'}
      opacity={isEdit ? 0.5 : 1}
    >
      <Text>{t_recurring}</Text>
      <Controller
        name="recurrence_frequency"
        control={control}
        as={
          <Menu>
            {!isRecurring ? (
              <Button w="max-content" {...inputStyles} isDisabled>
                <LocaleText text={frequencyLabel(recurrenceFrequency)} />{' '}
              </Button>
            ) : (
              <MenuButton as={Button} w="max-content" minW="60px" {...inputStyles} _focus={{ boxShadow: 'none' }}>
                <LocaleText text={frequencyLabel(recurrenceFrequency)} />
              </MenuButton>
            )}
            <MenuList w="max-content" borderRadius="10px">
              <MenuItem onClick={() => handleSelect(FrequencyType.DAY)} _hover={{ backgroundColor: 'black' }}>
                <LocaleText text={t_day} />
              </MenuItem>
              <MenuItem onClick={() => handleSelect(FrequencyType.WEEK)} _hover={{ backgroundColor: 'black' }}>
                <LocaleText text={t_week} />
              </MenuItem>
              <MenuItem onClick={() => handleSelect(FrequencyType.MONTH)} _hover={{ backgroundColor: 'black' }}>
                <LocaleText text={t_month} />
              </MenuItem>
            </MenuList>
          </Menu>
        }
      />
    </Flex>
  )
}
