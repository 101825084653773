import React, { forwardRef } from 'react'
import { useSelector } from 'react-redux'
import { Box, Button, Input, InputGroup, InputLeftElement, InputRightElement } from '@chakra-ui/core'
import { MenuListComponentProps } from 'react-select'
import { BsSearch } from 'react-icons/all'
import useLocaleText from 'components/useLocaleText'
import { RootState } from 'constants/interfaces'
import { CustomOverflow } from 'components/helperStyles'
import { isRtlSelector } from 'redux/selectors/general'
import { IOption } from './SelectOption'
import LocaleText from 'components/LocaleText'

interface IProps extends Omit<MenuListComponentProps<IOption, false>, 'innerRef'> {
  isShowSearch?: boolean
  field?: string
  value?: any
  onDeleteSelection?: (arg: object | null) => void
}

export const SelectMenuList = forwardRef<HTMLDivElement, IProps>(
  ({ isShowSearch = false, field, value, onDeleteSelection, children, ...props }, ref) => {
    const search_feed_filter_search = useLocaleText('search_feed_filter_search')
    const locale = useSelector((state: RootState) => state.general.locale)
    const isRTL = useSelector(isRtlSelector)

    const handleNameCreator = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!props.selectProps.onInputChange) return
      props.selectProps.onInputChange(e.target.value, { action: 'input-change' })
    }

    return (
      <CustomOverflow
        maxHeight="300px"
        borderRadius="10px"
        overflow="auto"
        w="max-content"
        maxW="265px"
        px="5px"
        py="10px"
        cursor="pointer"
        showScroll={false}
        locale={locale}
        ref={ref}
      >
        {isShowSearch && (
          <InputGroup border="none">
            {isRTL ? (
              <InputRightElement>
                <Box>
                  <BsSearch />
                </Box>
              </InputRightElement>
            ) : (
              <InputLeftElement>
                <BsSearch />
              </InputLeftElement>
            )}

            <Input
              placeholder={search_feed_filter_search}
              autoFocus
              type="text"
              value={props.selectProps.inputValue}
              onChange={handleNameCreator}
              border="none"
              _focus={{ outline: 'none' }}
            />
          </InputGroup>
        )}
        {field === 'country_code' && value && Object.keys(value).length > 0 && (
          <Button width="100%" onClick={onDeleteSelection} variant="outline" justifyContent="flex-start" border="none">
            <LocaleText text="search_feed_clear_filter_web" />
          </Button>
        )}
        {children}
      </CustomOverflow>
    )
  }
)
