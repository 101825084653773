import dayjs from 'dayjs'

export const applyTimeToDate = (date: Date, frequencyTime?: string): Date => {
  if (frequencyTime) {
    const [hours, minutes] = frequencyTime.split(':').map(Number)
    date.setHours(hours, minutes, 0, 0)
  }
  return date
}

export const getNextDateForDayOfWeek = (startDate: Date, dayOfWeek: number): Date => {
  const date = dayjs(startDate)
  const desiredDay = dayOfWeek
  const currentDay = date.day()
  let diff = desiredDay - currentDay
  if (diff < 0) {
    diff += 7
  }
  return date.add(diff, 'day').toDate()
}
