import React, { useCallback, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { FrequencyType } from 'components/NewsFeed/PollTaskForm/utils'
import SliderSwitch from 'components/CommonComponents/SliderSwitch'
import useLocaleText from 'components/useLocaleText'
import { useFormStepContext } from '../../FormStepContext'

export const RecurringSwitch = () => {
  const { control, setValue, watch, triggerValidation } = useFormContext()
  const { isEdit } = useFormStepContext()

  const [savedDeadline, setSavedDeadline] = useState<string | null>(null)
  const currentDeadline = watch('deadline')

  const one_time_task = useLocaleText('one_time_task')
  const recuuring_task = useLocaleText('recuuring_task')

  const handleToggle = useCallback(
    async (isChecked: boolean) => {
      if (isChecked) {
        setSavedDeadline(currentDeadline)

        setValue('recurrence_frequency', FrequencyType.DAY)
        setValue('deadline', null)
      } else {
        if (savedDeadline) {
          setValue('deadline', savedDeadline)
        }
        setValue('recurrence_frequency', null)
        setValue('days_of_week', null)
        setValue('frequency_time', '')
      }
      await triggerValidation('deadline')
    },
    [currentDeadline, savedDeadline, setValue, triggerValidation]
  )

  return (
    <Controller
      name="is_recurring"
      control={control}
      key={'is_recurring'}
      valueName="isChecked"
      onChangeName="onChange"
      as={
        <SliderSwitch
          activeLabel={recuuring_task}
          inactiveLabel={one_time_task}
          onChange={(isChecked) => handleToggle(isChecked)}
          isEdit={isEdit}
        />
      }
      onChange={(args: any[]) => {
        const isChecked = args[0]
        handleToggle(isChecked)
        return isChecked
      }}
    />
  )
}
