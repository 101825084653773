import React from 'react'
import { Subtask } from 'constants/interfaces'
import { VotersList } from './VotersList'

interface IProps {
  pollSubtask: Subtask
}
const VotesOverview: React.FC<IProps> = ({ pollSubtask }) => {
  return <VotersList pollSubtask={pollSubtask} />
}

export default VotesOverview
