import React from 'react'
import { Box, Flex, Text } from '@chakra-ui/core'
import colors from 'constants/colors'
import useLocaleText from 'components/useLocaleText'
import { RootState } from 'constants/interfaces'
import { useUserName } from 'hooks/useUserName'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { WidgetWrapper } from 'components/TasksV2/UI/WidgetWrapper'

interface IProps {
  created_by: string
  location_id: string
  isDisplayDate?: boolean
  children?: React.ReactNode
}
export const ConstantFields: React.FC<IProps> = ({ created_by, location_id, isDisplayDate = true, children }) => {
  const {
    config: { groups },
  } = useSelector((state: RootState) => state.config)

  const t_location = useLocaleText('location')
  const t_assigted_to = useLocaleText('created_by')

  const userName = useUserName(created_by)
  const contextName = groups?.[location_id]?.name
  const dateNow = dayjs().format('DD/MM/YYYY')

  return (
    <Flex flexDir="column" w="100%" color={colors.greyDark} style={{ rowGap: '30px' }}>
      {isDisplayDate && (
        <WidgetWrapper p="10px" borderRadius="10px" w="50%" mx="auto">
          <Text>{dateNow}</Text>
        </WidgetWrapper>
      )}

      <Flex flexDir="column" w="100%" style={{ rowGap: '15px' }} pointerEvents="none">
        <Box>
          <Text px="10px" pb="5px">
            {t_location}
          </Text>
          <WidgetWrapper p="10px" borderRadius="10px" w="100%">
            <Text>{contextName}</Text>
          </WidgetWrapper>
        </Box>

        <Box>
          <Text px="10px" pb="5px">
            {t_assigted_to}
          </Text>
          <WidgetWrapper p="10px" borderRadius="10px" w="100%">
            <Text>{userName}</Text>
          </WidgetWrapper>
        </Box>
      </Flex>
      {children}
    </Flex>
  )
}
