import { Box, Flex, Text } from '@chakra-ui/core'
import { IDynamicTaskView, RootState } from 'constants/interfaces'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import ServiceCallTypeMenu from './ServiceCallTypeMenu'
import Description from './ServiceCallDescription'
import useLocaleText from 'components/useLocaleText'

interface Props {
  serviceCallView: IDynamicTaskView
}

const ServiceCallFormCreation: React.FC<Props> = ({ serviceCallView }) => {
  const { control, watch } = useFormContext()
  // const uid = useSelector((state: RootState) => state.auth.uid)
  // const { activeGroupID } = useSelector((state: RootState) => state.config)
  const { isLoading } = useSelector((state: RootState) => state.serviceCalls)

  const description = useLocaleText('description')
  const issue_description = useLocaleText('issue_description')

  return (
    <Flex
      flexDir="column"
      mt={4}
      pointerEvents={isLoading ? 'none' : 'all'}
      opacity={isLoading ? 0.5 : 1}
      w={{ base: '100%', md: '80%' }}
      style={{ rowGap: '30px' }}
      mx={'auto'}
    >
      <Text textAlign="center" fontSize="20px" fontWeight="bold">
        {issue_description}
      </Text>
      <ServiceCallTypeMenu serviceCallView={serviceCallView} />
      <Box>
        <Text px="10px" pb="5px">
          {description}
        </Text>
        <Description descPath="desc" imagesPath="images" videosPath="videos" filesPath="files" />
      </Box>

      <Controller name="location_id" control={control} as={<Box />} defaultValue={watch('location_id')} />
      <Controller name="config.statuses" control={control} as={<Box />} defaultValue={watch('config.statuses')} />
      <Controller name="supervisor" control={control} as={<Box />} defaultValue={watch('supervisor')} />
      <Controller name="view" control={control} as={<Box />} defaultValue={watch('view')} />
    </Flex>
  )
}

export default ServiceCallFormCreation
