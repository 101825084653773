import React from 'react'
import { IconProps } from './types'
import { Flex } from '@chakra-ui/core'
import { ChartNoAxesColumn } from 'lucide-react'

const PollIcon: React.FC<IconProps> = ({
  iconWidth = 24,
  iconHeight = 24,
  width = '40px',
  height = '40px',
  iconColor = '#FFF',
  bgColor = '#C3F1DD',
  ...props
}) => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      bg={bgColor}
      width={width}
      height={height}
      borderRadius="full"
      {...props}
    >
      <ChartNoAxesColumn width={iconWidth} height={iconHeight} color={iconColor} strokeWidth={3} />
    </Flex>
  )
}

export default PollIcon
