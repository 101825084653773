import React from 'react'
import useLocaleText from 'components/useLocaleText'
import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import 'dayjs/locale/en'
import { FrequencyType } from 'components/NewsFeed/PollTaskForm/utils'
import { Flex, Text } from '@chakra-ui/core'
import { Repeat } from 'lucide-react'
import { StepType, useFormStepContext } from 'components/TasksV2/TaskCreation/FormStepContext'
import { DAYS_OF_WEEK_FULL } from '../../TimeFrameSelection/FrequencyDatesPicker/utils'
import { useSelector } from 'react-redux'
import { RootState } from 'constants/interfaces'
import { useDateLocale } from 'hooks/useDateLocale'
import { calculateRecurrenceDeadline, calculateTots } from 'components/TasksV2/TaskCreation/utils'
import keys from 'constants/keys'
import { useFormContext } from 'react-hook-form'

dayjs.extend(localeData)

interface SummaryProps {
  fromTs: string
  toTs: string
  locale: string
  translations?: Object
  daysOfWeek?: number[]
  t_from?: string
  t_to: string
  t_rec_sum_start_date: string
  t_rec_sum_end_date: string
  recurrenceDeadline?: string | null
  recurringText: string
}

export const Summery: React.FC = () => {
  const { formData } = useFormStepContext()
  const { watch } = useFormContext()
  const timeFrameFormData = formData[StepType.TIME_FRAME_SELECTION]

  const locale = useSelector((state: RootState) => state.general.locale)
  const translations = useSelector((state: RootState) => state.config.translations)

  const isLocaleReady = useDateLocale(locale)

  const t_to = useLocaleText('t_to')
  const t_from = useLocaleText('t_from')
  const t_sum_start_date = useLocaleText('t_sum_start_date')
  const t_sum_end_date = useLocaleText('t_sum_end_date')
  const t_rec_sum_start_date = useLocaleText('t_rec_sum_start_date')
  const t_rec_sum_end_date = useLocaleText('t_rec_sum_end_date')
  const t_daily_recurring = useLocaleText('t_daily_recurring')
  const t_monthly_recurring = useLocaleText('t_monthly_recurring')
  const t_weekly_recurring = useLocaleText('t_weekly_recurring')
  const t_days_recurring = useLocaleText('t_days_recurring')

  const isRecurring = timeFrameFormData?.is_recurring ?? false
  const fromTs = getFormattedDate(timeFrameFormData?.from_ts || watch('from_ts'))
  const deadline = getFormattedDate(timeFrameFormData?.deadline || watch('deadline'))
  const toTs = calculateTots({ is_recurring: isRecurring, deadline, formattedDeadline: deadline })
  const daysOfWeek = timeFrameFormData?.days_of_week as number[]
  const frequency = timeFrameFormData?.recurrence_frequency ?? null

  const recurrenceDeadline = timeFrameFormData?.is_recurring
    ? calculateRecurrenceDeadline({
        is_recurring: timeFrameFormData?.is_recurring ?? false,
        recurrence_frequency: timeFrameFormData?.recurrence_frequency ?? null,
        from_ts: timeFrameFormData?.from_ts || watch('from_ts'),
        frequency_time: timeFrameFormData?.frequency_time ?? '',
        monthly_range: timeFrameFormData?.monthly_range ?? [],
        days_of_week: timeFrameFormData?.days_of_week ?? null,
      })
    : ''

  const renderSummaryContent = () => {
    if (frequency === FrequencyType.DAY) {
      return renderDailySummary({
        fromTs,
        toTs,
        daysOfWeek,
        locale,
        translations,
        t_from,
        t_to,
        t_rec_sum_start_date,
        t_rec_sum_end_date,
        recurringText: daysOfWeek?.length === 7 ? t_daily_recurring : t_days_recurring,
      })
    }
    if (frequency === FrequencyType.WEEK) {
      return renderWeeklySummary({
        fromTs,
        toTs,
        daysOfWeek,
        locale,
        translations,
        t_from,
        t_to,
        t_rec_sum_start_date,
        t_rec_sum_end_date,
        recurringText: t_weekly_recurring,
        recurrenceDeadline,
      })
    }
    if (frequency === FrequencyType.MONTH) {
      return renderMonthlySummary({
        fromTs,
        toTs,
        locale,
        t_from,
        t_to,
        t_rec_sum_start_date,
        t_rec_sum_end_date,
        recurringText: t_monthly_recurring,
        recurrenceDeadline,
      })
    }
    return renderDefaultSummary({ fromTs, deadline, locale, t_sum_start_date, t_sum_end_date })
  }

  if (!isLocaleReady) {
    return null
  }

  return (
    <Flex flexDir="column">
      <Text>
        {isRecurring && (
          <Repeat
            size={14}
            strokeWidth={3}
            style={{ display: 'inline', verticalAlign: 'middle', marginRight: '4px' }}
          />
        )}
        {renderSummaryContent()}
      </Text>
    </Flex>
  )
}

// Helper Functions
const getFormattedDate = (date: string): string => dayjs(date).format(keys.DEFAULT_STRING_FORMAT_TIMESTAMP)

const renderDailySummary: React.FC<SummaryProps> = ({
  fromTs,
  toTs,
  daysOfWeek,
  locale,
  translations,
  t_from,
  t_to,
  t_rec_sum_start_date,
  t_rec_sum_end_date,
  recurringText,
}) => (
  <Text as="span" lineHeight={1.8}>
    {t_rec_sum_start_date} {dayjs(fromTs).locale(locale).format('DD MMMM YYYY')} {recurringText}{' '}
    {daysOfWeek?.length === 7
      ? ''
      : daysOfWeek?.map((day) => translations?.[locale]?.[DAYS_OF_WEEK_FULL[day]]).join(', ')}{' '}
    {t_from} {dayjs(fromTs).locale(locale).format('HH:mm')} {t_to} {dayjs(toTs).locale(locale).format('HH:mm')}. <br />
    {t_rec_sum_end_date} {dayjs(toTs).locale(locale).format('DD MMMM YYYY')}
  </Text>
)

const renderWeeklySummary: React.FC<SummaryProps> = ({
  fromTs,
  toTs,
  daysOfWeek,
  locale,
  translations,
  t_to,
  t_rec_sum_start_date,
  t_rec_sum_end_date,
  recurringText,
  recurrenceDeadline,
}) => (
  <Text as="span" lineHeight={1.8}>
    {t_rec_sum_start_date} {dayjs(fromTs).locale(locale).format('DD MMMM YYYY')} {recurringText}{' '}
    {translations?.[locale]?.[DAYS_OF_WEEK_FULL[daysOfWeek![0]]]} {dayjs(fromTs).locale(locale).format('HH:mm')} {t_to}{' '}
    {translations?.[locale]?.[DAYS_OF_WEEK_FULL[daysOfWeek![1]]]}{' '}
    {dayjs(recurrenceDeadline).locale(locale).format('HH:mm')}. <br />
    {t_rec_sum_end_date} {dayjs(toTs).locale(locale).format('DD MMMM YYYY')}
  </Text>
)

const renderMonthlySummary: React.FC<SummaryProps> = ({
  fromTs,
  toTs,
  locale,
  t_to,
  t_rec_sum_start_date,
  t_rec_sum_end_date,
  recurringText,
  recurrenceDeadline,
}) => (
  <Text as="span" lineHeight={1.8}>
    {t_rec_sum_start_date} {dayjs(fromTs).locale(locale).format('DD MMMM YYYY')} {recurringText}{' '}
    {dayjs(fromTs).locale(locale).format('DD HH:mm')} {t_to}{' '}
    {dayjs(recurrenceDeadline).locale(locale).format('DD HH:mm')}. <br />
    {t_rec_sum_end_date} {dayjs(toTs).locale(locale).format('DD MMMM YYYY')}
  </Text>
)

const renderDefaultSummary: React.FC<{
  fromTs: string
  deadline: string
  locale: string
  t_sum_start_date: string
  t_sum_end_date: string
}> = ({ fromTs, deadline, locale, t_sum_start_date, t_sum_end_date }) => (
  <>
    {fromTs && (
      <Text as={'span'} lineHeight={1.8}>
        {t_sum_start_date} {dayjs(fromTs).locale(locale).format('MMMM DD YYYY HH:mm')} <br />
      </Text>
    )}

    {deadline && (
      <Text as={'span'} lineHeight={1.8}>
        {t_sum_end_date} {dayjs(deadline).locale(locale).format('MMMM DD YYYY HH:mm')}
      </Text>
    )}
  </>
)
