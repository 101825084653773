import { httpAuth } from 'config/apiClient'
import endpoints from 'constants/endpoints'
import { RootState, SupervisorOrHandler } from 'constants/interfaces'
import { isEmpty } from 'lodash'
import { AnyAction } from 'redux'
import { ThunkAction } from 'redux-thunk'

export interface ConfigOption {
  name: string
  option: string
}

interface Config {
  options: ConfigOption[]
  statuses: Status[]
}

export interface Status {
  color: string
  name: string
  value: number
}

export interface CreateServiceCallObject {
  config: Config
  desc: string
  images: string[]
  videos: string[]
  files: string[]
  location_id: string
  view: number
  supervisor: SupervisorOrHandler
}
export interface ServiceCall extends CreateServiceCallObject {
  created_by: string
  status: number
  updated_by: string
  updated_at_ts: number
  created_at_ts: number
  service_call_number: number
  service_call_id: string
  assignee?: SupervisorOrHandler
  notes?: string
  ref_to_chat?: string
}

export const actionTypes = {
  CREATE_SERVICE_CALL: 'CREATE_SERVICE_CALL',
  SET_SERVICE_CALLS: 'SET_SERVICE_CALLS',
  SET_SERVICE_CALLS_DASHBOARD: 'SET_SERVICE_CALLS_DASHBOARD',
  IS_LOADING: 'IS_LOADING',
  SET_DATE_FILTER: 'SET_DATE_FILTER',
  SET_HISTORICAL_SERVICE_CALLS: 'SET_HISTORICAL_SERVICE_CALLS',
}

export const setIsLoading = (isLoading: boolean) => ({
  type: actionTypes.IS_LOADING,
  payload: isLoading,
})

export const setServiceCalls = (serviceCalls: ServiceCall[]) => ({
  type: actionTypes.SET_SERVICE_CALLS,
  payload: serviceCalls,
})

export const setServiceCallsDashboard = (serviceCalls: ServiceCall[]) => ({
  type: actionTypes.SET_SERVICE_CALLS_DASHBOARD,
  payload: serviceCalls,
})

export const setHistoricalServiceCalls = (serviceCalls: ServiceCall[]) => ({
  type: actionTypes.SET_HISTORICAL_SERVICE_CALLS,
  payload: serviceCalls,
})

export const setDateFilter = (date: Date[] | null) => ({
  type: actionTypes.SET_DATE_FILTER,
  payload: date,
})

export const createServiceCall =
  (data: any): ThunkAction<Promise<boolean>, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(setIsLoading(true))

    try {
      const tempdata = {
        ...data,
        supervisor: {
          context_id: data.supervisor.context_id,
          context_type: data.supervisor.context_type,
        },
      }
      const response = await httpAuth.post(endpoints.serviceCalls, tempdata)

      return !!response
    } catch (e) {
      console.log(e)
      return false
    } finally {
      dispatch(setIsLoading(false))
    }
  }
export const getServiceCalls =
  (context_id: string): ThunkAction<Promise<ServiceCall[] | null>, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    dispatch(setIsLoading(true))
    const { serviceCalls: currentServiceCalls } = getState().serviceCalls
    try {
      const response = await httpAuth.get(endpoints.serviceCalls, { params: { context_id } })
      const { data: apiServiceCalls }: { data: ServiceCall[] } = response

      const mergedServiceCalls = [...currentServiceCalls]

      apiServiceCalls.forEach((newCall) => {
        const index = mergedServiceCalls.findIndex(
          (existingCall) => existingCall.service_call_number === newCall.service_call_number
        )
        if (index !== -1) {
          mergedServiceCalls[index] = newCall
        } else {
          mergedServiceCalls.push(newCall)
        }
      })

      dispatch(setServiceCalls(mergedServiceCalls))
      return mergedServiceCalls
    } catch (e) {
      console.error(e)
      return null
    } finally {
      dispatch(setIsLoading(false))
    }
  }

interface ServiceCallDashboardArgs {
  context_id: string
  filter_closed?: boolean
  from_ts?: string
  to_ts?: string
}
export const getServiceCallsDashboard =
  ({
    context_id,
    filter_closed,
    from_ts,
    to_ts,
  }: ServiceCallDashboardArgs): ThunkAction<Promise<ServiceCall[] | null>, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const query = {
        context_id,
        filter_closed,
        from_ts,
        to_ts,
      }
      const response = await httpAuth.get(endpoints.serviceCallsDashboard, { params: query })
      const { data } = response

      if (!isEmpty(data)) {
        dispatch(setServiceCallsDashboard(data))
        return data
      } else {
        return null
      }
    } catch (e) {
      return null
    } finally {
      dispatch(setIsLoading(false))
    }
  }

export const getHistoricalServiceCalls =
  ({
    context_id,
    from_ts,
    to_ts,
    viewId,
  }: ServiceCallDashboardArgs & { viewId: number }): ThunkAction<
    Promise<ServiceCall[] | null>,
    RootState,
    unknown,
    AnyAction
  > =>
  async (dispatch, getState) => {
    dispatch(setIsLoading(true))
    try {
      const { historicalServiceCalls } = getState().serviceCalls
      const query = {
        context_id,
        filter_closed: true,
        from_ts,
        to_ts,
        view: viewId,
      }
      const response = await httpAuth.get(endpoints.serviceCallsDashboard, { params: query })
      const { data } = response

      if (!isEmpty(data)) {
        const mergedCalls = [...historicalServiceCalls, ...data]
        const uniqueCalls = mergedCalls.filter(
          (call, index, self) => index === self.findIndex((c) => c.service_call_id === call.service_call_id)
        )
        dispatch(setHistoricalServiceCalls(uniqueCalls))
        return uniqueCalls
      } else {
        return null
      }
    } catch (e) {
      return null
    } finally {
      dispatch(setIsLoading(false))
    }
  }

interface UpdateServiceCallArgs {
  service_call_id: string
  assignee: {
    context_id: string
    context_type: number
  }
  status: number
  notes: string
  ref_to_chat?: string
}

export const updateServiceCall =
  ({
    service_call_id,
    assignee,
    status,
    notes,
    ref_to_chat,
  }: UpdateServiceCallArgs): ThunkAction<Promise<boolean>, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { serviceCalls, serviceCallsDashboard } = getState().serviceCalls
    dispatch(setIsLoading(true))

    try {
      const serviceCall = serviceCalls.find((serviceCall) => serviceCall.service_call_id === service_call_id)

      const updatedServiceCalls = serviceCalls.map((call) => {
        if (call.service_call_id === service_call_id && serviceCall) {
          return {
            ...serviceCall,
            status,
            ref_to_chat,
          }
        }
        return call
      })
      const serviceCallDashboard = serviceCallsDashboard.find(
        (serviceCall) => serviceCall.service_call_id === service_call_id
      )
      const updatedServiceCallDashboard = serviceCallsDashboard.map((call) => {
        if (call.service_call_id === service_call_id && serviceCallDashboard) {
          return {
            ...serviceCallDashboard,
            status,
            notes,
            ref_to_chat,
            assignee,
          }
        }
        return call
      })

      dispatch(setServiceCalls(updatedServiceCalls))
      dispatch(setServiceCallsDashboard(updatedServiceCallDashboard))

      const body = {
        service_call_id,
        assignee,
        status,
        notes,
        priority: 1,
        ref_to_chat,
      }
      const result = await httpAuth.put(endpoints.serviceCalls, body)

      return !!result
    } catch (e) {
      console.log(e)
      return false
    } finally {
      dispatch(setIsLoading(false))
    }
  }

export const getServiceCall =
  ({
    contextId,
    service_call_id,
  }: {
    contextId: string
    service_call_id: string
  }): ThunkAction<Promise<ServiceCall | null>, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { serviceCalls } = getState().serviceCalls
    dispatch(setIsLoading(true))
    try {
      const query = {
        context_id: contextId,
        service_call_id,
      }
      const response = await httpAuth.get(endpoints.serviceCall, { params: query })
      const { data: serviceCall }: { data: ServiceCall } = response

      if (serviceCall) {
        const existingCall = serviceCalls.find((call) => call.service_call_number === serviceCall.service_call_number)

        let updatedServiceCalls
        if (existingCall) {
          updatedServiceCalls = serviceCalls.map((call) =>
            call.service_call_number === serviceCall.service_call_number ? serviceCall : call
          )
        } else {
          updatedServiceCalls = [...serviceCalls, serviceCall]
        }

        dispatch(setServiceCalls(updatedServiceCalls))
      }

      return serviceCall
    } catch (e) {
      console.error(e)
      return null
    } finally {
      dispatch(setIsLoading(false))
    }
  }

export const createChatToken =
  (service_call_id: string): ThunkAction<Promise<string | undefined>, RootState, unknown, AnyAction> =>
  async () => {
    try {
      const body = {
        service_call_id,
      }
      const response = await httpAuth.post(endpoints.createServiceCallChatToken, body)
      const { data } = response
      return data?.token
    } catch (e) {
      return undefined
    }
  }
