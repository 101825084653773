import React from 'react'
import { Button, Flex, Skeleton, Text } from '@chakra-ui/core'
import { AppDispatch } from 'config/redux'
import colors from 'constants/colors'
import keys from 'constants/keys'
import { useMediaState } from 'hooks/useMediaState'
import { useDispatch, useSelector } from 'react-redux'
import { updateResults } from 'redux/actions/tasks_v2'
import MediaSlider from 'components/CommonComponents/MediaSlider'
import { ResultCommments } from 'components/TasksV2/CommentsSection/ResultCommments'
import { ImagePlus, Trash2, Video } from 'lucide-react'
import { SubtaskWithUuid } from 'components/TasksV2/Insights/Content/AllContextsMediaResultsOverview'
import { extractMediaIdentifier } from 'utils'
import { RootState } from 'constants/interfaces'
import { deleteCommentsOfMedia } from 'redux/actions/comments'

interface IProps {
  images: string[]
  videos: string[]
  subtask: SubtaskWithUuid
  updatedBy: string
  retail_id_context_id: string
  recurrence_ts: string | null
  resultContextId: string
  isReadMode: boolean
  mediaType: number
  onMediaChange?: (index: number) => void
  isShowSubtaskTitle?: boolean
  currentMediaIndex?: number
  tid: string
}

export const MediaSubtaskCompleting: React.FC<IProps> = ({
  images,
  videos,
  subtask,
  retail_id_context_id,
  recurrence_ts,
  onMediaChange,
  resultContextId,
  isReadMode,
  mediaType,
  updatedBy,
  isShowSubtaskTitle = false,
  currentMediaIndex = 0,
  tid,
}) => {
  const { st_id } = subtask
  const dispatch: AppDispatch = useDispatch()
  const { cdn, rid } = useSelector((state: RootState) => state.config.config)

  const {
    uploadedImagesURL,
    uploadedVideosURL,
    handleMediaUploading,
    addMediaHandler,
    imageInputRef,
    loading,
    handleDeleteMedia,
  } = useMediaState({
    images: images ?? [],
    videos: videos ?? [],
  })

  const mediaURLs = [...(uploadedImagesURL ?? []), ...(uploadedVideosURL ?? [])]

  const isImage = mediaType === keys.SUBTASK_TYPES.IMAGE_SUBTASK

  const handleUploadMedia: any = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const data = await handleMediaUploading(e)
    if (!data) return
    const newMedia = [...mediaURLs, ...(isImage ? data.uploadedImages : data.uploadedVideos)]

    onMediaChange?.(newMedia.length - 1)

    dispatch(
      updateResults({
        [isImage ? 'images' : 'videos']: newMedia,
        tid,
        st_id,
        type: isImage ? keys.SUBTASK_TYPES.IMAGE_SUBTASK : keys.SUBTASK_TYPES.VIDEO_SUBTASKS,
        resultContextId,
        status: 2,
      })
    )
  }

  const handleDeleteMediaItem = () => {
    handleDeleteMedia(currentMediaIndex)
    const newMedia = mediaURLs.filter((_, i) => i !== currentMediaIndex)
    const status = newMedia.length > 0 ? 2 : 1
    dispatch(
      updateResults({
        [isImage ? 'images' : 'videos']: newMedia,
        tid,
        st_id,
        type: isImage ? keys.SUBTASK_TYPES.IMAGE_SUBTASK : keys.SUBTASK_TYPES.VIDEO_SUBTASKS,
        resultContextId,
        status,
      })
    )

    const contextIdRecurrenceTs = `${resultContextId}${recurrence_ts ? '_' + recurrence_ts : ''}`
    const url = mediaURLs?.length > 0 ? mediaURLs[currentMediaIndex] : ''
    const mediaHash = extractMediaIdentifier({ url, cdn, rid, uid: updatedBy })
    const stIdContextIdRecurrenceTs = `${st_id}_${contextIdRecurrenceTs}`
    const oid = `${st_id}_${contextIdRecurrenceTs}-${mediaHash}`
    if (mediaHash) {
      dispatch(deleteCommentsOfMedia([oid], [mediaHash], [stIdContextIdRecurrenceTs]))
    }
  }

  const Icon = isImage ? ImagePlus : Video

  return (
    <>
      <Flex flexDir="column" justifyContent="center" mt="20px">
        {mediaURLs.length === 0 ? (
          <Flex flexDir="column" alignItems="center" py="20px" style={{ rowGap: '20px' }}>
            <Button
              borderRadius="full"
              p="20px"
              m="auto"
              h="auto"
              bg="black"
              onClick={addMediaHandler}
              cursor="pointer"
              transition="0.2s ease-in-out"
              _hover={{ bg: 'black', transform: 'scale(1.1)' }}
              _focus={{ outline: 'none' }}
            >
              <Icon size={46} color="white" />
            </Button>
            <Text color={colors.greyDark} pb="10px" fontWeight="600">
              Add {isImage ? 'Images' : 'Videos'}
            </Text>
            <input
              type="file"
              accept={isImage ? 'image/*' : 'video/*'}
              multiple
              ref={imageInputRef}
              style={{ display: 'none' }}
              onChange={handleUploadMedia}
            />
          </Flex>
        ) : (
          <Flex w="100%" flexDir="column" pos="relative">
            {loading ? (
              <Skeleton height="300px" w="auto" />
            ) : (
              <>
                <MediaSlider
                  media={mediaURLs}
                  // openImageModalHandler={openImageModalHandler}
                  onMediaChange={onMediaChange}
                  currentMediaIndex={currentMediaIndex}
                />
                {!isReadMode && (
                  <Button
                    cursor="pointer"
                    bottom="10px"
                    right="10px"
                    p={0}
                    pos="absolute"
                    zIndex={99999}
                    bg="white"
                    w="30px"
                    height="30px"
                    minW="unset"
                    borderRadius="full"
                    transition="0.2s ease-in-out"
                    _hover={{ transform: 'scale(1.1)' }}
                    _focus={{ outline: 'none' }}
                    onClick={handleDeleteMediaItem}
                  >
                    <Trash2 size={15} color="black" />
                  </Button>
                )}
              </>
            )}
          </Flex>
        )}

        <Flex
          flexDir="column"
          mt="10px"
          mb="20px"
          alignItems="center"
          style={{
            rowGap: '10px',
          }}
        >
          {isShowSubtaskTitle && (
            <Text mt="10px" fontSize="14px" fontWeight="bold" textAlign="center">
              {subtask.title}
            </Text>
          )}
          {mediaURLs.length > 0 && !isReadMode && (
            <>
              <Button
                cursor="pointer"
                m="auto"
                h="auto"
                p="10px"
                borderRadius="full"
                bg="black"
                transition="0.2s ease-in-out"
                _hover={{ transform: 'scale(1.1)' }}
                _focus={{ outline: 'none' }}
                onClick={addMediaHandler}
              >
                <Icon size={35} cursor="pointer" color="white" />
              </Button>
              <input
                type="file"
                accept={isImage ? 'image/*' : 'video/*'}
                multiple
                ref={imageInputRef}
                style={{ display: 'none' }}
                onChange={handleUploadMedia}
              />
            </>
          )}
        </Flex>
      </Flex>

      <ResultCommments
        subtask={subtask}
        retail_id_context_id={retail_id_context_id}
        recurrence_ts={recurrence_ts}
        updatedBy={updatedBy}
        resultContextId={resultContextId}
        mediaIndex={currentMediaIndex}
        objectType={keys.COMMENT_OBJECT_TYPE.COMMENT_ON_IMAGE}
        media={mediaURLs}
        tid={tid}
      />
    </>
  )
}
