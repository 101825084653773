import { Flex, Text } from '@chakra-ui/core'
import { ControlledCustomSwitch } from 'components/TasksV2/UI/ControlledCustomSwitch'
import useLocaleText from 'components/useLocaleText'
import colors from 'constants/colors'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

interface Props {
  subtaskPath: string
}

export const WidgetIsRequiredSwitch: React.FC<Props> = ({ subtaskPath }) => {
  const { control, setValue } = useFormContext()
  const is_required = useLocaleText('required')

  return (
    <Flex w="100%" alignItems="center" justifyContent="flex-end" style={{ columnGap: '10px' }}>
      <Text color={colors.analyticGrey} fontSize="12px">
        {is_required}
      </Text>
      <Controller
        as={ControlledCustomSwitch}
        name={`${subtaskPath}.is_required`}
        control={control}
        defaultValue={true}
        valueName="isChecked"
        size="sm"
        onChange={([event]) => {
          const newValue = event.target.checked
          setValue(`${subtaskPath}.is_required`, newValue)
          return newValue
        }}
      />
    </Flex>
  )
}
