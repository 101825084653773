// hooks/useDeadlineDatePicker.ts
import { useState, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { applyTimeToDate } from '../utils'
import dayjs from 'dayjs'

const useDeadlineDatePicker = () => {
  const { watch, setValue, triggerValidation } = useFormContext()

  const deadline = watch('deadline') ? new Date(watch('deadline')) : null
  const fromTs = watch('from_ts') ? new Date(watch('from_ts')) : new Date()
  const frequencyTime = watch('frequency_time')
  const isRecurring = watch('is_recurring')

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)

  const deadlineDate = useMemo(() => (deadline ? dayjs(deadline).format('YYYY-MM-DD') : ''), [deadline])
  const deadlineTime = useMemo(() => (deadline ? dayjs(deadline).format('HH:mm') : ''), [deadline])

  const handleDateChange = async (date: Date | null) => {
    if (date) {
      const updatedDeadline = dayjs(date)
        .set('hour', dayjs(deadline || new Date()).hour())
        .set('minute', dayjs(deadline || new Date()).minute())
        .toISOString()

      if (frequencyTime) {
        const updatedDate = applyTimeToDate(new Date(updatedDeadline), frequencyTime)
        setValue('deadline', updatedDate.toISOString())
      } else {
        setValue('deadline', updatedDeadline)
      }
      await triggerValidation('deadline')
    }
  }

  const handleTimeChange = (time: string) => {
    const [hours, minutes] = time.split(':').map(Number)
    const updatedDeadline = dayjs(deadline || new Date())
      .set('hour', hours)
      .set('minute', minutes)
      .toISOString()

    setValue('deadline', updatedDeadline)
  }

  const openDatePicker = () => setIsDatePickerOpen(true)
  const closeDatePicker = () => setIsDatePickerOpen(false)

  return {
    deadline,
    fromTs,
    isRecurring,
    isDatePickerOpen,
    handleDateChange,
    handleTimeChange,
    deadlineDate,
    deadlineTime,
    openDatePicker,
    closeDatePicker,
  }
}

export default useDeadlineDatePicker
