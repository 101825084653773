import { getTreeNodesIds } from 'components/CommonComponents/GroupsTree/utils'
import keys from 'constants/keys'
import { isEmpty } from 'lodash'
import { useMemo, useState } from 'react'
import { getNamesForTooltip, getSplittedGroupNames } from '../components/NewsFeed/PostModal/utils'
import { useSelector } from 'react-redux'
import { ITag, RootState } from 'constants/interfaces'

interface IUsePostAudienceState {
  users?: string[]
  tags?: ITag[]
  tagIds?: string[]
  groups?: string[]
  postType?: number
  selectedOptionType: string
  isCalculateGroupsChildren?: boolean
}

export const useAudienceState = ({
  users = [],
  groups = [],
  tags = [],
  tagIds = [],
  postType,
  selectedOptionType,
  isCalculateGroupsChildren = true,
}: IUsePostAudienceState) => {
  const uid = useSelector((state: RootState) => state.auth.uid!)
  const { retailUsersObject, retail_users } = useSelector((state: RootState) => state.config.config)
  const { groups: treeGroups, users: treeUsers } = useSelector(
    (state: RootState) => state.config.userWritePermittedContext
  )
  const { groups: treeTasksGroups, users: treeTasksUsers } = useSelector(
    (state: RootState) => state.config.userWriteTaskPermissions
  )

  const targetGroups = postType === keys.POST_TYPE.POLL || selectedOptionType === 'POLL' ? treeTasksGroups : treeGroups
  const targetUsers = postType === keys.POST_TYPE.POLL || selectedOptionType === 'POLL' ? treeTasksUsers : treeUsers

  const [selectedUsersUids, setSelectedUsersUids] = useState<string[]>(users ?? [])

  const getGroupsChildren = useMemo(() => {
    return groups && groups?.length > 0 ? groups.map((group: any) => getTreeNodesIds(targetGroups, group)).flat(1) : []
  }, [groups, targetGroups])

  const [selectedGroupsIds, setSelectedGroupsIds] = useState<string[]>(
    postType !== keys.POST_TYPE.GROUP_POST && isCalculateGroupsChildren
      ? getGroupsChildren.length > 0
        ? getGroupsChildren
        : []
      : groups ?? []
  )

  const selectedGroupsIdsSet = useMemo(() => Array.from(new Set(selectedGroupsIds)), [selectedGroupsIds])

  const [groupsToPayload, setGroupsToPayload] = useState<string[]>(groups && groups.length > 0 ? groups : [])

  const [selectedTagsIds, setSelectedTagsIds] = useState<string[]>(tagIds ?? [])

  const namesForTooltip = useMemo(() => {
    if (!isEmpty(targetGroups) && retailUsersObject) {
      return getNamesForTooltip({
        groups: targetGroups,
        retailUsersObject,
        selectedGroupsIds: selectedGroupsIdsSet,
        groupsToPayload,
        selectedUsersUids,
        selectedOptionType,
        tags,
        selectedTagsIds,
      })
    } else {
      return []
    }
  }, [
    groupsToPayload,
    retailUsersObject,
    selectedGroupsIdsSet,
    selectedOptionType,
    selectedTagsIds,
    selectedUsersUids,
    tags,
    targetGroups,
  ])

  const splittedGroupNames = useMemo(() => {
    return getSplittedGroupNames(namesForTooltip)
  }, [namesForTooltip])

  const filteredRerailUsersByTreeUsers = useMemo(() => {
    const sortedUsers = retail_users
      .filter((user) => targetUsers?.includes(user.uid) && user.uid !== uid)
      .sort((a: any, b: any) => {
        const firstNameComparison = a?.first_name?.localeCompare(b?.first_name)
        if (firstNameComparison === 0) {
          return a?.last_name?.localeCompare(b?.last_name)
        }

        return firstNameComparison
      })

    return sortedUsers
  }, [retail_users, targetUsers, uid])

  const clearAudienceState = () => {
    setSelectedUsersUids([])
    setSelectedGroupsIds([])
    setGroupsToPayload([])
    setSelectedTagsIds([])
  }

  return {
    selectedUsersUids,
    setSelectedUsersUids,
    selectedGroupsIds: selectedGroupsIdsSet,
    setSelectedGroupsIds,
    selectedTagsIds,
    setSelectedTagsIds,
    groupsToPayload,
    setGroupsToPayload,
    splittedGroupNames,
    filteredRerailUsersByTreeUsers,
    namesForTooltip,
    treeUsers: targetUsers,
    treeGroups: targetGroups,
    clearAudienceState,
  }
}
