/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { handleLogin } from 'redux/actions/auth'
import { useForm } from 'react-hook-form'
import { FormControl, Input, Button, useToast, InputGroup, Flex, Box, Text } from '@chakra-ui/core'
import { AuthState, RootState } from 'constants/interfaces'
import isSolid from 'is-solid'
import LocaleText from 'components/LocaleText'
import useLocaleText from 'components/useLocaleText'
import { LoginWrapper } from 'components/Login/LoginWrapper'
import { persistor } from '../config/redux'
import keys from 'constants/keys'
import { PasswordEyeToggle } from 'components/Login/PasswordEyeToggle'
import { HiLockClosed } from 'react-icons/hi'
import { DividerWithText } from 'components/CommonComponents/DividerWithText'
import NavigationButton from 'components/Login/NavigationButton'

interface Inputs {
  example: string
  exampleRequired: string
}
interface LoginProps extends AuthState {
  handleLogin: any
  locale?: string
}

const Login = ({ handleLogin, isLoggedIn, isLoginLoading, isError }: LoginProps) => {
  const navigate = useNavigate()
  const { handleSubmit, errors, register } = useForm<Inputs>()
  const userNameText = useLocaleText('login_username')
  const passwordText = useLocaleText('login_password')
  const continueSSO = useLocaleText('continue_sso')
  const continue_otp = useLocaleText('continue_otp')

  const or = useLocaleText('or')
  const [isShow, setShow] = useState(false)

  const toast = useToast()
  const onSubmit = (data: any) => {
    if (isSolid(data.user_id) && isSolid(data.password)) {
      handleLogin(data.user_id, data.password, navigate)
      persistor.persist()
    } else {
      toast({
        title: 'Add username and password.',
        status: 'warning',
        duration: 9000,
        isClosable: true,
      })
    }
  }
  const handleForgotPassword = () => {
    navigate(keys.ROUTE_NAMES.FORGOT_PASSWORD)
  }

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Login failed',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }, [isError])

  if (isLoggedIn) {
    return <Navigate to={keys.ROUTE_NAMES.NEWS_FEED} />
  }
  return (
    <LoginWrapper
      header={
        <Text fontSize="32px" textAlign="center" mb="20px">
          <LocaleText text={'login_page_welcome'} />
        </Text>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl>
          <Input
            id="user_id"
            name="user_id"
            placeholder={userNameText}
            ref={register}
            borderRadius="20px"
            borderBottom="none"
            borderBottomLeftRadius={0}
            borderBottomRightRadius={0}
          />
          <InputGroup>
            <Input
              id="password"
              name="password"
              placeholder={passwordText}
              ref={register}
              borderRadius="20px"
              borderTopLeftRadius={0}
              borderTopRightRadius={0}
              type={!isShow ? 'password' : 'text'}
            />
            <PasswordEyeToggle isShow={isShow} setShow={setShow} />
          </InputGroup>

          {errors.exampleRequired && <span>This field is required</span>}
        </FormControl>

        <Button
          width="100%"
          borderRadius="20px"
          mt={4}
          variantColor="pink"
          variant="outline"
          isLoading={isLoginLoading}
          type="submit"
        >
          <LocaleText text="login" />
        </Button>
        <Button
          width="100%"
          mt={4}
          onClick={handleForgotPassword}
          style={{ background: 'none', color: '#d53f8c', borderRadius: '20px' }}
        >
          <LocaleText text="forgot_password" />
        </Button>
        <Box my="30px">
          <DividerWithText text={or} />
        </Box>

        <Flex flexDirection="column" style={{ rowGap: '20px' }}>
          <NavigationButton navigateTo={keys.ROUTE_NAMES.LOGIN_OTP} buttonText={continue_otp} />
          <NavigationButton navigateTo={keys.ROUTE_NAMES.LOGIN_SSO} buttonText={continueSSO} icon={<HiLockClosed />} />
        </Flex>
      </form>
    </LoginWrapper>
  )
}

const mapStateToProps = ({ auth, general }: RootState) => ({
  isLoggedIn: auth.isLoggedIn,
  isError: auth.isError,
  isLoginLoading: auth.isLoginLoading,
  locale: general.locale,
  token: auth.token,
})

export default connect(mapStateToProps, { handleLogin })(Login)
