import { Avatar, Flex, Input } from '@chakra-ui/core'
import React, { memo } from 'react'
import { CreatorDetails } from './CreatorDetails'
import { ChooseAudienceButton } from './ChooseAudienceButton'
import { PostModalSwitcher } from '../PostModalSwitcher'
import { OptionType } from '../constants'
import { MdOutlineAddPhotoAlternate } from 'react-icons/md'
import strings from 'constants/strings'

interface IProps {
  isEditing: boolean
  avatar: string
  first_name: string
  last_name: string
  user_role: number
  clearState: () => void
  splittedGroupNames: string
  namesForTooltip: string[]
  selectedOptionType: string
  isSwitchPopActive: boolean
  onTogglePopSwitch: () => void
  isSwitchAnnouncementActive: boolean
  onToggleAnnouncementSwitch: () => void
  onOpenAudienceModal: VoidFunction
  uploadedImagesURL?: string[]
  imageInputRef?: React.RefObject<HTMLInputElement>
  handleSingleMediaUploading: (e: React.ChangeEvent<HTMLInputElement>) => void
}
export const AudienceSelectionBlock = memo(
  ({
    avatar,
    isEditing,
    first_name,
    last_name,
    user_role,
    clearState,
    splittedGroupNames,
    namesForTooltip,
    selectedOptionType,
    isSwitchPopActive,
    onTogglePopSwitch,
    isSwitchAnnouncementActive,
    onToggleAnnouncementSwitch,
    onOpenAudienceModal,
    uploadedImagesURL,
    imageInputRef,
    handleSingleMediaUploading,
  }: IProps) => {
    return (
      <Flex alignItems="flex-start" justifyContent="space-between" flexDir="column" h="90px">
        <Flex justifyContent="space-between" w="100%" alignItems="flex-start">
          {selectedOptionType !== OptionType.CHAT ? (
            <>
              <Flex flexDir="column" style={{ rowGap: '10px' }}>
                <Flex alignItems="center">
                  <Avatar src={avatar} />
                  <Flex flexDir="column" mx="10px">
                    <CreatorDetails firstName={first_name} lastName={last_name} userRole={user_role} />
                  </Flex>
                </Flex>
              </Flex>
              <Flex flexDir="column" alignItems="flex-end" style={{ rowGap: '10px' }}>
                <ChooseAudienceButton
                  namesForTooltip={namesForTooltip}
                  groupNames={splittedGroupNames}
                  clearSelections={clearState}
                  onOpenAudienceModal={onOpenAudienceModal}
                  isEditing={isEditing}
                />

                {selectedOptionType === OptionType.POP && (
                  <PostModalSwitcher
                    isSwitchActive={isSwitchPopActive}
                    onToggleSwitch={onTogglePopSwitch}
                    literal="create_pop_as_post"
                  />
                )}
              </Flex>
            </>
          ) : (
            <>
              <Flex
                justifyContent={'center'}
                alignItems={'center'}
                cursor="pointer"
                w={'48px'}
                h={'48px'}
                bg="#A0AEC0"
                borderRadius="full"
                onClick={() => imageInputRef?.current?.click()}
              >
                {uploadedImagesURL?.[0] ? (
                  <Avatar w="48px" h="48px" src={uploadedImagesURL?.[0]} />
                ) : (
                  <MdOutlineAddPhotoAlternate style={{ width: '35px', height: '35px', fill: 'white' }} />
                )}
              </Flex>
              <Input
                ref={imageInputRef}
                accept={`${strings.SERVER_IMAGE_FORMATS},${strings.SERVER_VIDEO_FORMATS}`}
                type="file"
                multiple
                id="upload-image-button"
                style={{ display: 'none' }}
                onChange={handleSingleMediaUploading}
              />
              <Flex flexDir="column" alignItems="flex-end" style={{ rowGap: '10px' }}>
                <ChooseAudienceButton
                  namesForTooltip={namesForTooltip}
                  groupNames={splittedGroupNames}
                  clearSelections={clearState}
                  onOpenAudienceModal={onOpenAudienceModal}
                  isEditing={isEditing}
                />
                {!isEditing && (
                  <PostModalSwitcher
                    isSwitchActive={isSwitchAnnouncementActive}
                    onToggleSwitch={onToggleAnnouncementSwitch}
                    literal="create_announcement_chat"
                  />
                )}
              </Flex>
            </>
          )}
        </Flex>
      </Flex>
    )
  }
)
