import React from 'react'
import { Input, InputProps } from '@chakra-ui/core'
import { useSelector } from 'react-redux'
import { RootState } from 'constants/interfaces'

interface CustomTimePickerProps extends Omit<InputProps, 'onChange'> {
  value: string
  onChange: (value: string) => void
}

const CustomTimePicker: React.FC<CustomTimePickerProps> = ({ value, onChange }) => {
  const theme = useSelector((state: RootState) => state?.general?.theme)
  return (
    <Input
      type="time"
      value={value}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
      border="none"
      borderRadius="15px"
      padding="8px"
      fontSize="16px"
      color="gray.700"
      _hover={{
        borderColor: 'gray.400',
      }}
      _focus={{
        outline: 'none',
        boxShadow: 'none',
      }}
      css={{
        '&::-webkit-calendar-picker-indicator': {
          color: theme?.elementsColor,
          borderRadius: '50%',
          padding: '5px',
        },
        '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
          display: 'none',
        },
        '&::selection': {
          background: 'transparent',
        },
      }}
    />
  )
}

export default CustomTimePicker
