import { Flex } from '@chakra-ui/core'
import { css } from '@emotion/core'
import { Images, Paperclip } from 'lucide-react'
import React from 'react'
import { useSelector } from 'react-redux'
import { isRtlSelector } from 'redux/selectors/general'

interface IProps {
  onFileUploadClick: () => void
  onImageUploadClick: () => void
}
export const AddMediaButtonts: React.FC<IProps> = ({ onFileUploadClick, onImageUploadClick }) => {
  const isRtl = useSelector(isRtlSelector)
  return (
    <Flex
      alignItems="flex-start"
      pos="absolute"
      bottom="10px"
      right={isRtl ? 'unset' : '10px'}
      left={isRtl ? '10px' : 'unset'}
      style={{ columnGap: '10px' }}
      css={css`
        & > * {
          transition: transform 0.3s ease, margin 0.3s ease;
        }

        & > *:hover {
          transform: scale(1.2);
        }
      `}
    >
      <Flex
        onClick={onFileUploadClick}
        borderRadius="full"
        bg="black"
        w="30px"
        h="30px"
        alignItems="center"
        justifyContent="center"
        cursor="pointer"
        boxShadow="0px 0px 2px 1px white"
      >
        <Paperclip color="white" size={15} />
      </Flex>
      <Flex
        onClick={onImageUploadClick}
        borderRadius="full"
        bg="black"
        w="30px"
        h="30px"
        alignItems="center"
        justifyContent="center"
        cursor="pointer"
        boxShadow="0px 0px 2px 1px white"
      >
        <Images color="white" size={15} />
      </Flex>
    </Flex>
  )
}
