import React, { useCallback, useMemo, useState } from 'react'
import { Button, Flex, Text } from '@chakra-ui/core'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import { useSelector } from 'react-redux'
import { isRtlSelector } from 'redux/selectors/general'
import { RootState } from 'constants/interfaces'
import useLocaleText from 'components/useLocaleText'
import { StepType, useFormStepContext } from '../FormStepContext'
import LocaleText from 'components/LocaleText'
import ConfirmationPopup from 'components/CommonComponents/ConfirmationPopup'

export const Header = ({ onClose }: { onClose: () => void }) => {
  const isRTL = useSelector(isRtlSelector)
  const theme = useSelector((state: RootState) => state.general.theme)
  const isLoading = useSelector((state: RootState) => state.tasks_v2.isLoading)
  const t_next_step = useLocaleText('t_next_step')
  const t_createTask = useLocaleText('post')
  const t_save = useLocaleText('save')

  const card_confirm_text = useLocaleText('card_confirm_text')
  const card_confirm_desc = useLocaleText('card_confirm_desc')
  const yes = useLocaleText('yes')
  const no = useLocaleText('no')

  const { sumbitStep, goToPreviousStep, stepStack, isMediaUploading } = useFormStepContext()
  const [isConfirmPopupVisible, setIsConfirmPopupVisible] = useState(false)

  const hadnlePreviousStep = useCallback(() => {
    if (stepStack.length === 1) {
      setIsConfirmPopupVisible(true)
    } else {
      goToPreviousStep()
    }
  }, [goToPreviousStep, stepStack.length])

  const handleCloseConfirm = useCallback(() => {
    onClose()
    setIsConfirmPopupVisible(false)
  }, [onClose])

  const handleCancelConfirm = useCallback(() => {
    setIsConfirmPopupVisible(false)
  }, [])

  const currentStep = useMemo(() => stepStack[stepStack.length - 1], [stepStack])
  const backLiteral = stepStack.length === 1 ? 'cancel' : 'header_button_back'

  const submitButtonType = useMemo(() => {
    switch (currentStep) {
      case StepType.SUBTASK_TASK_CREATION:
        return (
          <Flex
            alignItems="center"
            cursor={isMediaUploading ? 'not-allowed' : 'pointer'}
            opacity={isMediaUploading ? 0.5 : 1}
            onClick={!isMediaUploading ? sumbitStep : undefined}
          >
            <Text fontSize="14px">{t_next_step}</Text>
            {isRTL ? <ChevronLeft /> : <ChevronRight />}
          </Flex>
        )
      case StepType.TASK_SETTINGS:
        return (
          <Button
            bg={theme?.elementsColor}
            color="white"
            _focus={{ outline: 'none' }}
            borderRadius="20px"
            onClick={sumbitStep}
            isLoading={isLoading}
          >
            <Text>{t_createTask}</Text>
          </Button>
        )
      case StepType.TIME_FRAME_SELECTION:
        return (
          <Button
            bg={theme?.elementsColor}
            color="white"
            _focus={{ outline: 'none' }}
            borderRadius="20px"
            onClick={sumbitStep}
            isLoading={isLoading}
          >
            <Text fontSize="14px">{t_save}</Text>
          </Button>
        )

      default:
        break
    }
  }, [currentStep, isLoading, isMediaUploading, isRTL, sumbitStep, t_createTask, t_next_step, t_save, theme])

  return (
    <Flex alignItems="center" justifyContent="space-between" bg="white" h="60px">
      <Flex alignItems="center" onClick={hadnlePreviousStep} cursor="pointer">
        {isRTL ? <ChevronRight /> : <ChevronLeft />}
        <Text fontSize="14px">
          <LocaleText text={backLiteral} />
        </Text>
      </Flex>

      {submitButtonType}

      <ConfirmationPopup
        isOpen={isConfirmPopupVisible}
        onConfirm={handleCloseConfirm}
        onCancel={handleCancelConfirm}
        title={card_confirm_text}
        description={card_confirm_desc}
        cancelButtonText={no}
        confirmButtonText={yes}
        isLoading={false}
      />
    </Flex>
  )
}
