import { ITasksOverviewState } from 'constants/interfaces'
import { actionTypes } from '../actions/tasksOverview'

export const initialState: ITasksOverviewState = {
  appliedFilter: null,
  renderFilter: null,
  overviewData: null,
  filteredData: null,
  isFetchingData: true,
  isSortedByDoneRation: false,
  activeStore: '',
  maxGroupLevel: 0,
  choosedGroup: 'all',
  isSingleGroup: false,
  dateFilter: null,
}

const tasksOverviewReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_IS_SINGLE_GROUP:
      return {
        ...state,
        isSingleGroup: action.payload,
      }
    case actionTypes.SET_MAX_GROUP_LEVEL:
      return {
        ...state,
        maxGroupLevel: action.payload,
      }
    case actionTypes.SET_ACTIVE_GROUP_ID:
      return {
        ...state,
        choosedGroup: action.groupId,
      }
    case actionTypes.SET_ACTIVE_STORE:
      return {
        ...state,
        activeStore: action.storeId,
      }
    case actionTypes.SET_INITIAL_FILTER:
      return {
        ...state,
        appliedFilter: action.payload,
        renderFilter: action.payload,
      }

    case actionTypes.UPDATE_FILTER:
      return {
        ...state,
        renderFilter: {
          ...action.updatedFilter,
        },
      }

    case actionTypes.SET_RENDER_FILTER:
      return {
        ...state,
        renderFilter: action.payload,
      }
    case actionTypes.SET_FILTER:
      return {
        ...state,
        appliedFilter: {
          ...action.appliedFilter,
        },
        isSortedByDoneRation: false,
        filteredData: action.payload,
      }
    case actionTypes.SET_INITIAL_DATA:
      return {
        ...state,
        overviewData: {
          ...action.data,
        },
      }
    case actionTypes.SET_FETCHING:
      return {
        ...state,
        isFetchingData: action.payload,
      }
    case actionTypes.SORT_BY_DONE_RATIO:
      return {
        ...state,
        isSortedByDoneRation: true,
        filteredData: {
          ...state.filteredData,
          groups: action.payload,
        },
      }
    case actionTypes.SET_DATE_FILTER:
      return {
        ...state,
        dateFilter: action.payload,
      }
    default:
      return state
  }
}

export default tasksOverviewReducer
