// SubtasksList.tsx
import React from 'react'
import { Flex } from '@chakra-ui/core'
import keys from 'constants/keys'
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd'
import PollWidget from './Widgets/Poll'
import ImageWidget from './Widgets/Image'
import VideoWidget from './Widgets/Video'
import LinkWidget from './Widgets/Link'
import { Content, Subtask, useFormStepContext } from '../../FormStepContext'
import OpenQuestionWidget from './Widgets/OpenQuestion'
import DescriptionWidget from './Widgets/Description'

interface SubtasksListProps {
  widgetRefs: React.MutableRefObject<(HTMLDivElement | null)[]>
  fields: (Subtask | Content)[]
}

const SubtasksList: React.FC<SubtasksListProps> = ({ widgetRefs, fields }) => {
  const {
    subtaskActions: { remove, move },
    isEdit,
  } = useFormStepContext()

  const handleDragEnd = (result: DropResult) => {
    if (isEdit) return
    const { source, destination } = result
    if (!destination || source.index === destination.index) return
    move?.(source.index, destination.index)
  }

  const handleRemoveSubtask = (index: number) => {
    remove?.(index)
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="subtasks" isDropDisabled={isEdit}>
        {(provided) => (
          <Flex
            direction="column"
            style={{ rowGap: '45px' }}
            mt="35px"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {fields.map((field, index) => {
              const subtaskPath = `sub_tasks[${index}]`
              return (
                <Draggable key={field.id} draggableId={field.id!} index={index} isDragDisabled={isEdit}>
                  {(provided, snapshot) => (
                    <div
                      ref={(el) => {
                        provided.innerRef(el)
                        widgetRefs.current[index + 1] = el
                      }}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        opacity: field.type !== keys.SUBTASK_TYPES.DESCRIPTION && isEdit ? 0.5 : 1,
                        pointerEvents: field.type !== keys.SUBTASK_TYPES.DESCRIPTION && isEdit ? 'none' : 'auto',
                        ...provided.draggableProps.style,
                      }}
                    >
                      {field.type === keys.SUBTASK_TYPES.DESCRIPTION && (
                        <DescriptionWidget
                          index={index}
                          isDragging={snapshot.isDragging}
                          field={field as Content}
                          subtaskPath={subtaskPath}
                          remove={() => handleRemoveSubtask(index)}
                        />
                      )}
                      {field.type === keys.SUBTASK_TYPES.POLL_SUBTASK && (
                        <PollWidget
                          index={index}
                          isDragging={snapshot.isDragging}
                          field={field as Subtask}
                          subtaskPath={subtaskPath}
                          remove={() => handleRemoveSubtask(index)}
                        />
                      )}
                      {field.type === keys.SUBTASK_TYPES.IMAGE_SUBTASK && (
                        <ImageWidget
                          index={index}
                          isDragging={snapshot.isDragging}
                          subtaskPath={subtaskPath}
                          field={field as Subtask}
                          remove={() => handleRemoveSubtask(index)}
                        />
                      )}
                      {field.type === keys.SUBTASK_TYPES.VIDEO_SUBTASKS && (
                        <VideoWidget
                          index={index}
                          subtaskPath={subtaskPath}
                          isDragging={snapshot.isDragging}
                          field={field as Subtask}
                          remove={() => handleRemoveSubtask(index)}
                        />
                      )}
                      {field.type === keys.SUBTASK_TYPES.LINK_SUBTASK && (
                        <LinkWidget
                          subtaskPath={subtaskPath}
                          index={index}
                          isDragging={snapshot.isDragging}
                          field={field as Subtask}
                          remove={() => handleRemoveSubtask(index)}
                        />
                      )}
                      {field.type === keys.SUBTASK_TYPES.OPEN_QUESTION && (
                        <OpenQuestionWidget
                          subtaskPath={subtaskPath}
                          index={index}
                          isDragging={snapshot.isDragging}
                          field={field as Subtask}
                          remove={() => handleRemoveSubtask(index)}
                        />
                      )}
                    </div>
                  )}
                </Draggable>
              )
            })}
            {provided.placeholder}
          </Flex>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default SubtasksList
