import React, { useCallback } from 'react'
import { FixedSizeList as List } from 'react-window'
import { useSelector } from 'react-redux'
import { Box } from '@chakra-ui/core'
import { RootState } from 'constants/interfaces'
import { ContextItem } from './ContextItem'
import { isRtlSelector } from 'redux/selectors/general'
import { NoResults } from 'components/CommonComponents/GroupsTree/NoResults'

interface IProps {
  onOpen: VoidFunction
  tid: string
  recurrenceTs: string | null
  isThereOverviewButtons: boolean
  contexts: {
    contextId: string
    status: number
    taskContextId: string
    name: string
    userDefaultGroup: string
  }[]
}

export const ContextsList: React.FC<IProps> = ({ onOpen, contexts, tid, recurrenceTs, isThereOverviewButtons }) => {
  const { deepDiveInsights } = useSelector((state: RootState) => state.tasks_v2)
  const isRtl = useSelector(isRtlSelector)
  const { insights } = deepDiveInsights

  const Row = useCallback(
    ({ index, style }: { index: number; style: React.CSSProperties }) => {
      const item = contexts[index]

      if (!item) {
        return null
      }

      const { contextId, status, name, taskContextId, userDefaultGroup } = item
      const typeCounts = insights[contextId] ? insights[contextId].typeCounts : null

      return (
        <Box
          style={{
            ...style,
            paddingRight: isRtl ? 0 : 15,
            paddingLeft: isRtl ? 15 : 0,
            paddingTop: 5,
          }}
        >
          <ContextItem
            key={contextId}
            contextId={contextId}
            userDefaultGroup={userDefaultGroup}
            tid={tid}
            taskContextId={taskContextId}
            statusValue={status}
            typeCounts={typeCounts}
            onOpen={onOpen}
            recurrenceTs={recurrenceTs ?? null}
            name={name}
          />
        </Box>
      )
    },
    [contexts, insights, isRtl, tid, onOpen, recurrenceTs]
  )
  const height = window.innerHeight - (isThereOverviewButtons ? 400 : 345)
  return (
    <>
      {contexts.length === 0 ? (
        <NoResults />
      ) : (
        <List
          height={height}
          itemCount={contexts.length}
          itemSize={105}
          width="calc(100% + 15px)"
          style={{
            direction: isRtl ? 'rtl' : 'ltr',
            marginBottom: '-20px',
            paddingBottom: '20px',
          }}
        >
          {Row}
        </List>
      )}
    </>
  )
}
