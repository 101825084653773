import { Flex } from '@chakra-ui/core'
import React, { useEffect, useRef } from 'react'
import ChooseAudience from './ChooseAudience'
import TaskTypeSelection from './TaskTypeSelection'
import { TimeFramePreview } from './TimeFramePreview'
import { FormContext, useForm } from 'react-hook-form'
import { StepType, TaskAudience, useFormStepContext } from '../../FormStepContext'
import { IndividualResultsSwitch } from './IndividualResultsSwitch'
import dayjs from 'dayjs'

const TaskSettingStep = () => {
  const { setCurrentFormSubmit, updateFormData, formData, submitAllForms } = useFormStepContext()
  const fromTsFormContext = formData[StepType.TIME_FRAME_SELECTION]?.from_ts
  const deadlineFormContext = formData[StepType.TIME_FRAME_SELECTION]?.deadline

  const defaultValues = formData[StepType.TASK_SETTINGS] || {
    audience: {
      groups: [],
      users: [],
      tags: [],
    },
    view: 4,
    is_individual_results: false,
    from_ts: fromTsFormContext ? fromTsFormContext : dayjs().toISOString(),
    deadline: deadlineFormContext ? deadlineFormContext : dayjs(dayjs()).add(8, 'hour').toISOString(),
  }

  const methods = useForm({
    defaultValues,
  })

  const { handleSubmit, getValues } = methods

  const currentValues = getValues() as {
    audience: TaskAudience
    view: number | null
    is_individual_results: boolean
    from_ts: string
    deadline: string
  }

  useEffect(() => {
    setCurrentFormSubmit(() =>
      handleSubmit((data) => {
        updateFormData(StepType.TASK_SETTINGS, data)
        submitAllForms()
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const prevValuesRef = useRef<any>()
  useEffect(() => {
    const values = currentValues
    if (JSON.stringify(values) !== JSON.stringify(prevValuesRef.current)) {
      updateFormData(StepType.TASK_SETTINGS, values)
      prevValuesRef.current = values
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods, updateFormData])

  return (
    <FormContext {...methods}>
      <Flex flexDir="column" style={{ rowGap: '40px' }}>
        <ChooseAudience />
        <TaskTypeSelection />
        <TimeFramePreview />
        <IndividualResultsSwitch />
      </Flex>
    </FormContext>
  )
}

export default TaskSettingStep
