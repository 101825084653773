import React, { memo, useEffect, useState } from 'react'
import { CircularProgress, CircularProgressLabel, Text } from '@chakra-ui/core'
import colors from 'constants/colors'

interface ICircularProgressProps {
  isLoading: boolean
  itemsCount: number
  symbol?: string
  trackColor?: string
  progressColor?: string
  thinkness?: number
  textColor?: string
  text?: string
}
export const AnimatedCircularProgress: React.FC<ICircularProgressProps> = memo(
  ({ isLoading, itemsCount, symbol = '', trackColor, progressColor, thinkness, textColor, text = '' }) => {
    const [progressValue, setProgressValue] = useState<number>(0)
    const [displayedCount, setDisplayedCount] = useState<number>(0)

    useEffect(() => {
      if (!isLoading) {
        const totalItems = itemsCount
        const duration = 500
        const steps = 100
        const stepInterval = duration / steps

        let progress = 0
        let count = 0

        const progressStep = 100 / steps
        const countStep = totalItems / steps

        const interval = setInterval(() => {
          progress += progressStep
          count += countStep

          if (progress >= 100) {
            progress = 100
            count = totalItems
            clearInterval(interval)
          }

          setProgressValue(progress)
          setDisplayedCount(Math.min(Math.ceil(count), totalItems))
        }, stepInterval)

        return () => clearInterval(interval)
      }
    }, [isLoading, itemsCount])

    return (
      <CircularProgress
        value={isLoading ? 0 : progressValue}
        size="200px"
        thickness={thinkness || 0.05}
        css={{
          '& [data-progress-track]': {
            stroke: trackColor || colors.greyLight4,
          },
          '& [data-progress-indicator]': {
            stroke: progressColor || colors.analyticGrey,
          },
        }}
      >
        <CircularProgressLabel
          color={textColor || colors.analyticGrey}
          fontWeight="500"
          display="flex"
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          w="100%"
        >
          <>
            <Text pos="relative">
              <Text color={colors.analyticGrey} as="span">
                {isLoading ? 0 : displayedCount}
              </Text>
              <Text as="span" color={colors.analyticGrey}>
                {symbol}
              </Text>
            </Text>
            {text && (
              <Text pos="absolute" top="60px" fontSize="16px" color={colors.analyticGrey} fontWeight="500">
                {text}
              </Text>
            )}
          </>
        </CircularProgressLabel>
      </CircularProgress>
    )
  }
)
