import { ChatPreview } from 'components/ChatsPreviews/ChatPreview'
import { AppDispatch } from 'config/redux'
import { RootState } from 'constants/interfaces'
import React, { memo, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getChat } from 'redux/actions/feed'
import { ServiceCall } from 'redux/actions/serviceCall'
import { calculateViewContextForData, SERVICE_CALL_STATUSES } from './utils'
import { Post } from 'redux/reducers/feed'
import { Flex, Spinner } from '@chakra-ui/core'
import { useLocation } from 'react-router-dom'

interface IProps {
  serviceCall: ServiceCall
}
export const ServiceCallChat: React.FC<IProps> = memo(({ serviceCall }) => {
  const { ref_to_chat, supervisor, assignee } = serviceCall
  const dispatch: AppDispatch = useDispatch()
  const location = useLocation()
  const { chats, fetching } = useSelector((state: RootState) => state.feed)
  const { uid } = useSelector((state: RootState) => state.auth)
  const {
    activeGroupID,
    config: { tags },
  } = useSelector((state: RootState) => state.config)
  const existedChat = chats.find((chat) => chat.post_id === ref_to_chat) ?? null
  const [chat, setChat] = useState<Post | null>(existedChat)
  const isGroupChatPage = location.pathname.includes('group-chat')

  const handleGetChat = useCallback(async () => {
    if (!ref_to_chat || chat || serviceCall.status === SERVICE_CALL_STATUSES.CLOSED) {
      return
    }
    const contextId = calculateViewContextForData({
      uid: uid!,
      activeGroupID: activeGroupID!,
      tags,
      supervisor,
      assignee,
    })

    const response = await dispatch(getChat({ chat_id: ref_to_chat, contextId, type: 2 }))
    setChat(response)
  }, [activeGroupID, assignee, chat, dispatch, ref_to_chat, serviceCall.status, supervisor, tags, uid])

  useEffect(() => {
    handleGetChat()
  }, [handleGetChat])

  return (
    <Flex alignItems="center" justifyContent="center">
      {fetching ? (
        <Spinner mt="24px" thickness="2px" speed=".5s" emptyColor="transparent" color="gray" size="xl" />
      ) : (
        <> {chat && <ChatPreview chat={chat} isAllowToNavigate={!isGroupChatPage} />}</>
      )}
    </Flex>
  )
})
