import BaseModal from 'components/CommonComponents/BaseModal'
import { RootState } from 'constants/interfaces'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { ActionSuccessContent } from './ActionSuccessContent'
import { ActionFailedContent } from './ActionFailedContent'
import { DeleteContent } from './DeleteUserContent'
import { DeleteGroupContent } from './DeleteGroupContent'
import { CancelAction } from './CancelAction'
import { ConfirmActionContent } from './ConfirmActionContent'

interface IProps {
  isOpen: boolean
  onClose: () => void
  watch?: any
  closeOnOverlayClick?: boolean
  selectedIds?: string[]
  handleNextStep?: () => void
  row?: any
  mode: string
  actionLiteral?: string
  route?: string
  groupToDelete?: string
  onSuccessGroupChange?: () => void
  handleSaveGroups?: () => void
  defautGroup?: string
  handleAddUser?: () => void
  handleEditUser?: () => void
  handleFinish?: () => void
  setGroupToDelete?: (group: string) => void
}

const UsersActionModal = memo(
  ({
    isOpen,
    onClose,
    closeOnOverlayClick,
    selectedIds,
    handleNextStep,
    mode,
    row,
    actionLiteral,
    route,
    groupToDelete,
    onSuccessGroupChange,
    handleAddUser,
    handleEditUser,
    handleFinish,
    setGroupToDelete,
  }: IProps) => {
    const { isActionSuccessful, isError } = useSelector((state: RootState) => state.admin.userTable)

    const renderModalContent = () => {
      if (!isActionSuccessful && !isError) {
        if (mode === 'edit') {
          return (
            <ConfirmActionContent
              onClose={onClose}
              literal="admin_update_user_confirm"
              handleAction={handleEditUser!}
            />
          )
        }
        if (mode === 'add') {
          return (
            <ConfirmActionContent onClose={onClose} literal="admin_create_user_confirm" handleAction={handleAddUser!} />
          )
        }
        if (mode === 'default_group') {
          return (
            <ConfirmActionContent
              onClose={onClose}
              literal="admin_default_group_confirm"
              handleAction={handleFinish!}
            />
          )
        }
        if (mode === 'delete') {
          return <DeleteContent onClose={onClose} row={row} />
        }
        if (mode === 'delete_group') {
          return (
            <DeleteGroupContent
              group={groupToDelete}
              onClose={onClose}
              selectedIds={selectedIds}
              setGroupToDelete={setGroupToDelete}
            />
          )
        }
        if (mode === 'cancel') {
          return <CancelAction onClose={onClose} />
        }
      }
      if (isError) {
        return <ActionFailedContent onClose={onClose} />
      }
      if (isActionSuccessful && actionLiteral) {
        return (
          <ActionSuccessContent
            onClose={onClose}
            handleNextStep={handleNextStep}
            actionLiteral={actionLiteral}
            route={route}
            onSuccessGroupChange={onSuccessGroupChange}
          />
        )
      }
    }

    return (
      <>
        {isOpen && (
          <BaseModal
            closeOnOverlayClick={closeOnOverlayClick}
            isOpen={isOpen}
            onClose={onClose}
            bodyContent={renderModalContent()}
          />
        )}
      </>
    )
  }
)

export default UsersActionModal
