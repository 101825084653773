import { useDisclosure } from '@chakra-ui/core'
import LocaleText from 'components/LocaleText'
import keys from 'constants/keys'
import React from 'react'
import { ChooseContext } from './ChooseContext'
import { useSelector } from 'react-redux'
import { RootState } from 'constants/interfaces'
import { useLocation } from 'react-router-dom'
import { Layers } from 'lucide-react'
import { SideBarButton } from './SideBarButton'

export const ChooseContextButton: React.FC<{ setIsHidden?: (hide: boolean) => void }> = ({ setIsHidden }) => {
  const selectedRoute = useSelector((state: RootState) => state.general.selectedRoute)
  const { pathname } = useLocation()
  const theme = useSelector((state: RootState) => state?.general?.theme)
  const { isReportLoading } = useSelector((state: RootState) => state.reports)
  const { activeGroupID } = useSelector((state: RootState) => state.config)
  const { groups } = useSelector((state: RootState) => state.config.config)
  const { isOpen: isOpenGroupsTree, onOpen: onOpenGroupsTree, onClose: onCloseGroupsTree } = useDisclosure()

  const handleOpenGroupsTree = () => {
    onOpenGroupsTree()
    setIsHidden?.(true)
  }

  const handleCloseGroupsTree = () => {
    onCloseGroupsTree()
    setIsHidden?.(false)
  }
  return (
    <>
      {selectedRoute !== keys.ROUTE_NAMES.DASHBOARD && (
        <>
          <SideBarButton
            isDisabled={pathname === keys.ROUTE_NAMES.OPEN_GROUPTREE || isReportLoading}
            onClick={handleOpenGroupsTree}
            color={theme?.elementsColor}
            border={`1px solid ${theme?.backgroundMedium} !important`}
            background="white !important"
            borderRadius="20px"
          >
            <Layers size={16} />
            <LocaleText text={groups?.[activeGroupID || '']?.name || ''} />
          </SideBarButton>
        </>
      )}
      {isOpenGroupsTree && <ChooseContext isOpen={isOpenGroupsTree} onClose={handleCloseGroupsTree} />}
    </>
  )
}
