import { Input, InputGroup, InputLeftElement, InputProps, InputRightElement } from '@chakra-ui/core'
import useLocaleText from 'components/useLocaleText'
import { Search } from 'lucide-react'
import React from 'react'
import { useSelector } from 'react-redux'
import { isRtlSelector } from 'redux/selectors/general'

interface SearchInputProps extends InputProps {
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const SearchInput: React.FC<SearchInputProps> = ({ value, onChange, ...props }) => {
  const isRtl = useSelector(isRtlSelector)
  const search = useLocaleText('search')

  return (
    <InputGroup bg="white" borderRadius="15px" {...props}>
      {isRtl ? (
        <InputLeftElement>
          <Search />
        </InputLeftElement>
      ) : (
        <InputRightElement>
          <Search />
        </InputRightElement>
      )}

      <Input
        borderRadius="15px"
        type="text"
        placeholder={search}
        border="none"
        _focus={{ outline: 'none' }}
        value={value}
        onChange={onChange}
      />
    </InputGroup>
  )
}
