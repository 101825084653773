// utils/mediaUtils.ts
import React from 'react'
export const isVideo = (url: string) => /\.(mp4|m4v|mov|webm|ogg)$/i.test(url)

interface GalleryItem {
  original: string
  thumbnail: string
  renderItem?: () => React.ReactNode
  renderThumbInner?: () => React.ReactNode
}

export const createGalleryItems = (urls: string[]): GalleryItem[] => {
  return urls.map((url) => {
    if (isVideo(url)) {
      return {
        original: url,
        thumbnail: url,
        renderItem: () => (
          <div>
            <video controls style={{ width: '100%', height: '100%', objectFit: 'contain' }}>
              <source src={url} />
            </video>
          </div>
        ),
        renderThumbInner: () => (
          <div>
            <video style={{ width: '100%', height: '100%', objectFit: 'cover' }}>
              <source src={url} />
            </video>
          </div>
        ),
      }
    } else {
      return {
        original: url,
        thumbnail: url,
      }
    }
  })
}
