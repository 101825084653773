import { Button, Flex, Text, useDisclosure } from '@chakra-ui/core'
import { BackButton } from 'components/TasksV2/UI/BackButton'
import BaseModalV2 from 'components/TasksV2/UI/BaseModalV2'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { serviceCallConfigSelector } from 'redux/selectors/config'
import ServiceCallForm from './ServiceCallFormCreation'
import { RootState } from 'constants/interfaces'
import useLocaleText from 'components/useLocaleText'
import { FormContext, useForm } from 'react-hook-form'
import { CreateServiceCallObject, createServiceCall } from 'redux/actions/serviceCall'
import { AppDispatch } from 'config/redux'
import ConfirmationPopup from 'components/CommonComponents/ConfirmationPopup'

interface Props {
  isOpen: boolean
  onClose: VoidFunction
  serviceCallViewId: number
}

const ServiceCallCreationModal: React.FC<Props> = ({ isOpen, onClose, serviceCallViewId }) => {
  const dispatch: AppDispatch = useDispatch()
  const serviceCallsViews = useSelector(serviceCallConfigSelector)
  const theme = useSelector((state: RootState) => state.general.theme)
  const { activeGroupID } = useSelector((state: RootState) => state.config)
  const { isLoading } = useSelector((state: RootState) => state.serviceCalls)
  const serviceCallView = serviceCallsViews.find(({ task_view_id }) => task_view_id === serviceCallViewId)

  const { isOpen: isConfirmationOpen, onOpen: onOpenConfirmation, onClose: onCloseConfirmation } = useDisclosure()

  const t_create_service_call = useLocaleText('send')
  const card_confirm_text = useLocaleText('card_confirm_text')
  const card_confirm_desc = useLocaleText('card_confirm_desc')
  const yes = useLocaleText('yes')
  const no = useLocaleText('no')

  const methods = useForm({
    defaultValues: {
      location_id: activeGroupID!,
      supervisor: serviceCallView?.service_request_config?.supervisor,
      desc: '',
      images: [],
      files: [],
      videos: [],
      view: serviceCallViewId,
      config: {
        options: [],
        statuses: serviceCallView?.service_request_config?.statuses!,
      },
    },
  })

  const onSubmit = async (data: CreateServiceCallObject) => {
    const res = await dispatch(createServiceCall(data))
    if (res) {
      onClose()
    }
  }

  const handleConfirm = () => {
    onClose()
    onCloseConfirmation()
  }

  const handleCancelConfirm = () => {
    onCloseConfirmation()
  }

  if (!serviceCallView) return null

  return (
    <>
      <FormContext {...methods}>
        <BaseModalV2
          isOpen={isOpen}
          onClose={onClose}
          modalFooterProps={{ background: '#f4f4f4' }}
          header={
            <Flex justifyContent="space-between" h="60px" alignItems="center">
              <BackButton title={serviceCallView.task_view_name} event={onOpenConfirmation} />
              <Button
                bg={theme?.elementsColor}
                color="white"
                _focus={{ outline: 'none' }}
                borderRadius="20px"
                isLoading={isLoading}
                isDisabled={isLoading}
                onClick={methods.handleSubmit(onSubmit)}
              >
                <Text>{t_create_service_call}</Text>
              </Button>
            </Flex>
          }
          body={<ServiceCallForm serviceCallView={serviceCallView} />}
          footer={<></>}
        />
      </FormContext>
      <ConfirmationPopup
        isOpen={isConfirmationOpen}
        onConfirm={handleConfirm}
        onCancel={handleCancelConfirm}
        title={card_confirm_text}
        description={card_confirm_desc}
        cancelButtonText={no}
        confirmButtonText={yes}
        isLoading={false}
      />
    </>
  )
}

export default ServiceCallCreationModal
