import React from 'react'
import { BsQuestionLg } from 'react-icons/bs'

import { Flex } from '@chakra-ui/core'
import { IconProps } from './types'

const QuestionIcon: React.FC<IconProps> = ({
  iconWidth = 30,
  iconHeight = 30,
  width = '40px',
  height = '40px',
  iconColor = '#D5A6BD',
  bgColor = '#EAD1DC',
  ...props
}) => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      bg={bgColor}
      width={width}
      height={height}
      borderRadius="full"
      {...props}
    >
      <BsQuestionLg width={iconWidth} height={iconHeight} color={iconColor} strokeWidth={0.5} />
    </Flex>
  )
}

export default QuestionIcon
