import { FrequencyType } from 'components/NewsFeed/PollTaskForm/utils'
import { isUserIncludedInGroups } from 'components/NewsFeed/PostModal/utils'
import { IConfigStateGroups, ITag } from 'constants/interfaces'
import keys from 'constants/keys'
import dayjs from 'dayjs'
import { Content, Subtask } from './FormStepContext'

type RecurrenceDeadlineArgs = {
  ts: string
  frequencyTime: string
}
const bindTimeToDate = ({ ts, frequencyTime }: RecurrenceDeadlineArgs): string => {
  const baseDate = dayjs(ts)

  const [hours, minutes] = frequencyTime.split(':').map(Number)

  let recurrenceDeadline = baseDate.set('hour', hours).set('minute', minutes).set('second', 0).set('millisecond', 0)

  // If the resulting time is before the original ts, add a day
  if (recurrenceDeadline.isBefore(baseDate)) {
    recurrenceDeadline = recurrenceDeadline.add(1, 'day')
  }

  return recurrenceDeadline.format(keys.DEFAULT_STRING_FORMAT_TIMESTAMP)
}

type CalculateRecurrenceDeadlineArgs = {
  is_recurring: boolean
  recurrence_frequency: number | null
  from_ts: string
  frequency_time: string
  monthly_range: string[]
  days_of_week: number[] | null
}
export const calculateRecurrenceDeadline = ({
  is_recurring,
  recurrence_frequency,
  from_ts,
  frequency_time,
  monthly_range,
  days_of_week,
}: CalculateRecurrenceDeadlineArgs) => {
  if (!is_recurring) {
    return null
  }

  if (recurrence_frequency === FrequencyType.DAY) {
    return bindTimeToDate({ ts: from_ts, frequencyTime: frequency_time })
  }

  if (recurrence_frequency === FrequencyType.MONTH) {
    return bindTimeToDate({ ts: monthly_range[1], frequencyTime: frequency_time })
  }

  if (recurrence_frequency === FrequencyType.WEEK) {
    const [startRange, endRange] = days_of_week ?? []
    const daysDifference = endRange - startRange
    const daysToAdd = daysDifference >= 0 ? daysDifference : 7 + daysDifference

    const endDay = dayjs(from_ts).add(daysToAdd, 'day')
    return bindTimeToDate({ ts: endDay.toISOString(), frequencyTime: frequency_time })
  }
}

type CalculateDaysOfWeek = {
  recurrence_frequency: number | null
  days_of_week: number[] | null
}
export const calculateDaysOfWeek = ({
  recurrence_frequency,
  days_of_week,
}: CalculateDaysOfWeek): number[] | number | null => {
  if (!days_of_week) {
    return null
  }
  if (recurrence_frequency === FrequencyType.DAY) {
    return days_of_week?.length === 7 ? null : days_of_week
  }
  if (recurrence_frequency === FrequencyType.WEEK) {
    return [days_of_week[0]]
  }
  return null
}

type CalculateTaskUsersArgs = {
  users: string[]
  groups: string[]
  tags: string[]
  uid: string
  configTags: ITag[]
  treeGroups: IConfigStateGroups
}

export const calculateTaskUsers = ({
  users,
  groups,
  tags,
  uid,
  configTags,
  treeGroups,
}: CalculateTaskUsersArgs): string[] => {
  const isCreatorInGroups = isUserIncludedInGroups(uid, groups, treeGroups)

  const checkIsCreatorInTags = () => {
    if (!tags.length) {
      return false
    }
    const selectedTags = configTags.filter((tag) => tags.includes(tag.sk))
    const mergedTagsGroups = Array.from(new Set(selectedTags.map((tag) => tag?.groups ?? []).flat()))

    const mergedTagsUsers = Array.from(new Set(selectedTags.map((tag) => tag?.users ?? []).flat()))
    return isUserIncludedInGroups(uid, mergedTagsGroups, treeGroups) || mergedTagsUsers.includes(uid)
  }

  const isCreatorInTags = checkIsCreatorInTags()

  return isCreatorInGroups || isCreatorInTags ? users : Array.from(new Set([...users, uid]))
}

type TotsArgs = {
  is_recurring: boolean
  deadline: string | null
  formattedDeadline: string
}
export const calculateTots = ({ is_recurring, deadline, formattedDeadline }: TotsArgs) => {
  return is_recurring
    ? formattedDeadline.toString()
    : dayjs(deadline).add(7, 'day').format(keys.DEFAULT_STRING_FORMAT_TIMESTAMP)
}

type RecurrenceFrequencyArgs = {
  frequency: number | null
  days_of_week: number[] | null
}
export const calculateRecurrenceFrequency = ({ frequency, days_of_week }: RecurrenceFrequencyArgs) => {
  if (frequency === FrequencyType.DAY && days_of_week && days_of_week?.length < 7) {
    return FrequencyType.WEEK
  }

  return frequency
}

type SubtaskOutput = {
  title: string
  type: number
  external_link?: string
  is_multi_choice?: boolean
  options?: string[]
  is_numeric?: boolean
  is_required?: boolean
}

export type SubtaskWithNumeric = Subtask & { is_numeric?: boolean }

export const transformSubtasks = (input: (SubtaskWithNumeric | Content)[]): (SubtaskOutput | Content)[] => {
  return input.map((subtask) => {
    if ('index_ref' in subtask) {
      return subtask as Content
    }

    const { title, type, external_link, is_multi_choice, options, is_numeric, is_required } = subtask

    const transformed: SubtaskOutput = { title, type, is_required }

    if (external_link) {
      transformed.external_link = external_link
    }

    if (type === keys.SUBTASK_TYPES.POLL_SUBTASK) {
      transformed.is_multi_choice = is_multi_choice ?? false
      transformed.options = options ?? []
    }
    if (type === keys.SUBTASK_TYPES.OPEN_QUESTION) {
      transformed.is_numeric = is_numeric
    }

    return transformed
  })
}
