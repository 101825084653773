import React, { forwardRef } from 'react'
import { Box, Flex, FormControl } from '@chakra-ui/core'
import { Controller, useFormContext } from 'react-hook-form'
import { TaskInput } from 'components/TasksV2/UI/TaskInput'
import useLocaleText from 'components/useLocaleText'
import { ErrorMessage } from 'components/TasksV2/UI/ErrorMessage'

const TaskTitle = forwardRef<HTMLDivElement>((props, ref) => {
  const { errors, control } = useFormContext()
  const t_task_name_error = useLocaleText('t_task_name_error')
  const t_task_title = useLocaleText('t_task_title')

  return (
    <Flex w="100%" justifyContent="center" ref={typeof ref === 'function' ? ref : undefined} {...props}>
      <FormControl mb={4} w="60%">
        <Controller
          name="title"
          control={control}
          rules={{
            required: 'Task title is required',
            maxLength: { value: 100, message: 'Max length is 100 characters' },

            validate: (value) => {
              return value.trim() !== '' || t_task_name_error
            },
          }}
          as={TaskInput}
          isError={!!errors.title}
          placeholder={t_task_title}
          border="none"
        />
        {errors.title && (
          <Box px="10px" my="5px">
            <ErrorMessage errorMessage={t_task_name_error} />
          </Box>
        )}
      </FormControl>
    </Flex>
  )
})

export default TaskTitle
