import React from 'react'
import { Stack, Skeleton } from '@chakra-ui/core'
import { useMediaState } from 'hooks/useMediaState'
import HiddenFileInput from '../../../../../UI/HiddenInut'
import useLocaleText from 'components/useLocaleText'
import strings from 'constants/strings'
import { LinkInputField } from './LinkInputFied'
import { FileUploadButton } from './FIleUploadButton'
import { Subtask } from 'components/TasksV2/TaskCreation/FormStepContext'
import { useFormContext } from 'react-hook-form'

interface Props {
  pathName: string
  titleName: string
  field: Subtask
}

export const LinkPath: React.FC<Props> = ({ pathName, titleName, field }) => {
  const { control, setValue, getValues, triggerValidation } = useFormContext()

  const { handleFileUpload, isUploadLoading, fileInputRef } = useMediaState({
    files: [],
  })

  const t_pls_add_link = useLocaleText('t_pls_add_link')

  const handleClickOnAddFile = () => {
    fileInputRef.current?.click()
  }

  const handleLinkSubtaskFiles = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const response = await handleFileUpload(e)
    const fileName = response?.fileName
    const fileUrl = response?.fileUrl

    if (fileUrl) {
      setValue(pathName, fileUrl)
      triggerValidation(pathName)

      const currentTitle = getValues(titleName)
      if (!currentTitle && fileName) {
        setValue(titleName, fileName)
      }
    }
  }

  return (
    <>
      {isUploadLoading ? (
        <Skeleton height="30px" borderRadius="10px" mt="5px" />
      ) : (
        <Stack direction="row" align="center">
          <LinkInputField
            name={pathName}
            control={control}
            placeholder={t_pls_add_link}
            defaultValue={field?.external_link}
          />
          <FileUploadButton onClick={handleClickOnAddFile} />
        </Stack>
      )}
      <HiddenFileInput
        ref={fileInputRef}
        accept={strings.POST_FILE_ACCEPT_EXTENSIONS}
        onChange={handleLinkSubtaskFiles}
      />
    </>
  )
}
