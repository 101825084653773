import { TaskInput } from 'components/TasksV2/UI/TaskInput'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import useLocaleText from 'components/useLocaleText'
import { Subtask } from 'components/TasksV2/TaskCreation/FormStepContext'

interface Props {
  titleName: string
  field: Subtask
}
export const LinkTitle: React.FC<Props> = ({ titleName, field }) => {
  const { control } = useFormContext()
  const t_link_title = useLocaleText('t_link_title')

  return (
    <Controller
      as={TaskInput}
      name={titleName}
      control={control}
      placeholder={t_link_title}
      rules={{
        required: 'Title is required',
        validate: (value) => {
          return value.trim() !== '' || 'Title is required'
        },
      }}
      key={titleName}
      boxShadow="none"
      borderRadius="10px"
      border="none"
      px="5px"
      defaultValue={field.title}
    />
  )
}
