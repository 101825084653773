import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { Text } from '@chakra-ui/core'
import { css, keyframes } from '@emotion/core'
import { Content, Subtask } from '../../FormStepContext'
import useLocaleText from 'components/useLocaleText'
import colors from 'constants/colors'
import keys from 'constants/keys'

const shakeAnimation = keyframes`
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
`

const shakeEffect = css`
  animation: ${shakeAnimation} 0.2s ease;
  color: red;
`

export const SubtasksValidationMessage = forwardRef(({ fields }: { fields: (Subtask | Content)[] }, ref) => {
  const [isShaking, setIsShaking] = useState(false)
  const t_add_subtasks_empty_screen = useLocaleText('t_add_subtasks_empty_screen')

  const triggerShake = () => {
    setIsShaking(true)
    const timer = setTimeout(() => setIsShaking(false), 1000)
    return () => clearTimeout(timer)
  }

  useImperativeHandle(ref, () => ({
    triggerShake,
  }))

  const validFields = fields?.filter((field) => field.type !== keys.SUBTASK_TYPES.DESCRIPTION)

  return (
    <>
      {!validFields?.length && (
        <Text textAlign="center" fontSize="14px" css={isShaking ? shakeEffect : { color: colors.analyticGrey }}>
          {t_add_subtasks_empty_screen}
        </Text>
      )}
    </>
  )
})
