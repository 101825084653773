import { RootState, Task } from 'constants/interfaces'
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { DeepDiveInsights, TaskFromInsights, TasksInsights } from 'redux/actions/tasks_v2'

export enum InsightsContentType {
  ALL_CONTEXTS_MEDIA_RESULTS_PREVIEW = 'ALL_CONTEXTS_MEDIA_RESULTS_PREVIEW',
  ALL_CONTEXTS_MEDIA_RESULTS_OVERVIEW = 'ALL_CONTEXTS_MEDIA_RESULTS_OVERVIEW',
  INSIGHTS = 'INSIGHTS',
}

export interface InsightsContentStack {
  contentType: InsightsContentType
  props?: any
}
export interface ICurrentTaskInsight extends TaskFromInsights {
  tid: string
}

interface InsightsContextProps {
  contentStack: InsightsContentStack[]
  setContentStack: React.Dispatch<React.SetStateAction<InsightsContentStack[]>>
  openContent: (contentType: InsightsContentType, props?: any) => void
  closeContent: () => void
  insights: TasksInsights
  taskFromInsights: Task | null
  tid: string
  currentInsightTask: ICurrentTaskInsight
  deepDiveInsights: DeepDiveInsights
  resultContextId: string | null
  isReadMode: boolean
  retailIdContextId: string | null
  recurrenceTs: string | null
}

const InsightsStackContext = createContext<InsightsContextProps | undefined>(undefined)

export const InsightsStackProvider: React.FC<{
  children: React.ReactNode
  tid: string
  onClose: () => void
  content?: InsightsContentStack
  recurrenceTs: string | null
}> = ({ children, content, onClose, tid, recurrenceTs }) => {
  const [contentStack, setContentStack] = useState<InsightsContentStack[]>([
    content ? { ...content } : { contentType: InsightsContentType.INSIGHTS },
  ])
  const currentContent = contentStack[contentStack.length - 1]
  const { uid } = useSelector((state: RootState) => state.auth)
  const {
    activeGroupID,
    config: { rid },
  } = useSelector((state: RootState) => state.config)

  const { insights, taskFromInsights, deepDiveInsights } = useSelector((state: RootState) => state.tasks_v2)

  const currentInsightTask = {
    ...insights[tid],
    tid,
  }

  const selectedContextId = currentContent?.props?.contextId ?? null

  const resultContextId = selectedContextId ?? null

  const retailIdContextId = resultContextId ? `${rid}_${resultContextId}` : null

  const isReadMode = !(resultContextId === uid || resultContextId === activeGroupID)

  const openContent = useCallback((contentType: InsightsContentType, props?: any) => {
    setContentStack((prevStack) => [...prevStack, { contentType, props }])
  }, [])

  const closeContent = useCallback(() => {
    setContentStack((prevStack) => {
      const newStack = prevStack.slice(0, -1)
      return newStack
    })
  }, [])

  useEffect(() => {
    if (contentStack.length === 0) {
      onClose()
    }
  }, [contentStack, onClose])

  return (
    <InsightsStackContext.Provider
      value={{
        contentStack,
        setContentStack,
        openContent,
        closeContent,
        insights,
        currentInsightTask,
        taskFromInsights,
        tid,
        deepDiveInsights,
        isReadMode,
        resultContextId,
        retailIdContextId,
        recurrenceTs,
      }}
    >
      {children}
    </InsightsStackContext.Provider>
  )
}

export const useInsightsContext = () => {
  const context = useContext(InsightsStackContext)
  if (!context) {
    throw new Error('useInsightsContext must be used within a InsightsStackContext')
  }
  return context
}
