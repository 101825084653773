import { actionTypes } from '../actions/feed'

interface BasePost {
  updated_at_ts: number
  updated_by: string
  post_id: string
  created_by: string
  created_at_ts: number
  group_users: string[]
  ras_id: string
  ref_id?: string
  type: number
  title: string
  likesArray?: Array<string>
  likes_counter: number
  views_counter: number
  liked: boolean
  post_origin_group_names: string[]
  post_origin_users: string[]
  post_origin_groups: string[]
  post_origin_tags: string[]
  groups: string[]
  users: string[]
  tags: string[]
  admins?: string[]
  is_self_pin?: boolean
  is_new_version?: boolean
  is_new?: boolean
  is_manager_pin?: boolean
  pin_created_at_ts?: number
  images?: string[]
  videos?: string[]
  audios?: string[]
  files?: string[]
  rid?: string
  ref_type?: number
}

export interface Post extends Omit<BasePost, 'liked'> {
  liked: {
    [key: string]: boolean
  }
  ping: {
    [key: string]: { context_id: string; status: boolean }
  }
  viewed: {
    [key: string]: boolean
  }
}

export interface PostInitialState {
  posts: Post[]
  chats: Post[]
  fetching: boolean
  chatsFetching: boolean
  likedPosts: {
    [postId: string]: boolean
  }
  hasMorePosts: boolean
  hasMoreChats: boolean
  searchPosts: Array<Post>
  isSearchFetching: boolean
  isAllPostCollapsed: boolean | null
  isShowAllMyPosts: boolean
  selectedPostID: string
  selectedChatID: string | null
  consolidationFilterOptions: string[] | null
  prefetchedChats: Post[]
}

export const initialState: PostInitialState = {
  posts: [],
  chats: [],
  prefetchedChats: [],
  fetching: false,
  chatsFetching: false,
  likedPosts: {},
  hasMorePosts: true,
  hasMoreChats: true,
  searchPosts: [],
  isSearchFetching: false,
  isAllPostCollapsed: null,
  isShowAllMyPosts: true,
  selectedPostID: '',
  selectedChatID: null,
  consolidationFilterOptions: null,
}

const reducerPosts = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_CHATS_FETCHING:
      return {
        ...state,
        chatsFetching: action.fetching,
      }
    case actionTypes.SET_HAS_MORE_CHATS:
      return {
        ...state,
        hasMoreChats: action.payload,
      }
    case actionTypes.SET_CHATS: {
      const existingChats: Post[] = state.chats || []
      const newChats: Post[] = action.chats || []

      const combinedChats = action.isRefresh ? newChats : [...existingChats, ...newChats]

      const dedupedChats = combinedChats.reduce((acc: Post[], chat: Post) => {
        const index = acc.findIndex((c) => c.post_id === chat.post_id)
        if (index === -1) {
          acc.push(chat)
        } else {
          acc[index] = chat
        }
        return acc
      }, [])

      return {
        ...state,
        chats: dedupedChats,
      }
    }
    case actionTypes.SET_SELECTED_CHAT:
      return {
        ...state,
        selectedChatID: action.payload,
      }
    case actionTypes.SET_CONSOLIDATION_FILTER_OPTIONS:
      return {
        ...state,
        consolidationFilterOptions: action.payload,
      }
    case actionTypes.SET_SELECTED_POST:
      return {
        ...state,
        selectedPostID: action.payload,
      }
    case actionTypes.SET_IS_SHOW_ALL_MY_POSTS:
      return {
        ...state,
        isShowAllMyPosts: action.payload,
      }
    case actionTypes.SET_IS_ALL_POSTS_COLLAPSED:
      return {
        ...state,
        isAllPostCollapsed: action.payload,
      }
    case actionTypes.SET_POSTS:
      return {
        ...state,
        posts:
          action.newPost || action.isRefresh || action.isDeleting || action.isNewLike
            ? action.posts
            : [...state.posts, ...action.posts],
      }
    case actionTypes.SET_FETCHING:
      return {
        ...state,
        fetching: action.fetching,
      }
    case actionTypes.SET_HAS_MORE_POSTS:
      return {
        ...state,
        hasMorePosts: action.payload,
      }
    case actionTypes.RESET_FEED:
      return {
        ...initialState,
      }
    case actionTypes.SET_SEARCH_POST:
      return {
        ...state,
        searchPosts: action.payload,
      }
    case actionTypes.SET_IS_SEARCH_FETCHING:
      return {
        ...state,
        isSearchFetching: action.payload,
      }
    case actionTypes.SET_PREFETCHED_CHATS:
      return {
        ...state,
        prefetchedChats: [...state.prefetchedChats, action.payload],
      }
    default:
      return state
  }
}

export default reducerPosts
