import React from 'react'
import { RenoveItemIcon } from './RenoveItemIcon'
import { useSelector } from 'react-redux'
import { isRtlSelector } from 'redux/selectors/general'

interface Props {
  onRemove: () => void
}
export const RemoveItemButton: React.FC<Props> = ({ onRemove }) => {
  const isRtl = useSelector(isRtlSelector)
  const handleRemove = async () => {
    onRemove()
  }

  return (
    <RenoveItemIcon
      pos="absolute"
      aria-label="Remove Subtask"
      onClick={handleRemove}
      zIndex={2}
      style={{
        top: '-5px',
        right: isRtl ? 'unset' : '-7px',
        left: isRtl ? '-7px' : 'unset',
        background: 'white',
        cursor: 'pointer',
        boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.25)',
        width: '25px',
        height: '25px',
      }}
    />
  )
}
