import { Button, Text } from '@chakra-ui/core'
import LocaleText from 'components/LocaleText'
import colors from 'constants/colors'
import { Upload } from 'lucide-react'
import React from 'react'
import { useSelector } from 'react-redux'
import { isRtlSelector } from 'redux/selectors/general'

interface ExportToExcelButtonProps<T> {
  literal?: string
  action?: (args: T) => void
  actionWithoutArgs?: () => void
  actionArgs?: T
  icon?: React.ReactNode
}
export default function ExportToExcelButton<T>({
  literal = 'dashboard_export_excel_button',
  icon = <Upload size="18px" color={colors.greyMain} />,
  action,
  actionArgs,
  actionWithoutArgs,
}: ExportToExcelButtonProps<T>) {
  const isRtl = useSelector(isRtlSelector)
  const tagretAction = action || actionWithoutArgs
  return (
    <Button
      px="6"
      py="3"
      borderRadius="10px"
      backgroundColor="#fff"
      onClick={() => tagretAction?.(actionArgs as T)}
      _focus={{ outline: 'none' }}
    >
      {icon}
      <Text fontWeight="600px" fontSize="12px" color={colors.blackLight} ml={isRtl ? '0' : '2'} mr={isRtl ? '2' : '0'}>
        <LocaleText text={literal} />
      </Text>
    </Button>
  )
}
