import { IConfigRetailUser, IConfigStateGroups, Result } from 'constants/interfaces'
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import { SubtaskDeepDiveItem } from 'redux/actions/tasks_v2'
import { getContextName } from 'hooks/useContextName'

export const exportMediaToExcel = (
  subtasks: SubtaskDeepDiveItem[],
  mediaResults: Result[],
  taskTitle: string,
  groups: IConfigStateGroups,
  retailUsersObject: {
    [key: string]: IConfigRetailUser
  }
): void => {
  const workbook = new ExcelJS.Workbook()
  const worksheet = workbook.addWorksheet('Media Results')

  // Define columns
  worksheet.columns = [
    { header: 'Store name', key: 'context_name', width: 40 },
    { header: 'Subtask title', key: 'subtask_title', width: 40 },
    { header: 'Subtask type', key: 'sub_task_type', width: 20 },
    { header: 'Result', key: 'result', width: 100 },
  ]

  // Styles for headers
  worksheet.getRow(1).font = { bold: true } // Make headers bold
  worksheet.getRow(1).alignment = { vertical: 'middle', horizontal: 'center' } // Center align headers
  worksheet.getRow(1).height = 20 // Minimum height for headers

  // Create a map for quick subtask lookup by st_id
  const subtaskMap = new Map(subtasks.map((subtask) => [subtask.st_id, subtask.title]))

  // Group mediaResults by context_id
  const groupedResults = mediaResults.reduce((acc, result) => {
    const { context_id } = result
    if (!acc[context_id]) {
      acc[context_id] = []
    }
    acc[context_id].push(result)
    return acc
  }, {} as Record<string, Result[]>)

  // Process each context
  Object.entries(groupedResults).forEach(([context_id, results]) => {
    const context_name = getContextName(context_id, groups, retailUsersObject!)

    results.forEach((result) => {
      const { st_id, sub_task_type, images = [], videos = [] } = result

      // Get subtask title
      const subtask_title = subtaskMap.get(st_id) || 'Unknown'

      // Determine media (images or videos)
      const media = images.length > 0 ? images : videos

      media.forEach((url) => {
        const row = worksheet.addRow({
          context_name,
          subtask_title,
          sub_task_type: sub_task_type === 2 ? 'image' : 'video',
          result: url,
        })

        row.eachCell((cell, colNumber) => {
          cell.alignment = {
            vertical: 'middle',
            horizontal: colNumber === 1 ? 'center' : 'left', // Center the first column
            wrapText: true, // Wrap text
          }
          cell.style = {
            font: {
              size: 12,
              name: 'Arial',
            },
            alignment: {
              vertical: 'middle',
              horizontal: colNumber === 1 ? 'center' : 'left', // Center the first column
              wrapText: true, // Wrap text
            },
          }

          // Styles for the first column
          if (colNumber === 1) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFF1F1F1' }, // Gray color
            }
          }
          cell.border = {
            top: { style: 'thin', color: { argb: 'FFB0B0B0' } },
            left: { style: 'thin', color: { argb: 'FFB0B0B0' } },
            bottom: { style: 'thin', color: { argb: 'FFB0B0B0' } },
            right: { style: 'thin', color: { argb: 'FFB0B0B0' } },
          }
        })
      })
    })
  })

  // Save the file
  workbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(new Blob([buffer]), `${taskTitle}-media-results.xlsx`)
  })
}
