import React, { useState } from 'react'
import { Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, Divider, Flex, Text } from '@chakra-ui/core'
import LocaleText from 'components/LocaleText'
import NavMenu, { IconBlock, NavItem } from 'components/Sidebar/NavMenu'
import { CustomModalClose } from 'components/Elements'

import { RootState } from 'constants/interfaces'
import { ReactComponent as SettingsPink } from 'assets/icon-settings-circle.svg'
import { useSelector } from 'react-redux'
import keys from 'constants/keys'

interface IProps {
  isOpen: boolean
  onClose: () => void
}

const MobileMenu: React.FC<IProps> = ({ isOpen, onClose }) => {
  const {
    config: { permission },
  } = useSelector((state: RootState) => state.config)

  const [isHidden, setIsHidden] = useState(false)

  return (
    <Drawer isOpen={isOpen} size="xs" placement="left" onClose={onClose}>
      <DrawerOverlay display={isHidden ? 'none' : 'block'} />
      <DrawerContent display={isHidden ? 'none' : 'flex'} flexDir="column" justifyContent="space-between">
        <Flex flexDir="column">
          <CustomModalClose onClick={onClose} />
          <DrawerHeader p="3">
            <LocaleText text="mobile_menu_header" />
          </DrawerHeader>
          <DrawerBody p="3">
            <NavMenu setIsHidden={setIsHidden} />
            <Divider mt="10%" />
          </DrawerBody>
        </Flex>
        {permission?.users === 3 && (
          <NavItem to={`${keys.ROUTE_NAMES.ADMIN_SETTINGS}/users`} style={{ padding: '0.75rem' }}>
            <IconBlock>
              <SettingsPink />
            </IconBlock>
            <Text mx="5" textTransform="capitalize">
              <LocaleText text="admin_settings" />
            </Text>
          </NavItem>
        )}
      </DrawerContent>
    </Drawer>
  )
}

export default MobileMenu
