import { RootState } from 'constants/interfaces'
import keys from 'constants/keys'
import { AnyAction } from 'redux'
import { ThunkAction } from 'redux-thunk'
import authService from 'services/authService'
import { delay, deleteAllCookies, setCookie } from '../../utils'
import { Auth } from 'aws-amplify'
import axios, { AxiosError } from 'axios'
import endpoints from 'constants/endpoints'
import { jwtDecode } from 'jwt-decode'

export const actionTypes = {
  SET_TOKEN: '[AUTH] SET_TOKEN',
  CLEAR_TOKEN: '[AUTH] CLEAR_TOKEN',
  SET_IS_LOGIN_LOADING: '[AUTH] SET_IS_LOGIN_LOADING',
  SET_IS_ERROR: '[AUTH] SET_IS_ERROR',
  LOGIN_BY_LINK: '[AUTH] LOGIN_BY_LINK',
  SET_CANDIDATE_TO_LOGIN: '[AUTH] SET_CANDIDATE_TO_LOGIN',
  SET_IS_LOGGED_IN: '[AUTH] SET_IS_LOGGED_IN',
  SET_IS_SESSION_CLOSED: '[AUTH] SET_IS_SESSION_CLOSED',
  SET_REFRESHED_TOKEN: '[AUTH] SET_REFRESHED_TOKEN',
  LOGOUT_USER: '[AUTH] LOGOUT_USER',
  SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
  SET_USERS_FORGOT_PASSWORD_DETAILS: 'SET_USERS_FORGOT_PASSWORD_DETAILS',
  SET_USER_NAME: 'SET_USER_NAME',
  SET_UID: 'SET_UID',
}
export const setUserName = (username: string) => ({
  type: actionTypes.SET_USER_NAME,
  payload: username,
})
export const setUsersForgotPasswordDetails = (details: string) => ({
  type: actionTypes.SET_USERS_FORGOT_PASSWORD_DETAILS,
  payload: details,

  SET_UID: 'SET_UID',
})
export const setUserId = (uid: string) => ({
  type: actionTypes.SET_UID,
  payload: uid,
})
export const setErrorMessage = (errorMessage: any) => ({
  type: actionTypes.SET_ERROR_MESSAGE,
  payload: errorMessage,
})

export const logoutUser = () => ({
  type: actionTypes.LOGOUT_USER,
})

export const setIsLoggedIn = (isLoggedIn: boolean) => ({
  type: actionTypes.SET_IS_LOGGED_IN,
  payload: isLoggedIn,
})

export const setToken = (token: string | null) => ({
  type: actionTypes.SET_TOKEN,
  token,
})

export const setRefreshedToken = (token: string) => ({
  type: actionTypes.SET_REFRESHED_TOKEN,
  payload: token,
})

export const setCandidateToLogin = (candidate: any | null) => ({
  type: actionTypes.SET_CANDIDATE_TO_LOGIN,
  payload: candidate,
})

export const clearToken = () => ({
  type: actionTypes.CLEAR_TOKEN,
})

export const setLoading = (isLoading: boolean) => ({
  type: actionTypes.SET_IS_LOGIN_LOADING,
  payload: isLoading,
})

export const setError = (isError: boolean) => ({
  type: actionTypes.SET_IS_ERROR,
  isError,
})

export const setSessionClosed = (isClosed: boolean) => ({
  type: actionTypes.SET_IS_SESSION_CLOSED,
  isClosed,
})

export const handleError = (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
  dispatch(setError(true))
  await delay(1000)
  dispatch(setError(false))
}

export const handleLogin =
  (userId: string, password?: string, redirectTo?: (url: string) => void) => async (dispatch: any) => {
    dispatch(setLoading(true))
    try {
      let token: string | undefined = ''
      if (password) {
        const user = await authService.signIn(userId, password)
        const uid = user.getSignInUserSession()?.getAccessToken().payload.username

        let token: string | undefined = ''
        if (user.getSignInUserSession()) {
          token = user?.getSignInUserSession()?.getIdToken()?.getJwtToken()
        }

        if (token) {
          setCookie('token', token)
          dispatch(setUserId(uid))
          dispatch(setToken(token))
          dispatch(setIsLoggedIn(true))
        } else {
          dispatch(setCandidateToLogin(user))

          if (user.challengeName === keys.amplifyChallenges.changePassword && redirectTo) {
            redirectTo(keys.ROUTE_NAMES.CHANGE_PASSWORD)
          }

          if (
            user.challengeName === keys.amplifyChallenges.smsMfa ||
            user.challengeName === keys.amplifyChallenges.tokenMfa
          ) {
            if (redirectTo) {
              redirectTo(keys.ROUTE_NAMES.LOGIN_CONFIRM)
            }
          }
        }
      }

      if (!token) return
      dispatch(setError(false))
      dispatch(setToken(token))
    } catch (error) {
      dispatch(handleError())
    } finally {
      dispatch(setLoading(false))
    }
  }

export const handleChangePassword =
  (password: string, redirectTo: (url: string) => void): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState: () => RootState) => {
    dispatch(setLoading(true))

    try {
      const user = getState().auth.candidateToLogin
      if (user) {
        const userWithNewPassword = await authService.newPassword(user, password)
        const uid = await userWithNewPassword.getSignInUserSession()?.getAccessToken().payload.username
        if (userWithNewPassword) {
          dispatch(setCandidateToLogin(userWithNewPassword))
          dispatch(setUserId(uid))
          if (!userWithNewPassword.challengeName) {
            const token = user?.getSignInUserSession()?.getIdToken()?.getJwtToken()
            if (token) {
              dispatch(setCandidateToLogin(null))
              dispatch(setToken(token))
              redirectTo(keys.ROUTE_NAMES.NEWS_FEED)
            }
          } else if (userWithNewPassword.challengeName === keys.amplifyChallenges.smsMfa) {
            redirectTo(keys.ROUTE_NAMES.LOGIN_CONFIRM)
          }
        }
      }
    } catch (err) {
      const error: AxiosError | any = err
      if (error instanceof Error) {
        dispatch(setErrorMessage(error.message))
      }
      dispatch(handleError())
    } finally {
      dispatch(setLoading(false))
      dispatch(setErrorMessage(''))
    }
  }

export const handleConfirmCode =
  (code: string, redirectTo: (url: string) => void): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState: () => RootState) => {
    dispatch(setLoading(true))
    try {
      const user = getState().auth.candidateToLogin
      if (user) {
        const cognitoUser = await authService.confirmLogin(user, code)
        const uid = await cognitoUser.getSignInUserSession()?.getAccessToken().payload.username

        let token: string | undefined = ''
        if (cognitoUser.getSignInUserSession) {
          token = user?.getSignInUserSession()?.getIdToken()?.getJwtToken()
        }
        if (token) {
          dispatch(setUserId(uid))
          dispatch(setCandidateToLogin(null))
          dispatch(setToken(token))
          setCookie('token', token)
          redirectTo(keys.ROUTE_NAMES.NEWS_FEED)
          dispatch({ type: actionTypes.SET_IS_ERROR, isError: false })
        }
      }
    } catch (error) {
      dispatch(handleError())
    } finally {
      dispatch(setLoading(false))
    }
  }

export const logoutHandler = () => async (dispatch: any) => {
  try {
    await authService.signOut()
    for (let key in localStorage) {
      if (key.startsWith('CognitoIdentityServiceProvider')) {
        localStorage.removeItem(key)
      }
    }
    deleteAllCookies()

    dispatch(logoutUser())
  } catch (error) {
    console.error('Logout error:', error)
  }
}

export const refreshToken = (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
  try {
    const currentUser = await Auth.currentAuthenticatedUser()
    const currentSession = await Auth.currentSession()
    currentUser.refreshSession(currentSession.getRefreshToken(), (err: any, newSession: any) => {
      if (err) throw err
      const token = newSession.idToken.jwtToken
      if (token) {
        setCookie('token', token)
        dispatch(setRefreshedToken(token))
      }
    })
  } catch (error) {
    dispatch(handleError())
  }
}

export const resetPassword =
  (username: string, redirectTo: (url: string) => void): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const { CodeDeliveryDetails } = await Auth.forgotPassword(username)
      if (CodeDeliveryDetails) {
        dispatch(
          setCandidateToLogin({
            destination: CodeDeliveryDetails.Destination,
            method: CodeDeliveryDetails.AttributeName,
            userName: username,
          })
        )
      }
      redirectTo(keys.ROUTE_NAMES.FORGOT_PASSWORD_SUBMIT)
    } catch (err) {
      const error: AxiosError | any = err
      if (error instanceof Error) {
        dispatch(setErrorMessage(error.message))
      }
      dispatch(handleError())
    }
  }

export const resetPasswordSubmit =
  (
    username: string,
    newPassword: string,
    code: string,
    redirectTo: (url: string) => void
  ): ThunkAction<any, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      await Auth.forgotPasswordSubmit(username!, code, newPassword)
      const user = await authService.signIn(username!, newPassword)
      const uid = await user.getSignInUserSession()?.getAccessToken().payload.username

      let token: string | undefined = ''
      if (user.getSignInUserSession) {
        token = user?.getSignInUserSession()?.getIdToken()?.getJwtToken()
      }

      if (token) {
        dispatch(setUserId(uid))
        setCookie('token', token)
        dispatch(setToken(token))
        dispatch(setIsLoggedIn(true))
        redirectTo(keys.ROUTE_NAMES.NEWS_FEED)
        dispatch(setCandidateToLogin(null))
      }
      if (
        user.challengeName === keys.amplifyChallenges.smsMfa ||
        user.challengeName === keys.amplifyChallenges.tokenMfa
      ) {
        dispatch(setCandidateToLogin(user))

        redirectTo(keys.ROUTE_NAMES.LOGIN_CONFIRM)
      }
    } catch (err) {
      const error: AxiosError | any = err
      if (error instanceof Error) {
        dispatch(setErrorMessage(error.message))
      }
      dispatch(handleError())
    }
  }

interface TokenDetails {
  grant_type: string
  client_id: string | undefined
  redirect_uri: string | undefined
  code: string
  [key: string]: string | undefined
}

export const getAWSTokenData = async (
  code: string
): Promise<{
  access_token: string
  id_token: string
  refresh_token: string
  token_type: string
  expires_in: number
} | null> => {
  const details: TokenDetails = {
    grant_type: 'authorization_code',
    client_id: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_OAUTH_DOMAIN_REDIRECTSIGNIN,
    code,
  }
  const formBody = Object.keys(details)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(details[key] || '')}`)
    .join('&')
  return fetch(`https://${process.env.REACT_APP_OAUTH_DOMAIN}/oauth2/token`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: formBody,
  })
    .then((response) => response.json())
    .then((data) => {
      if (data) {
        return data
      }
      return null
    })
    .catch((error) => {
      console.error('getAWSTokenData error:', error)
      return null
    })
}

const X_AMZ_TARGET = 'AWSCognitoIdentityProviderService.RespondToAuthChallenge'

interface GetOTPResponse {
  country_code?: string
  phone_number?: string
  email?: string
}

export const getOTP =
  (data: GetOTPResponse): ThunkAction<Promise<boolean>, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true))
      const response = await axios.put(endpoints.OTP, data, {
        headers: {
          'X-Amz-Target': X_AMZ_TARGET,
          client_id: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID || '',
        },
      })
      if (response.status === 200) {
        return true
      }
      return false
    } catch (error) {
      dispatch(handleError())
      return false
    } finally {
      dispatch(setLoading(false))
    }
  }

export interface ConfirmOTPCode extends GetOTPResponse {
  otp: string
}

export interface ConfirmOTPCodeResponse {
  otp_token: string
  uids: string[]
}

interface AWSTokenResponse {
  AccessToken: string
  ExpiresIn: number
  TokenType: string
  RefreshToken: string
  IdToken: string
}

const handleAuthentication = async (tokenData: AWSTokenResponse, dispatch: any): Promise<boolean> => {
  await authService.handleAuthenticatedUserManually({
    access_token: tokenData.AccessToken,
    id_token: tokenData.IdToken,
    refresh_token: tokenData.RefreshToken,
    token_type: tokenData.TokenType,
    expires_in: tokenData.ExpiresIn,
  })

  const decodedToken = jwtDecode(tokenData.IdToken) as { [key: string]: any }
  const userId = decodedToken['cognito:username']

  setCookie('token', tokenData.IdToken)
  dispatch(setToken(tokenData.IdToken))
  dispatch(setUserId(userId))
  dispatch(setIsLoggedIn(true))

  return true
}

export const confirmOTPCode =
  (data: ConfirmOTPCode): ThunkAction<Promise<ConfirmOTPCodeResponse | number | null>, RootState, unknown, AnyAction> =>
  async (dispatch: any) => {
    try {
      dispatch(setLoading(true))
      const response = await axios.get(endpoints.OTP, {
        params: data,
        headers: {
          'X-Amz-Target': X_AMZ_TARGET,
          client_id: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID || '',
        },
      })

      if (response.status === 200) {
        if ('IdToken' in response.data) {
          const tokenData = response.data as AWSTokenResponse
          return await handleAuthentication(tokenData, dispatch)
        }
        return response.data
      }
      return null
    } catch (err) {
      const error: AxiosError | any = err
      return error?.response?.status
    } finally {
      dispatch(setLoading(false))
    }
  }

interface LoginOTP {
  otp_token: string
  uid: string
}

export const loginOTP =
  (data: LoginOTP): ThunkAction<Promise<boolean>, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true))
      const response = await axios.get(endpoints.loginOTP, {
        params: data,
        headers: {
          'X-Amz-Target': X_AMZ_TARGET,
          client_id: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID || '',
        },
      })

      if (response.status === 200 && 'IdToken' in response.data) {
        const tokenData = response.data as AWSTokenResponse
        return await handleAuthentication(tokenData, dispatch)
      }
      return false
    } catch (error) {
      dispatch(handleError())
      return false
    } finally {
      dispatch(setLoading(false))
    }
  }
