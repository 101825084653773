import React from 'react'
import { WidgetWrapper } from 'components/TasksV2/UI/WidgetWrapper'
import { Box, Flex, Text } from '@chakra-ui/core'
import { Pen } from 'lucide-react'
import useLocaleText from 'components/useLocaleText'
import { Summery } from './Summery'
import { ErrorMessage } from 'components/TasksV2/UI/ErrorMessage'
import { Controller, useFormContext } from 'react-hook-form'
import { StepType, useFormStepContext } from 'components/TasksV2/TaskCreation/FormStepContext'
import { FormInputLabel } from 'components/TasksV2/UI/FormInputLabel'

export const TimeFramePreview = () => {
  const { stepStack, setStepStack } = useFormStepContext()
  const { errors, control } = useFormContext()

  const t_choose_dates = useLocaleText('t_choose_dates')

  const handleClick = () => {
    setStepStack([...stepStack, StepType.TIME_FRAME_SELECTION])
  }

  return (
    <Box>
      <Controller name="from_ts" control={control} as={<Box />} key="from_ts" />
      <Controller name="deadline" control={control} as={<Box />} key="deadline" />
      <FormInputLabel label={t_choose_dates} />
      <Flex style={{ columnGap: '10px' }} alignItems="center" w="100%" flexDir="row-reverse">
        <Flex
          alignItems="center"
          justifyContent="center"
          bg="#ababab"
          p="8px"
          borderRadius="full"
          w="30px"
          height="30px"
        >
          <Pen size={16} strokeWidth={3} cursor="pointer" color="white" />
        </Flex>

        <WidgetWrapper w="100%" onClick={handleClick} p="10px" cursor="pointer" isError={!!errors.timeFrame?.from_ts}>
          <Summery />
        </WidgetWrapper>
      </Flex>

      {errors.timeFrame?.from_ts && (
        <Text fontSize="12px" color="red" px="15px" mt="5px">
          <ErrorMessage errorMessage="Please select a date" />
        </Text>
      )}
    </Box>
  )
}
