import React from 'react'
import { Flex, FlexProps } from '@chakra-ui/core'
import colors from 'constants/colors'

interface StatusBlockProps extends FlexProps {
  children?: React.ReactNode
}
export const StatusBlock: React.FC<StatusBlockProps> = ({ children, ...props }) => (
  <Flex
    direction="column"
    borderRadius="15px"
    alignItems="flex-start"
    justifyContent="center"
    transition="all 0.5s ease-in-out"
    transformOrigin="top"
    overflow="hidden"
    boxShadow={`0 2px 4px 0 ${colors.analyticsGreyLight}`}
    {...props}
  >
    {children}
  </Flex>
)
