import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useResults } from 'components/TasksV2/hooks/useResults'
import { useTaskOverviewContext } from 'components/TasksV2/TasksOverview/TaskOverviewStackProvider'
import { StatusCircle } from 'components/TasksV2/UI/StatusCircle'
import { SubtaskTitile } from 'components/TasksV2/UI/SubtaskTitile'
import { WidgetIcon } from 'components/TasksV2/UI/WidgetIcon'
import { WidgetWrapper } from 'components/TasksV2/UI/WidgetWrapper'
import useLocaleText from 'components/useLocaleText'
import { AppDispatch } from 'config/redux'
import colors from 'constants/colors'
import { Subtask } from 'constants/interfaces'
import keys from 'constants/keys'
import { resetResults, updateResults } from 'redux/actions/tasks_v2'
import TextareaAutosize from 'react-textarea-autosize'
import { Box } from '@chakra-ui/core'
import { isRtlSelector } from 'redux/selectors/general'
import { TaskInput } from 'components/TasksV2/UI/TaskInput'
import { ResetMenu } from 'components/TasksV2/UI/ResetMenu'
import { resetSubtasksResults } from 'components/TasksV2/TasksOverview/utils'
import { TextAreaSubmitBtn } from 'components/TasksV2/UI/TextAreaSubmitBtn'
import { SkipSubtaskButton } from 'components/TasksV2/UI/SkipSubtaskButton'

interface OpenQuestionProps {
  subtask: Subtask
}

const OpenQuesionSubtask: React.FC<OpenQuestionProps> = ({ subtask }) => {
  const { resultContextId, isReadMode, isShowResetMenu, task } = useTaskOverviewContext()
  const { recurrence_ts } = task
  const { is_numeric, title, type, st_id, tid } = subtask
  const isRtl = useSelector(isRtlSelector)
  const dispatch: AppDispatch = useDispatch()
  const subTaskResult = useResults({ subtask, resultContextId })
  const [isLoading, setIsLoading] = useState(false)

  const handleResetResults = async () => {
    if (!subTaskResult) {
      return
    }
    setIsLoading(true)

    const isResOk = await dispatch(
      resetResults({
        tid,
        sub_tasks_ids: [subTaskResult.st_id],
        result_context_id: resultContextId,
        ...(recurrence_ts ? { recurrence_ts } : {}),
      })
    )
    if (!isResOk) {
      return
    }
    resetSubtasksResults({
      tid,
      filteredResultsBySubtasksIds: [subTaskResult],
      contextId: resultContextId,
      dispatch,
    })

    setIsLoading(false)
  }

  const [answer, setAnswer] = useState<string | number | null>(subTaskResult?.answer ?? null)
  const [isFocused, setIsFocused] = useState(false)

  const t_your_text_answer = useLocaleText('your_answer')
  const t_your_number_answer = useLocaleText('your_number_answer')
  const reset_answer = useLocaleText('reset_answer')

  const isSubtaskDone =
    subTaskResult?.status === keys.TASK_STATUSES.DONE.value ||
    subTaskResult?.status === keys.TASK_STATUSES.SKIPPED.value

  const statusCurcleColor = useMemo(() => {
    if (subTaskResult?.status === keys.TASK_STATUSES.SKIPPED.value) {
      return '#EA9999'
    }
    return colors.greenPoll
  }, [subTaskResult])

  const handleChangeText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    let value = e.target.value
    setAnswer(value.trim() === '' ? null : value)
  }

  const handleChangeNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value
    setAnswer(value.trim() === '' ? null : Number(value))
  }

  const handleUpdateResult = () => {
    if (answer === subTaskResult?.answer || (answer === null && subTaskResult?.answer === null)) {
      return
    }
    dispatch(
      updateResults({
        tid,
        st_id,
        type: keys.SUBTASK_TYPES.OPEN_QUESTION,
        resultContextId,
        status: answer === null ? keys.TASK_STATUSES.TODO.value : keys.TASK_STATUSES.DONE.value,
        answer: answer,
        isNumeric: is_numeric,
      })
    )
  }
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      handleUpdateResult()
    }
  }
  useEffect(() => {
    if (subTaskResult?.answer === null) {
      setAnswer(null)
    }
  }, [subTaskResult])

  return (
    <WidgetWrapper p="15px" pos="relative" pointerEvents={isLoading ? 'none' : 'all'} opacity={isLoading ? 0.5 : 1}>
      <Box pos="absolute" top="10px" left={isRtl ? 'unset' : '15px'} right={isRtl ? '15px' : 'unset'}>
        <SkipSubtaskButton
          isRequired={subtask?.is_required}
          isSkipped={subTaskResult?.status === keys.TASK_STATUSES.SKIPPED.value}
          isResult={!!subTaskResult?.answer}
          subtaskType={subtask.type}
          tid={tid}
          st_id={subtask.st_id}
          resultContextId={resultContextId}
        />
      </Box>

      {isSubtaskDone && <StatusCircle color={statusCurcleColor} />}

      {isShowResetMenu && (
        <Box
          pos="absolute"
          top="3px"
          right={isRtl ? 'unset' : '25px'}
          left={isRtl ? '25px' : 'unset'}
          onClick={(e) => e.stopPropagation()}
        >
          <ResetMenu menuItemText={reset_answer} handleReset={handleResetResults} isLoading={isLoading} />
        </Box>
      )}

      <WidgetIcon subtaskType={type} />

      <SubtaskTitile mt="30px" title={title} />

      <Box pos="relative" pointerEvents={isReadMode ? 'none' : 'all'}>
        {is_numeric ? (
          <TaskInput
            value={answer ?? ''}
            onChange={handleChangeNumber}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onKeyDown={handleKeyDown}
            placeholder={t_your_number_answer}
            boxShadow="none"
            type="number"
            borderRadius="10px"
            p="10px"
            h="unset"
            tabIndex={-1}
          />
        ) : (
          <TextareaAutosize
            value={answer ?? ''}
            onChange={handleChangeText}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onKeyDown={handleKeyDown}
            minRows={isReadMode || !isFocused ? 1 : 2}
            placeholder={t_your_text_answer}
            tabIndex={-1}
            style={{
              transition: 'all 0.3s ease',
              padding: '10px',
              width: '100%',
              resize: 'none',
              outline: 'none',
              paddingLeft: isRtl ? '50px' : '10px',
              paddingRight: isRtl ? '10px' : '50px',
              background: 'transparent',
              borderRadius: '10px',
              border: '1px solid #E0E0E0',
            }}
          />
        )}

        {!isReadMode && isFocused && <TextAreaSubmitBtn onSubmit={handleUpdateResult} is_numeric={is_numeric} />}
      </Box>
    </WidgetWrapper>
  )
}

export default OpenQuesionSubtask
