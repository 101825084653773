import { IConfigRetailUser, IConfigStateGroups, Result } from 'constants/interfaces'
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import { getContextName } from 'hooks/useContextName'

export const exportAnswersToExcel = (
  data: Result[],
  groups: IConfigStateGroups,
  retailUsersObject: { [key: string]: IConfigRetailUser },
  fileName: string
): void => {
  const workbook = new ExcelJS.Workbook()
  const worksheet = workbook.addWorksheet('worksheet 1')

  worksheet.columns = [
    { header: 'Store Name', key: 'context_id', width: 50 },
    { header: 'Answer', key: 'answer', width: 100 },
  ]

  worksheet.getRow(1).font = { bold: true }
  worksheet.getRow(1).alignment = { horizontal: 'center' }
  worksheet.getRow(1).height = 20

  data.forEach((item) => {
    const row = worksheet.addRow({
      context_id: getContextName(item.context_id, groups, retailUsersObject!),
      answer: item.answer,
    })

    row.eachCell((cell, colNumber) => {
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'left',
        wrapText: true,
      }
      cell.font = {
        size: 12,
        name: 'Arial',
        bold: colNumber === 1,
      }

      if (colNumber === 1) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFF1F1F1' },
        }
      }

      cell.border = {
        top: { style: 'thin', color: { argb: 'FFB0B0B0' } },
        left: { style: 'thin', color: { argb: 'FFB0B0B0' } },
        bottom: { style: 'thin', color: { argb: 'FFB0B0B0' } },
        right: { style: 'thin', color: { argb: 'FFB0B0B0' } },
      }
    })
  })

  workbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(new Blob([buffer]), `${fileName}.xlsx`)
  })
}
