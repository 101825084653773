import React, { memo, useEffect, useRef, useState } from 'react'
import { Box, Flex, Input, Button, Text } from '@chakra-ui/core'

import { ReactComponent as IconFile } from 'assets/files_blue.svg'
import { ReactComponent as IconMedia } from 'assets/gallery_green.svg'
import { ReactComponent as IconMicrophone } from 'assets/voice_message_red.svg'

import colors from 'constants/colors'
import LocaleText from 'components/LocaleText'
import { RootState } from 'constants/interfaces'
import strings from 'constants/strings'
import { AudioRecorder } from 'components/AudioRecorder'
import { PreviewFile } from 'config/types'
import { useSelector } from 'react-redux'
import { splitString } from './utils'
import { HiOutlineLink } from 'react-icons/hi'
import { OptionType } from '../constants'

interface IProps {
  selectedOptionType: string
  fileInputRef?: React.RefObject<HTMLInputElement>
  imageInputRef?: React.RefObject<HTMLInputElement>
  formFileRef?: React.RefObject<HTMLFormElement>
  handleMediaUpload: (event: React.ChangeEvent<HTMLInputElement>) => void
  nextStepHandler?: (...args: any) => void
  attachedLink: string | null
  submitActionHandler: () => void
  isDisabledPublishButton: boolean
  handleFileUpload: (event: React.SyntheticEvent<HTMLInputElement>) => void
  onSuccessAudioRecording: (audio: PreviewFile) => void
  onOpenLinkAttachmentModal: () => void
  handleSingleMediaUploading: (e: React.ChangeEvent<HTMLInputElement>) => Promise<any>
}

const PostModalFooter = memo(
  ({
    selectedOptionType,
    fileInputRef,
    imageInputRef,
    handleMediaUpload,
    attachedLink,
    isDisabledPublishButton,
    handleFileUpload,
    formFileRef,
    onSuccessAudioRecording,
    submitActionHandler,
    onOpenLinkAttachmentModal,
    handleSingleMediaUploading,
  }: IProps) => {
    const theme = useSelector((state: RootState) => state.general.theme)
    const audioRef = useRef<any>(null)
    const imageInputButtonHandler = () => {
      imageInputRef?.current?.click()
    }
    const fileInputButtonHandler = () => {
      fileInputRef?.current?.click()
    }
    const [isRecording, setIsRecording] = useState(false)
    const startRecording = () => setIsRecording(true)
    const stopRecoding = () => {
      audioRef?.current?.stop()
      setIsRecording(false)
    }
    const splittedAttachedLink = attachedLink ? splitString(attachedLink) : null

    const attachedLinkTitle = splittedAttachedLink
      ? splittedAttachedLink.key === 'posts'
        ? 'linked_chats_title'
        : 'linked_tasks_title'
      : null

    useEffect(() => () => stopRecoding(), [])
    return (
      <Flex flexDirection="column" width="100%" h="130px" justifyContent="flex-end">
        {selectedOptionType === OptionType.POST && (
          <>
            {isRecording && (
              <Box my="5px">
                <AudioRecorder
                  ref={audioRef}
                  isRecording={isRecording}
                  onStopRecording={stopRecoding}
                  onSuccess={onSuccessAudioRecording}
                  showCheckAudioStep={false}
                />
              </Box>
            )}
            {!isRecording && (
              <Flex
                color={colors.greyMain}
                fontSize="14px"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                bg="white"
                position="relative"
                overflow="hidden"
                borderRadius="12px"
                py="10px"
                height="50px"
                mb="5px"
              >
                <Button display="flex" mx="3px" variant="unstyled" onClick={imageInputButtonHandler}>
                  <IconMedia />
                </Button>
                <Button display="flex" variant="unstyled" onClick={fileInputButtonHandler}>
                  <IconFile />
                </Button>
                <Button display="flex" variant="unstyled" onClick={startRecording} isLoading={false}>
                  <IconMicrophone />
                </Button>
                <Button
                  display="flex"
                  mx="3px"
                  minW="80px"
                  variant="unstyled"
                  onClick={() => onOpenLinkAttachmentModal()}
                  bg={attachedLinkTitle ? colors.greyLight4 : 'transparent'}
                >
                  {attachedLinkTitle ? (
                    <Text isTruncated p="5px">
                      <LocaleText text={attachedLinkTitle} />
                    </Text>
                  ) : (
                    <HiOutlineLink size="25px" />
                  )}
                </Button>
              </Flex>
            )}
            <form ref={formFileRef}>
              <Input
                ref={imageInputRef}
                accept={`${strings.SERVER_IMAGE_FORMATS},${strings.SERVER_VIDEO_FORMATS}`}
                type="file"
                multiple
                id="upload-image-button"
                style={{ display: 'none' }}
                onChange={handleMediaUpload}
              />
              <Input
                ref={fileInputRef}
                type="file"
                multiple
                id="upload-file-button"
                style={{ display: 'none' }}
                onChange={handleFileUpload}
                accept={strings.POST_FILE_ACCEPT_EXTENSIONS}
              />
            </form>
          </>
        )}

        {selectedOptionType === OptionType.POP && (
          <Flex justifyContent="center" my="10px">
            <Button display="flex" mx="3px" maxW="2.5rem" variant="unstyled" onClick={imageInputButtonHandler}>
              <IconMedia />
            </Button>
            <form ref={formFileRef}>
              <Input
                ref={imageInputRef}
                accept={`${strings.SERVER_IMAGE_FORMATS},${strings.SERVER_VIDEO_FORMATS}`}
                type="file"
                multiple
                id="upload-image-button"
                style={{ display: 'none' }}
                onChange={handleSingleMediaUploading}
              />
            </form>
          </Flex>
        )}

        <Button
          onClick={submitActionHandler}
          right="0"
          variant="ghost"
          borderRadius="20px"
          fontWeight={500}
          isDisabled={isDisabledPublishButton || isRecording}
          textTransform="uppercase"
          background={theme?.elementsColor}
          color="white"
          _hover={{
            boxShadow: '0 0 5px #ccc',
          }}
        >
          <LocaleText text="publish" />
        </Button>
      </Flex>
    )
  }
)

export default PostModalFooter
