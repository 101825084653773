import { Box, Flex, Skeleton, Text, useDisclosure } from '@chakra-ui/core'
import { AnimatedCircularProgress } from 'components/CommonComponents/AnimatedCircularProgress'
import { CustomSkeleton } from 'components/Elements'
import { DashboardOverviewMenu } from 'components/TasksInsights/DashboardOverviewMenu'
import { calculateTaskStats, TaskStatusesBlocks } from 'components/TasksOverview/TaskStatusesBlocks'
import { ContextsList } from 'components/TasksV2/Insights/Content/ContextsList'
import { SearchInput } from 'components/TasksV2/Insights/Content/ContextsList/SearchInput'
import { InsightsModal } from 'components/TasksV2/Insights/InsightsModal'
import { InsightsContentType } from 'components/TasksV2/Insights/InsightsStackProvider'
import { TasksStatusesMenu } from 'components/TasksV2/Insights/TasksStatusesMenu'
import { getSubtasksByType } from 'components/TasksV2/Insights/utils'
import { TaskOverviewModal } from 'components/TasksV2/TasksOverview/TaskOverviewModal'
import {
  ITaskOverviewContentStack,
  TaskOverviewContentType,
} from 'components/TasksV2/TasksOverview/TaskOverviewStackProvider'
import { DashboardMenuButton } from 'components/TasksV2/UI/DashboardMenuButton'
import useLocaleText from 'components/useLocaleText'
import { AppDispatch } from 'config/redux'
import colors from 'constants/colors'
import { RootState } from 'constants/interfaces'
import keys from 'constants/keys'
import { getContextName } from 'hooks/useContextName'
import { isEmpty } from 'lodash'
import { ChartNoAxesColumn, ChevronLeft, ChevronRight, Images } from 'lucide-react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { BsQuestionCircle } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getInsightsV2, getTasksInsights, setTasksDeepDiveInsights } from 'redux/actions/tasks_v2'
import { isRtlSelector } from 'redux/selectors/general'
import { getProgressPercentage } from 'utils'

const TaskInsights = () => {
  const dispatch: AppDispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const tid = searchParams.get('tid')
  const recurrenceTs = searchParams.get('recurrence_ts') ?? null
  const t_media_overview = useLocaleText('t_media_overview')
  const polls_overview = useLocaleText('polls_overview')
  const open_questions_overview = useLocaleText('open_questions_overview')

  const isRtl = useSelector(isRtlSelector)
  const {
    insights: allInsights,
    deepDiveInsights,
    taskFromInsights,
    isInsightsLoading,
  } = useSelector((state: RootState) => state.tasks_v2)
  const {
    config: { groups, retailUsersObject, mappedGroupsByUsers },
  } = useSelector((state: RootState) => state.config)

  const { isOpen: isTaskOverviewOpen, onOpen: onOpenTaskOverview, onClose: onCloseTaskOverview } = useDisclosure()
  const { isOpen: isMediaGalleryOpen, onOpen: onOpenMediaGallery, onClose: onCloseMediaGallery } = useDisclosure()

  const [taskOverviewContent, setTaskOverviewContent] = useState<ITaskOverviewContentStack | null>(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [selectedStatusValue, setSelectedStatusValue] = useState<number>(-1)

  const taskTitle = tid ? allInsights?.[tid]?.title : ''
  const tasksStatuses = useMemo(() => calculateTaskStats(deepDiveInsights?.contexts || {}), [deepDiveInsights])

  const sortedContexts = useMemo(() => {
    const retailUserKeys = new Set(Object.keys(retailUsersObject!))
    const groupKeys = new Set(Object.keys(groups))

    const getEffectiveStatus = (status: number) => {
      return status === -1 || status === -3 ? 2 : status
    }

    return Object.entries(deepDiveInsights.contexts)
      .reduce((acc, [contextId, value]) => {
        if (retailUserKeys.has(contextId) || groupKeys.has(contextId)) {
          acc.push({
            contextId,
            status: value.status,
            taskContextId: value.taskContextId,
            name: getContextName(contextId, groups, retailUsersObject!),
            userDefaultGroup: mappedGroupsByUsers?.[contextId]?.[0] ?? '',
          })
        }
        return acc
      }, [] as { contextId: string; status: number; taskContextId: string; name: string; userDefaultGroup: string }[])
      .sort((a, b) => getEffectiveStatus(b.status) - getEffectiveStatus(a.status))
  }, [deepDiveInsights.contexts, groups, mappedGroupsByUsers, retailUsersObject])

  const filteredContexts = useMemo(() => {
    if (!searchQuery && selectedStatusValue === -1) {
      return sortedContexts
    }

    const isMatchingSearchQuery = (name: string, userDefaultGroup: string) => {
      if (!searchQuery) return true

      const query = searchQuery.toLowerCase()
      return name.toLowerCase().includes(query) || userDefaultGroup?.toLowerCase().includes(query)
    }

    const isMatchingStatus = (status: number) => {
      if (selectedStatusValue === -1) return true

      if (selectedStatusValue === keys.TASK_STATUSES.DONE.value) {
        return status === selectedStatusValue || status === keys.TASK_STATUSES.SKIPPED.value
      }

      return status === selectedStatusValue
    }

    return sortedContexts.filter(
      ({ name, userDefaultGroup, status }) => isMatchingSearchQuery(name, userDefaultGroup) && isMatchingStatus(status)
    )
  }, [searchQuery, selectedStatusValue, sortedContexts])

  const handleOpenTaskOverview = useCallback(() => {
    setTaskOverviewContent(null)
    onOpenTaskOverview()
  }, [onOpenTaskOverview])

  const handleOpenPollOverview = useCallback(
    (contentProps: any) => {
      setTaskOverviewContent({ contentType: TaskOverviewContentType.VOTES_OVERVIEW, props: contentProps })
      onOpenTaskOverview()
    },
    [onOpenTaskOverview]
  )
  const handleOpenQuestionOverview = useCallback(
    (contentProps: any) => {
      setTaskOverviewContent({ contentType: TaskOverviewContentType.OPEN_QUESTION_OVERVIEW, props: contentProps })
      onOpenTaskOverview()
    },
    [onOpenTaskOverview]
  )

  const goBack = () => {
    dispatch(setTasksDeepDiveInsights({ contexts: {}, insights: {} }))
    navigate(keys.ROUTE_NAMES.DASHBOARD)
  }

  useEffect(() => {
    if (tid && !isEmpty(allInsights)) {
      dispatch(getTasksInsights({ tid, recurrence_ts: recurrenceTs ?? null }))
    }
  }, [allInsights, dispatch, recurrenceTs, tid])

  useEffect(() => {
    if (isEmpty(allInsights)) {
      dispatch(getInsightsV2({}))
    }
    // eslint-disable-next-line
  }, [])

  if (!tid) return null

  const { subtasks } = getSubtasksByType({
    insights: deepDiveInsights.insights,
    allInsights,
    subtaskTypes: [
      keys.SUBTASK_TYPES.IMAGE_SUBTASK,
      keys.SUBTASK_TYPES.VIDEO_SUBTASKS,
      keys.SUBTASK_TYPES.POLL_SUBTASK,
      keys.SUBTASK_TYPES.OPEN_QUESTION,
    ],
    tid,
  })
  const pollSubtasks = subtasks.filter((subtask) => subtask.type === keys.SUBTASK_TYPES.POLL_SUBTASK)
  const mediaSubtasks = subtasks.filter(
    (subtask) => subtask.type === keys.SUBTASK_TYPES.IMAGE_SUBTASK || subtask.type === keys.SUBTASK_TYPES.VIDEO_SUBTASKS
  )
  const questionSubtasks = subtasks.filter((subtask) => subtask.type === keys.SUBTASK_TYPES.OPEN_QUESTION)
  const isThereOverviewButtons = pollSubtasks.length > 0 || mediaSubtasks.length > 0 || questionSubtasks.length > 0

  return (
    <Flex position="relative" width="100%" height="calc(100vh - 90px)" pt="2.5rem" background="#fff">
      <Flex pl={isRtl ? 0 : '25px'} pr={isRtl ? '25px' : 0} flexDir="column" w={{ base: '100%', lg: '70%' }}>
        <Flex justifyContent="space-between" alignItems="center">
          <Flex alignItems="center" style={{ columnGap: '10px' }}>
            <Box cursor="pointer" onClick={goBack}>
              {isRtl ? (
                <ChevronRight color={colors.greyMain} style={{ padding: '0' }} />
              ) : (
                <ChevronLeft color={colors.greyMain} style={{ padding: '0' }} />
              )}
            </Box>
            <Text>{taskTitle}</Text>
          </Flex>

          <TasksStatusesMenu
            selectedStatusValue={selectedStatusValue}
            setSelectedStatusValue={setSelectedStatusValue}
          />
          <SearchInput
            onChange={(e) => setSearchQuery(e.target.value)}
            value={searchQuery}
            border="1px solid #E5E5E5"
          />
        </Flex>

        <Flex position="relative" flexDir="column" alignItems="center">
          <TaskStatusesBlocks deepDiveInsights={deepDiveInsights} isShowIcons />
          <Flex alignItems="center" style={{ columnGap: '20px' }}>
            <DashboardOverviewMenu
              tid={tid}
              handleOpenOverview={handleOpenQuestionOverview}
              recurrenceTs={recurrenceTs}
              subtasks={questionSubtasks}
              btnText={open_questions_overview}
              icon={<BsQuestionCircle size={18} />}
            />
            <DashboardOverviewMenu
              tid={tid}
              subtasks={pollSubtasks}
              recurrenceTs={recurrenceTs}
              handleOpenOverview={handleOpenPollOverview}
              btnText={polls_overview}
              icon={<ChartNoAxesColumn size={18} />}
            />
            {isInsightsLoading ? (
              <Skeleton height="40px" width="200px" mb="15px" borderRadius="30px" />
            ) : (
              mediaSubtasks.length > 0 && (
                <DashboardMenuButton
                  isLoading={isMediaGalleryOpen}
                  isDataFetching={false}
                  onClick={onOpenMediaGallery}
                  icon={<Images size={18} />}
                  btnText={t_media_overview}
                />
              )
            )}
          </Flex>
        </Flex>
        {isInsightsLoading || isEmpty(allInsights) || isEmpty(deepDiveInsights) ? (
          <Box>
            {Array.from({ length: 8 }).map((_, index) => (
              <CustomSkeleton key={index} mb="3" height="111px" width="100%" borderRadius="15px" />
            ))}
          </Box>
        ) : (
          <>
            {filteredContexts.length > 0 && (
              <ContextsList
                onOpen={handleOpenTaskOverview}
                recurrenceTs={recurrenceTs}
                tid={tid}
                contexts={filteredContexts}
                isThereOverviewButtons={isThereOverviewButtons}
              />
            )}
          </>
        )}
      </Flex>
      <Flex
        justifyContent="center"
        alignItems="center"
        display={{ base: 'none', lg: 'flex' }}
        w={{ base: '100%', lg: '30%' }}
      >
        <AnimatedCircularProgress
          isLoading={isInsightsLoading}
          itemsCount={getProgressPercentage(tasksStatuses.totalTasks, tasksStatuses.statuses.done)}
          symbol="%"
          thinkness={0.02}
        />
      </Flex>

      {isTaskOverviewOpen && taskFromInsights && (
        <TaskOverviewModal
          onClose={onCloseTaskOverview}
          task={taskFromInsights}
          isComeFromDashboard
          {...(taskOverviewContent !== null && { content: taskOverviewContent })}
        />
      )}
      {isMediaGalleryOpen && tid && (
        <InsightsModal
          onClose={onCloseMediaGallery}
          tid={tid}
          selectedRecurrenceTs={recurrenceTs}
          content={{ contentType: InsightsContentType.ALL_CONTEXTS_MEDIA_RESULTS_PREVIEW }}
        />
      )}
    </Flex>
  )
}

export default TaskInsights
