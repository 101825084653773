import { Box, Button, Divider, Flex, Portal, Text } from '@chakra-ui/core'
import { RootState } from 'constants/interfaces'
import React from 'react'
import { useSelector } from 'react-redux'

interface Props {
  onCancel: VoidFunction
  onConfirm: VoidFunction
  isOpen: boolean
  isLoading: boolean
  title?: string
  description?: string
  cancelButtonText?: string
  confirmButtonText?: string
}
const ConfirmationPopup: React.FC<Props> = ({
  isOpen,
  onCancel,
  onConfirm,
  isLoading,
  title,
  description,
  cancelButtonText,
  confirmButtonText,
}) => {
  const theme = useSelector((state: RootState) => state?.general?.theme)

  if (!isOpen) {
    return null
  }

  return (
    <Portal>
      <Box
        position="fixed"
        top="0"
        left="0"
        right="0"
        bottom="0"
        bg="rgba(0, 0, 0, 0.5)"
        zIndex={9999}
        pointerEvents="auto"
      />
      <Flex
        position="fixed"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        bg="white"
        borderRadius="12px"
        flexDirection="column"
        alignItems="flex-start"
        p="24px"
        zIndex={10000}
        boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)"
        maxWidth="600px"
        width="100%"
        pointerEvents="auto"
      >
        <Text fontWeight="bold" fontSize="20px" mb="10px">
          {title}
        </Text>
        <Divider />
        <Text fontSize="14px" color="gray.600" mb="20px">
          {description}
        </Text>
        <Flex justifyContent="flex-end" w="100%" style={{ columnGap: '10px' }}>
          <Button
            onClick={onCancel}
            variant="outline"
            isDisabled={isLoading}
            isLoading={isLoading}
            borderColor={theme?.elementsColor}
            color={theme?.elementsColor}
            borderRadius="8px"
            _hover={{ opacity: 0.6 }}
          >
            {cancelButtonText}
          </Button>
          <Button
            onClick={onConfirm}
            bg={theme?.elementsColor}
            color="white"
            borderRadius="8px"
            _hover={{ opacity: 0.6 }}
          >
            {confirmButtonText}
          </Button>
        </Flex>
      </Flex>
    </Portal>
  )
}

export default ConfirmationPopup
