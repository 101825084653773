// hooks/useSubtasksMediaOverview.ts
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from 'config/redux'
import { RootState } from 'constants/interfaces'
import { getSubtasksResultsOverview } from 'redux/actions/tasks_v2'
import keys from 'constants/keys'
import { ICurrentTaskInsight } from '../Insights/InsightsStackProvider'

export const useSubtasksMediaOverview = (
  task: ICurrentTaskInsight,
  contextIds: string[],
  recurrenceTs: string | null,
  subtaskIds: string[]
) => {
  const { tid, recurrence_frequency, recurrence_interval } = task

  const dispatch: AppDispatch = useDispatch()

  const { allResults } = useSelector((state: RootState) => state.tasks_v2)

  const mediaResults = useMemo(() => {
    return (
      allResults[tid]?.filter(
        (result) =>
          result.sub_task_type === keys.SUBTASK_TYPES.IMAGE_SUBTASK ||
          result.sub_task_type === keys.SUBTASK_TYPES.VIDEO_SUBTASKS
      ) ?? []
    )
  }, [allResults, tid])

  const [displayedContexts, setDisplayedContexts] = useState<string[]>([])
  const [currentPage, setCurrentPage] = useState(0)
  const pageSize = 10

  const [loadingContexts, setLoadingContexts] = useState<{ [contextId: string]: boolean }>({})

  useEffect(() => {
    const contextsToLoad = contextIds.slice(0, pageSize)

    if (contextsToLoad.length === 0) return

    setDisplayedContexts(contextsToLoad)

    setLoadingContexts((prev) => {
      const newLoadingContexts = { ...prev }
      contextsToLoad.forEach((contextId) => {
        newLoadingContexts[contextId] = true
      })
      return newLoadingContexts
    })

    dispatch(
      getSubtasksResultsOverview({
        tid,
        recurrence_interval,
        sub_tasks_ids: subtaskIds,
        recurrence_ts: recurrenceTs,
        recurrence_frequency,
        contextIds: contextsToLoad,
      })
    ).then(() => {
      setLoadingContexts((prev) => {
        const newLoadingContexts = { ...prev }
        contextsToLoad.forEach((contextId) => {
          newLoadingContexts[contextId] = false
        })
        return newLoadingContexts
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadMoreData = () => {
    const nextPage = currentPage + 1
    const offset = nextPage * pageSize
    const contextsToLoad = contextIds.slice(offset, offset + pageSize)

    if (contextsToLoad.length === 0) {
      return
    }

    const contextsToFetch = contextsToLoad.filter(
      (contextId) => !mediaResults.some((result) => result.context_id === contextId)
    )

    if (contextsToFetch.length === 0) {
      setDisplayedContexts((prevContexts) => [...prevContexts, ...contextsToLoad])
      setCurrentPage(nextPage)
      return
    }

    setDisplayedContexts((prevContexts) => [...prevContexts, ...contextsToLoad])
    setCurrentPage(nextPage)

    setLoadingContexts((prev) => {
      const newLoadingContexts = { ...prev }
      contextsToFetch.forEach((contextId) => {
        newLoadingContexts[contextId] = true
      })
      return newLoadingContexts
    })

    dispatch(
      getSubtasksResultsOverview({
        tid,
        recurrence_interval,
        sub_tasks_ids: subtaskIds,
        recurrence_ts: recurrenceTs,
        recurrence_frequency,
        contextIds: contextsToFetch,
      })
    ).then(() => {
      setLoadingContexts((prev) => {
        const newLoadingContexts = { ...prev }
        contextsToFetch.forEach((contextId) => {
          newLoadingContexts[contextId] = false
        })
        return newLoadingContexts
      })
    })
  }

  const hasMore = displayedContexts.length < contextIds.length

  return {
    displayedContexts,
    mediaResults,
    loadMoreData,
    hasMore,
    loadingContexts,
  }
}
