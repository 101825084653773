import React, { useState, useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useInsightsContext } from '../../InsightsStackProvider'
import { MediaSubtaskCompleting } from 'components/TasksV2/TasksOverview/Content/MediaSubtasksCompleting/MediaSubtaskCompleting'
import { SubtaskDeepDiveItem } from 'redux/actions/tasks_v2'
import useLocaleText from 'components/useLocaleText'
import { Text } from '@chakra-ui/core'
import { useResultsData } from 'components/TasksV2/hooks/useResultsData'

export interface SubtaskWithUuid extends SubtaskDeepDiveItem {
  uuid: string
}

interface IProps {
  contextId: string
  tid: string
  subtasks: SubtaskDeepDiveItem[]
}

const MediaResultsOverview: React.FC<IProps> = ({ contextId, tid, subtasks }) => {
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0)
  const t_no_media = useLocaleText('no_media')

  const { images, videos, mediaIndexToStId, getSubtask, results } = useResultsData(tid, contextId, subtasks)

  const { recurrenceTs, resultContextId, retailIdContextId, isReadMode } = useInsightsContext()

  const media = useMemo(() => [...images, ...videos], [images, videos])

  const currentStId = mediaIndexToStId[currentMediaIndex]

  const currentSubtask = useMemo(() => {
    if (!currentStId) return null

    const subtask = getSubtask(currentStId)

    if (!subtask) return null

    return { ...subtask, uuid: uuidv4() } as SubtaskWithUuid
  }, [currentStId, getSubtask])

  const onMediaChange = (index: number) => {
    if (index >= 0 && index < media.length) {
      setCurrentMediaIndex(index)
    }
  }

  const result = results.find((result) => result.st_id === currentStId)

  if (!currentSubtask || (!videos?.length && !images.length)) {
    return (
      <Text fontSize="20px" textAlign="center">
        {t_no_media}
      </Text>
    )
  }

  return (
    <MediaSubtaskCompleting
      subtask={currentSubtask}
      images={images}
      videos={videos}
      updatedBy={result?.updated_by ?? ''}
      retail_id_context_id={retailIdContextId!}
      recurrence_ts={recurrenceTs}
      resultContextId={resultContextId!}
      isReadMode={isReadMode}
      onMediaChange={onMediaChange}
      mediaType={currentSubtask.type}
      currentMediaIndex={currentMediaIndex}
      isShowSubtaskTitle={true}
      tid={tid}
    />
  )
}

export default MediaResultsOverview
