// eslint-disable-next-nine @typescript-eslint/no-use-before-define
import React from 'react'
import { Box, Flex, Divider, Text } from '@chakra-ui/core'
import styled from '@emotion/styled'
import colors from '../../constants/colors'
import NavMenu, { IconBlock } from './NavMenu'
import { NavItem } from './NavMenu'
import { useSelector } from 'react-redux'
import keys from 'constants/keys'
import { RootState } from 'constants/interfaces'
import LocaleText from 'components/LocaleText'
import { ReactComponent as Settings } from 'assets/icon-admin-settings.svg'

const StyledSidebar = styled(Box)<{ isHebrew: boolean }>`
  width: 20%;
  position: sticky;
  top: 0;
  z-index: 3;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100vh;
`

const SidebarContent = styled(Flex)`
  top: 4rem;
  position: relative;
`

const Sidebar = () => {
  const { locale } = useSelector((state: RootState) => state.general)
  const { permission } = useSelector((state: RootState) => state.config.config)
  const isHebrew = locale === 'he'

  return (
    <StyledSidebar as="aside" bg={colors.greyMedium} p="20px" isHebrew={isHebrew}>
      <SidebarContent justifyContent="space-between" flexDirection="column" h="calc(100vh - 90px)">
        <Flex flexDir="column">
          <NavMenu />
          <Divider mb="20px" />
        </Flex>
        {permission?.users === 3 && (
          <NavItem to={`${keys.ROUTE_NAMES.ADMIN_SETTINGS}/users`}>
            <IconBlock>
              <Settings style={{ fill: colors.blackLight }} />
            </IconBlock>
            <Text mx="5" textTransform="capitalize">
              <LocaleText text="admin_console" />
            </Text>
          </NavItem>
        )}
      </SidebarContent>
    </StyledSidebar>
  )
}

export default Sidebar
