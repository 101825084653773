export default {
  DEFAULT_LOCALE: 'en',
  DEFAULT_ADMIN_USER_NAME: 'STOREE',
  LOCALE_MAP: {
    en: 'english',
    he: 'hebrew',
    es: 'spanish',
    fr: 'french',
    pt: 'portuguese',
    it: 'italian',
  },
  DEEP_LINK: 'deep_link',
  USER_ID: 'uid',
  PRODUCT_ID: 'pid',
  DATA: 'data',
  SUB_TASKS_ORDER: 'sub_tasks_order',
  READABLE_DATE_FORMAT: 'DD/MM/YYYY HH:mm',
  DEFAULT_STRING_FORMAT_TIMESTAMP: 'YYYYMMDDHHmmss',
  USER_ROLES: {
    0: 'store_manager',
    1: 'district_manager',
    2: 'chain_store_manager',
  },

  RESPONSE_ERROR_STATUSES: {
    NO_AUTHORIZATION: 'unauthorized',
    NO_PERMISSION: 'no_permission',
    INTERNAL_SERVER_ERROR: 'internal_server_error',
    NOT_FOUND: 'not_found',
  },

  USER_GROUP_ROLES: {
    WORKER: 0,
    MANAGER: 1,
    AVATAR_MANAGER: 2,
  },

  USER_ACTIONS_MAP: {
    VIEW: 1,
    EDIT: 2,
    APP_ADMIN: 3,
  },

  IMPROVING_TASKS_COLORS: ['#FFE3B3', ' #F8D3E9', '#D1D8FA', '#FED9CA', ' #F9B8BB', ' #F1E3FC'],
  STATUS_COLORS: {
    0: '#2DCE85',
    1: '#4659CE',
    2: '#E9586A',
    3: '#D3208B',
    4: '#FDA000',
    5: '#879AF2',
  },
  STATUS_COLORS_LENGTH: 6,
  ROUTE_NAMES: {
    NEWS_FEED: '/',
    NEWS_FEED_SEARCH: '/search',
    REPORTS: '/reports',
    CUSTOM_REPORTS: '/custom-reports',
    OPERATIONAL_TASKS: '/operational-tasks',
    AUTO_TASKS: '/auto-tasks',
    TRAINING_TASKS: '/training-tasks',
    HISTORY_TASKS: '/history-tasks',
    CREATE_TASK: '/create-task',
    OPEN_GROUPTREE: '/open_group_tree',
    DASHBOARD: '/dashboard',
    DASHBOARD_DETAIL: (refId: string = ':refId', groupId: string = ':groupId') => `/dashboard/${refId}/${groupId}`,
    DASHBOARD_SUBTASKS: (refId: string = ':refId', groupId: string = ':groupId', category: string = ':category') => {
      return `/dashboard/${refId}/${groupId}/${category}`
    },
    CHANGE_PASSWORD: '/change-password',
    LOGIN_CONFIRM: '/login-confirm',
    LOGIN_PAGE: '/login',
    LOGIN_OTP: '/login-otp',
    LOGIN_CODE: '/code',
    FORGOT_PASSWORD: '/forgot-password',
    FORGOT_PASSWORD_SUBMIT: '/forgot-password-submit',
    LOGIN_SSO: '/login-sso',
    HUB: '/hub',
    SUBTASKS: (viewId: string = ':viewId', tid: string = ':tid') => `/hub/${viewId}/${tid}`,
    RANKING: (index: string = ':index') => `/ranking/${index}`,
    INTEGRATIONS: '/integrations',
    IMPROVING_SALES: '/improving-sales',
    IMPROVING_SALES_DETAIL: (tid: string = ':tid') => `/improving-sales/${tid}`,
    // ADMIN_SETTINGS: (option: string = ':page') => `/admin-settings/${option}`,
    ADMIN_SETTINGS: '/admin-settings',
    USERS_TABLE: '/admin-settings/users',
    ADD_USER: '/admin-settings/users/add-user',
    EDIT_USER: (uid: string = ':uid') => `/admin-settings/users/edit-user/${uid}`,
    EXTERNAL_SITE: '/jotform',
    CHAT_PAGE: '/chat',
    GROUP_CHAT: (chatId: string = ':id') => `/group-chat/${chatId}`,
    GROUP_CHAT_ANNOUNCEMENT: (chatId: string = ':id', commentId: string = ':commentId') =>
      `/group-chat/${chatId}/${commentId}`,
    TASKS_CONTEXTS_LIST: 'dashboard/tasks-contexts-list',
    SERVICE_CALLS_DASHBOARD: '/dashboard-service-calls',
  },

  DEFAULT_SUBTASK_DONE_STATUS: -1,
  TASK_TYPES: {
    DATA_TASK: '0',
    MANUAL_TASK: '1',
    TRAINING_TASK: '2',
    HISTORY_TASK: '3',
  },
  SUBTASK_TYPES: {
    TEXT_SUBTASK: 0,
    PRODUCT_SUBTASK: 1,
    IMAGE_SUBTASK: 2,
    LINK_SUBTASK: 3,
    VIDEO_SUBTASKS: 4,
    UPLOAD_FILE_SUBTASK: 5,
    POLL_SUBTASK: 6,
    OPEN_QUESTION: 8,
    DESCRIPTION: -1,
  },
  SUBTASK_V2_TYPES: {
    POLL_SUBTASK: 6,
    IMAGE_SUBTASK: 2,
  },
  TASK_V2_VIEWS: {
    POLL: 4,
  },
  TASK_V2_TYPES: {
    TASK: 0,
    POLL: 3,
  },
  TASK_TYPE: {
    TASK: 0,
    REPORT: 1,
    EXTERNAL_LINK: 2,
  },
  POST_TYPE: {
    GROUP_POST: 2,
    GROUP_POST_ANNOUNCEMENT: 3,
    LINKED: 1,
    REGULAR: 0,
    POLL: 4,
  },
  COMMENT_OBJECT_TYPE: {
    COMMNET_ON_POST: 1,
    COMMENT_ON_SUBTASK: 2,
    CREATE_POST: 3,
    COMMENT_REPLY: 4,
    COMMENT_SUBTASK_REPLY: 5,
    MESSAGE_ON_GROUP: 18,
    MESSAGE_ON_ANNOUNCEMENT_GROUP: 19,
    COMMENT_ON_ANNOUNCEMENT_GROUP_MESSAGE: 20,
    COMMENT_ON_IMAGE: 21,
  },

  CREATE_TASK_CONSTANTS: {
    MAX_TASK_NAME_LENGTH: 36,
    MAX_CATEGORY_NAME_LENGTH: 23,
  },
  TASK_STATUSES: {
    TODO: {
      value: 0,
      localeLiteral: 'task_status_todo',
    },
    INPROGRESS: {
      value: 1,
      localeLiteral: 'task_status_inprogress',
    },
    DONE: {
      value: 2,
      localeLiteral: 'task_status_done',
    },
    SKIPPED: {
      value: -3,
      localeLiteral: 'task_status_skipped',
    },
  },
  inactionsTime: {
    firstStage: 900000,
    secondStage: 1200000,
  },
  amplifyChallenges: {
    changePassword: 'NEW_PASSWORD_REQUIRED',
    smsMfa: 'SMS_MFA',
    tokenMfa: 'SOFTWARE_TOKEN_MFA',
  },
  MAX_FILE_SIZES: {
    docx: 3000000,
    doc: 3000000,
    jpeg: 8000000,
    jpg: 8000000,
    m4v: 64000000,
    mov: 64000000,
    mp4: 64000000,
    pdf: 3000000,
    png: 8000000,
    xls: 3000000,
    xlsx: 3000000,
  },
  TASK_STATUS: {
    0: { color: '#AAB0BC', title: 'analytical_todo', value: 0 },
    1: { color: '#FFE180', title: 'analytical_inprogress', value: 1 },
    2: { color: '#98E6C3', title: 'analytical_done', value: 2 },
  },
  EXTERNAL_LINK_PHRASE: 'external',
  FORMS_STOREE: 'forms.storee.ai',
  extensionsForCloudViewer: ['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx'],
  TASKS_PAGES: [
    { id: 1, literal: 'data_task_page', ref_id: 'tasks-0' },
    { id: 2, literal: 'ops_task_page', ref_id: 'tasks-1' },
    { id: 3, literal: 'training_task_page', ref_id: 'tasks-2' },
    { id: 4, literal: 'vm_task_page', ref_id: 'tasks-3' },
  ],
  TASK_KEY: {
    0: 'autoTasks',
    1: 'manualTasks',
    2: 'trainingTasks',
    3: 'historyTasks',
  },
  TASK_STATUS_KEY: 'taskStatus',
  COMPANY_NAME: 'STOREE',
  RANKING_DEFAULT_FILTER_VIEW: 0,

  REPORT_DATE_NAMES: {
    MTD: 1,
    WTD: 2,
    YTD: 0,
    YESTERDAY: 3,
    LAST_MONTH: 5,
    LAST_WEEK: 6,
    CUSTOM_DATE: 4,
  },
  REPORTS_DATE_OPTIONS: [
    { type: 1, name: 'time_4' },
    { type: 2, name: 'time_3' },
    { type: 0, name: 'time_5' },
    { type: 3, name: 'time_2' },
    { type: 5, name: 'time_6' },
    { type: 6, name: 'time_7' },
    { type: 4, name: 'search_tasks_between_dates' },
  ],
  COMPETITION_KPIS_ICON: 'default/icons/ranking/ranking_competition_target.svg',
  COMPETITION_KPIS_MEDIA_DATA: [
    {
      podium: 'default/images/contest_podium_a.png',
      color: '#D7545F',
    },
    {
      podium: 'default/images/contest_podium_b.png',
      color: '#D7BB5E',
    },
    {
      podium: 'default/images/contest_podium_c.png',
      color: '#4E82E6',
    },
  ],
  SELECT_MODES: {
    SINGLE: 'single',
    MULTIPLE: 'multiple',
  },
  AUTH_CONTEXT: 1,
  POP_TYPE: {
    POP: 1,
    RE_POP: 2,
    GPT_POP: 3,
  },
  POP_TITLE_MAX_LENGTH: 20,
}
